import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step43-1.png'


export default class step43 extends React.Component{

    render() {
        return (
            <div className="step43">
                <div className="head">
                    Рекомендации для повышения активности кошки
                </div>
                <ul className="list">
                    <li className="list_item">
                        Положить на пол бумажный пакет или картонную коробку, а когда кошка войдет внутрь, поцарапать снаружи каким-нибудь предметом.
                    </li>
                    <li className="list_item">
                        Спрятать игрушку под подушку или одеяло. Просунуть руку под одеяло и слегка потеребить игрушку.
                    </li>
                    <li className="list_item">
                        Многие кошки будут гоняться за лазерной указкой часами напролет.
                    </li>
                    <li className="list_item">
                        Можно попробовать поиграть с кошкой в прятки. Если кошка наслаждается игровым процессом, она сначала будет прятаться, а затем преследовать человека!
                    </li>
                    <li className="list_item">
                        Необходимо всегда заканчивать игру на спокойной ноте.
                    </li>
                    <li className="list_item">
                        Не оставлять кошке ее любимые игрушки на целый день. Лучше оставить кошке несколько игрушек для спонтанных игр, а самые любимые игрушки приносите ей один раз в день или даже раз в неделю.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
