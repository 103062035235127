import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step23-1.png";

export default class step23 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Некоторые кошки очень боятся звука грома и вида молний. Следует убедиться, что  кошка имеет необходимый доступ к своему убежищу, если снаружи бушует ураган или сильный ливень. Включите радиоприемник или телевизор, чтобы заглушить шум бури. Обеспечьте укрытие, которое позволит кошке минимизировать последствия нежелательного шума. Например, оставьте шкаф приоткрытым, чтобы кошка могла спрятаться внутри. Если владелец находится во время грозы дома, следует проводить время вместе с кошкой. Попробуйте вовлечь кошку в игру, чтобы отвлечь ее на время непогоды. Можно дать ей любимые лакомства и вознаградить похвалой, чтобы создать у кошки более приятные ассоциации с бурей или ливнем.
                        </li>
                        <li>
                            Кошки, которые становятся очень возбужденными или агрессивными во время штормов, могут нуждаться в обеспечении им небольшого пространства, где они могут переждать непогоду. Например, можно посадить кошку в переноску или ванную комнату. Если  кошка демонстрирует признаки тревоги или беспокойства во время непогоды, возможно применение медикаментов.
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
