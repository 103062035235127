import React from 'react'
import SliderImgDes from "../../../Components/LessonAndTestComponents/SliderImgDes";

import img1 from "../../../image/lessons/lesson8/step10-1.png";
import img2 from "../../../image/lessons/lesson8/step10-2.png";
// import img3 from "../../../image/lessons/lesson8/step10-3.png";

export default class step10 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    sliderData = {
        1: {
            text: `На фотографиях крупным планом видно, как мордочка кошки становится все более испуганной. Когда появятся эти признаки, примите соответствующие меры, чтобы уменьшить тревогу кошки.`,
            img: img1,
        },
        2: {
            text: `Расширяющиеся зрачки (мидриаз) и умеренное вращение / сплющивание ушей указывают на усиление страха .`,
            img: img2
        },
        3: {
            text: `Слегка суженные или продолговатые зрачки, горизонтальные и обращенные вперед уши и более напряженная челюсть демонстрируют нарастающее опасение и страх .`,
            img: ''
        },
    }

    render() {
        return (
            <div className="step9">
                <div className="head">
                    Изменение мимики
                </div>
                <SliderImgDes
                    showNextBtn={this.showNextBtn}
                    sliderData={this.sliderData}
                />
            </div>
        )
    }
}
