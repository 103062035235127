import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson31/step9-1.png'

export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <div className="head">
                    Классификация по состоянию здоровья
                </div>
                <TextBlock
                    text={`Классификация кормов по состоянию здоровья животного выглядит следующим образом:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для здоровых животных
                        </div>
                        <div className="flex_el_wrp">
                            <p className="flex_el_text">
                                Обеспечивают организм здорового животного <b>всеми питательными веществами</b> и поддерживают здоровье.
                            </p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Профилактические корма
                        </div>
                        <div className="flex_el_wrp">
                            <p className="flex_el_text">
                                <b>Cнижают риск</b> возникновения проблем со здоровьем, к которым <b>предрасположено</b> животное, например, существуют специальные корма для кошек с чувствительным пищеварением (не является лечебным) и др.
                            </p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Лечебные корма
                        </div>
                        <div className="flex_el_wrp">
                            <ul className='list'>
                                <li className='list_item'>Предназначены для больных животных с <b>определенными диагнозами;</b></li>
                                <li className='list_item'>Имеют <b>особый состав;</b></li>
                                <li className='list_item'>Используются как <b>часть комплексного лечения</b> животных.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
