import React from 'react'

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <ul className="list">
                    <li className="list_item">
                        Кошка может воспринимать чей-то <b>взгляд прямо в ее глаза как угрозу</b>. Медленное моргание передаст чувство доверия и дружелюбия осматриваемой кошке.
                    </li>
                    <li className="list_item">
                        Если кошка становится напряженной или возбужденной, временно прекратите обследование <b>и дайте ей расслабиться</b>. При поспешных действиях вы можете не обратить должного внимания на этот простой подход.
                    </li>
                    <li className="list_item">
                        Самое главное, что нужно помнить при осмотре кошки: <b>вы не должны переборщить с насильственным сдерживанием или коррекцией резистентного поведения.</b> Кошки, которые демонстрируют незначительные проявления тревоги, такие как беспокойство, могут лучше всего реагировать на осматривающего их ветеринара, если врач продолжает осмотр в нейтральной, обдуманной манере.
                    </li>
                    <li className="list_item">
                        Всегда ищите возможность <b>вознаградить позитивное поведение животного</b> лакомством или лаской. Это побуждает кошку расслабиться. Позитивное поведение может быть незаметным, например, изменение выражения лица или ослабление напряжения тела.
                    </li>
                </ul>
            </div>
        )
    }
}
