import React from 'react';
import './style.sass';
import PageHeader from '../../Components/PageHeader'
// import Buttons from '../../Components/Buttons'
import TextBlock from '../../Components/LessonAndTestComponents/TextBlock'
import {Link} from "react-router-dom";

import img1 from '../../image/comfort-logo.svg';


export default class OrganizersInformation extends React.Component {

    render() {
        return (
            <div className="AboutProject">
                <PageHeader
                    svg={
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.5391 17.2844C32.4337 14.201 31.6433 11.6365 30.1822 9.62472L33.5952 1.46436C33.9566 0.600157 33.0918 -0.271782 32.2248 0.0805098C26.1807 2.54163 26.5527 2.39044 26.5323 2.39888C24.8251 3.14847 21.4853 4.61248 21.1302 4.76929C19.1769 4.50982 16.8777 4.50279 14.8702 4.76929C14.648 4.67085 11.1598 3.14144 9.46809 2.39888C9.4463 2.38904 9.81966 2.54163 3.77557 0.0805098C2.90651 -0.273188 2.04447 0.602267 2.40518 1.46436L5.81816 9.62402C4.35706 11.6358 3.56674 14.201 3.46127 17.2837C-1.98797 22.1898 -0.767337 28.7054 5.46941 32.6685C12.4571 37.1091 23.5398 37.1119 30.531 32.6685C36.8191 28.6738 37.9483 22.1539 32.5391 17.2844ZM30.6709 2.99096L28.6164 7.90265C27.4344 6.85632 26.0654 6.09829 24.5572 5.5688C28.1186 4.00705 26.6757 4.61811 30.6709 2.99096ZM11.4425 5.5688C9.93497 6.09829 8.56598 6.85562 7.38332 7.90265L5.32948 2.99096C9.32044 4.616 7.88465 4.00845 11.4425 5.5688ZM4.87456 29.5949L9.97927 27.4067C10.5143 27.1774 10.7626 26.5572 10.5333 26.0221C10.3041 25.487 9.68395 25.2388 9.14887 25.468L3.38182 27.9397C2.18299 26.238 1.74283 24.2445 2.45439 22.2531L9.45403 22.9865C10.032 23.047 10.5516 22.6286 10.6128 22.0477C10.6733 21.4683 10.2528 20.9494 9.67411 20.8889L3.57096 20.249C4.07651 19.5901 4.65026 19.0304 5.18464 18.5797C5.41948 18.3821 5.55589 18.0917 5.55941 17.7851C5.66206 8.60793 12.3812 6.68193 17.9998 6.68193C23.6185 6.68193 30.3383 8.60793 30.441 17.7851C30.4445 18.0917 30.5816 18.3821 30.8158 18.5797C31.3501 19.0304 31.9239 19.5901 32.4294 20.249L26.3263 20.8889C25.7469 20.9494 25.3264 21.4683 25.3876 22.0477C25.4481 22.6279 25.9677 23.047 26.5464 22.9865L33.546 22.2531C34.2576 24.2452 33.8167 26.2387 32.6186 27.9397L26.8522 25.468C26.3171 25.2388 25.697 25.4863 25.4678 26.0221C25.2385 26.5572 25.486 27.1774 26.0218 27.4067L31.1265 29.5949C24.6915 35.3364 11.2723 35.3033 4.87456 29.5949Z" fill="#4147DA"/>
                            <path d="M13.4309 17.0531V15.8021C13.4309 15.2199 12.9591 14.7474 12.3762 14.7474C11.7933 14.7474 11.3215 15.2192 11.3215 15.8021V17.0531C11.3215 17.6353 11.7933 18.1078 12.3762 18.1078C12.9591 18.1078 13.4309 17.6353 13.4309 17.0531Z" fill="#4147DA"/>
                            <path d="M23.6242 14.7474C23.042 14.7474 22.5695 15.2192 22.5695 15.8021V17.0531C22.5695 17.6353 23.0413 18.1078 23.6242 18.1078C24.2071 18.1078 24.6789 17.636 24.6789 17.0531V15.8021C24.6789 15.2192 24.2064 14.7474 23.6242 14.7474Z" fill="#4147DA"/>
                            <path d="M21.9367 25.9455C21.3545 25.9455 20.882 26.4173 20.882 27.0002C20.882 27.5044 20.472 27.9143 19.9679 27.9143C19.4638 27.9143 19.0538 27.5044 19.0538 27.0002V24.6797L20.9403 23.0948C22.9689 21.3903 21.8945 18.072 19.1241 18.072H16.8678C14.1144 18.072 13.0245 21.3861 15.0587 23.0948L16.9452 24.6797V27.0002C16.9452 27.5044 16.5352 27.9143 16.0311 27.9143C15.5269 27.9143 15.117 27.5044 15.117 27.0002C15.117 26.418 14.6452 25.9455 14.0623 25.9455C13.4794 25.9455 13.0076 26.4173 13.0076 27.0002C13.0076 28.6674 14.364 30.0232 16.0304 30.0232C16.782 30.0232 17.4697 29.7468 17.9984 29.2919C18.5279 29.7475 19.2156 30.0232 19.9665 30.0232C21.6336 30.0232 22.9892 28.6667 22.9892 27.0002C22.9914 26.4173 22.5196 25.9455 21.9367 25.9455ZM16.164 20.6259C16.2244 20.4593 16.3974 20.1808 16.8755 20.1808H19.1249C19.603 20.1808 19.7752 20.4593 19.8357 20.6252C19.9222 20.8636 19.9018 21.2131 19.584 21.4803L17.9998 22.8107L16.4157 21.4796C16.0979 21.2124 16.0775 20.8636 16.164 20.6259Z" fill="#4147DA"/>
                        </svg>
                    }
                    text={`О проекте Cat&nbsp;Friendly – С любовью к кошкам`}
                />
                <div className="AboutProject_content">
                    <div className="flex">
                        <TextBlock
                            text={`Наша цель – <b>развитие в обществе культуры Cat Friendly – С любовью к кошкам</b>, основанной на понимании потребностей и особенностей поведения кошек. 
                            
                            Особое значение для ветеринарного сообщества  имеет <b>подход Cat Friendly – С любовью к кошкам,</b> который помогает сделать обслуживание кошек в ветеринарных клиниках высокопрофессиональным, уважительным и дружелюбным, повысить эффективность и результативность лечения и ухода за ними. 
                            
                            Обучение и подготовка ветеринарных врачей в направлении уважительного и доверительного обращения с кошками необходимы для того, чтобы <b>избежать стресса и обеспечить эффективное ветеринарное обслуживания.</b>
                            
                            Без соответствующей подготовки стресс у кошки может перерасти в страх или связанную со страхом агрессию. Стресс также может изменить результаты клинического обследования и лабораторных исследований, что приведет к установлению неправильного диагноза и ненужному лечению. Неправильное обращение с кошкой может стать причиной травмирования самой кошкой, владельца и/или сотрудников ветеринарной клиники.`}
                        />
                        <img src={img1} alt="" className="flex_img"/>
                    </div>
                    <TextBlock
                        text={`<b>Использование подхода Cat Friendly – С любовью к кошкам при оказании ветеринарных услуг поможет избежать указанных проблем и решить следующие задачи:</b>`}
                    />
                    <ol className="AboutProject_list">
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_number">1.</span> <span className="AboutProject_item_pink">Уменьшить страх и боль</span> у кошек.
                        </li>
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_number">2.</span> <span className="AboutProject_item_pink">Укрепить взаимосвязь</span> между ветеринарным врачом, владельцем и его кошкой.
                        </li>
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_number">3.</span> <span className="AboutProject_item_pink">Установить доверительные отношения и обеспечить уверенность</span>, что кошка получит наиболее эффективную ветеринарную помощь и лечение.
                        </li>
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_number">4.</span> <span className="AboutProject_item_pink">Повысить эффективность, результативность и удовлетворенность работой</span> со стороны коллектива ветеринарной клиники.
                        </li>
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_number">5.</span> Повысить соответствие ожиданиям владельцев кошек, <span className="AboutProject_item_pink">снизить их тревожность и обеспокоенность</span>.
                        </li>
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_number">6.</span> <span className="AboutProject_item_pink">Уменьшить количество травм</span>, которые может получить владелец кошки и сотрудники клиники при обследовании и лечении кошки.
                        </li>
                    </ol>
                    <br />
                    <TextBlock
                        text={`<b>Хотите стать членом движения Cat Friendly – С любовью к кошкам? Для этого Вам необходимо:</b>`}
                    />
                    <ol className="AboutProject_list">
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_dot"></span> Пройти обучение и получить сертификат <span className="AboutProject_item_pink">«Специалист Cat Friendly – С любовью к кошкам».</span>
                        </li>
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_dot"></span> Подать заявку на получение знака <span className="AboutProject_item_pink">«Здесь комфортно кошкам»</span> для ветеринарной клиники.
                        </li>
                    </ol>
                    <br />
                    <TextBlock
                        text={`<b>Что необходимо сделать для получения сертификата «Специалист Cat Friendly – С любовью к кошкам»:</b>`}
                    />
                    <ol className="AboutProject_list">
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_dot"></span> Пройти обучение по всем темам <span className="AboutProject_item_pink">(100%)</span> раздела Общий курс «Cat Friendly – С любовью к кошкам».
                        </li>
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_dot"></span> Пройти обучение <span className="AboutProject_item_pink">минимум на 30%</span> в разделе курса «Медицина кошек».
                        </li>
                        <li className="AboutProject_item">
                            <span className="AboutProject_item_dot"></span> Ответить правильно на <span className="AboutProject_item_pink">70%</span> вопросов тестов в каждом пройденном уроке.
                        </li>
                    </ol>
                    <br />
                    <TextBlock
                        text={`<b>Как подать заявку на получение знака «Здесь комфортно кошкам»:</b>`}
                    />
                    <TextBlock
                        text={`Форма для подачи заявки находится ниже, перед тем, как её заполнить, рекомендуем вам ознакомиться с правилами выдачи знака «Здесь комфортно кошкам» и требованиями к клиникам, комфортным для кошек. Обратите внимание, что заявку может подать только директор, главный врач или руководитель клиники.`}
                    />
                    <TextBlock
                        text={`После получения знака «Здесь комфортно кошкам» клиника добавляется в список клиник, комфортных для кошек. `}
                    />
                    <div className="AboutProject_buttons">
                        <div className="updating-info">
                            <Link className="btn btn_default" to={'accreditation'}>Заявка на получение знака «Здесь комфортно кошкам»</Link>
                            {/*
                            <div className="updating-info_text">
                                Информация обновляется...
                            </div>*/}
                        </div>
                        {/*test button*/}
                        {/*<div className="updating-info">
                            <Link className="btn btn_default" to={'testform'}>Тест формы</Link>
                        </div>*/}

                        <div className="updating-info">
                            <Link className="btn btn_default" to={'accreditatedclinics'}>Клиники со знаком «Здесь комфортно кошкам»</Link>
                            {/*<button className="btn btn_default">
                                Аккредитованные клиники
                            </button>*/}
                            {/*<div className="updating-info_text">
                                Информация обновляется...
                            </div>*/}
                        </div>
                        <a
                            className="btn btn_default"
                            href="/demands.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Требования к Клиникам комфортным для кошек
                        </a>
                        <a
                            className="btn btn_default"
                            href="/sign_rules.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Правила выдачи знака
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

