import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/krutickaya.png'
import img2 from '../../../image/Lecturers/krutickaya-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Альфа-адреноблокаторы: обзор современной литературы, опыт применения и отмены`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Крутицкая'}
                    name={'Надежда Сергеевна'}
                    text={'Ветеринарный врач, ветеринарная клиника «Ковчег», г. Москва, Президент Ассоциации ветеринарных врачей «НефроУроВет»'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
