import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

// import img1 from '../../../image/lessons/lesson11/step17-1.png'

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <div className="head">
                    «Это мое, мое, МОЕ!»
                </div>
                <ul className="list">
                    <li className="list_item">Кошкам нравится иметь свое собственное пространство, свои игрушки и
                        вещи.
                    </li>
                    <li className="list_item">Они должны дать понять всем, что владеют предметом. Естественный способ
                        сделать это – пометить мочой. Так они будут чувствовать себя в безопасности, особенно при
                        признаках тревоги и страха.
                    </li>
                    <li className="list_item">Кошки не злопамятны. Они просто бывают чем-то расстроены.</li>
                    <li className="list_item">Необходимо убедиться, что у кошки нет никаких медицинских проблем.</li>
                    <li className="list_item">Не стоит кричать на кошку, если она совершила проступок.</li>
                    <li className="list_item">Не нужно тыкать ее носом в ту область, где она метила свою территорию.
                    </li>
                    <li className="list_item">Не нужно заставлять кошку ходить в лоток. Это заставит кошку нервничать
                        еще сильнее и бояться лотка.
                    </li>
                    <li className="list_item">Кошка может метить территорию, когда в доме появляется что-то или кто-то
                        новый, например, другой питомец или ребенок. Кошка должна понять, что вы продолжаете её любить.
                    </li>
                    <li className="list_item">При наличии во дворе дома других животных необходимо установить
                        заградительные барьеры, которые смогут препятствовать визуальному контакту кошки с ними, например,
                        закрыть окно газетой, фольгой или светоотражающей лентой. Иначе кошка будет помечать свою территорию.
                    </li>
                </ul>
            </div>
        )
    }
}
