import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson9/step10-1.png'
import img2 from '../../../image/lessons/lesson9/step10-2.png'
import img3 from '../../../image/lessons/lesson9/step10-3.png'
import img4 from '../../../image/lessons/lesson9/step10-4.png'
import img5 from '../../../image/lessons/lesson9/step10-5.png'

export default class step10 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 6) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 6) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {
            img: img1,
            text: `Запахи (например, других животных, дезинфицирующих средств)`,
        },
        2: {
            img: img2,
            text: `Шум (например, медицинское оборудование, телефоны, лай собак, человеческий разговор)`,
        },
        3: {
            img: img3,
            text: `Другие животные и незнакомые люди, не являющиеся хозяевами.`,
        },
        4: {
            img: img3,
            text: `Сенсорная перегрузка от напряженной больничной обстановки .`,
        },
        5: {
            img: img4,
            text: `Клеточная среда, которая не позволяет спрятаться`,
        },
        6: {
            img: img5,
            text: `Незнакомая еда и режим кормления`,
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step10">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать об источниках стресса для кошек в ветеринарных клиниках.`}
                />
                <div className="box">
                    <div className="box_el box_el--flex">
                        <div className="box_img bottle">
                            <div
                                className={'box_point ' + (points.indexOf(1) >= 0 && 'active ') + (pointActive === 1 && 'heigher')}
                                onClick={() => {this.showPopup(1)}}
                            >
                                <div className="box_point_inner"/>
                            </div>
                            <div className={'block red ' + (pointActive === 1 && 'active')}>
                                <div className="block_head">
                                    {this.popupData[1].head}
                                </div>
                                <TextBlock
                                    text={this.popupData[1].text}
                                />
                            </div>
                            <img src={img1} className="imgCenter" alt=""/>
                        </div>
                        <div className="box_img phone">
                            <div
                                className={'box_point ' + (points.indexOf(2) >= 0 && 'active ') + (pointActive === 2 && 'heigher')}
                                onClick={() => {this.showPopup(2)}}
                            >
                                <div className="box_point_inner"/>
                            </div>
                            <div className={'block green ' + (pointActive === 2 && 'active')}>
                                <div className="block_head">
                                    {this.popupData[2].head}
                                </div>
                                <TextBlock
                                    text={this.popupData[2].text}
                                />
                            </div>
                            <img src={img2} className="imgCenter" alt=""/>
                        </div>
                    </div>
                    <div className="box_el">
                        <div className="box_img doctor">
                            <div
                                className={'box_point ' + (points.indexOf(3) >= 0 && 'active ') + (pointActive === 3 && 'heigher')}
                                onClick={() => {this.showPopup(3)}}
                            >
                                <div className="box_point_inner"/>
                            </div>
                            <div
                                className={'box_point ' + (points.indexOf(4) >= 0 && 'active ') + (pointActive === 4 && 'heigher')}
                                onClick={() => {this.showPopup(4)}}
                            >
                                <div className="box_point_inner"/>
                            </div>
                            <div className={'block ' + (pointActive === 3 && 'active')}>
                                <div className="block_head">
                                    {this.popupData[3].head}
                                </div>
                                <TextBlock
                                    text={this.popupData[3].text}
                                />
                            </div>
                            <div className={'block ' + (pointActive === 4 && 'active')}>
                                <div className="block_head">
                                    {this.popupData[4].head}
                                </div>
                                <TextBlock
                                    text={this.popupData[4].text}
                                />
                            </div>
                            <img src={img3} className="imgCenter" alt=""/>
                        </div>
                    </div>
                    <div className="box_el">
                        <div className="box_img cell">
                            <div
                                className={'box_point ' + (points.indexOf(5) >= 0 && 'active ') + (pointActive === 5 && 'heigher')}
                                onClick={() => {this.showPopup(5)}}
                            >
                                <div className="box_point_inner"/>
                            </div>
                            <div className={'block ' + (pointActive === 5 && 'active')}>
                                <div className="block_head">
                                    {this.popupData[5].head}
                                </div>
                                <TextBlock
                                    text={this.popupData[5].text}
                                />
                            </div>
                            <img src={img4} className="imgCenter" alt=""/>
                        </div>
                        <div className="box_img feed">
                            <div
                                className={'box_point ' + (points.indexOf(6) >= 0 && 'active ') + (pointActive === 6 && 'heigher')}
                                onClick={() => {this.showPopup(6)}}
                            >
                                <div className="box_point_inner"/>
                            </div>
                            <div className={'block ' + (pointActive === 6 && 'active')}>
                                <div className="block_head">
                                    {this.popupData[6].head}
                                </div>
                                <TextBlock
                                    text={this.popupData[6].text}
                                />
                            </div>
                            <img src={img5} className="imgCenter" alt=""/>
                        </div>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <img src={this.popupData[pointActive].img} alt="" className="info_img"/>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
