import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson7/step36-1.png";


export default class step36 extends React.Component {

    state = {
        answersData:
            [
                `Да`,
                `Нет`,
            ]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step36">
                <TextBlock
                    text={`Определите, иллюстрируют ли следующие картинки конфликт между кошками:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[1]}
                    showNextBtn={this.showNextBtn}
                    quantity={50}
                    correctText={`Данная картинка иллюстрирует конфликтное поведение кошек. Когда кошка защищает себя от другой кошки (в данном случае − это кошка справа), она бросается назад с выгнутой спиной. Он может очень быстро двигать хвостом, выгибать его над спиной или помещать между задними ногами. Уши становятся плоскими, зрачки расширяются, а шерсть может встать дыбом. Кошки делают это, чтобы казаться как можно более крупными и угрожающими.`}
                />
            </div>
        )
    }
}
