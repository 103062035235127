import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step37 extends React.Component{

    render() {
        return (
            <div className="step37">
                <div className="head">
                    О методах скраффинга
                </div>
                <TextBlock
                    text={`«Скраффинг» – это общий термин, обозначающий различные захваты на коже шеи кошки.
                    
                    Некоторые ветеринарные врачи не используют скраффинг и предпочитают другие методы. Другие ветеринарные врачи используют скраффинг очень редко. Третьи считают, что это допустимо, чтобы кошка не убежала или не ранила кого-то.
                    
                     Если вы считаете, что скраффинг является единственной альтернативой, то обращайтесь с кошкой как можно мягче.
                     
                     «Клипирование» или «пинч-индуцированное ингибирование поведения» - использование зажимов для оказания давления на кожу дорсальной области шеи. Данная техника имеет место быть при условии надлежащего осуществления.`}
                />
            </div>
        )
    }
}
