import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson2/step3-1.png'

export default class step3 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    };

    showPopup = (pointActive) => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }


        this.setState({points: points, pointActive: pointActive === this.state.pointActive ? 0 : pointActive})

        if (points.length === 3) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {
            text: `3D-зрение помогает кошке точно оценивать местоположение объекта, что особенно важно во время охоты.`,
        },
        2: {
            text: `Угол бинокулярного зрения у кошек составляет 140 градусов.`,
        },
        3: {
            text: `Кошка имеет довольно большой угол обзора — 200 градусов (у человека — 180 градусов).`,
        },
    }

    render() {
        const {pointActive} = this.state
        return (
            <div className="step3">
                <div className="head">
                    Бинокулярное зрение
                </div>
                <InfoBlock
                    text={`Наведите на ключевые особенности, чтобы узнать о них.`}
                />
                <div className="box">
                    <div className="box_img">
                        <div
                            className={'box_point ' + (pointActive === 1 && 'active')}
                            onClick={() => {this.showPopup(1)}}
                        >1</div>
                        <div
                            className={'box_point ' + (pointActive === 2 && 'active')}
                            onClick={() => {this.showPopup(2)}}
                        >2</div>
                        <div
                            className={'box_point ' + (pointActive === 3 && 'active')}
                            onClick={() => {this.showPopup(3)}}
                        >3</div>
                        <div className={'block red ' + (pointActive === 1 && 'active')}>
                            <TextBlock
                                text={this.popupData[1].text}
                            />
                        </div>
                        <div className={'block green ' + (pointActive === 2 && 'active')}>
                            <TextBlock
                                text={this.popupData[2].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 3 && 'active')}>
                            <TextBlock
                                text={this.popupData[3].text}
                            />
                        </div>
                        <img src={img1} className="imgCenter" alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
