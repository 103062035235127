import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';


export default class step29 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <p>Важно организовать прием у врача таким образом, чтобы он не был стрессовым для кошки. Ниже приведен список рекомендаций, которыми владелец сможет воспользоваться, чтобы сделать визит к врачу приятным для всех.
                    </p>
                    <ul>
                        <li>
                            Нужно позволить кошке привыкнуть к своей переноске задолго до посещения ветеринарной клиники. Это можно сделать, оставив кошачью переноску открытой, чтобы кошка смогла исследовать ее самостоятельно, и поместив кошачью мяту или любимые лакомства кошки в переноску, чтобы кошка смогла заглянуть внутрь. Можно сделать переноску постоянным спальным местом или укрытием для кошки
                        </li>
                        <li>
                            Пусть кошка привыкнет к поездке в автомобиле, сидя в переноске. Её можно посадить в переноску, поощрив любимым лакомством, и прокатиться с ней несколько раз по кварталу. Кошка будет меньше бояться, если увидит, что поездка на автомобиле в переноске не всегда заканчивается посещением ветеринарной клиники. При этом кошка также сможет лучше привыкнуть к езде в автомобиле. Когда кошка едет в вашем автомобиле, включите стереосистему на небольшую громкость, закройте окна в машине и включите кондиционер. Слишком шумные колонки стереосистемы или громкие звуки в дороге могут напугать кошку. <b>Не следует сажать кошку в машину, в которой жарко или душно. Не оставлять ее там без присмотра!</b>
                        </li>

                    </ul>
                </div>

            </div>
        )
    }
}
