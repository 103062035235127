import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="head">Поведение владельца домашнего животного в комнате для осмотра</div>
                    <TextBlock
                        text={`Не только кошки испытывают тревогу во время посещения ветеринарной клиники. Владелец кошки также часто испытывает определенную взволнованность и напряжение. 
                        
                        <b>Когда кошка чувствует тревогу своего хозяина, ее тревога в подавляющем большинстве случаев возрастает.</b>

                        Следующие рекомендации помогут владельцу немного успокоить своего питомца в комнате для осмотра:`}
                    />

                    <ul className="list">
                        <li className="list_item">Избегайте «человеческих» методов снятия напряжения, которые, несмотря на то, что направлены на успокоение кошки, на самом деле могут только усилить ее чувство тревоги. Примеры включают в себя прижимание кошки к своему телу, разговор или пристальный взгляд в ее лицо, а также нарушение или вторжение в ее личное пространство. Человеческие звуки, предназначенные для успокоения или оказания расслабляющего эффекта (например, «ш-ш-ш»), могут имитировать шипение другой кошки.</li>
                        <li className="list_item">Недопустимо пытаться успокоить кошку, применяя силу (щелкать по носу и ушам, прихватывать за шкирку, трясти кошку, укачивая как младенца) и строго или угрожающе разговаривая с ней. Это может напугать кошку и спровоцировать реакцию «дерись или беги». Владельцы кошек и члены ветеринарной бригады должны помнить, что, несмотря на то, что домашние питомцы являются членами семьи, кошки не являются людьми и иначе реагируют на подобные приемы.</li>
                        <li className="list_item">Кошка будет показывать и сама всячески намекать, когда она разрешит к себе прикоснуться. В условиях пребывания в ветеринарной клинике проинструктируйте владельца не трогать и не выпускать свою кошку из кошачьей переноски до тех пор, пока сотрудник ветеринарной клиники сам не попросит об этом.</li>
                        <li className="list_item">Способствуйте позитивному поведению кошки и игнорируйте негативное поведение, не пытайтесь его исправить.</li>
                    </ul>
            </div>
        )
    }
}
