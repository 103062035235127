import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step19 extends React.Component {

    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`Некоторые одинокие домашние кошки становятся тревожными, если владельцы надолго оставляют их одних.
                    
                    Признаки, проявляемые при боязни расставания у кошек:`}
                />
                <ul className="list">
                    <li className="list_item">
                        Появление звуков, сигнализирующих об ухудшении состояния вашей кошки («плач», стоны, мяуканье).
                    </li>
                    <li className="list_item">
                        Нарушение ежедневного рациона: кошка не ест и не пьет, пока владелец находится в отъезде.
                    </li>
                    <li className="list_item">
                        Рвота, наличие кусочков пищи или шерсти в рвоте.
                    </li>
                    <li className="list_item">
                        Чрезмерный уход за шерстью.
                    </li>
                    <li className="list_item">
                        Агрессивное поведение.
                    </li>
                    <li className="list_item">
                        Слишком бурные приветствия, когда владелец возвращается домой.
                    </li>
                </ul>
            </div>
        )
    }
}
