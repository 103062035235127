import React from 'react'
import './style.sass'


export default class Button extends React.Component{
    render() {
        return (
            <button type="button" className={`btn ${this.props.class}`} style={this.props.style} onClick={this.props.onClick}>
                {this.props.svg && this.props.svg}
                {this.props.name}
            </button>
        )
    }
}