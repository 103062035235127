import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson31/step6-1.png'


export default class step6 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    };

    showPoint = pointActive => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({
            pointActive: pointActive,
            points: points
        })

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step6">
                <div className="head">
                    Классификация по возрасту животного
                </div>
                <TextBlock
                    text={`Повторите материал об особенностях питания котят.`}
                />
                <InfoBlock
                    text={`Нажимайте на кружочки, чтобы узнать факты.`}
                />
                <div className="imgDiv">
                    <div
                        className={'point ' + (points.indexOf(1) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(1)}}
                    >
                    </div>
                    <div
                        className={'point ' + (points.indexOf(2) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(2)}}
                    >
                    </div>
                    <div
                        className={'point ' + (points.indexOf(3) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(3)}}
                    >
                    </div>
                    <div
                        className={'point ' + (points.indexOf(4) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(4)}}
                    >
                    </div>
                    <img src={img1} alt="" className="imgCenter"/>
                    <div className="imgDiv_answers">
                        <div
                            className={'answer ' + (points.indexOf(1) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Если вес котенка составляет 80% веса взрослой кошки, ему нужно <b>в 1,2 раза больше калорий.</b></p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(2) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">В рационе котенка должно содержаться <b>в 4,5 раза больше цинка,</b> чем в рационе взрослой кошки.</p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(3) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Котятам необходимо <b>в 5,8 раз больше кальция,</b> чем взрослым кошкам.</p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(4) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Потребность котят <b>в фосфоре в 6,4 раза превышает</b> потребность взрослых кошек.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
