import React from 'react'

import img1 from "../../../image/lessons/lesson12/step16-1.svg";
import img2 from "../../../image/lessons/lesson12/step16-2.svg";
import img2xs from "../../../image/lessons/lesson12/step16-2xs.svg";
import img3 from "../../../image/lessons/lesson12/step16-3.svg";


export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <div className="head">
                    Дружелюбные позы
                </div>
                <div className="flex">
                    <div className="flex_img-box">
                        <img src={img1} alt="" className="flex_img"/>
                    </div>
                    <div className="flex_text">
                        Когда кошки подходят с дружелюбными намерениями, они часто держат хвост торчком, при этом усы направлены вперед, а сами кошки трутся подбородками или головами о других кошек или людей. Кошки делают это в момент приветствия, или когда они уверенно исследуют что-то новое.
                    </div>
                </div>
                <div className="flex grey">
                    <div className="flex_img-box">
                        <picture>
                            <source srcSet={img2xs} media="(max-width: 767px)"/>
                            <img src={img2} alt="" className="flex_img"/>
                        </picture>
                    </div>
                    <div className="flex_text">
                        Когда кошки хотят играть, они могут перевернуться и обнажить свои животы.<br/>(Самки также могут проявлять такое поведение во время спаривания).
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_img-box">
                        <img src={img3} alt="" className="flex_img"/>
                    </div>
                    <div className="flex_text">
                        Кошки также могут выгибать свои хвосты назад или быстро двигать ими, когда они счастливы и хотят играть. Их зрачки могут быть расширены (становясь большими), а уши в этот момент направлены вперед. Однако кошки с быстро движущимися хвостами могут быть очень возбуждены, и играть с ними в таком состоянии может быть не очень хорошей идеей, поскольку они могут стать агрессивными и начать кусаться или царапаться.
                    </div>
                </div>
            </div>
        )
    }
}
