import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

// import img1 from "../../../image/lessons/lesson8/step34-1.png";


export default class step34 extends React.Component {

    render() {
        return (
            <div className="step34">
                <div className="head">
                    Минимизация стресса в результате ветеринарных процедур
                </div>
                <ul className="list">
                    <li className="list_item">
                        При нескольких процедурах лучше начать с менее инвазивных.
                    </li>
                    <li className="list_item">
                        Покажите, как давать таблетки. Дайте еду или вкусные лакомства, в которых можно спрятать таблетки.
                    </li>
                    <li className="list_item">
                        Доводите инъекции до комнатной температуры, если это не влияет на эффективность.
                    </li>
                    <li className="list_item">
                        Используйте разные иглы для аспирации и для введения инъекций.
                    </li>
                    <li className="list_item">
                        Удержание головы и тела в естественном положении позволяет проводить процедуры с минимальными ограничениями.
                    </li>
                    <li className="list_item">
                        Для болезненно реагирующих кошек примените местный анестетик или используйте опиоиды трансмукозального применения.
                    </li>
                    <li className="list_item">
                        Многие процедуры требуют минимального удержания и ограничения подвижности, если кошка находится в естественном положении.
                    </li>
                </ul>
                {/*<img src={img1} alt="" className="imgCenter"/>*/}
            </div>
        )
    }
}
