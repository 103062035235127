import React from 'react'
import './style.sass'

export default (props) => {
    return (
        <div
            className={'PageHeader ' + props.PageHeaderType}
        >
            {props.svg}
            <span className="PageHeader_text" 
                  dangerouslySetInnerHTML={{ __html: props.text }}
            />
        </div>
    )
}
