import React from 'react'
import './style.sass'

export default class Lecturer extends React.Component {

    // Пример использования lesson 16 step1
    render() {
        return (
            <div className="Lecturer">
                <div className="Lecturer_info">
                    <div className="Lecturer_info_img-block">
                        <img src={this.props.img} alt="" className="Lecturer_info_img"/>
                        {this.props.icon ?
                            <img src={this.props.icon} alt="" className="Lecturer_info_icon"/>
                        : null}
                    </div>
                    <div className="Lecturer_info_text">
                        <div className="Lecturer_info_status">
                            {this.props.type ? this.props.type : 'Лектор'}
                            {/*Лектор*/}
                        </div>
                        <div className="Lecturer_info_fio">
                            <div className="Lecturer_info_name">
                                {this.props.surname}
                            </div>
                            <div className="Lecturer_info_name">
                                {this.props.name}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="Lecturer_description"
                    dangerouslySetInnerHTML={{ __html: this.props.text }}
                />
            </div>
        )
    }
}
