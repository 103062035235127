import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson30/step4-1.png';


export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">

                <div className="head">
                    Информация на обратной стороне упаковки
                </div>

                <TextBlock
                    text={`Также на упаковке может содержаться дополнительная полезная информация для владельца животного. Например, на упаковке сухого корма PERFECT FIT® для взрослых кошек с чувствительным пищеварением можно найти следующую информацию:`}
                />
                <div className="flex">
                    <div className="flex_el left">
                        <div className="flex_el_item">
                            Описание продукта
                            <span className="flex_el_number">1</span>
                        </div>
                        <div className="flex_el_item">
                            Формула «5 слагаемых здоровья»
                            <span className="flex_el_number">2</span>
                        </div>
                        <div className="flex_el_item">
                            Функциональные преимущества корма
                            <span className="flex_el_number">3</span>
                        </div>
                        <div className="flex_el_item">
                            Расчет рекомендованной порции
                            <span className="flex_el_number">4</span>
                        </div>
                    </div>
                    <div className="flex_el right">
                        <img className="img" src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
