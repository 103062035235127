import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step2-1.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="flex">
                    <TextBlock
                        text={`<b>Численность домашних кошек в России почти в 2 раза больше численности домашних собак. Но не смотря на это, кошкам реже оказывают ветеринарную помощь, чем собакам.
                                В частности, клиенты заявляют о трудностях, связанных с помещением кошки в переноску в домашних условиях, поездкой в ветеринарную клинику и общением с испуганной кошкой в ветеринарной клинике.
                                
                                Следствием этого является недостаточное количество посещений клиники владельцами кошек.</b>`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
                <TextBlock
                    text={`Без соответствующей подготовки кошачий стресс может перерасти в страх или связанную со страхом агрессию. Возникающий в результате стресс может изменить результаты обследования и лабораторных анализов, что приведет к установлению неправильного диагноза и ненужному лечению. Без сострадательного и уважительного обращения со стороны ветеринарной бригады клиенты могут почувствовать, что ветеринарные врачи не имеют необходимых навыков и сострадания или не понимают потребности и характер кошек.`}
                />
                <div className="info">
                    Cat Friendly – С любовью к кошкам – концепция обучения и подготовки ветеринарных врачей в отношении уважительного и доверительного обращения с кошками, направленная на избежание стресса и достижения целей эффективного ветеринарного обслуживания.
                </div>
            </div>
        )
    }
}
