import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step17-1.png";
import img2 from "../../../image/lessons/lesson6/step17-2.png";


export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`Кошки являются плотоядными животными – они питаются в основном мелкими <b>грызунами, птицами и жуками</b>. Поскольку охота занимает большую часть жизни дикой кошки, даже домашние кошки хотят заниматься охотой и сопутствующей деятельностью. Имейте это в виду, когда покупаете игрушки для своей кошки.
                    
                    Отдавайте предпочтение тем игрушкам, которые по форме напоминают <b>мышей, птиц и жуков</b> (определите «охотничьи предпочтения» вашей кошки). Если вы действительно хотите сделать процесс «охоты» за игрушкой захватывающим и максимально реалистичным, играя с вашей кошкой, заставьте игрушку двигаться так, как будто она действительно живая.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_img"/>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className="flex_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
