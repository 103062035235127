import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson6/step9-1.png'
import img2 from '../../../image/lessons/lesson6/step9-2.png'
import img3 from '../../../image/lessons/lesson6/step9-3.png'
import img4 from '../../../image/lessons/lesson6/step9-4.png'
import img5 from '../../../image/lessons/lesson6/step9-5.png'
import img6 from '../../../image/lessons/lesson6/step9-6.png'
import img7 from '../../../image/lessons/lesson6/step9-7.png'
import img8 from '../../../image/lessons/lesson6/step9-8.png'
import img9 from '../../../image/lessons/lesson6/step9-9.png'
import img10 from '../../../image/lessons/lesson6/step9-10.png'

export default class step9 extends React.Component {

    state = {
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    showPopup = (pointActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (!showPopup) {
            this.setState({
                pointActive: pointActive,
                showPopup: true,
            })
        } else if (pointActive >= 8) {
            this.setState({showPopup: false, pointActive: 0})
        } else {
            this.setState({
                pointActive: pointActive + 1,
                showPopup: true,
            })
        }

        if (points.length === 8) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {
            text: `Научите вашу кошку приносить игрушки. Прикрепите любимую игрушку кошки к нитке или шнурку, бросьте игрушку и потихоньку сматывайте шнурок, чтобы кошка побежала за игрушкой. Ваша кошка может научиться приносить вам игрушку, поскольку она будет вознаграждена, когда вы снова бросите игрушку.
            
            Прикрепите игрушку к импровизированному шнурку и волочите ее по дому. Не забудьте закидывать игрушку на возвышенности, например, на высокие столы или стулья, чтобы ваша кошка прыгала за игрушкой. Многие кошки будут преследовать такую игрушку, куда бы вы ее ни закинули. Шнурок с узлом на конце также отлично подходит для игр с вашей кошкой, особенно для тех кошек, которые любят охотиться на жуков.`,
            img: img1
        },
        2: {
            text: `Спрячьте игрушку под подушку или одеяло. Просуньте руку под одеяло и слегка потеребите игрушку, тем самым вы вызовите у вашей кошки интерес сделать подкоп и посмотреть, что же там спрятано.`,
            img: img2
        },
        3: {
            text: `Положите на пол бумажный пакет или картонную коробку. Когда ваша кошка войдет внутрь, чтобы исследовать новый предмет, поцарапайте коробку или пакет снаружи каким-нибудь предметом, кошке станет интересно, где источник звука. Также можно положить в коробку или пакет скомканную бумажку или игрушку. Хотя сам по себе бумажный пакет или картонная коробка это уже прекрасный подарок для любопытного хищника!`,
            img: img3,
            imgBottom: img10
        },
        4: {
            text: `Многие кошки будут гоняться за лазерной указкой часами напролет. Убедитесь, что вы не направляете лазер в глаза людей или животных. Вы можете прекратить использовать лазерную указку, если ваша кошка демонстрирует признаки тревоги или стресса из-за того, что обращает внимание на указку даже тогда, когда она выключена.<br><strong>ВАЖНО!</strong><br>Говоря про игру с кошкой лазерной указкой, важно играть с ней правильно. Есть кошки, изначально игнорирующие игру с лазерной указкой. Некоторые кошки спокойно воспринимают завершение игры или быстро теряют к ней интерес, понимая, что огонек нематериальный (как игра с тенью или солнечным зайчиком) и его невозможно поймать. Они не выказывают признаков беспокойства и неудовлетворенности.<br>Однако многие взрослые кошки склонны к фрустрации из-за невозможности завершить акт охоты: они еще долго ищут красный огонек, подходят к владельцу, вокализируют и беспокоятся. 
Котята, как и все детеныши, наименее склонны к фрустрации, что позволяет им эффективнее исследовать окружающий мир, не останавливаться после неудачной попытки и пробовать снова и снова.<br>Поэтому для начала нужно выяснить степень склонности кошки к фрустрации, после чего станет ясно, подходит ли для нее игра с лазерной указкой или же от нее придется отказаться навсегда.<br>Лазерная указка неплохой помощник при наведении - например показать кошке новую когтеточку или помочь найти навесные полки и лежанки. Чтобы избежать фрустрации, можно спрятать лакомство или любимую кошачью игрушку в месте, куда животное приведет красный огонек, таким образом "добыча" будет поймана.<br>Стоит также отметить, что к фрустрации у некоторых кошек может приводить не только лазерная указка, но также, например, вид на птичью кормушку из окна, аквариум с рыбками, клетка с птицами или грызунами. Разумеется, если кошка не смогла добраться до них и завершить акт охоты.
`,
            img: img4
        },
        5: {
            text: `Повесьте игрушку на петельку от дверной ручки или используйте для этого вешалку на верхней части дверного проема. Помогите сделать игру интересной для вашей кошки – дайте ей хорошенько встряхнуть игрушку, чтобы игрушка моталась из стороны в сторону на веревочке, а кошка прыгала за ней.`,
            img: img5
        },
        6: {
            text: `Бросьте на пол кусочек сухого кошачьего корма или другое лакомство. Смотрите, как кошка будет задорно гоняться за ним каждый раз, когда корм оказывается на полу!`,
            img: img6
        },
        7: {
            text: `Покажите вашей кошке, как можно катать мячик, подтолкнув его по гладкой напольной поверхности, например, по твердой напольной плитке или на полу в ванной.`,
            img: img7
        },
        8: {
            text: `Если ваша кошка самонадеянна и легка на подъем, попробуйте поиграть с ней в прятки. Притворяйтесь, прячьтесь и чаще выглядывайте из-за укрытия. Позвольте вашей кошке наблюдать, как вы крадетесь к ближайшему укрытию.
            
            Наконец, выйдите из своего последнего укрытия и пощекочите вашу кошку. Если ваша кошка наслаждается игровым процессом, она сначала будет прятаться, а затем преследовать вас! Старайтесь не пугать свою кошку и всегда заканчивайте игру на спокойной ноте.`,
            img: img9
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step9">
                <div className="head">
                    Рекомендации по повышению активности кошки
                </div>
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать, каким образом можно повысить активность кошки.`}
                />
                <div className="box">
                    <div className="box_img">
                        {Object.keys(this.popupData).slice(1, 9).map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'box_point ' + (points.indexOf(Number(item)) >= 0 && 'active ') + (pointActive === Number(item) && 'heigher')}
                                    onClick={() => {this.showPopup(Number(item))}}
                                >
                                    <img src={this.popupData[item].img} alt="" className="box_point_img"/>
                                </div>
                            )
                        })}
                        <img src={img8} className="imgCenter" alt=""/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <div className="info_top">
                            <div className="info_img-box">
                                <img src={this.popupData[pointActive].img} alt="" className="info_img"/>
                            </div>
                        </div>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                        {pointActive === 3 &&
                        <img src={this.popupData[pointActive].imgBottom} alt="" className="info_img_bottom"/>
                        }
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
