import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson10/step8-1.png'
import img2 from '../../../image/lessons/lesson10/step8-2.png'
import img3 from '../../../image/lessons/lesson10/step8-3.png'

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <div className="flex">
                    <TextBlock
                        text={`Некоторые кошки гораздо комфорнее чувствуют себя, если прикасаться к ним через плед или полотенце.
                        
                        Также в одеяло можно запеленать беспокойную кошку для проведения коротких манипуляций (например, для забора крови или осмотра головы).`}
                    />
                    <img src={img1} className="flex_img" alt=""/>
                </div>
                <div className="block">
                    <img src={img2} className="block_img left" alt=""/>
                    <TextBlock
                        text={`Снимите или откройте дверцу переноски и позвольте кошке самой выйти из нее.
                        
                        Можно привлечь внимание пациента игрушкой или лакомством.`}
                    />
                    <img src={img3} className="block_img right" alt=""/>
                </div>
            </div>
        )
    }
}
