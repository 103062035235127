import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step25 extends React.Component {

    render() {
        return (
            <div className="step25">
                <TextBlock
                    text={`Если кошка все еще не заинтересована новой игрушкой, можно предложить ей другой вид добычи в качестве игрушки и другой способ охоты:`}
                />
                <ul className="list">
                    <li className="list_item">
                        Предложить кошке поохотиться за едой. Для этого нужно поместить еду в игрушку, которая заставит кошку изрядно потрудиться, чтобы вытащить лакомство.
                    </li>
                    <li className="list_item">
                        Спрятать еду в разных местах по всему дому, чтобы кошка смогла отправиться на ее поиски.
                    </li>
                    <li className="list_item">
                        Оставить радио или телевизор включенными в течение дня или даже на ночь.
                    </li>
                    <li className="list_item">
                        Позвонить себе домой и оставить голосовое сообщение для вашей кошки.
                    </li>
                    <li className="list_item">
                        Необходимо обеспечить кошке убежище.
                    </li>
                </ul>
            </div>
        )
    }
}
