import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson34/step6-1.png';


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Мы расскажем Вам о специальной формуле <span class="text_blue">«5 слагаемых здоровья»</span>, которая является универсальной для всех рационов PERFECT FIT™ для кошек.
                            
                            А также Вы узнаете об индивидуальных особенностях каждого рациона для кошек.
                            
                            После прохождения данной части урока Вас будет ждать задание на закрепление полученных знаний о рационах PERFECT FIT™ для кошек.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_box-img">
                            <img src={img1} alt="" className="flex_el_img"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
