import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

// import img1 from "../../../image/lessons/lesson11/step28-1.svg";
// import img2 from "../../../image/lessons/lesson11/step28-1.svg";
// import img3 from "../../../image/lessons/lesson11/step28-1.svg";

export default class step28 extends React.Component {

    state = {
        answersData: {
            1: {
                img:
                    <svg className="svgFill" width="36" height="48" viewBox="0 0 36 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.527 0H14.902C14.1253 0 13.4957 0.629625 13.4957 1.40625V2.9475H9.27697C8.50035 2.9475 7.87072 3.57712 7.87072 4.35375V9.11719H3.65197C2.87535 9.11719 2.24572 9.74681 2.24572 10.5234V18.7725C1.88619 18.6929 1.682 18.6544 1.66494 18.6511C0.713286 18.4737 -0.129433 19.2895 0.0165356 20.2455L4.06841 46.8058C4.17313 47.4926 4.76375 48 5.45854 48H29.5992C30.2938 48 30.8846 47.4926 30.9893 46.8058C32.7547 35.2347 35.0119 20.4371 35.0412 20.2455C35.201 19.1984 34.1857 18.3543 33.1833 18.7081V7.35563C33.1833 6.579 32.5537 5.94938 31.7771 5.94938H26.1521C25.3754 5.94938 24.7458 6.579 24.7458 7.35563V9.11719H21.9333V1.40625C21.9332 0.629625 21.3036 0 20.527 0ZM20.6043 26.676L31.8924 22.236L29.849 35.6312C27.35 32.0608 24.1383 29.0967 20.6043 26.676ZM16.3082 2.8125H19.1207V24.2374L17.6516 24.8153C17.5183 24.7381 16.8724 24.3684 16.3082 24.064C16.3082 23.272 16.3082 3.59269 16.3082 2.8125ZM10.6832 5.76H13.4957V22.6531C12.6883 22.2766 11.6242 21.8129 10.6832 21.435C10.6832 20.6774 10.6832 6.23044 10.6832 5.76ZM5.05822 11.9297H7.87072V20.3869C6.98788 20.0828 5.98822 19.7647 5.05822 19.4966V11.9297ZM6.6665 45.1875L3.11038 21.8772C13.3321 24.5634 24.1746 30.5777 29.194 39.9247L28.3911 45.1875H6.6665ZM27.5582 8.76188H30.3707V19.8123L27.5582 20.9185V8.76188ZM24.7457 11.9297V22.0248L21.9332 23.131V11.9297H24.7457Z"/>
                    </svg>,
                text: `1 порция картофеля фри`
            },
            2: {
                img:
                    <svg width="95" height="48" viewBox="0 0 95 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M79.5846 0H73.9596C73.183 0 72.5533 0.629625 72.5533 1.40625V2.9475H68.3346C67.558 2.9475 66.9283 3.57712 66.9283 4.35375V9.11719H62.7096C61.933 9.11719 61.3033 9.74681 61.3033 10.5234V18.7725C60.9438 18.6929 60.7396 18.6544 60.7226 18.6511C59.7709 18.4737 58.9282 19.2895 59.0742 20.2455L63.126 46.8058C63.2307 47.4926 63.8214 48 64.5162 48H88.6568C89.3515 48 89.9422 47.4926 90.0469 46.8058C91.8123 35.2347 94.0695 20.4371 94.0988 20.2455C94.2586 19.1984 93.2433 18.3543 92.2409 18.7081V7.35563C92.2409 6.579 91.6113 5.94938 90.8347 5.94938H85.2097C84.4331 5.94938 83.8034 6.579 83.8034 7.35563V9.11719H80.9909V1.40625C80.9908 0.629625 80.3612 0 79.5846 0ZM79.6619 26.676L90.95 22.236L88.9066 35.6312C86.4076 32.0608 83.1959 29.0967 79.6619 26.676ZM75.3658 2.8125H78.1783V24.2374L76.7092 24.8153C76.576 24.7381 75.93 24.3684 75.3658 24.064C75.3658 23.272 75.3658 3.59269 75.3658 2.8125ZM69.7408 5.76H72.5533V22.6531C71.7459 22.2766 70.6818 21.8129 69.7408 21.435C69.7408 20.6774 69.7408 6.23044 69.7408 5.76ZM64.1158 11.9297H66.9283V20.3869C66.0455 20.0828 65.0458 19.7647 64.1158 19.4966V11.9297ZM65.7241 45.1875L62.168 21.8772C72.3897 24.5634 83.2322 30.5777 88.2516 39.9247L87.4487 45.1875H65.7241ZM86.6158 8.76188H89.4283V19.8123L86.6158 20.9185V8.76188ZM83.8033 11.9297V22.0248L80.9908 23.131V11.9297H83.8033Z"/>
                        <path d="M20.527 0H14.902C14.1253 0 13.4957 0.629625 13.4957 1.40625V2.9475H9.27697C8.50035 2.9475 7.87072 3.57712 7.87072 4.35375V9.11719H3.65197C2.87535 9.11719 2.24572 9.74681 2.24572 10.5234V18.7725C1.88619 18.6929 1.682 18.6544 1.66494 18.6511C0.713286 18.4737 -0.129433 19.2895 0.0165356 20.2455L4.06841 46.8058C4.17313 47.4926 4.76375 48 5.45854 48H29.5992C30.2938 48 30.8846 47.4926 30.9893 46.8058C32.7547 35.2347 35.0119 20.4371 35.0412 20.2455C35.201 19.1984 34.1857 18.3543 33.1833 18.7081V7.35563C33.1833 6.579 32.5537 5.94938 31.7771 5.94938H26.1521C25.3754 5.94938 24.7458 6.579 24.7458 7.35563V9.11719H21.9333V1.40625C21.9332 0.629625 21.3036 0 20.527 0ZM20.6043 26.676L31.8924 22.236L29.849 35.6312C27.35 32.0608 24.1383 29.0967 20.6043 26.676ZM16.3082 2.8125H19.1207V24.2374L17.6516 24.8153C17.5183 24.7381 16.8724 24.3684 16.3082 24.064C16.3082 23.272 16.3082 3.59269 16.3082 2.8125ZM10.6832 5.76H13.4957V22.6531C12.6883 22.2766 11.6242 21.8129 10.6832 21.435C10.6832 20.6774 10.6832 6.23044 10.6832 5.76ZM5.05822 11.9297H7.87072V20.3869C6.98788 20.0828 5.98822 19.7647 5.05822 19.4966V11.9297ZM6.6665 45.1875L3.11038 21.8772C13.3321 24.5634 24.1746 30.5777 29.194 39.9247L28.3911 45.1875H6.6665ZM27.5582 8.76188H30.3707V19.8123L27.5582 20.9185V8.76188ZM24.7457 11.9297V22.0248L21.9332 23.131V11.9297H24.7457Z"/>
                    </svg>,
                text: `2 порции картофеля фри`
            },
            3: {
                img:
                    <svg width="154" height="120" viewBox="0 0 154 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M138.527 0H132.902C132.125 0 131.496 0.629625 131.496 1.40625V2.9475H127.277C126.5 2.9475 125.871 3.57712 125.871 4.35375V9.11719H121.652C120.875 9.11719 120.246 9.74681 120.246 10.5234V18.7725C119.886 18.6929 119.682 18.6544 119.665 18.6511C118.713 18.4737 117.871 19.2895 118.017 20.2455L122.068 46.8058C122.173 47.4926 122.764 48 123.459 48H147.599C148.294 48 148.885 47.4926 148.989 46.8058C150.755 35.2347 153.012 20.4371 153.041 20.2455C153.201 19.1984 152.186 18.3543 151.183 18.7081V7.35563C151.183 6.579 150.554 5.94938 149.777 5.94938H144.152C143.375 5.94938 142.746 6.579 142.746 7.35563V9.11719H139.933V1.40625C139.933 0.629625 139.304 0 138.527 0ZM138.604 26.676L149.892 22.236L147.849 35.6312C145.35 32.0608 142.138 29.0967 138.604 26.676ZM134.308 2.8125H137.121V24.2374L135.652 24.8153C135.518 24.7381 134.872 24.3684 134.308 24.064C134.308 23.272 134.308 3.59269 134.308 2.8125ZM128.683 5.76H131.496V22.6531C130.688 22.2766 129.624 21.8129 128.683 21.435C128.683 20.6774 128.683 6.23044 128.683 5.76ZM123.058 11.9297H125.871V20.3869C124.988 20.0828 123.988 19.7647 123.058 19.4966V11.9297ZM124.667 45.1875L121.11 21.8772C131.332 24.5634 142.175 30.5777 147.194 39.9247L146.391 45.1875H124.667ZM145.558 8.76188H148.371V19.8123L145.558 20.9185V8.76188ZM142.746 11.9297V22.0248L139.933 23.131V11.9297H142.746Z"/>
                        <path d="M79.527 0H73.902C73.1253 0 72.4957 0.629625 72.4957 1.40625V2.9475H68.277C67.5003 2.9475 66.8707 3.57712 66.8707 4.35375V9.11719H62.652C61.8753 9.11719 61.2457 9.74681 61.2457 10.5234V18.7725C60.8862 18.6929 60.682 18.6544 60.6649 18.6511C59.7133 18.4737 58.8706 19.2895 59.0165 20.2455L63.0684 46.8058C63.1731 47.4926 63.7638 48 64.4585 48H88.5992C89.2938 48 89.8846 47.4926 89.9893 46.8058C91.7547 35.2347 94.0119 20.4371 94.0412 20.2455C94.201 19.1984 93.1857 18.3543 92.1833 18.7081V7.35563C92.1833 6.579 91.5537 5.94938 90.7771 5.94938H85.1521C84.3754 5.94938 83.7458 6.579 83.7458 7.35563V9.11719H80.9333V1.40625C80.9332 0.629625 80.3036 0 79.527 0ZM79.6043 26.676L90.8924 22.236L88.849 35.6312C86.35 32.0608 83.1383 29.0967 79.6043 26.676ZM75.3082 2.8125H78.1207V24.2374L76.6516 24.8153C76.5183 24.7381 75.8724 24.3684 75.3082 24.064C75.3082 23.272 75.3082 3.59269 75.3082 2.8125ZM69.6832 5.76H72.4957V22.6531C71.6883 22.2766 70.6242 21.8129 69.6832 21.435C69.6832 20.6774 69.6832 6.23044 69.6832 5.76ZM64.0582 11.9297H66.8707V20.3869C65.9879 20.0828 64.9882 19.7647 64.0582 19.4966V11.9297ZM65.6665 45.1875L62.1104 21.8772C72.3321 24.5634 83.1746 30.5777 88.194 39.9247L87.3911 45.1875H65.6665ZM86.5582 8.76188H89.3707V19.8123L86.5582 20.9185V8.76188ZM83.7457 11.9297V22.0248L80.9332 23.131V11.9297H83.7457Z"/>
                        <path d="M20.527 0H14.902C14.1253 0 13.4957 0.629625 13.4957 1.40625V2.9475H9.27697C8.50035 2.9475 7.87072 3.57712 7.87072 4.35375V9.11719H3.65197C2.87535 9.11719 2.24572 9.74681 2.24572 10.5234V18.7725C1.88619 18.6929 1.682 18.6544 1.66494 18.6511C0.713286 18.4737 -0.129433 19.2895 0.0165356 20.2455L4.06841 46.8058C4.17313 47.4926 4.76375 48 5.45854 48H29.5992C30.2938 48 30.8846 47.4926 30.9893 46.8058C32.7547 35.2347 35.0119 20.4371 35.0412 20.2455C35.201 19.1984 34.1857 18.3543 33.1833 18.7081V7.35563C33.1833 6.579 32.5537 5.94938 31.7771 5.94938H26.1521C25.3754 5.94938 24.7458 6.579 24.7458 7.35563V9.11719H21.9333V1.40625C21.9332 0.629625 21.3036 0 20.527 0ZM20.6043 26.676L31.8924 22.236L29.849 35.6312C27.35 32.0608 24.1383 29.0967 20.6043 26.676ZM16.3082 2.8125H19.1207V24.2374L17.6516 24.8153C17.5183 24.7381 16.8724 24.3684 16.3082 24.064C16.3082 23.272 16.3082 3.59269 16.3082 2.8125ZM10.6832 5.76H13.4957V22.6531C12.6883 22.2766 11.6242 21.8129 10.6832 21.435C10.6832 20.6774 10.6832 6.23044 10.6832 5.76ZM5.05822 11.9297H7.87072V20.3869C6.98788 20.0828 5.98822 19.7647 5.05822 19.4966V11.9297ZM6.6665 45.1875L3.11038 21.8772C13.3321 24.5634 24.1746 30.5777 29.194 39.9247L28.3911 45.1875H6.6665ZM27.5582 8.76188H30.3707V19.8123L27.5582 20.9185V8.76188ZM24.7457 11.9297V22.0248L21.9332 23.131V11.9297H24.7457Z"/>
                        <path d="M138.527 72H132.902C132.125 72 131.496 72.6296 131.496 73.4062V74.9475H127.277C126.5 74.9475 125.871 75.5771 125.871 76.3537V81.1172H121.652C120.875 81.1172 120.246 81.7468 120.246 82.5234V90.7725C119.886 90.6929 119.682 90.6544 119.665 90.6511C118.713 90.4737 117.871 91.2895 118.017 92.2455L122.068 118.806C122.173 119.493 122.764 120 123.459 120H147.599C148.294 120 148.885 119.493 148.989 118.806C150.755 107.235 153.012 92.4371 153.041 92.2455C153.201 91.1984 152.186 90.3543 151.183 90.7081V79.3556C151.183 78.579 150.554 77.9494 149.777 77.9494H144.152C143.375 77.9494 142.746 78.579 142.746 79.3556V81.1172H139.933V73.4062C139.933 72.6296 139.304 72 138.527 72ZM138.604 98.676L149.892 94.236L147.849 107.631C145.35 104.061 142.138 101.097 138.604 98.676ZM134.308 74.8125H137.121V96.2374L135.652 96.8153C135.518 96.7381 134.872 96.3684 134.308 96.064C134.308 95.272 134.308 75.5927 134.308 74.8125ZM128.683 77.76H131.496V94.6531C130.688 94.2766 129.624 93.8129 128.683 93.435C128.683 92.6774 128.683 78.2304 128.683 77.76ZM123.058 83.9297H125.871V92.3869C124.988 92.0828 123.988 91.7647 123.058 91.4966V83.9297ZM124.667 117.188L121.11 93.8772C131.332 96.5634 142.175 102.578 147.194 111.925L146.391 117.188H124.667ZM145.558 80.7619H148.371V91.8123L145.558 92.9185V80.7619ZM142.746 83.9297V94.0248L139.933 95.131V83.9297H142.746Z"/>
                        <path d="M79.527 72H73.902C73.1253 72 72.4957 72.6296 72.4957 73.4062V74.9475H68.277C67.5003 74.9475 66.8707 75.5771 66.8707 76.3537V81.1172H62.652C61.8753 81.1172 61.2457 81.7468 61.2457 82.5234V90.7725C60.8862 90.6929 60.682 90.6544 60.6649 90.6511C59.7133 90.4737 58.8706 91.2895 59.0165 92.2455L63.0684 118.806C63.1731 119.493 63.7638 120 64.4585 120H88.5992C89.2938 120 89.8846 119.493 89.9893 118.806C91.7547 107.235 94.0119 92.4371 94.0412 92.2455C94.201 91.1984 93.1857 90.3543 92.1833 90.7081V79.3556C92.1833 78.579 91.5537 77.9494 90.7771 77.9494H85.1521C84.3754 77.9494 83.7458 78.579 83.7458 79.3556V81.1172H80.9333V73.4062C80.9332 72.6296 80.3036 72 79.527 72ZM79.6043 98.676L90.8924 94.236L88.849 107.631C86.35 104.061 83.1383 101.097 79.6043 98.676ZM75.3082 74.8125H78.1207V96.2374L76.6516 96.8153C76.5183 96.7381 75.8724 96.3684 75.3082 96.064C75.3082 95.272 75.3082 75.5927 75.3082 74.8125ZM69.6832 77.76H72.4957V94.6531C71.6883 94.2766 70.6242 93.8129 69.6832 93.435C69.6832 92.6774 69.6832 78.2304 69.6832 77.76ZM64.0582 83.9297H66.8707V92.3869C65.9879 92.0828 64.9882 91.7647 64.0582 91.4966V83.9297ZM65.6665 117.188L62.1104 93.8772C72.3321 96.5634 83.1746 102.578 88.194 111.925L87.3911 117.188H65.6665ZM86.5582 80.7619H89.3707V91.8123L86.5582 92.9185V80.7619ZM83.7457 83.9297V94.0248L80.9332 95.131V83.9297H83.7457Z"/>
                        <path d="M20.527 72H14.902C14.1253 72 13.4957 72.6296 13.4957 73.4062V74.9475H9.27697C8.50035 74.9475 7.87072 75.5771 7.87072 76.3537V81.1172H3.65197C2.87535 81.1172 2.24572 81.7468 2.24572 82.5234V90.7725C1.88619 90.6929 1.682 90.6544 1.66494 90.6511C0.713286 90.4737 -0.129433 91.2895 0.0165356 92.2455L4.06841 118.806C4.17313 119.493 4.76375 120 5.45854 120H29.5992C30.2938 120 30.8846 119.493 30.9893 118.806C32.7547 107.235 35.0119 92.4371 35.0412 92.2455C35.201 91.1984 34.1857 90.3543 33.1833 90.7081V79.3556C33.1833 78.579 32.5537 77.9494 31.7771 77.9494H26.1521C25.3754 77.9494 24.7458 78.579 24.7458 79.3556V81.1172H21.9333V73.4062C21.9332 72.6296 21.3036 72 20.527 72ZM20.6043 98.676L31.8924 94.236L29.849 107.631C27.35 104.061 24.1383 101.097 20.6043 98.676ZM16.3082 74.8125H19.1207V96.2374L17.6516 96.8153C17.5183 96.7381 16.8724 96.3684 16.3082 96.064C16.3082 95.272 16.3082 75.5927 16.3082 74.8125ZM10.6832 77.76H13.4957V94.6531C12.6883 94.2766 11.6242 93.8129 10.6832 93.435C10.6832 92.6774 10.6832 78.2304 10.6832 77.76ZM5.05822 83.9297H7.87072V92.3869C6.98788 92.0828 5.98822 91.7647 5.05822 91.4966V83.9297ZM6.6665 117.188L3.11038 93.8772C13.3321 96.5634 24.1746 102.578 29.194 111.925L28.3911 117.188H6.6665ZM27.5582 80.7619H30.3707V91.8123L27.5582 92.9185V80.7619ZM24.7457 83.9297V94.0248L21.9332 95.131V83.9297H24.7457Z"/>
                    </svg>,
                text: `6 порций картофеля фри`
            },
        }

    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step28">
                <TextBlock
                    text={`Некоторые владельцы предлагают животным пищу со своего стола или лакомства, предназначенные для людей. Это может вызывать проблемы, так как лакомства для людей обычно более калорийны, чем специальные лакомства для животных.
                    
                    Допустим, тунец — это лакомство для кошки, а картофель фри — для человека. <span class="text_blue">
                    
                    <b>Как вы думаете, одна банка консервов тунца для кошки эквивалентна скольким порциям картофеля фри для человека?</b></span>`}
                />
                <InfoBlock
                    text={`Выберите подходящий вариант ответа.`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'imgText'}
                    rightAnswers={[3]}
                    showNextBtn={this.showNextBtn}
                    quantity={'33.25'}
                    correctText={`1 банка тунца эквивалентна для кошек целым 6 порциям картофеля фри для человека! Чтобы убедиться в том, что человеческая еда, как правило, излишне калорийна для животных, изучите таблицу далее.`}
                />
            </div>
        )
    }
}
