import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step45-1.png'


export default class step45 extends React.Component{

    render() {
        return (
            <div className="step45">
                <ul className="list">
                    <li className="list_item">
                        Для кошек, которые царапают ножки стула или углы дивана, необходима вертикальная когтеточка достаточной высоты для обеспечения отличной растяжки.
                    </li>
                    <li className="list_item">
                        Для кошек, которые любят царапать циновки и ковры – горизонтальные когтеточки или специальный коврик.
                    </li>
                    <li className="list_item">
                        Большинство кошек предпочитают когтеточки, сделанные из грубого материала, который они могут измельчить, например, из сизаля.
                    </li>
                    <li className="list_item">
                        Выбирая материал, отдайте предпочтение прочным устойчивым конструкциям, чтобы когтеточка не смогла упасть во время использования.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
