import React from 'react'

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <ul className="list">
                    <li className="list_item">
                        Психологический настрой вкупе с надлежащим подходом к физическому обследованию кошки со стороны ветеринарных врачей имеют решающее значение для успешного взаимодействия с осматриваемой кошкой. Будьте терпеливы, спокойны, позитивны и уверены в себе.
                    </li>
                    <li className="list_item">
                        Умение проявить терпение во взаимодействии с «кошачьими» пациентами повышает эффективность успешного и быстрого обследования. Поспешный подход может вызвать у кошки беспокойство либо привести к неполному обследованию или лечению. Если кошка остается спокойной и расслабленной во время обследования, ее владелец обретет доверие к ветеринарному врачу.
                    </li>
                    <li className="list_item">
                        Отметьте в медицинской карте больной кошки <b>подходы</b>, которые были ранее успешны или, наоборот, не имели успеха.
                    </li>
                    <li className="list_item">
                        Откройте дверцу кошачьей переноски или снимите с нее крышку, чтобы кошка могла выйти самостоятельно. Это даст кошке чувство контроля и безопасности. Избегайте насильственного изъятия кошки из кошачьей переноски. Лакомство или игрушки могут побудить к выходу кошек, которые отказываются это делать.
                    </li>
                    <li className="list_item">
                        Осмотрите кошку <b>там, где она вам позволяет</b>. Обследование может осуществляться на смотровой кушетке, на полу, весах или в кошачьей переноске.
                    </li>
                </ul>
            </div>
        )
    }
}
