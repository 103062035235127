import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson11/step32-1.png";


export default class step32 extends React.Component {

    render() {
        return (
            <div className="step32">
                <div className="text_top">
                    Как вы знаете, инструкции по кормлению, написанные на упаковках кормов, являются только ориентиром. Хозяин должен постоянно следить за общим состоянием питомца путем оценки упитанности.
                </div>
                <div className="block">
                    <span className="block_violet">Количественная оценка упитанности</span> – очень полезный технический приём, позволяющий определить кондицию тела и отследить ее изменения с течением времени.
                    <img src={img1} alt="" className="block_img"/>
                </div>
                <TextBlock
                    text={`Чтобы оценить упитанность животного, нужно посмотреть на него с двух ракурсов:`}
                />
                <ul className="list">
                    <li className="list_item">вид сверху;</li>
                    <li className="list_item">вид сбоку в профиль.</li>
                </ul>
                <TextBlock
                    text={`Стоит обратить внимание, насколько сильно выступают грудная клетка и позвоночник и насколько легко их почувствовать наощупь. Осмотр питомца позволит сделать полезные выводы и скорректировать его питание.`}
                />
            </div>
        )
    }
}
