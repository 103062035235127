import React from 'react'
// import MainPage from "../Page/Main";
import MyTraining from "../Page/MyTraining";
import MyTesting from "../Page/MyTesting";
import OrganizersInformation from "../Page/OrganizersInformation";
import MemberOrganization from "../Page/MemberOrganization";
import AboutProject from "../Page/AboutProject";
import Faq from "../Page/Faq";
import Video from "../Page/Video";
import { Switch, Route, Redirect } from 'react-router-dom'
import TestPage from "../Page/Test";
import Lessons from "../Page/Lessons";
import ProfileCard from "../Components/ProfileCard";
import CoocieForm from "../Page/CoocieForm"
import Dostupnost from "../Page/dostupnost"
import Accreditation from "../Page/Accreditation"
import AccreditationTest from "../Page/AccreditationTest"
import AccreditatedClinics from "../Page/AccreditatedClinics"
import NoteInside from "../Page/NoteInside"


export default class Main extends React.Component{

    state = {
        data: this.props.data
    }

    render() {
        return (
            <main>
                <Switch>
                    <Route
                        exact
                        path='/'
                        render={() => {
                            return (
                                <Redirect to="/myTraining" />
                            )
                        }}
                    />
                    <Route exact path='/organizersInformation' component={OrganizersInformation}/>
                    {/*<Route exact path='/memberOrganization' component={MemberOrganization}/>*/}
                    <Route exact path='/aboutProject' component={AboutProject}/>
                    <Route path='/myTraining' children={<MyTraining data={this.state.data}/>}/>
                    <Route path='/myTesting' component={MyTesting}/>
                    <Route path='/faq' component={Faq}/>
                    <Route path='/video/:id/:id' component={Video}/>
                    <Route path='/video' component={Video}/>
                    <Route path='/test' component={TestPage}/>
                    <Route path='/lesson' component={Lessons}/>
                    <Route path='/profileCard' component={ProfileCard}/>
                    <Route path='/coociesform' component={CoocieForm}/>
                    <Route path='/dostupnost' component={Dostupnost}/>
                    <Route path='/accreditation' component={Accreditation}/>
                    <Route path='/testform' component={AccreditationTest}/>
                    <Route path='/accreditatedclinics' component={AccreditatedClinics}/>
                    <Route path='/noteInside' component={NoteInside}/> {/*Внутренняя стартовая*/}
                </Switch>
            </main>
        )
    }
}
