import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step19-1.png";


export default class step19 extends React.Component {

    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`<b>Кошки-самцы обычно живут одни</b>, независимо от источника пищи, поскольку они также конкурируют с другими самцами за самок.
                    
                    Кошки могут быть агрессивными, когда защищают свою территорию, а могут быть, наоборот, боязливыми, когда им угрожают. Кроме того, кошки могут участвовать в игре друг с другом (особенно котята) и ухаживать за шерстью друг друга в течение всего взрослого периода жизни.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
