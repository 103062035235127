import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson31/step13-1.png'
import img2 from '../../../image/lessons/lesson31/step13-2.png'


export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="head">
                    Классификация по балансу питательных веществ
                </div>
                <TextBlock
                    text={`Как вы помните, по балансу питательных веществ готовые рационы делятся на полнорационные и лакомства.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Полнорационные корма
                        </h3>
                        <p className="flex_el_text">
                            <b>Полностью удовлетворяют все потребности</b> животного. Питомца можно кормить готовыми полнорационными кормами <b>на протяжении всей его жизни.</b>
                        </p>
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Лакомства
                        </h3>
                        <p className="flex_el_text">
                            <b>Не могут использоваться как основное питание,</b> а являются <b>дополнением</b> к нему. Могут решать как особые задачи (функциональные лакомства), так и использоваться для поощрения питомца и выражения любви хозяина.
                        </p>
                        <img className="flex_el_img" src={img2} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
