import React from 'react'
import './style.sass'

export default class PanelStudy extends React.Component {
    state = {
        team: '',
    };

    componentDidMount() {
    }


    render() {
        const {team} = this.state;
        const {userSteps, lesson} = this.props;

        return (
            <div
                className={'PanelStudy ' + (userSteps && 'active') + (this.props.steps === '0' ? ' hidden' : '')}
            >
                {!this.props.active &&
                    <div className="disabled"/>
                }
                {this.props.finished !== 0 && this.props.finished !== false &&
                <div className="done">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <circle cx="15" cy="15" r="15" fill="#A7268E"/>
                        <line x1="8.12132" y1="15" x2="13" y2="19.8787" stroke="white" strokeWidth="3"
                              strokeLinecap="square" strokeLinejoin="round"/>
                        <line x1="22" y1="11.1213" x2="13.1213" y2="20" stroke="white" strokeWidth="3"
                              strokeLinecap="square" strokeLinejoin="round"/>
                    </svg>
                </div>
                }
                <div className={"name"}>{this.props.name}</div>
                <div className="desc">
                    <div className="steps">
                        <span className={'userSteps'}>{this.props.userSteps}</span>
                        <span className={'allStep'}>{this.props.steps}</span>
                    </div>
                    <div className="text">
                        <span>
                            {this.props.text}
                        </span>
                        <div className="button">
                            <button
                                className="btn btn_default" /*{"btn btn_blue" + (this.props.userSteps === 0 ? 'btn_white' : 'btn_blue')}*/
                                onClick={()=>{
                                    this.props.history.push('/test', lesson );
                                }}
                            >
                                {this.props.finished === 0 || this.props.finished === false  ? 'Пройти тест' : 'пройти заново'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="star" style={{color: team.baseTextColor}}>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={'#fff'} fillRule="evenodd" clipRule="evenodd"
                              d="M10.4853 0L14.1566 6L21 7.64706L16.4476 13L16.9762 20L10.4853 17.3235L3.99441 20L4.55245 13L0 7.64706L6.81399 6L10.4853 0ZM10.4853 2.67647L7.69511 7.20588L2.55524 8.47059L6.02098 12.5294L5.60979 17.8235L10.5147 15.7941L15.4196 17.8235L15.0084 12.5294L18.4448 8.47059L13.2755 7.23529L10.4853 2.67647Z"/>
                    </svg>
                    {this.props.balls} баллов
                    <div className="line "/>
                </div>
            </div>
        )
    }
}
