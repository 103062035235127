import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/spirina.png'
import img2 from '../../../image/Lecturers/spirina-icon.png'
import img3 from '../../../image/Lecturers/gorshkov.png'
import img4 from '../../../image/Lecturers/gorshkov-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Синдром орофациальной боли кошек – стоматолог и/или невролог?`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Спирина'}
                    name={'Анна Сергеевна'}
                    text={'Ветеринарный врач, к.в.н., Президент Национального ветеринарного стоматологического союза; Руководитель отделения ветеринарной стоматологии и ЧЛХ сети ветеринарных клиник «Денталвет»,\n' +
                    'г. Москва. Старший преподаватель УВЦ «Денталвет». Докторант ДГТУ, заведующий кафедрой ветеринарной стоматологии и ЧЛХ ДГТУ'}
                />

                <Lecturer
                    img={img3}
                    icon={img4}
                    surname={'Горшков'}
                    name={'Сергей Сергеевич'}
                    text={'Ветеринарный врач-хирург, ветеринарная клиника БЭСТ, г. Новосибирск'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
