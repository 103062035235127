import React from 'react'
import './style.sass'
import music1 from "../../music/Fostog's_Touch_1.mp3";
import music2 from "../../music/Luna_and_June's_Purrfect_Harmony_2.mp3";
import music3 from "../../music/Penelope's_Nocturne.mp3";
import music4 from "../../music/Musica_para_Ojos.mp3";
import music5 from "../../music/Potato's_Song.mp3";
import music6 from "../../music/Rusty's_Ballad.mp3";
import music7 from "../../music/Whiskas.mp3";

export default props => {

    const playList = [
        music1,
        music2,
        music3,
        music4,
        music5,
        music6,
        music7,
    ];

    return (
        <div className="music">
            <div className="music__header">Музыка для кошек:</div>
            {playList.map((item, index) => {
                return (
                    <div className="music__block" key={index}>
                        <p className="music__title">{item.replace('/static/media/', '').slice(0, -13).replace(new RegExp("_", "g"), ' ')}</p>
                        <audio controls>
                            <source src={item} type="audio/mpeg"/>
                            Your browser does not support the audio element.
                        </audio>
                        <a className="music__download" href={item} target="_blank" download>Скачать</a>
                    </div>
                )
            })}
        </div>
    )
}
