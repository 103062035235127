import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step7-1.png";

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <div className="text-top">
                    <ul>
                        <li>
                            Ежедневно нужно выделять достаточное время для игры с кошкой.
                        </li>
                        <li>
                            Можно использовать  интерактивные игрушки, например, лазерные указки и дразнилки из перьев, чтобы играть с кошкой.
                        </li>
                        <li>
                            Когда будут найдены подходящие игрушки, нужно предоставить кошке возможность выбрать из нескольких игрушек.
                        </li>
                        <li>
                            Менять игрушки нужно каждые несколько дней, чтобы заинтересовать кошку. Часть игрушек можно периодически прятать, а через некоторое время доставать. Так вы заново заинтересуете кошку уже знакомыми игрушками.
                        </li>
                        <li>
                            Можно также спрятать вкусные угощения в разных местах по всему дому, чтобы кошка могла охотиться за ними.
                        </li>
                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
