import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

// import img1 from "../../../image/lessons/lesson2/step2-1.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="text-top">
                    По мере того, как кошки становятся все более озабоченными своим окружением, они будут стараться избегать контакта с угрозами. Оценка таких угроз происходит по данной шкале и может быстро меняться в зависимости от серьезности угрозы. Самые высокие баллы обычно присваиваются самым стрессовым угрозам, от которых кошка не в состоянии скрыться.
                </div>
                <div className="emotion">
                    <div className="emotion_box">
                        <div className="emotion_circle">
                            1
                            <div className="emotion_circle_text top">
                                Расслабленное состояние
                            </div>
                        </div>
                        <div className="emotion_circle">
                            2
                            <div className="emotion_circle_text bottom">
                                Состояние тревоги
                            </div>
                        </div>
                        <div className="emotion_circle">
                            3
                            <div className="emotion_circle_text top">
                                Состояние настороже
                            </div>
                        </div>
                        <div className="emotion_circle">
                            4
                            <div className="emotion_circle_text bottom">
                                Состояние беспокойства
                            </div>
                        </div>
                        <div className="emotion_circle">
                            5
                            <div className="emotion_circle_text top">
                                Состояние легкого испуга
                            </div>
                        </div>
                        <div className="emotion_circle">
                            6
                            <div className="emotion_circle_text bottom">
                                Сильный испуг
                            </div>
                        </div>
                    </div>
                    <div className="emotion_line"></div>
                </div>
                <div className="info">
                    Характеристики каждого уровня стресса с иллюстрациями будут представлены далее в уроке
                </div>
            </div>
        )
    }
}
