import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import OnClickCircleBlock from "../../../Components/LessonAndTestComponents/OnClickCircleBlock";

export default class step8 extends React.Component {
    state = {
        flexData: [
            <svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7109 5.625C14.2934 5.625 14.7656 5.1528 14.7656 4.57031C14.7656 3.98782 14.2934 3.51562 13.7109 3.51562C13.1284 3.51562 12.6562 3.98782 12.6562 4.57031C12.6562 5.1528 13.1284 5.625 13.7109 5.625Z"/>
                <path d="M13.7109 20.6821C14.5236 20.6821 15.2878 20.3654 15.8629 19.7902C16.2748 19.3783 16.2748 18.7106 15.8629 18.2987C15.4511 17.8868 14.7833 17.8868 14.3714 18.2987C14.1946 18.4754 13.9601 18.5727 13.7109 18.5727C13.4617 18.5727 13.2271 18.4754 13.0504 18.2987C12.6385 17.8868 11.9707 17.8868 11.5588 18.2987C11.1469 18.7106 11.1469 19.3783 11.5588 19.7902C12.134 20.3654 12.8983 20.6821 13.7109 20.6821Z"/>
                <path d="M21.4453 24.6094H18.2812V23.0206C19.9931 21.6674 21.0938 19.5731 21.0938 17.2266V6.67969C21.0938 5.0123 19.797 3.64296 18.1592 3.52484C17.685 1.50715 15.8712 0 13.7109 0C11.5506 0 9.73687 1.50715 9.26269 3.52484C7.6249 3.64296 6.32812 5.0123 6.32812 6.67969V17.2266C6.32812 19.5731 7.42873 21.6674 9.14062 23.0206V24.6094H5.97656C2.68109 24.6094 0 27.2905 0 30.5859V34.9453C0 35.5278 0.472219 36 1.05469 36H26.3672C26.9497 36 27.4219 35.5278 27.4219 34.9453V30.5859C27.4219 27.2905 24.7408 24.6094 21.4453 24.6094ZM19.2656 32.8359C19.2656 32.2544 19.7388 31.7812 20.3203 31.7812C20.9019 31.7812 21.375 32.2544 21.375 32.8359C21.375 33.4175 20.9019 33.8906 20.3203 33.8906C19.7388 33.8906 19.2656 33.4175 19.2656 32.8359ZM19.2656 26.7188V29.8533C18.038 30.2887 17.1562 31.461 17.1562 32.8359C17.1562 33.2056 17.2206 33.5605 17.3377 33.8906H14.7656V31.5915L17.8111 26.7188H19.2656ZM13.7109 2.10938C15.0679 2.10938 16.1719 3.21335 16.1719 4.57031C16.1719 5.92727 15.0679 7.03125 13.7109 7.03125C12.354 7.03125 11.25 5.92727 11.25 4.57031C11.25 3.21335 12.354 2.10938 13.7109 2.10938ZM8.4375 6.67969C8.4375 6.17428 8.79504 5.75114 9.27035 5.64891C9.75649 7.65 11.5624 9.14062 13.7109 9.14062C15.8594 9.14062 17.6654 7.65 18.1515 5.64891C18.6268 5.75114 18.9844 6.17428 18.9844 6.67969V10.5469H8.4375V6.67969ZM8.4375 17.2266V12.6562H18.9844V17.2266C18.9844 20.1343 16.6187 22.5 13.7109 22.5C10.8032 22.5 8.4375 20.1343 8.4375 17.2266ZM13.7109 24.6094C14.5735 24.6094 15.4017 24.4602 16.1719 24.1871V25.3616L13.7109 29.2991L11.25 25.3616V24.1871C12.0201 24.4602 12.8483 24.6094 13.7109 24.6094ZM2.10938 30.5859C2.10938 28.4536 3.8442 26.7188 5.97656 26.7188H9.61073L12.6562 31.5915V33.8906H2.10938V30.5859ZM25.3125 33.8906H23.303C23.42 33.5605 23.4844 33.2056 23.4844 32.8359C23.4844 31.461 22.6026 30.2887 21.375 29.8533V26.7188H21.4453C23.5777 26.7188 25.3125 28.4536 25.3125 30.5859V33.8906Z"/>
            </svg>,
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 21C16 20.4477 16.4477 20 17 20H28C28.5523 20 29 20.4477 29 21C29 21.5523 28.5523 22 28 22H17C16.4477 22 16 21.5523 16 21Z"/>
                <path fillRule="evenodd" clip-rule="evenodd" d="M12.8536 9.14645C13.0488 9.34171 13.0488 9.65829 12.8536 9.85355L10.3536 12.3536C10.1583 12.5488 9.84171 12.5488 9.64645 12.3536L8.14645 10.8536C7.95118 10.6583 7.95118 10.3417 8.14645 10.1464C8.34171 9.95118 8.65829 9.95118 8.85355 10.1464L10 11.2929L12.1464 9.14645C12.3417 8.95118 12.6583 8.95118 12.8536 9.14645ZM12.8536 14.1464C13.0488 14.3417 13.0488 14.6583 12.8536 14.8536L10.3536 17.3536C10.1583 17.5488 9.84171 17.5488 9.64645 17.3536L8.14645 15.8536C7.95118 15.6583 7.95118 15.3417 8.14645 15.1464C8.34171 14.9512 8.65829 14.9512 8.85355 15.1464L10 16.2929L12.1464 14.1464C12.3417 13.9512 12.6583 13.9512 12.8536 14.1464ZM12.8536 19.1464C13.0488 19.3417 13.0488 19.6583 12.8536 19.8536L10.3536 22.3536C10.1583 22.5488 9.84171 22.5488 9.64645 22.3536L8.14645 20.8536C7.95118 20.6583 7.95118 20.3417 8.14645 20.1464C8.34171 19.9512 8.65829 19.9512 8.85355 20.1464L10 21.2929L12.1464 19.1464C12.3417 18.9512 12.6583 18.9512 12.8536 19.1464Z"/>
                <path d="M16 16C16 15.4477 16.4477 15 17 15H28C28.5523 15 29 15.4477 29 16C29 16.5523 28.5523 17 28 17H17C16.4477 17 16 16.5523 16 16Z"/>
                <path d="M16 11C16 10.4477 16.4477 10 17 10H28C28.5523 10 29 10.4477 29 11C29 11.5523 28.5523 12 28 12H17C16.4477 12 16 11.5523 16 11Z"/>
                <path fillRule="evenodd" clip-rule="evenodd" d="M33.375 6.75H33.0001V24.75H33.3751C33.9961 24.75 34.5001 25.254 34.5001 25.875C34.5001 26.496 33.9961 27 33.3751 27H19.9532L25.2796 34.2062C25.6486 34.7057 25.5436 35.4107 25.0441 35.7797C24.8431 35.9282 24.6076 36.0002 24.3751 36.0002C24.0316 36.0002 23.6896 35.8427 23.4691 35.5442L19.125 29.6655V34.8753C19.125 35.4963 18.621 36.0003 18 36.0003C17.379 36.0003 16.875 35.4963 16.875 34.8753V29.6653L12.5296 35.5442C12.1606 36.0452 11.4571 36.1487 10.9561 35.7797C10.4566 35.4107 10.3516 34.7057 10.7206 34.2062L16.0469 27H2.62507C2.00407 27 1.50007 26.496 1.50007 25.875C1.50007 25.254 2.00407 24.75 2.62507 24.75H2.99992V6.75H2.625C1.1775 6.75 0 5.5725 0 4.125V2.625C0 1.1775 1.1775 0 2.625 0H33.375C34.8225 0 36 1.1775 36 2.625V4.125C36 5.5725 34.8225 6.75 33.375 6.75ZM4.12073 4.5H2.625C2.418 4.5 2.25 4.332 2.25 4.125V2.625C2.25 2.418 2.418 2.25 2.625 2.25H33.375C33.582 2.25 33.75 2.418 33.75 2.625V4.125C33.75 4.332 33.582 4.5 33.375 4.5H31.8793L31.8751 4.49999L31.8709 4.5H4.1291L4.12492 4.49999L4.12073 4.5ZM5.24992 6.75V24.75H30.7501V6.75H5.24992Z"/>
            </svg>,
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M35.9297 4.80661C35.7718 4.35314 35.3774 4.03701 34.9 3.9818L32.3163 3.6838L32.018 1.10036C31.9631 0.622998 31.6473 0.228313 31.1935 0.070384C30.7401 -0.0878197 30.2473 0.0250652 29.9076 0.364819L24.8003 5.4721C24.5308 5.74155 24.4012 6.11316 24.4449 6.49191L24.8143 9.69389L23.6766 10.8318C18.0546 5.9761 9.52429 6.21561 4.18931 11.5506C-1.39644 17.1363 -1.39644 26.2251 4.18931 31.8108C9.77505 37.3966 18.8638 37.3966 24.4495 31.8108C29.7845 26.4758 30.024 17.9455 25.1683 12.3238L26.3062 11.1858L29.5082 11.5553C29.8867 11.5989 30.2586 11.4696 30.528 11.1998L35.6353 6.09256C35.9751 5.75281 36.0877 5.26007 35.9297 4.80661ZM22.9576 30.3191C18.1944 35.0823 10.4441 35.0823 5.68098 30.3191C0.917842 25.5557 0.917842 17.8057 5.68098 13.0423C10.1931 8.53043 17.3853 8.29258 22.18 12.3284L20.2511 14.2573C16.5237 11.2746 11.0533 11.5097 7.60085 14.9621C3.89625 18.6667 3.89625 24.6947 7.60085 28.3993C11.3054 32.1039 17.3334 32.1039 21.038 28.3993C24.4905 24.9468 24.7256 19.4764 21.7428 15.749L23.6717 13.8201C27.7075 18.6148 27.4697 25.807 22.9576 30.3191ZM13.7623 22.2378C14.1743 22.6498 14.8422 22.6498 15.2542 22.2378L16.6566 20.8352C16.9714 21.7116 16.7783 22.7309 16.0774 23.4318C15.11 24.3994 13.5357 24.3994 12.5683 23.4318C11.6007 22.4644 11.6007 20.8904 12.5683 19.9227C13.269 19.2218 14.2885 19.0287 15.1647 19.3435L13.7623 20.7462C13.3503 21.1579 13.3503 21.8259 13.7623 22.2378ZM16.7366 17.7716C14.9669 16.6779 12.6098 16.8976 11.0764 18.4311C9.28643 20.221 9.28643 23.1338 11.0764 24.9237C12.8663 26.7137 15.7791 26.714 17.569 24.9237C19.1025 23.3903 19.3222 21.0332 18.2285 19.2636L20.239 17.2528C22.4058 20.146 22.1751 24.2786 19.546 26.9076C16.664 29.7896 11.9745 29.7896 9.09252 26.9076C6.21052 24.0256 6.21052 19.3361 9.09252 16.4541C11.7213 13.825 15.8541 13.594 18.7471 15.7611L16.7366 17.7716ZM29.333 9.41127L26.8729 9.12727L26.5889 6.66715L30.1284 3.12761L30.3069 4.67312C30.369 5.21118 30.7881 5.63086 31.327 5.69348L32.8725 5.87173L29.333 9.41127Z"/>
            </svg>,
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 15C17.9865 15 17.9715 15 17.958 15C17.619 14.9865 17.304 14.8215 17.1 14.55L15.1875 12H11.625C10.1775 12 9 10.8225 9 9.375V2.625C9 1.1775 10.1775 0 11.625 0H24.375C25.8225 0 27 1.1775 27 2.625V9.375C27 10.8225 25.8225 12 24.375 12H21.135L18.846 14.616C18.633 14.8605 18.324 15 18 15ZM11.625 2.25C11.418 2.25 11.25 2.418 11.25 2.625V9.375C11.25 9.582 11.418 9.75 11.625 9.75H15.75C16.104 9.75 16.437 9.9165 16.65 10.2L18.0675 12.09L19.779 10.1355C19.992 9.8895 20.301 9.75 20.625 9.75H24.375C24.582 9.75 24.75 9.582 24.75 9.375V2.625C24.75 2.418 24.582 2.25 24.375 2.25H11.625Z"/>
                <path d="M30 16.5C29.985 16.5 29.97 16.5 29.9565 16.5C29.616 16.4865 29.301 16.32 29.0985 16.0485L27.9135 14.4585C27.5415 13.9605 27.645 13.2555 28.143 12.8835C28.6425 12.5115 29.346 12.615 29.718 13.113L30.0705 13.587L31.779 11.6355C31.992 11.3895 32.301 11.25 32.625 11.25H33.375C33.579 11.25 33.75 11.079 33.75 10.875V4.125C33.75 3.921 33.579 3.75 33.375 3.75H31.125C30.504 3.75 30 3.246 30 2.625C30 2.004 30.504 1.5 31.125 1.5H33.375C34.8225 1.5 36 2.6775 36 4.125V10.875C36 12.3225 34.8225 13.5 33.375 13.5H33.135L30.846 16.116C30.633 16.3605 30.324 16.5 30 16.5Z"/>
                <path d="M6 16.5C5.676 16.5 5.367 16.3605 5.154 16.116L2.865 13.5H2.625C1.1775 13.5 0 12.3225 0 10.875V4.125C0 2.6775 1.1775 1.5 2.625 1.5H4.875C5.496 1.5 6 2.004 6 2.625C6 3.246 5.496 3.75 4.875 3.75H2.625C2.421 3.75 2.25 3.921 2.25 4.125V10.875C2.25 11.079 2.421 11.25 2.625 11.25H3.375C3.699 11.25 4.008 11.3895 4.221 11.634L5.9295 13.5855L6.282 13.1115C6.654 12.6135 7.3575 12.51 7.857 12.882C8.355 13.254 8.4585 13.959 8.0865 14.457L6.9015 16.047C6.699 16.32 6.384 16.4865 6.0435 16.5C6.03 16.5 6.015 16.5 6 16.5Z"/>
                <path d="M30 25.5C28.3455 25.5 27 24.1545 27 22.5C27 20.8455 28.3455 19.5 30 19.5C31.6545 19.5 33 20.8455 33 22.5C33 24.1545 31.6545 25.5 30 25.5ZM30 21.75C29.586 21.75 29.25 22.086 29.25 22.5C29.25 22.914 29.586 23.25 30 23.25C30.414 23.25 30.75 22.914 30.75 22.5C30.75 22.086 30.414 21.75 30 21.75Z"/>
                <path d="M34.875 33C34.254 33 33.75 32.496 33.75 31.875V31.125C33.75 30.0915 32.9085 29.25 31.875 29.25H28.125C27.504 29.25 27 28.746 27 28.125C27 27.504 27.504 27 28.125 27H31.875C34.1505 27 36 28.8495 36 31.125V31.875C36 32.496 35.496 33 34.875 33Z"/>
                <path d="M6 25.5C4.3455 25.5 3 24.1545 3 22.5C3 20.8455 4.3455 19.5 6 19.5C7.6545 19.5 9 20.8455 9 22.5C9 24.1545 7.6545 25.5 6 25.5ZM6 21.75C5.586 21.75 5.25 22.086 5.25 22.5C5.25 22.914 5.586 23.25 6 23.25C6.414 23.25 6.75 22.914 6.75 22.5C6.75 22.086 6.414 21.75 6 21.75Z"/>
                <path d="M1.125 33C0.504 33 0 32.496 0 31.875V31.125C0 28.8495 1.8495 27 4.125 27H7.875C8.496 27 9 27.504 9 28.125C9 28.746 8.496 29.25 7.875 29.25H4.125C3.0915 29.25 2.25 30.0915 2.25 31.125V31.875C2.25 32.496 1.746 33 1.125 33Z"/>
                <path d="M18 26.25C15.519 26.25 13.5 24.231 13.5 21.75C13.5 19.269 15.519 17.25 18 17.25C20.481 17.25 22.5 19.269 22.5 21.75C22.5 24.231 20.481 26.25 18 26.25ZM18 19.5C16.7595 19.5 15.75 20.5095 15.75 21.75C15.75 22.9905 16.7595 24 18 24C19.2405 24 20.25 22.9905 20.25 21.75C20.25 20.5095 19.2405 19.5 18 19.5Z"/>
                <path d="M25.125 36C24.504 36 24 35.496 24 34.875V32.625C24 31.5915 23.1585 30.75 22.125 30.75H13.875C12.8415 30.75 12 31.5915 12 32.625V34.875C12 35.496 11.496 36 10.875 36C10.254 36 9.75 35.496 9.75 34.875V32.625C9.75 30.3495 11.5995 28.5 13.875 28.5H22.125C24.4005 28.5 26.25 30.3495 26.25 32.625V34.875C26.25 35.496 25.746 36 25.125 36Z"/>
            </svg>]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step6">
                <InfoBlock
                    text={`Нажмите на иконки, чтобы получить информацию`}
                />
                <OnClickCircleBlock
                    flexData={flexData}
                    showNextBtn={this.showNextBtn}
                >
                    <div className="info_wrap">
                        <TextBlock
                            text={` <b>Шаг 1. Назначьте ответственного сотрудника, который будет отвечать за внедрение рекомендаций в вашу ветеринарную практику.</b>
                            
                            Выберите обученного сотрудника, который понимает поведение кошек, их потребности и процессы течения заболеваний, а также готов обучать других членов ветеринарной бригады и давать рекомендации или советы владельцам по уходу за кошками. Распорядитесь о том, чтобы выбранный вами сотрудник по уходу за кошками рекомендовал соответствующие изменения в отделениях ветеринарной клиники, оборудовании, процедурах и поведении персонала, что позволит улучшить уход за кошками.`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`<b>Шаг 2. Внедрите комплекс необходимых мероприятий для реализации настоящих Руководящих принципов.</b>
                            
                            Обеспечьте проведение очередных совещаний сотрудников ветеринарной клиники для оценки прогресса и внесения соответствующих корректив в первоначальный план.`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`<b>Шаг 3. Выберите из урока две или три инициативы по уходу за больными кошками, и сосредоточьтесь непосредственно на них.</b>
                            
                            Постепенный прогресс будет более успешным, нежели попытка выполнить все рекомендации сразу.`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`<b>Шаг 4. Периодически проводите совещания ветеринарных врачей.</b>
                            
                            Обсуждайте оптимальные подходы к выполнению рекомендаций, содержащихся в данном уроке, проводите обзор достигнутого прогресса и поиск решений проблемных вопросов.`}
                        />
                    </div>
                </OnClickCircleBlock>
            </div>
        )
    }

}
