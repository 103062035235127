import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step4-1.png";

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <div className="text-top">
                    <p>Важным шагом будет сразу решить, держать ли кошку исключительно в помещении или позволить ей выходить на улицу. Если решением владельца будет держать кошку только в помещении, возможно будет избежать большого количества проблем со здоровьем кошки, включая снижение риска травм, инфекционных заболеваний и паразитов.</p>
                    <p><b>Если твердо решено, что кошка будет жить исключительно в доме, следующие рекомендации помогут облегчить период адаптации к новым условиям.</b></p>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
