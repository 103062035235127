import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step30-1.png";
import img2 from "../../../image/lessons/lesson6/step30-2.png";


export default class step30 extends React.Component {

    render() {
        return (
            <div className="step30">
                <div className="head">
                    Уши
                </div>
                <TextBlock
                    text={`Прижатые назад уши сообщают о том, что кошка раздражена, напугана или готовится к атаке. Если же уши кошки направлены вперед, то это значит, что она чем-то заинтересована.
                    
                    <b>Обратите внимание, что постоянно поникшие уши могут быть признаками боли или плохого самочувствия у кошек, и это требует контроля со стороны врача.</b>`}
                />
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
