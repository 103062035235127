import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson7/step24-1.png'


export default class step24 extends React.Component {


    render() {
        return (
            <div className="step24">
                <div className="flex">
                    <TextBlock
                        text={`Подыскивая потенциальные игрушки для своей кошки можно представить, на какое животное такая игрушка может походить.
                        
                        Определение любимой добычи должно занять всего несколько минут при игре с большинством кошек.
                        
                        Если кошка постепенно теряет интерес к любимой игрушке, можно попробовать показать ей новую игрушку с теми же характеристиками.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
