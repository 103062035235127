import React from 'react'

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <ul className="list">
                    <li className="list_item">
                        Кошки могут испытывать значительную боль и страдания от диагностических или экстренных процедур, таких как повторная венепункция, пункция мочевого пузыря или установка большого по диаметру сердечного катетера. Эффект таких вмешательств может быть как кумулятивным, так и аддитивным, если одна и та же процедура повторяется либо если последовательно выполняется несколько различных процедур.
                    </li>
                    <li className="list_item">
                        Применение анальгетиков, таких как трансмукозальный бупренорфин, может значительно облегчить выполнение этих задач. Если соответствующее физическое сдерживание не удалось и кошка уже испытывает боль, либо если требуемое вмешательство будет повторным или болезненным для кошки (например, обработка раны, перевязка), в таких случаях целесообразно использовать химическую седацию или общую анестезию в сочетании с анальгетиками.
                    </li>
                </ul>
            </div>
        )
    }
}
