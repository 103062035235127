import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step15 extends React.Component {

    render() {
        return (
            <div className="step15">
                <TextBlock
                    text={`Недавно опубликованные исследования показали, что владельцы кошек в США и Канаде редко посещают ветеринарные клиники. Несмотря на то, что владельцы кошек, как правило, имеют более высокий уровень осведомленности о своих питомцах, они менее склонны к посещению с кошками ветеринарных врачей, чем владельцы собак.
                    
                    Специальные рабочие группы обнаружили, что одним из наиболее важных факторов, способствующих меньшему количеству визитов кошек к ветеринарным врачам, являются <b>признаки стресса, проявляемого кошкой.</b>
                    
                    Сложность добровольного нахождения кошки в клетке-переноске в совокупности с различными звуками, издаваемыми питомцами, и физическими признаками напряжения, проявляемыми кошками во время пребывания на приеме в ветеринарной клинике и во время обследований, сильно расстраивают их владельцев. В результате многие владельцы кошек в попытке избегания этих неприятных аспектов отказываются от посещения ветеринарной клиники.
                    
                    Улучшение опыта пребывания в ветеринарной клинике кошки и ее владельца до, во время и после посещения ветиринарного врача, включая госпитализацию, будет способствовать тому, что владельцы кошек будут чаще приводить своих питомцев на обследование и не допускать запускания болезни.`}
                />
            </div>
        )
    }
}
