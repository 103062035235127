import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson11/step27-1.png";
import img2 from "../../../image/lessons/lesson11/step27-2.png";


export default class step27 extends React.Component {

    render() {
        return (
            <div className="step27">
                <TextBlock
                    text={`Использование лакомств важно для укрепления связи между животным и его владельцем, а также для решения таких функциональных задач как, например, чистка зубов.
                    
                    Для сохранения здоровья питомца нужно ответственно подходить к выбору угощений. Перекорм лакомствами может привести к проблемам с лишним весом. Существуют специализированные лакомства для животных, которые положительно влияют на здоровье. Но даже при использовании специализированных лакомств важно придерживаться рекомендаций производителя относительно суточной нормы.`}
                />
                <div className="flex">
                    <div className="flex_img-box red">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="15" cy="15" r="15" fill="#CC3333"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.5211 10.365C10.9256 9.89996 11.6036 9.8761 12.0355 10.3117L15 13.3022L17.9645 10.3117C18.3964 9.8761 19.0744 9.89996 19.4789 10.365C19.8835 10.8301 19.8613 11.5603 19.4294 11.996L16.5673 14.8832L19.661 18.004C20.0929 18.4397 20.1151 19.1699 19.7105 19.635C19.306 20.1 18.628 20.1239 18.1961 19.6883L15 16.4642L11.8039 19.6883C11.372 20.1239 10.694 20.1 10.2895 19.635C9.88495 19.1699 9.90711 18.4397 10.339 18.004L13.4327 14.8832L10.5706 11.996C10.1387 11.5603 10.1165 10.8301 10.5211 10.365Z" fill="white"/>
                        </svg>
                        <img src={img1} alt="" className="img"/>
                    </div>
                    <div className="flex_img-box green">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="15" cy="15" r="15" fill="#4DD06A"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M22.6801 10.3747C23.1272 10.852 23.1027 11.6014 22.6253 12.0485L14.1971 19.9432C13.7408 20.3706 13.031 20.3697 12.5758 19.9411L8.37245 15.9837C7.89627 15.5354 7.87369 14.7859 8.32201 14.3097C8.77034 13.8335 9.5198 13.811 9.99598 14.2593L13.3896 17.4544L21.0062 10.3199C21.4835 9.87284 22.2329 9.89733 22.6801 10.3747Z" fill="white"/>
                        </svg>
                        <img src={img2} alt="" className="img"/>
                    </div>
                </div>
            </div>
        )
    }
}
