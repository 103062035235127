import React from 'react'
import './style.sass'
import TextBlock from "../TextBlock";
import Slider from "react-slick";

export default class SliderImgDes extends React.Component {

    //Пример использования lesson8, step 9

    state = {
        sliderData: this.props.sliderData,
    };

    changeSlide = (next) => {
        if(next + 1 === Object.keys(this.state.sliderData).length){
            this.props.showNextBtn()
        }
    }

    render() {
        const {sliderData} = this.state
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        adaptiveHeight: true,
                    }
                },
            ]
        };
        return (
            <div className={"SliderImgDes"}>
                <Slider {...settings}>
                    {Object.values(sliderData).map(item => {
                        return (
                            <div
                                key={item.img}
                                className="slider_el"

                            >
                                <div className="slider_flex">
                                    <img className={'slider_img'} src={item.img} alt=""/>
                                    <div className="slider_flex_box">
                                        <TextBlock
                                            text={item.text}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        )
    }
}
