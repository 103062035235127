import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step31-1.png";
import img2 from "../../../image/lessons/lesson7/step31-2.png";
import img3 from "../../../image/lessons/lesson7/step31-3.png";


export default class step31 extends React.Component {

    render() {
        return (
            <div className="step31">
                <TextBlock
                    text={`Три типа конфликтов: наступательный, оборонительный и переориентированный.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Наступательный
                        </div>
                        <div className="flex_el_text">
                            Более уверенная кошка подходит к другим кошкам и контролирует взаимодействие.
                        </div>
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Оборонительный
                        </div>
                        <div className="flex_el_text">
                            Кошка пытается увеличить дистанцию между собой и той кошкой, которую она воспринимает в качестве угрозы.
                        </div>
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Переориентированный
                        </div>
                        <div className="flex_el_text">
                            Пугливая кошка может не защищаться от более уверенной кошки, но может создать угрозу для менее угрожающей кошки.
                        </div>
                        <img src={img3} alt="" className="flex_el_img"/>
                    </div>
                </div>
                <TextBlock
                    text={`Несмотря на то, что кошки, участвующие в любом типе конфликта, могут метить территорию за пределами кошачьего лотка, если внимательно изучить их дальнейшее поведение, то можно обнаружить, что у пугливых кошек, скорее всего, разовьется цистит.`}
                />
            </div>
        )
    }
}
