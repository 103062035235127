import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step19 extends React.Component {

    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`Следующее оборудование и принадлежности помогут снизить стресс у кошек, находящихся на ветеринарном лечении во время госпитализации:`}
                />
                <ul className="list">
                    <li className="list_item">
                        Простые в использовании весы с приподнятыми бортами. Например, это могут быть детские весы (весы для новорожденных).

                    </li>
                    <li className="list_item">
                        Маленькие, тихие машинки для стрижки шерсти.
                    </li>
                    <li className="list_item">
                        Пробирки для сбора крови малого объема.
                    </li>
                    <li className="list_item">
                        Большие, толстые банные полотенца или полотенца с густым ворсом для оказания успокаивающего эффекта или комфортного пребывания в клетках.
                    </li>
                    <li className="list_item">
                        Материал, используемый в ковриках для йоги, или нескользящие коврики для подкладки днищ клеток, или медицинских кушеток для обследования в целях обеспечения тепла в клетке и создания нескользящей поверхности.
                    </li>
                    <li className="list_item">
                        Одеяла или защитные перчатки, предназначенные для обращения с агрессивными животными, предпочтительны с длинными манжетами для защиты рук ветеринарного врача.
                    </li>
                    <li className="list_item">
                        Специальные маски для сведения к минимуму зрительного раздражения и оказания успокаивающего эффекта у некоторых кошек.
                    </li>
                    <li className="list_item">
                        Кошачьи сумки или переноски для оказания расслабляющего и успокаивающего эффекта.
                    </li>
                </ul>
            </div>
        )
    }
}
