import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step18-1.png";

export default class step18 extends React.Component {

    render() {
        return (
            <div className="step18">
                <TextBlock
                    text={`Все участки, которые пометила кошка, необходимо очистить специальным ферментативным очистителем, как, например, спрей FELIWAY®.`}
                />
                <div className="head">
                    Как он работает?
                </div>
                <ul className="list">
                    <li className="list_item">
                        Является синтетической версией вещества, которое выделяет кошка, когда трется щекой о ногу или мебель.
                    </li>
                    <li className="list_item">
                        Помогает обеспечить успокаивающий эффект для некоторых кошек и уменьшить их желание метить территорию. Запах говорит кошке, что она в безопасности.
                    </li>
                    <li className="list_item">
                        Доступен в виде диффузора, который подключается к настенной розетке.
                    </li>
                </ul>
                <TextBlock
                    text={`Для получения дополнительной информации посетите <a class="text_link" href="https://www.feliway.com/ru" target="_blank" rel="noopener noreferrer">сайт</a>.`}
                />
                <img src={img1} alt="" className={'imgCenter'}/>
            </div>
        )
    }
}
