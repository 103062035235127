import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step42-1.png'


export default class step42 extends React.Component{

    render() {
        return (
            <div className="step42">
                <div className="head">
                    Рекомендации для повышения активности кошки
                </div>
                <ul className="list">
                    <li className="list_item">
                        Кошку можно научить приносить игрушки.
                    </li>
                    <li className="list_item">
                        Прикрепить игрушку к импровизированному шнурку и волочить ее по дому.
                    </li>
                    <li className="list_item">
                        Закидывать игрушку на возвышенности, например, на высокие столы или стулья, чтобы кошка прыгала за ней.
                    </li>
                    <li className="list_item">
                        Показать кошке, как можно катать мячик.
                    </li>
                    <li className="list_item">
                        Повесить игрушку на петельку от дверной ручки или вешалку на верхней части дверного проема, чтобы игрушка моталась из стороны в сторону на веревочке, а кошка прыгала за ней.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
