import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";
import ListNumeral from "../../../Components/LessonAndTestComponents/ListNumeral";

import img1 from "../../../image/lessons/lesson7/step16-1.png";
import img2 from "../../../image/lessons/lesson7/step16-2.png";
import img3 from "../../../image/lessons/lesson7/step16-3.png";

export default class step16 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points, showPopup} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (!showPopup) {
            this.setState({
                popupActive: popupActive,
                showPopup: true,
                points: points,
            })
        } else if (popupActive >= 3) {
            this.setState({showPopup: false, popupActive: 0})
        } else {
            this.setState({
                popupActive: popupActive +1,
                showPopup: true,
                points: points,
            })
        }

        if (points.length === 3) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            img: img1,
            head: `Линолеум`,
            data: [
                `Протрите лужу бумажными полотенцами или шваброй, смоченной в мыльной воде.`,
                `Тщательно очистите место и смойте теплой водой.`,
                `Протрите область губкой, смоченной белым уксусом.`,
                `Дайте области подсохнуть.`,
            ]
        },
        2: {
            img: img2,
            head: `Твердая древесина и цемент`,
            data: [
                `Хорошо промокните лужу бумажными полотенцами, а затем используйте ферментативный очиститель.`,
                `Если запах сильный либо если под полом бетонная поверхность с песком, отбелите и отполируйте ваш деревянный пол.`,
            ]
        },
        3: {
            img: img3,
            head: `Стирка в стиральной машинке`,
            data: [
                `Поставьте белье на машинную стирку с добавлением одного стакана белого уксуса без моющего средства.`,
                `Когда цикл стирки закончится, добавьте моющее средство и поставьте на обычный режим стирки.`,
            ]
        },
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step16">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о правилах уборки.`}
                />
                <div className="flex">
                    <div className="flex_el" onClick={() => {this.showPopup(1)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[1].img} alt="" className="flex_el_img"/>
                        </div>
                        {this.popupData[1].head}
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(2)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[2].img} alt="" className="flex_el_img"/>
                        </div>
                        {this.popupData[2].head}
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(3)}}>
                        <div className="flex_el_img-box">
                            <img src={this.popupData[3].img} alt="" className="flex_el_img"/>
                        </div>
                        {this.popupData[3].head}
                    </div>
                </div>
                {showPopup &&
                <PopupInStep
                    closePopup={this.closePopup}
                    imgHead={this.popupData[popupActive].img}
                    head={this.popupData[popupActive].head}
                    changePoint={() => {this.showPopup(popupActive)}}
                >
                    {popupActive === 1 &&
                    <ListNumeral
                        keyNumber={popupActive}
                        data={this.popupData[1].data}
                    />
                    }
                    {popupActive === 2 &&
                    <ListNumeral
                        keyNumber={popupActive}
                        data={this.popupData[2].data}
                    />
                    }
                    {popupActive === 3 &&
                    <ListNumeral
                        keyNumber={popupActive}
                        data={this.popupData[3].data}
                    />
                    }
                </PopupInStep>
                }
            </div>
        )
    }
}
