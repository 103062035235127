import React from 'react'
import './style.sass'

export default class VideoSectionButton extends React.Component{
    render() {
        return (
            <button
                className={this.props.class}
                onClick={this.props.onClick}
            >
                {this.props.svg}
                {this.props.name}
            </button>
        )
    }
}