import React from 'react'

import ResumeNew from "../../../Components/LessonAndTestComponents/ResumeNew";

export default class step18 extends React.Component {

    state = {
        resumeData: [
            `Минимизируйте воздействие шума на кошку .`,
            `Минимизируйте ненужное передвижение по комнате для осмотра во время обследования кошки.`,
            `Изолируйте кошек от собак и других видов животных.`,
            `Расположите кошачью переноску высоко над полом.`,
            `Позвольте кошке найти укрытие и спрятаться, когда представится такая возможность.`,
            `Используйте успокаивающие синтетические феромоны.`,
        ]
    }

    render() {
        return (
            <div className="step18">
                <ResumeNew
                    text={`<b>Методы снижения стресса подходят для всех областей ветеринарной клиники.</b>`}
                    resumeData={this.state.resumeData}
                />
            </div>
        )
    }
}
