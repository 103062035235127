import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from "../../../image/Lecturers/siatkovskaia.png";
import img2 from "../../../image/Lecturers/siatkovskaia-icon.png";

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Cat friendly clinic & cat friendly professional. – Клиники, комфортные для кошек. С любовью к кошкам.`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Сятковская'}
                    name={'Ольга Серафимовна'}
                    text={'Почетный член Национальной ассоциации медицины кошек. Основатель и президент Эстонского общества врачей фелинологов. Ветеринарный врач ветеринарного центра Хааберсти, Таллин, Эстония.'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке Вас ждёт:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
