import React from 'react'
import './style.sass'

export default function Element(props) {
    return (
        <div className="guru_mobile">
            <div className="guru_flex">
                <div className={'img'}>
                    <img src={props.img} alt="" className="img_img"/>
                </div>
                <div className="guru_flex_status">
                    <p className="guru_flex_text">Ваш статус:</p>
                    <p className="name">{props.name}</p>
                    <div className="guru_flex_block">
                        <div className={'guru_flex_block_el ' + (props.active >= 0 && 'active')}/>
                        <div className={'guru_flex_block_el ' + (props.active >= 1 && 'active')}/>
                        <div className={'guru_flex_block_el ' + (props.active >= 2 && 'active')}/>
                        <div className={'guru_flex_block_el ' + (props.active >= 3 && 'active')}/>
                        <div className={'guru_flex_block_el ' + (props.active >= 4 && 'active')}/>
                        <div className={'guru_flex_block_el ' + (props.active >= 5 && 'active')}/>
                        <div className={'guru_flex_block_el ' + (props.active >= 6 && 'active')}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
