import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

// import img1 from '../../../image/lessons/lesson34/step12-1.png'
import List from "../../../Components/LessonAndTestComponents/List";


export default class step12 extends React.Component {

    ListData = {
        1:
            [
                `декларация о соответствии;`,
                `сертификат качества c результатами анализов;`,
                `ветеринарное свидетельство;`
            ],
        2:
            [
                `визуальный контроль;`,
                `спектральная идентификация;`,
                `содержание макронутриентов и влажности;`,
                `исследование на микотоксины;`,
                `окислительные характеристики.`,
                `и другие исследования`
            ]
    };

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`Входной контроль сырья осуществляется в два этапа: 
                    проверка сопроводительной документации и лабораторный контроль.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="attention">
                            Проверка документации
                        </div>
                        <div className="flex_wrp">
                            <h3 className="flex_head">
                                К обязательной сопроводительной документации относятся:
                            </h3>
                            <List ListData={this.ListData[1]}/>
                        </div>
                    </div>
                    <div className="flex_el arrow">
                        <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM1 9H25V7H1V9Z" fill="#4147DA"/>
                        </svg>
                    </div>
                    <div className="flex_el">
                        <div className="attention">
                            Лабораторный контроль
                        </div>
                        <div className="flex_wrp">
                            <h3 className="flex_head">
                                В лабораторный контроль входят следующие меры:
                            </h3>
                            <List ListData={this.ListData[2]}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
