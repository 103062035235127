import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson31/step4-1.png';
import img2 from '../../../image/lessons/lesson31/step4-2.png';
import img3 from '../../../image/lessons/lesson31/step4-3.png';

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <div className="head">
                    Классификация по возрасту животного
                </div>
                <TextBlock
                    text={`Как вы знаете, существуют следующие виды кормов в зависимости от возрастных особенностей кошек:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для котят
                        </div>
                        <div className="flex_el_wrp">
                            <ul className="list">
                                <li className="list_item">Высокая калорийность;</li>
                                <li className="list_item">Высокая усвояемость;</li>
                                <li className="list_item">Повышенное содержание белка и некоторых микроэлементов для развития и роста.</li>
                            </ul>
                        </div>
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для взрослых кошек
                        </div>
                        <div className="flex_el_wrp">
                            <ul className="list">
                                <li className="list_item">Сбалансированный состав для поддержания обмена веществ взрослых животных;</li>
                                <li className="list_item">Хорошая усвояемость;</li>
                                <li className="list_item">Высокая вкусовая привлекательность.</li>
                            </ul>
                        </div>
                        <img className="flex_el_img" src={img2} alt=""/>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для стареющих кошек
                        </div>
                        <div className="flex_el_wrp">
                            <ul className="list">
                                <li className="list_item">Увеличенное содержание некоторых витаминов и минералов, например, витамина Е для укрепления иммунитета и защиты организма;</li>
                                <li className="list_item">Высокая усвояемость;</li>
                                <li className="list_item">Высокая вкусовая привлекательность.</li>
                            </ul>
                        </div>
                        <img className="flex_el_img" src={img3} alt=""/>
                    </div>
                </div>

            </div>
        )
    }
}
