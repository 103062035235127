import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step53-1.png'


export default class step53 extends React.Component{

    render() {
        return (
            <div className="step53">
                <TextBlock
                    text={`Такой же способ можно использовать при смене наполнителя для лотка: как только кошка начнет уверенно использовать новый наполнитель, что в среднем занимает несколько дней, можно постепенно начать уменьшать количество старого.
                    
                    Для адаптации к новым предметам кошкам необходимо разрешить исследовать их в удобное для них время. Для облегчения процесса привыкания к новому предмету можно использовать специальные кошачьи феромоны, кошачью мяту, любимую игрушку или лакомство, что позволит вызвать у кошки интерес к новым предметам.`}
                />
                {/*<img src={img1} alt="" className="imgCenter"/>*/}
            </div>
        )
    }
}
