import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson8/step31-1.jpg'


export default class step31 extends React.Component{

    render() {
        return (
            <div className="step31">
                <div className="head">
                    Что делать, если кошка не выходит из переноски самостоятельно
                </div>
                <TextBlock
                    text={`Сделайте все возможное, чтобы провести осмотр кошки внутри переноски (пример на фото).
                    Никогда не хватайте кошку, чтобы насильно вытащить ее из переноски.
                    
                    Не наклоняйте переноску и не трясите ее, чтобы кошка оттуда выпала.
                    Не используйте кошачьи мешки, сетки и перчатки со спокойными кошками.
                    
                    Чтобы побудить кошку выйти из переноски, немного подтолкните её.
                    Если кошка испугана, постарайтесь действовать медленно и максимально комфортно для неё. Обсудите с владельцем возможность оптимального выбора кошачьей переноски.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
