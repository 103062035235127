import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from '../../../Components/LessonAndTestComponents/OnClickTest';

import img1 from '../../../image/lessons/lesson10/step9-1.png'

export default class step9 extends React.Component {

    state = {
        answersData: [
            `Пациент находится в спокойном состоянии`,
            `Кошка встревожена`,
            `Пациент ведет себя агрессивно`,
        ],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Оцените эмоцию кошки на фотографии:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[1]}
                    showNextBtn={this.showNextBtn}
                    correctText={`На фото пациент не испытывает страха и готов к сотрудничеству .`}
                />
            </div>
        )
    }
}
