import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson11/step9-1.png'


export default class step9 extends React.Component{

    state = {
        resumeData: [
            `Беременность у кошек длится <b>9–9,5 недель.</b>`,
            `Беременным кошкам можно разрешить <b>свободный</b> доступ к корму.`,
            `В период беременности и лактации кошкам <b>подойдут рационы для котят</b>, так как они удовлетворят её повышенные требования в энергии и питательных веществах.`,
            `Первые <b>4 недели</b> жизни котята питаются исключительно материнским молоком.`,
        ]
    }

    render() {
        return (
            <div className="step9">
                <Resume
                    title={'Выводы'}
                    resumeData={this.state.resumeData}
                    img={img1}
                />
                <TextBlock
                    text={`Далее вы узнаете о питании котят.`}
                />
            </div>
        )
    }
}
