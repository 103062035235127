import React from 'react'
import './style.sass'
import Specialty from "../Specialty";
import Inputs from "../Inputs";
import InputFile from "../InputFile";
import LessonStatistics from "../LessonsStatistics";
import Checkbox from "../Checkbox";
import Api from '../../Service/Api'


export default class Questionnaire extends React.Component {

    state = {
        user: '',
        surname: '',
        surnameError: false,
        name: '',
        nameError: false,
        patronymic: '',
        patronymicError: false,
        birthday: '',
        birthdayError: false,
        phone: '',
        phoneError: false,
        email: '',
        emailError: false,
        emailErrorText: false,
        placeWork: '',
        placeWorkError: '',
        city: '',
        cityError: false,
        specialty: 1,
        specialization: '',
        specializationError: false,
        diploma: false,
        university: '',
        universityError: '',
        agree: false,
        agreeError: false,
        eighteen: false,
        eighteenError: false,
        subscribe: false,
        subscribeError: false,
        formCompleted: false,
        file: '',
        ext: '',
        canSent: false
    }

    Api = new Api();

    changeAgree = () => {
        const {agree} = this.state
        this.setState({
            agree: !agree,
            agreeError: false,
        })
        this.onCompletedForm()
    }

    changeEighteen = () => {
        this.setState({
            eighteen: !this.state.eighteen,
            eighteenError: false,
        })
    }

    changeSubscribe = () => {
        this.setState({
            subscribe: !this.state.subscribe,
            subscribeError: false,
        })
    }

    chooseSpecialty = (specialty) => {
        this.setState({
            specialty: specialty
        })
    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-._0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    onCompletedForm = () => {
        this.setState({formCompleted: true})
    }

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'surname' : {
                this.setState({
                    surname: value,
                    surnameError: false,
                })
                break;
            }
            case 'name' : {
                this.setState({
                    name: value,
                    nameError: false,
                })
                break;
            }
            case 'patronymic' : {
                this.setState({
                    patronymic: value,
                    patronymicError: false,
                })
                this.onCompletedForm()
                break;
            }
            case 'birthday' : {
                this.setState({
                    birthday: value,
                    birthdayError: false,
                })
                break;
            }
            case 'phone' : {
                this.setState({
                    phone: value,
                    phoneError: false,
                })
                break;
            }
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
            case 'placeWork' : {
                this.setState({
                    placeWork: value,
                    placeWorkError: false,
                });
                break;
            }
            case 'city' : {
                this.setState({
                    city: value,
                    cityError: false,
                })
                break;
            }
            case 'specialization' : {
                this.setState({
                    specialization: value,
                    specializationError: false,
                })
                break;
            }
            case 'university' : {
                this.setState({
                    university: value,
                    universityError: false,
                })
                break;
            }
        }
    }

    updateFile = (value, ext) => {
        this.setState({file: value, ext: ext})
        console.log(111, ext)
    }

    sendDate = () => {
        const {
            surname,
            name,
            patronymic,
            email,
            phone,
            city,
            file,
            // cityOwn,
            specialty,
            birthday,
            placeWork,
            specialization,
            university,
            agree,
            eighteen,
            ext,
            // ownAddress
        } = this.state

        if (!this.valid_email(email)) {
            this.setState({emailError: true})
        } else if (!city.length) {
            this.setState({cityError: true})
        } else if (!agree) {
            this.setState({agreeError: true})
        } else if (!eighteen) {
            this.setState({eighteenError: true})
        } else if (!name.length) {
            this.setState({nameError: true})
        } else if (!surname.length) {
            this.setState({surnameError: true})
        } else if (!patronymic.length) {
            this.setState({patronymicError: true})
        } else if (!phone.length) {
            this.setState({phoneError: true})
        } else if (!birthday.length) {
            this.setState({birthdayError: true})
        } else if (!specialization.length && specialty === 1) {
            this.setState({specializationError: true})
        } else if (!university.length && specialty === 2) {
            this.setState({universityError: true})
        } else {
            this.Api.questionnaireForm(name, surname, patronymic, email, specialty, phone, city, birthday, placeWork, specialization, university, file, ext)
                .then((res) => {
                    if (res.status === 200) {
                        this.props.changeType();
                    }else{
                        // Object.keys(res.error).map(key => {
                        //     // eslint-disable-next-line
                        //     this.state[key + 'Error'] = true;
                        //     // eslint-disable-next-line
                        //     this.state[key + 'ErrorText'] = res.error[key];
                        //     this.setState({errors: new Date()})
                        //     return false
                        // })
                        console.log('error')
                    }
                })
        }
    }

    unblockSending = () => {
        this.setState({canSent: true})
    }

    render() {
        const {
            surnameError,
            nameError,
            patronymicError,
            birthdayError,
            phoneError,
            emailError,
            emailErrorText,
            placeWorkError,
            cityError,
            specialty,
            specializationError,
            agree,
            agreeError,
            eighteen,
            eighteenError,
            subscribe,
            subscribeError,
            formCompleted,
            canSent,
        } = this.state


        return (
            <div className="questionnaire">
                <div className="questionnaire__wrp">
                    <form action="#" className="questionnaire__form">
                        <div className="questionnaire__name">
                            Online-анкета
                        </div>
                        <div className="questionnaire__description">
                            членства Национальной ассоциации медицины кошек
                        </div>
                        <div className="questionnaire__flex">
                            <Inputs
                                name={'surname'}
                                placeholder={'Фамилия'}
                                type={'text'}
                                className={surnameError && 'error'}
                                onChange={this.updateState}
                                required={'required'}
                            />
                            <Inputs
                                name={'name'}
                                placeholder={'Имя'}
                                type={'text'}
                                className={nameError && 'error'}
                                onChange={this.updateState}
                                required={'required'}
                            />
                        </div>
                        <div className="questionnaire__flex">
                            <Inputs
                                name={'patronymic'}
                                placeholder={'Отчество'}
                                type={'text'}
                                className={patronymicError && 'error'}
                                onChange={this.updateState}
                                required={'required'}
                            />
                            <Inputs
                                name={'birthday'}
                                placeholder={'Дата рождения'}
                                type={'text'}
                                className={birthdayError && 'error'}
                                onChange={this.updateState}
                                mask={'99.99.9999'}
                                required={'required'}
                            />
                        </div>
                        <div className="questionnaire__flex">
                            <Inputs
                                name={'phone'}
                                placeholder={'Телефон'}
                                type={'tel'}
                                className={phoneError && 'error'}
                                onChange={this.updateState}
                                mask={'+7 (999) 999-99-99'}
                                required={'required'}
                            />
                            <Inputs
                                name={'email'}
                                placeholder={'E-mail'}
                                onChange={this.updateState}
                                type={'email'}
                                className={emailError && 'error'}
                                errorText={emailErrorText}
                                required={'required'}
                            />
                        </div>
                        <div className="questionnaire__flex">
                            <Inputs
                                name={'placeWork'}
                                placeholder={'Место работы'}
                                type={'text'}
                                className={placeWorkError && 'error'}
                                onChange={this.updateState}
                            />
                            <Inputs
                                name={'city'}
                                placeholder={'Город'}
                                onChange={this.updateState}
                                type={'text'}
                                className={cityError && 'error'}
                                required={'required'}
                            />
                        </div>
                        <Specialty
                            chooseSpecialty={this.chooseSpecialty}
                        />
                        {specialty === 1
                            ?
                            <div className="questionnaire__block">
                                <Inputs
                                    name={'specialization'}
                                    placeholder={'Специализация'}
                                    onChange={this.updateState}
                                    type={'text'}
                                    className={specializationError && 'error'}
                                    required={'required'}
                                />
                                <InputFile
                                    id={'questionnaire__diploma-file'}
                                    name={'file'}
                                    placeholder={'Скан диплома'}
                                    updateFile={this.updateFile}
                                    key={1}
                                />
                            </div>
                            :
                            <div className="questionnaire__block">
                                <Inputs
                                    name={'university'}
                                    placeholder={'ВУЗ'}
                                    onChange={this.updateState}
                                    type={'text'}
                                    className={specializationError && 'error'}
                                    required={'required'}
                                />
                                <InputFile
                                    id={'questionnaire__student-card'}
                                    name={'file'}
                                    placeholder={'Скан студенческого'}
                                    updateFile={this.updateFile}
                                    key={2}
                                />
                            </div>
                        }
                        <LessonStatistics unblockSending={this.unblockSending}/>
                        <div className="questionnaire__box">
                            <div className="agree">
                                <span className="agree_star">*</span>
                                <Checkbox
                                    active={agree}
                                    error={agreeError}
                                    onClick={this.changeAgree}
                                />
                                <div className="agree_text">
                                    <span className="link-no">Я ознакомился и согласен с </span><a href="/regulations.pdf" target="_blank" rel="noopener noreferrer"><span className="link-yes">Правилами использования сайта, </span></a>
                                    <a href="/confidentiality.pdf" target="_blank" rel="noopener noreferrer"><span className="link-yes">Положением о конфиденциальности</span></a><span className="link-no"> и даю свое согласие на обработку персональных данных.</span>
                                </div>
                            </div>
                            <div className="agree">
                                <span className="agree_star">*</span>
                                <Checkbox
                                    active={eighteen}
                                    error={eighteenError}
                                    onClick={this.changeEighteen}
                                />
                                <span className="link-no">Мне исполнилось 18 лет.</span>
                            </div>
                            <div className="agree">
                                <Checkbox
                                    active={subscribe}
                                    error={subscribeError}
                                    onClick={this.changeSubscribe}
                                />
                                <span className="link-no">Я согласен на получение информационных материалов.</span>
                            </div>
                        </div>
                        <div className={'info ' + (formCompleted && 'complete')}>
                            {formCompleted
                                ?
                                <p className="info__icon">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="10" fill="#4DD06A"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.843 7.13475C15.0439 7.32213 15.0532 7.6351 14.8638 7.83379L9.20522 13.7679C9.11075 13.8669 8.97915 13.9231 8.84146 13.9231C8.70378 13.9231 8.57218 13.8669 8.47771 13.7679L6.13625 11.3124C5.94679 11.1137 5.95606 10.8007 6.15695 10.6133C6.35785 10.426 6.67429 10.4351 6.86375 10.6338L8.84146 12.7078L14.1362 7.15523C14.3257 6.95654 14.6422 6.94737 14.843 7.13475Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>

                                </p>
                                :
                                <p className="info__icon">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86957 0.000713581C4.34823 0.0659263 -0.0644992 4.60908 0.000713581 10.1304C0.0659263 15.6518 4.60908 20.0645 10.1304 19.9993C15.6518 19.9341 20.0645 15.3909 19.9993 9.86957C19.9341 4.34823 15.3909 -0.0644991 9.86957 0.000713581ZM10.9782 3.32656C11.9999 3.32656 12.3042 3.91348 12.3042 4.58734C12.3042 5.43511 11.6303 6.21766 10.4782 6.21766C9.52177 6.21766 9.06528 5.73943 9.08702 4.93514C9.06528 4.26128 9.63046 3.32656 10.9782 3.32656ZM8.36968 16.2604C7.67408 16.2604 7.17412 15.8474 7.65234 13.978L8.45663 10.6956C8.58706 10.1739 8.6088 9.95652 8.45663 9.95652C8.23926 9.95652 7.34801 10.3261 6.80458 10.6739L6.45677 10.1087C8.15231 8.69574 10.087 7.86972 10.913 7.86972C11.6086 7.86972 11.7173 8.69574 11.3695 9.95652L10.4565 13.4128C10.3043 14.0214 10.3695 14.2388 10.5217 14.2388C10.7391 14.2388 11.4129 13.978 12.0868 13.4563L12.4781 13.978C10.8478 15.6083 9.06528 16.2604 8.36968 16.2604Z" fill="#C6C6C6"/>
                                    </svg>

                                </p>
                            }
                            <p className="info__text">
                                <span className="info__star">&#42;</span> поля отмеченные звездочкой обязательны для заполнения
                            </p>
                        </div>
                        <button
                            type='button'
                            className="btn btn_violet btn_center"
                            onClick={() => {
                                this.sendDate()
                            }}
                            disabled={formCompleted && agree && eighteen && canSent ? '' : 'disabled'}
                        >
                            Подать заявку
                        </button>
                    </form>
                </div>
            </div>
        )
    }
}
