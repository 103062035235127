import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import BlockTurnsIntoPopup from '../../../Components/LessonAndTestComponents/BlockTurnsIntoPopup';

import img1 from '../../../image/lessons/lesson33/step5-1.png'


export default class step5 extends React.Component {

    state = {
        pointActive: 0,
        points: [0],
        showPopup: false,
    };


    clickPoint = pointActive => {
        const {points} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        this.setState({pointActive, points})

        this.props.showNextBtn();
    }

    closePopup = () => {
        this.setState({pointActive: 0, showPopup: false})
    }

    render() {
        const {pointActive} = this.state;
        return (
            <div className="step5">
                <InfoBlock
                    text={`Нажмите на этикетку «100% высококачественные ингредиенты» и узнайте, какие ингредиенты FEDIAF считает натуральными.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`В основе влажных рационов WHISKAS® - <b>100% натуральные высококачественные ингредиенты: мясо и субпродукты</b>, которые соответствуют критериям натуральности <b>FEDIAF</b> (Европейская федерация производителей кормов для домашних животных).`}
                        />
                    </div>
                    <div className="flex_el">
                        {pointActive === 1 &&
                        <BlockTurnsIntoPopup
                            className={pointActive === 1 && 'active'}
                            closePopup={this.closePopup}
                        >
                            <div className="flex_el_block_closed" onClick={this.closePopup}/>
                            <div className="flex_el_block_head">
                                FEDIAF считает ингредиент натуральным, если он:
                            </div>
                            <ul className="list">
                                <li className="list_item">Имеет природное происхождение (получен из растений, животных, микроорганизмов и минералов);</li>
                                <li className="list_item">К нему ничего не было добавлено в процессе хранения, т.е. в его природный состав ничего не было привнесено;</li>
                                <li className="list_item">В процессе производства он был подвергнут только физическим методам обработки (например, помол, высушивание, температурная обработка).</li>
                            </ul>
                            <div className="flex_el_block_head bottom">
                                Натуральные ингредиенты*, входящие в состав влажных рационов Whiskas® соответствуют всем требованиям FEDIAF
                            </div>
                            <p><small>*мясо и субпродукты</small></p>
                        </BlockTurnsIntoPopup>
                        }

                        <div className="flex_el_img-button" onClick={() => {this.clickPoint(1)}}/>
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
