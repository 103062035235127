import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
               <TextBlock
                   text={`Благоприятная реакция кошек на синтетический аналог <b>кошачьего лицевого феромона (FFP)</b> является примером того, как уход за больными может улучшить результаты лечения «кошачьих» пациентов в условиях ветеринарной клиники.
                   
                   У госпитализированных кошек, которым наносили синтетический аналог кошачьего лицевого феромона на полотенце в их клетках, наблюдалось не только значительное снижение беспорядочного ухода за шерстью, но и улучшение показателей потребления пищи.
                   
                   Воздействие на кошек синтетического FFP во время премедикационных мероприятий перед анестезией также оказывало успокоение на кошек во время венозной катетеризации.`}
               />
            </div>
        )
    }
}
