import React from 'react';
import './style.sass';
import PageHeader from '../../Components/PageHeader'
// import Buttons from '../../Components/Buttons'
import TextBlock from '../../Components/LessonAndTestComponents/TextBlock'
import List from '../../Components/LessonAndTestComponents/List'
import Questionnaire from '../../Components/Questionnaire'

// import img1 from '../../image/online-form.png';


export default class OrganizersInformation extends React.Component {

    ListData1 = [
        `<b>Пройти обучение</b> на образовательной платформе Сat Friendly – С любовью к кошкам по всем темам раздела <b>Общий курс Сat Friendly – С любовью к кошкам и 30% тем в разделе Медицина кошек</b>, ответив правильно на <b>70% вопросов</b> тестов в каждом уроке.`,
        `<b>Ежегодно проходить тестирование по новым материалам</b>, загруженным на образовательную платформу Cat Friendly за последний год.`,
        `Ежегодно вносить членский взнос в размере 300 рублей.`,
    ]

    ListData2 = [
        `<b>Посещать все мероприятия</b>, проводимые Ассоциацией (мастер-классы, конференции и т.д.), на льготных условиях.`,
        `<b>Быть информированным</b> о планировании и проведении вышеуказанных мероприятий.`,
        `<b>Получать периодические издания</b>, выпускаемые Ассоциацией.`,
        `<b>Пользоваться информацией по вопросам медицины кошек</b>, которой располагает общество.`,
        `<b>Стажироваться по направлению Медицина кошек</b> в лучших клиниках в России и за рубежом на льготных условиях.`,
    ]

    render() {
        return (
            <div className="MemberOrganization">
                <PageHeader
                    svg={
                        <svg width="21" height="40" viewBox="0 0 21 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.67688 22.2651L6.67574 22.2697L4.40621 31.4009C3.72419 34.0313 3.65255 35.9173 4.23616 37.1913C4.54709 37.87 5.0315 38.347 5.62728 38.6378C6.19958 38.9171 6.8226 39 7.40072 39C8.60465 39 10.5151 38.4721 12.6516 37.4228C14.8213 36.3572 17.3197 34.7079 19.7021 32.3592C20.0602 32.0061 20.1004 31.4419 19.7959 31.0416L18.6912 29.5895C18.5304 29.3782 18.2923 29.2395 18.0292 29.204C17.7661 29.1684 17.4996 29.239 17.2886 29.4C16.3987 30.0793 15.5113 30.5862 14.7851 30.9199C14.5214 31.041 14.284 31.137 14.08 31.2094C14.1105 30.9879 14.1661 30.6979 14.2601 30.3261L16.8345 20.7179C17.3364 18.9227 17.5569 17.2875 17.3115 16.0405C17.1857 15.4014 16.922 14.7863 16.4316 14.3285C15.9255 13.8559 15.279 13.6454 14.5812 13.6454C13.1837 13.6454 11.0677 14.3026 8.78375 15.3825C6.45795 16.4822 3.82539 18.0835 1.3652 20.1046C0.977101 20.4235 0.885734 20.9807 1.1517 21.4068L2.13365 22.9799C2.27453 23.2056 2.49954 23.3659 2.75889 23.4252C3.01824 23.4846 3.29055 23.4381 3.51556 23.2962C4.22211 22.8504 5.182 22.3753 6.04755 22.0106C6.32139 21.8953 6.57967 21.7935 6.81148 21.709C6.77515 21.8689 6.73062 22.0532 6.67688 22.2651ZM14.0598 31.7641C14.0597 31.7642 14.0591 31.7622 14.0582 31.7579C14.0594 31.7619 14.0599 31.7641 14.0598 31.7641ZM6.94448 20.8709C6.9447 20.8706 6.94531 20.8751 6.94564 20.885C6.94442 20.876 6.94425 20.8711 6.94448 20.8709ZM19.509 5.50924C19.509 4.43832 19.2658 3.2802 18.464 2.38436C17.6433 1.4674 16.3963 1 14.7653 1C10.4251 1 8.36361 4.03239 8.42563 6.47916C8.39568 7.78425 8.75942 8.97548 9.66954 9.8305C10.5716 10.678 11.8543 11.0471 13.3538 11.0471C17.1114 11.0471 19.509 8.46739 19.509 5.50924Z" stroke="#4147DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                    text={`Как стать членом НАМК?`}
                />
                <div className="MemberOrganization_content">
                    <TextBlock
                        text={`Членом Национальной ассоциации медицины кошек может стать любой ветеринарный врач или студент, интересующийся проблемами, связанными со специализацией общества, выполняющий устав общества и разделяющий цели и задачи общества.`}
                    />
                    <div className="MemberOrganization_content_head">
                        Член Национальной ассоциации медицины кошек обязан:
                    </div>
                    <List
                        ListData={this.ListData1}
                    />
                    <div className="MemberOrganization_content_head">
                        Член Национальной ассоциации медицины кошек имеет право:
                    </div>
                    <List
                        ListData={this.ListData2}
                    />
                    {/*<TextBlock*/}
                    {/*    text={`<span class="text_violet">Вы можете стать членом Национальной Ассоциации медицины кошек, заполнив on-line анкету:</span>`}*/}
                    {/*/>*/}
                    {/*<Questionnaire/>*/}
                </div>
            </div>
        )
    }
}

