import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson8/step29-1.png";

export default class step29 extends React.Component {

    render() {
        return (
            <div className="step29">
                <TextBlock
                    text={`<b>Первая встреча с кошкой влияет на все последующие визиты в клинику.</b>`}
                />
                <ul className="list">
                    <li className="list_item">
                        При знакомстве обратитесь к владельцу по имени и назовите кошку ее кличкой, используйте сдержанный язык тела и умеренный голос.
                    </li>
                    <li className="list_item">
                        Узнайте о любых особенностях каждого конкретного животного до начала ветеринарного обследования.
                    </li>
                    <li className="list_item">
                        Постарайтесь максимально обеспечить наличие необходимого оборудования для осмотра и тестирования в процедурном кабинете.
                    </li>
                    <li className="list_item">
                        Подготовьте заранее все необходимые материалы
                    </li>
                    <li className="list_item">
                        Используйте бесшумные кусачки или ножницы.
                    </li>
                    <li className="list_item">
                        В процессе осмотра никогда не нужно спешить.
                    </li>
                    <li className="list_item">
                        Ведите себя спокойно, настройтесь на положительный исход и работайте в постоянном темпе.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
