import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson6/step10-1.png'

export default class step10 extends React.Component {

    render() {
        return (
            <div className="step10">
                <div className="head">
                    Кошачий насест и его роль для кошки
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<b>Кошки должны знать, что происходит вокруг них</b>. Это же кошки – они такие любопытные! Они могут слышать звуки, доносящиеся снаружи, и чувствовать множество странных запахов, но вместе с этим им необходимо иметь широкий угол обзора и быть всегда в курсе того, что происходит снаружи. Они хотят знать, что находятся в безопасности, поскольку если они могут слышать незнакомый звук или шум и чувствовать странный запах, но не могут увидеть сам предмет, от которого исходит этот шум или запах, это может сильно напугать их.`}
                />
            </div>
        )
    }
}
