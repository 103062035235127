import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson6/step12-1.png'

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="head">
                    Кошачий насест и его роль для кошки
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<b>Кошки могут чувствовать себя в большей безопасности, если они могут спать на возвышенности</b>. Поэтому важно обеспечить им такие места для лазания, чтобы они чувствовали себя в безопасности, зная, что никакое другое животное или человек не могли бы незаметно подкрасться к ним. Если у вас возникли проблемы с поиском подходящего места для насеста, понаблюдайте за вашей кошкой. Она сама покажет вам, где ей удобно и комфортно, обычно свернувшись калачиком на таком месте и сладко засыпая.`}
                />
            </div>
        )
    }
}
