import React from 'react'
import './style.sass'


export default class OwnData extends React.Component{
    render() {
        return (
            <div className={'OwnData'}>
                <span className="OwnData_label">{this.props.label}</span>
                <div
                    className={"OwnData_button " + (this.props.active && ' active ')}
                    dangerouslySetInnerHTML={{ __html: this.props.text }}
                    onClick={this.props.onClick}
                />
            </div>
        )
    }
}
