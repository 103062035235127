import React from 'react'
import SliderImgDes from '../../../Components/LessonAndTestComponents/SliderImgDes';

import img1 from '../../../image/lessons/lesson8/step9-1.png';
import img2 from '../../../image/lessons/lesson8/step9-2.png';
import img3 from '../../../image/lessons/lesson8/step9-3.png';
import img4 from '../../../image/lessons/lesson8/step9-4.png';
import img5 from '../../../image/lessons/lesson8/step9-5.png';

export default class step9 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    sliderData = {
        1: {
            text: `Кошка, изображенная на фотографии, проявляет все больше признаков страха по мере приближения ветеринарного врача.
            
            <b>Страх отступал, когда кошку удавалось отвлечь.</b>`,
            img: img1,
        },
        2: {
            text: `Ветеринарный врач находится примерно в 2 метрах от кошки и ее владельца. Кошка проявляет ранние признаки страха или беспокойства, слегка поворачивая уши и опуская их в горизонтальном положении. Спина начинает выгибаться дугой. Обратите внимание на легкое напряжение на мордочке кошки.`,
            img: img2
        },
        3: {
            text: `Кошка проявляет повышенный страх, прижимая лапы к туловищу, опуская голову и заставляя себя казаться меньше .`,
            img: img3
        },
        4: {
            text: `Кошка готова напасть на ветеринарного врача. Спина стала более выгнутой, а уши – более приплюснутыми. Это говорит о том, что кошка потенциально более агрессивна.`,
            img: img4
        },
        5: {
            text: `Ветеринарный врач показал кошке ее любимую игрушку. Спина больше не выгнута дугой, и общая поза кошки в этот момент более расслаблена .`,
            img: img5
        },
    }

    render() {
        return (
            <div className="step9">
                <div className="head">
                    Положение тела
                </div>
                <SliderImgDes
                    showNextBtn={this.showNextBtn}
                    sliderData={this.sliderData}
                />
            </div>
        )
    }
}
