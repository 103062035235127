import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

export default class step36 extends React.Component {

    state = {
        answersData:
            [
                `Метод стрижки когтей у кошки`,
                `Общий термин, обозначающий различные захваты на коже шеи кошки`,
                `Техника по работе со страхом у кошки`,
            ]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step36">
                <div className="head">
                    О методах скраффинга
                </div>
                <TextBlock
                    text={`Что такое скраффинг?`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Скраффинг - общий термин, обозначающий различные захваты на коже шеи кошки.`}
                />
            </div>
        )
    }
}
