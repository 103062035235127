import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Минимум один сотрудник <b>(ветеринарный врач)</b> клиники должен пройти обучение по программе Национальной ассоциации медицины кошек на образовательной платформе <a class="text_link" href="https://catfriendly.ru/" target="_blank" rel="noopener noreferrer">www.catfriendly.ru</a> и получить <span class="text-blue">Сертификат «Специалист Cat Friendly – С любовью к кошкам».</span>`}
                />
            </div>
        )
    }
}