import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';



export default class step24 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Время от времени на улице могут происходить строительные или ремонтные работы, из-за которых может возникнуть много шума и нежелательных звуков, которые могут быть достаточно пугающими для  кошки. Ремонт улиц, коммунальные работы и даже строительство дворовых территорий могут создавать достаточно шума, чтобы вызвать страх и беспокойство у кошек. Предоставьте кошке необходимое убежище. Всегда оставляйте радиоприемник или телевизор включенными рядом с убежищем, чтобы заглушить нежелательные звуки строительных
                            и ремонтных работ и обеспечить успокаивающий «белый шум» для кошки.
                        </li>
                        <li>
                            Можно поместить кошачий насест в ту часть дома, куда не проникают внешние звуки, или где они слышны не так громко. Сделать пребывание на насесте более привлекательным для кошки.
                            Для этого можно натереть его кошачьей мятой или оставить рядом вкусные лакомства.
                        </li>

                    </ul>
                </div>


            </div>
        )
    }
}
