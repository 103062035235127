import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson6/step16-1.png'

export default class step16 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 4) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {
            text: `Кошки не так общительны, как многие другие животные, и их общение и взаимодействие между собой и с другими видами происходит совсем не так, как у людей. Вопреки распространенному мнению, кошки, в первую очередь, полагаются именно на запах, а не на визуальное восприятие, чтобы общаться с другими кошками, находить себе пищу и обнаруживать хищников.`,
        },
        2: {
            text: `Кошки общаются и взаимодействуют с окружающим миром посредством «маркировки» предметов и других животных. Эти метки представляют собой запахи (называемые феромонами), выделяемые из специальных желез, расположенных на их лбу, щеках, основании хвоста и лапах, когда они царапают и трутся своим телом о различные предметы.`,
        },
        3: {
            text: `Кошки также не слишком полагаются на звуки, издаваемые другими кошками, поэтому они не обращают особого внимания на вербальные звуки, которые издают люди.`,
        },
        4: {
            text: `Очень важно, чтобы у домашних кошек было достаточно наполнителя в чистом кошачьем лотке, поскольку это поможет скрыть запах мочи после мочеиспускания. В условиях дикой природы кошки все время мочатся в новых чистых местах, поэтому если мы хотим, чтобы они использовали одно и то же место каждый раз, когда ходят в туалет, мы должны держать его в соответствующей чистоте!`,
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step16">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать основные социальные привычки кошек.`}
                />
                <div className="box">
                    <div className="box_img">
                        <div
                            className={'box_point ' + (points.indexOf(1) >= 0 && 'active ') + (pointActive === 1 && 'heigher')}
                            onClick={() => {this.showPopup(1)}}
                        >
                            <div className="box_point_inner"/>
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(2) >= 0 && 'active ') + (pointActive === 2 && 'heigher')}
                            onClick={() => {this.showPopup(2)}}
                        >
                            <div className="box_point_inner"/>
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(3) >= 0 && 'active ') + (pointActive === 3 && 'heigher')}
                            onClick={() => {this.showPopup(3)}}
                        >
                            <div className="box_point_inner"/>
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(4) >= 0 && 'active ') + (pointActive === 4 && 'heigher')}
                            onClick={() => {this.showPopup(4)}}
                        >
                            <div className="box_point_inner"/>
                        </div>
                        <div className={'block red ' + (pointActive === 1 && 'active')}>
                            <TextBlock
                                text={this.popupData[1].text}
                            />
                        </div>
                        <div className={'block green ' + (pointActive === 2 && 'active')}>
                            <TextBlock
                                text={this.popupData[2].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 3 && 'active')}>
                            <TextBlock
                                text={this.popupData[3].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 4 && 'active')}>
                            <TextBlock
                                text={this.popupData[4].text}
                            />
                        </div>
                        <img src={img1} className="imgCenter" alt=""/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <div className="info_icon">
                            <div className="info_icon_inner"/>
                        </div>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
