import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson31/step16-1.png'
import img1xs from '../../../image/lessons/lesson31/step16-1xs.png'


export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <div className="head">
                    Классификация по классу
                </div>
                <TextBlock
                    text={`Как различаются корма разных классов? Чем выше класс корма, тем выше его диетические свойства. 
                    
                    Диетические свойства — это содержание основных питательных веществ в корме. Если посмотреть на пирамиду (в качестве примера взяты влажные корма для кошек), можно заметить, что чем выше класс корма, тем больше в его составе содержания белков, жиров, а также энергетической ценности (Ккал). 
                    
                    Таким образом, можно сделать вывод, что чем выше класс корма, тем больше он содержит питательных веществ.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt=""/>
                </picture>
            </div>
        )
    }
}
