import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson11/step12-1.png'
import img1xs from '../../../image/lessons/lesson11/step12-1xs.png'
import img2 from '../../../image/lessons/lesson11/step12-2.png'

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="flex">
                    <picture>
                        <source srcSet={img1xs} media="(max-width: 767px)"/>
                        <img src={img1} alt="" className="flex_img"/>
                    </picture>
                    <img src={img2} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
