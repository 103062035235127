import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson8/step26-1.png'


export default class step26 extends React.Component {


    render() {
        return (
            <div className="step26">
                <div className="head">
                    Приемный покой / приемная
                </div>
                <TextBlock
                    text={`Минимизируйте время ожидания ветеринарного врача, назначив запланированный прием. В следующих целях можно изменить время приема:`}
                />
                <ul className="list">
                    <li className="list_item">
                        Необходимо осуществить прием больной кошки в более спокойное время суток;
                    </li>
                    <li className="list_item">
                        Разграничение приема кошек и собак;
                    </li>
                </ul>
                <TextBlock
                    text={`Минимизируйте взаимодействие животных в приемном покое:`}
                />
                <ul className="list">
                    <li className="list_item">
                        Держите собак подальше от кошек, предоставьте отдельные комнаты или используйте специальные экраны для создания отдельных зон.
                    </li>
                    <li className="list_item">
                        Расположите переноски на достаточной высоте от пола.
                    </li>
                    <li className="list_item">
                        Оборудуйте ветеринарную клинику полками или используйте стул, исключающий визуальный контакт животных.
                    </li>
                    <li className="list_item">
                        Используйте полотенца или одеяла, чтобы накрыть переноску, если это необходимо.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
