import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/lapshin.png'
import img2 from '../../../image/Lecturers/lapshin-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Лапшин'}
                    name={'Максим Николаевич'}
                    text={'Ветеринарный врач-невролог, нейрорадиолог. Ветеринарный Госпиталь "Skolkovo Vet"'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/w1E5vhsMGco'}
                    title={'https://www.youtube.com/watch?v=w1E5vhsMGco&feature=youtu.be'}

                />
            </div>
        )
    }
}
