import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step23 extends React.Component {


    render() {
        return (
            <div className="step23">
                <TextBlock
                    text={`<b>Использование синтетического аналога кошачьего лицевого феромона</b>`}
                />
                <ul className="list">
                    <li className="list_item">
                        Синтетический аналог лицевого феромона может оказывать успокаивающее действие и снижать тревогу, страх и агрессию, а также способствовать нормализации ухода за шерстью и улучшению потребления пищи у кошек в клетке.
                    </li>
                    <li className="list_item">
                        Эффективным является использование диффузоров с феромоном по всей ветеринарной клинике. Можно использовать специальный спрей примерно за 30 минут до ветеринарного обследования.
                    </li>
                    <li className="list_item">
                        Используйте синтетический аналог лицевого феромона исключительно в дополнение к процедуре создания благоприятной для кошек среды.
                    </li>
                </ul>
            </div>
        )
    }
}
