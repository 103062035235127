import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step3-1.png";

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Важно обеспечить пространство, которое кошка может идентифицировать как свое собственное. В таком месте должны располагаться:`}
                />
                <div className="flex">
                    <ul className="list">
                        <li className="list_item">миска с едой и водой;</li>
                        <li className="list_item">подстилка;</li>
                        <li className="list_item">кошачий лоток;</li>
                        <li className="list_item">когтеточка или место для насеста;</li>
                        <li className="list_item">окно с хорошим углом обзора на улицу или двор;</li>
                        <li className="list_item">несколько игрушек.</li>
                    </ul>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
