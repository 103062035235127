import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson43/step5-1.png";

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <hr className="string"/>
                <div className="head">Почему музыка для кошек важна в рамках подхода Cat Friendly – С любовью к кошкам</div>

                <TextBlock
                    text={`Музыка для кошек – способ избавить кошек от тревожности, которую они испытывают во время приёма у ветеринарного врача. Наличие естественных для кошек звуков – таких как мурчание, звук, когда котенок пьёт молоко – помогает им расслабиться, в результате чего врачу не приходится торопиться во время осмотра.`}
                />

                <img src={img1} className="imgCenter" alt=""/>
            </div>
        )
    }
}
