import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock';

import img1 from "../../../image/lessons/lesson33/step13-1.png";
import img2 from "../../../image/lessons/lesson33/step13-2.png";
import img3 from "../../../image/lessons/lesson33/step13-3.png";
import img4 from "../../../image/lessons/lesson33/step13-4.png";
import img5 from "../../../image/lessons/lesson33/step13-5.png";
import img6 from "../../../image/lessons/lesson33/step13-6.png";


export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="head">
                    Ключевые потребности взрослых кошек в питании
                </div>
                <div className="block">
                    <DefinitionBlock
                        text={`<span class="text_violet">Стерилизация</span> — один из элементов ответственного содержания домашних кошек.`}
                    />
                    <img src={img5} alt="" className="block_img"/>
                </div>
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>Стерилизация несет в себе ряд преимуществ для здоровья кошки:</b>`}
                        />
                        <div className="flex_el_li">
                            <img src={img1} alt="" className="flex_el_li_img"/>
                            <div className="flex_el_li_text">
                                Стерилизованные кошки становятся <span className="flex_el_li_pink">более спокойными и дружелюбными</span>;
                            </div>
                        </div>
                        <div className="flex_el_li">
                            <img src={img2} alt="" className="flex_el_li_img"/>
                            <div className="flex_el_li_text">
                                Стерилизованные кошки живут дольше, у них значительно <span className="flex_el_li_pink">снижается риск развития ряда заболеваний</span>.
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`<b>Особенности здоровья стерилизованных кошек:</b>`}
                        />
                        <div className="flex_el_li">
                            <img src={img3} alt="" className="flex_el_li_img"/>
                            <div className="flex_el_li_text">
                                Кошки становятся менее подвижными, больше спят, у них появляется <span className="flex_el_li_pink">склонность к набору лишнего веса</span>;
                            </div>
                        </div>
                        <div className="flex_el_li">
                            <img src={img4} alt="" className="flex_el_li_img"/>
                            <div className="flex_el_li_text">
                                Снижается частота мочеиспусканий, что приводит к застою мочи и <span className="flex_el_li_pink">повышению риска развития мочекаменной болезни</span>.
                            </div>
                        </div>
                    </div>
                </div>
                <img src={img6} alt="" className="imgCenter"/>
                <div className="info">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15" cy="15" r="14" stroke="white" stroke-width="2"/>
                        <path d="M14.1141 16.6168C14.1141 17.4158 14.7038 17.9103 15.4837 17.9103C16.2446 17.9103 16.8533 17.3967 16.8533 16.6168V9.29348C16.8533 8.51359 16.2446 8 15.4837 8C14.7038 8 14.1141 8.49457 14.1141 9.29348V16.6168ZM15.4837 22C16.3016 22 16.9674 21.3342 16.9674 20.5163C16.9674 19.6984 16.3016 19.0326 15.4837 19.0326C14.6658 19.0326 14 19.6984 14 20.5163C14 21.3342 14.6658 22 15.4837 22Z" fill="white"/>
                    </svg>
                    <TextBlock
                        text={`Важно предоставить стерилизованной кошке правильное питание, которое учитывает эти особенности обмена веществ.`}
                    />
                </div>
            </div>
        )
    }
}
