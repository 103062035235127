import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson31/step8-1.png'


export default class step8 extends React.Component {

    state = {
        pointActive: 0,
        points: [],
    };

    showPoint = pointActive => {
        const {points} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({
            pointActive: pointActive,
            points: points
        })

        if (points.length === 2) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step8">
                <div className="head">
                    Классификация по возрасту животного
                </div>
                <InfoBlock
                    text={`Нажимайте на кружочки, чтобы узнать факты о питании пожилых животных.`}
                />
                <div className="imgDiv">
                    <div
                        className={'point ' + (points.indexOf(1) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(1)}}
                    >
                    </div>
                    <div
                        className={'point ' + (points.indexOf(2) >= 0 ? 'point_out' : '')}
                        onClick={() => {this.showPoint(2)}}
                    >
                    </div>
                    <img src={img1} alt="" className="imgCenter"/>
                    <div className="imgDiv_answers">
                        <div
                            className={'answer ' + (points.indexOf(1) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Оптимальная усвояемость в наших рационах для стареющих животных обеспечивается <b>балансом</b> качественных высокоусвояемых, низкоаллергенных ингредиентов и специально подобранной клетчатки.</p>
                        </div>
                        <div
                            className={'answer ' + (points.indexOf(2) >= 0 ? 'answer_in' : '')}
                        >
                            <p className="answer_text">Стареющие животные <b>более требовательны к содержанию</b> в рационе таких незаменимых аминокислот, как аргинин, тирозин, триптофан, метионин, лейцин, фенилаланин и ряда других.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
