import React from 'react'

import img1 from "../../../image/lessons/lesson12/step17-1.svg";
import img2 from "../../../image/lessons/lesson12/step17-2.svg";
import img2xs from "../../../image/lessons/lesson12/step17-2xs.svg";
import img3 from "../../../image/lessons/lesson12/step17-3.svg";


export default class step17 extends React.Component {

    render() {
        return (
            <div className="step16">
                <div className="head">
                    Конфликтное поведение
                </div>
                <div className="flex">
                    <div className="flex_img-box">
                        <img src={img1} alt="" className="flex_img"/>
                    </div>
                    <div className="flex_text">
                        Когда кошка (на рисунке справа) собирается атаковать, ее туловище находится в прямолинейном положении, зрачки становятся узкими, хвост может быстро двигаться из стороны в сторону, уши подняты назад, а усы – вперед.
                    </div>
                </div>
                <div className="flex grey">
                    <div className="flex_img-box">
                        <picture>
                            <source srcSet={img2xs} media="(max-width: 767px)"/>
                            <img src={img2} alt="" className="flex_img"/>
                        </picture>
                    </div>
                    <div className="flex_text">
                        Когда кошка защищается от другой кошки (на рисунке слева), она откидывается назад и выгибает спину. Она может очень быстро двигать хвостом, выгибать его над спиной или просовывать между задними лапами. Уши становятся в этот момент плоскими, зрачки расширены (большие), а шерсть может встать дыбом. Кошки делают это, чтобы казаться как можно более крупными и угрожающими.
                    </div>
                </div>
                <div className="flex">
                    <div className="flex_img-box">
                        <img src={img3} alt="" className="flex_img"/>
                    </div>
                    <div className="flex_text">
                        Когда угроза близка, живот и лапы защищающейся кошки (на рисунке слева) могут касаться пола. Уши и усы будут откинуты назад и прижаты к голове, а зубы могут быть обнажены. Если во время конфликта кошки оказываются на спине или  переворачиваются на спину целенаправленно, то лишь для того, чтобы  яростно отразить нападение, защищаясь всеми четырьмя лапами. В то время как собаки при конфликтной ситуации демонстрируют живот, чтобы избежать драки.
                    </div>
                </div>
            </div>
        )
    }
}
