import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <TextBlock
                    text={`Под стационаром подразумевается помещение длительного лечения пациентов и место пробуждения пациентов после операций.`}
                />
                <TextBlock
                    text={`К нему предъявляются следующие требования:`}
                />
                <ul className="list">
                    <li className="list_item">
                        <div className="number">1.</div>
                        Стены и потолок клеток/боксов должны быть сделаны из твердого материала и быть непрозрачными.
                        Если стены или потолок представляют собой сетку или решетку, необходимо использовать специальные заградительные перегородки,
                        предотвращающие визуальный контакт и распространение нежелательных запахов.
                    </li>
                    <li className="list_item">
                        <div className="number">2.</div>
                        В идеале клетки не должны быть обращены друг к другу или примыкать друг к другу под углом менее 180°. При этом, если клетки расположены таким
                        образом, что одна клетка обращена к другой клетке (прямо или под углом), между клетками должен быть установлен сплошной непрозрачный барьер.
                    </li>
                </ul>
            </div>
        )
    }
}