import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson11/step2-1.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="flex">
                    <ol className="listNum">
                        <li className="listNum_item">
                            <span className="listNum_item_number">1.</span> <span className="listNum_item_text">Кошки – хищники. У них сильные челюсти и острые клыки для того, чтобы убивать и раздирать свою добычу.</span>
                        </li>
                        <li className="listNum_item">
                            <span className="listNum_item_number">2.</span> <span className="listNum_item_text">Им свойственна хорошо развитая и гибкая мускулатура передних конечностей — для того, чтобы бросаться на добычу.</span>
                        </li>
                        <li className="listNum_item">
                            <span className="listNum_item_number">3.</span> <span className="listNum_item_text">Хищников объединяет то, что они питаются пищей животного происхождения.</span>
                        </li>
                        <li className="listNum_item">
                            <span className="listNum_item_number">4.</span> <span className="listNum_item_text">Характерны для хищников и мощные когти.</span>
                        </li>
                    </ol>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
