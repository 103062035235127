import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from "../../../Components/LessonAndTestComponents/PlanInFirstStep";
import Creator from "../../../Components/LessonAndTestComponents/Creator";
import img1 from "../../../image/lessons/lesson30/step1-1.svg";

export default class step1 extends React.Component {

    planData = [
        `Какие научные методы используются при разработке рецептуры корма`,
        `Из каких этапов состоит процесс производства кормов`,
        `Какие технологии используются для изготовления промышленных кормов`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Отделом научной коммуникации компании «МАРС»'}
                    text={'Использованы материалы научно-исследовательского института Waltham. <a class="text_link" href="https://www.waltham.com" target="_blank" rel="noopener noreferrer">https://www.waltham.com</a>'}
                />
                <hr className="string"/>
                <TextBlock
                    text={`В этом уроке вы узнаете о том, как происходит разработка и изготовление корма, от рецептуры до упаковки.`}
                />
                <div className="head">План урока</div>

                {/*<TextBlock*/}
                {/*    text={`В этом уроке вы узнаете об основных различиях между кормами и особенностях выбора кормов. */}
                {/*    */}
                {/*    Вы узнаете:`}*/}
                {/*/>*/}

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
