import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from "../../../image/Lecturers/namk-mars.png";

export default class step1 extends React.Component {

    planData = [
        `Что такое музыка для кошек`,
        `Как появилась идея создания музыки для кошек`,
        `Почему музыка для кошек важна в рамках подхода Cat Friendly – С любовью к кошкам`,
        `Где найти музыку для кошек`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    type={'ПОДГОТОВЛЕНО'}
                    name={'Национальной ассоциацией медицины кошек и отделом научной коммуникации компании «МАРС»'}
                    text={'Использованы материалы Научно-исследовательского института питания домашних животных Waltham. <a href="https://www.waltham.com">https://www.waltham.com</a>'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`Любая музыка, которую когда-либо слышали кошки, была создана человеком и для человека. С тех времен, когда кошкам поклонялись, как богами, и до текущего дня, когда им поклоняются в Интернете, кошкам приходилось слушать музыку, которая им не очень нравится. Но всё изменилось! Бренд WHISKAS® совместно с композитором Дэвидом Тейе разработали проект «Жизнь Замурррчательная», создающий музыку для кошек.`}
                />

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
