import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from "../../../Components/LessonAndTestComponents/OnClickTest";

import img1 from "../../../image/lessons/lesson7/step33-1.png";


export default class step33 extends React.Component {

    state = {
        answersData:
            [
                `Да`,
                `Нет`,
            ]
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step33">
                <TextBlock
                    text={`Определите, иллюстрируют ли следующие картинки конфликт между кошками:`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'block'}
                    rightAnswers={[1]}
                    showNextBtn={this.showNextBtn}
                    quantity={50}
                    correctText={`Когда кошка (в данном случае – кошка слева) собирается атаковать, то она держит тело прямо, зрачки сужаются, хвост может быстро двигаться из стороны в сторону, уши - назад, а усы - вперед.`}
                />
            </div>
        )
    }
}
