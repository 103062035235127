import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step32-1.png";

export default class step32 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>Необходимо назначить одного человека, который должен нести ответственность за кошку и стать
                            ее основным опекуном во время лечения.
                        </li>
                        <li>Не стоит пытаться взаимодействовать или общаться с кошкой слишком часто, если она не готова
                            или плохо воспринимает общение.
                        </li>
                        <li>Нужно не забывать поощрять ее вкусными лакомствами и дарить похвалу, чтобы помочь сделать
                            процесс лечения позитивным опытом для кошки.
                        </li>
                        <li>Если кошка не очень хорошо принимает лекарства или вовсе отказывается от приема назначенных
                            ей медикаментов, можно смешать лекарство с ароматизированной основой, например, с рыбным или
                            куриным вкусом. Но не с привычным или любимым кормом!
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
