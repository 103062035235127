import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step40-1.png'


export default class step40 extends React.Component{

    render() {
        return (
            <div className="step40">
                <TextBlock
                    text={`Разрешить конфликтную ситуацию, возникшую с другими животными, собаками, детьми или взрослыми проще.
                    
                    Независимо от уверенности владельца, что его собака никогда не причинит вреда его кошке, для кошки собака – это враг номер один. Если кошка не претендует на господство над собакой, ей должны быть предоставлены способы для побега в любое время.
                    
                    В случае с людьми обычно достаточно объяснить детям и гостям, что кошки могут воспринимать грубое обращение не как игру, а как угрозу нападения хищника.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
