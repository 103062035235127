import React from 'react'
import './style.sass'
import InputMask from 'react-input-mask';


export default class InputSearch extends React.Component {

    state = {
        data: [],
        dataSort: [],
        search: '',
        search_show: false
    }

    componentDidMount() {
        this.setState({data: this.props.data, dataSort: this.props.data})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.updateDataState(this.props.data);
        }
    }

    updateDataState = () => {
        this.setState({data: this.props.data, dataSort: this.props.data})
    }

    showData = () => {
        const {search_show} = this.state
        this.setState({search_show: !search_show})
    }

    onChangeValue = (name, value, index) => {
        //Прокидываем индекс для выбора id клиники
        this.props.onChange(name, value, index)
        this.showData()
    }

    filterData = value => {
        let {data} = this.state
        // if (typeof data[0] === 'string') {
        //     data = data.slice().filter(el => {
        //         return el.toLowerCase().indexOf(value.toLowerCase()) > -1;
        //     })
        // } else {
        //     data = data.slice().filter(function(item, pos) {
        //         return data.indexOf(item.name) === pos;
        //     }).filter(el => {
        //         return el.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        //     })
        // }
        data = data.slice().filter(function(item, pos) {
            return data.indexOf(item) === pos;
        }).filter(el => {
            if (typeof el === 'string') {
                return el.toLowerCase().indexOf(value.toLowerCase()) > -1;
            } else {
                return el.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
            }
        })
        this.setState({search: value, dataSort: data})
    }

    render() {
        const {dataSort, value, search_show} = this.state
        return (
            <div className={'inputBlock ' + (this.props.option_show && 'show ') + (this.props.required && ' required')}>
                {this.props.label &&
                    <label htmlFor={this.props.id}>{this.props.label}</label>
                }
                <span className="star">&#42;</span>
                <InputMask
                    type={this.props.type ? this.props.type : 'text'}
                    className={'input ' + this.props.className}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onClick={this.showData}
                    readOnly={true}
                    required={this.props.required}
                    disabled={this.props.disabled}
                    mask={this.props.mask}
                />
                {this.props.complete &&
                    <div className={'input_complete'}>
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.7733 0.194641C13.0635 0.465301 13.0769 0.917368 12.8032 1.20436L4.62976 9.77579C4.4933 9.91889 4.30322 10 4.10434 10C3.90545 10 3.71537 9.91889 3.57892 9.77579L0.196804 6.22899C-0.0768633 5.942 -0.0634758 5.48993 0.226706 5.21927C0.516887 4.94861 0.973977 4.96185 1.24764 5.24884L4.10434 8.24464L11.7524 0.224215C12.026 -0.0627783 12.4831 -0.0760187 12.7733 0.194641Z" fill="#4DD06A"/>
                        </svg>
                    </div>
                }
                {this.props.errorText &&
                    <div className="errorText">{this.props.errorText}</div>
                }
                <div className={'search ' + (search_show && 'show')}>
                    <div
                        className="search_close"
                        onClick={this.showData}
                    />
                    <div className="search_box">
                        <InputMask
                            type={'text'}
                            className={'search_input'}
                            name={'search'}
                            value={value}
                            placeholder={'Введите запрос'}
                            onChange={(e) => {this.filterData(e.target.value)}}
                        />
                        <div className="search_box_icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.25529 9.15956C10.9564 7.47609 10.9564 4.74665 9.25529 3.06318C7.55423 1.37971 4.79626 1.37971 3.0952 3.06318C1.39413 4.74665 1.39413 7.47609 3.0952 9.15956C4.79626 10.843 7.55423 10.843 9.25529 9.15956ZM10.5418 10.4328C12.9534 8.04612 12.9534 4.17662 10.5418 1.78998C8.13022 -0.59666 4.22027 -0.59666 1.80869 1.78998C-0.602896 4.17662 -0.602896 8.04612 1.80869 10.4328C4.22027 12.8194 8.13022 12.8194 10.5418 10.4328Z" fill="#C6C6C6"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.91148 9.97446C10.2667 9.62287 10.8427 9.62287 11.198 9.97446L15.7336 14.4631C16.0888 14.8147 16.0888 15.3847 15.7336 15.7363C15.3783 16.0879 14.8023 16.0879 14.447 15.7363L9.91148 11.2477C9.55622 10.8961 9.55622 10.326 9.91148 9.97446Z" fill="#C6C6C6"/>
                            </svg>
                        </div>
                    </div>
                    <ul className="search_data">
                        {dataSort.map((item, index, dataSort) => {
                            if (typeof item === 'object') {
                                // dataSort = [...new Set(dataSort)]
                                dataSort.sort((a, b) => {
                                    const nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
                                    if (nameA < nameB)
                                        return -1
                                    if (nameA > nameB)
                                        return 1
                                    return 0
                                })
                            }
                            return (
                                <li
                                    key={index}
                                    className="search_data_el"
                                    onClick={() => {this.onChangeValue(this.props.name, item, index)}}
                                >
                                    {typeof item === 'string' ? item : item.name}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}
// .filter(function(item, pos) {
//     return dataSort.indexOf(item.name) === pos;
// })
