import React from 'react'

import img1 from '../../../image/lessons/lesson11/step16-1.png'
import img2 from '../../../image/lessons/lesson11/step16-2.png'
import img3 from '../../../image/lessons/lesson11/step16-3.png'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step16 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step16">
                {/*<TextBlock*/}
                {/*    text={`Поскольку кошки едят <b>маленькими порциями и часто</b>, у них в доступе всегда должен быть корм. Рекомендуется кормить кошек по следующей схеме:`}*/}
                {/*/>*/}
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <p className="flex_text">
                                <b>Нельзя</b> кормить кошку рационом для собак! В нем не хватает необходимых кошке питательных веществ.
                            </p>
                            <img className="img" src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <p className="flex_text">
                                <b>Нельзя</b> кормить взрослую кошку кормом для котят! Это несет в себе риски несбалансированного питания и избытка калорий.
                            </p>
                            <img className="img img_2" src={img2} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp flex_wrp--green">
                            <p className="flex_text">
                                <b>Рекомендуется</b> давать кошке корм, соответствующий ее возрасту.
                            </p>
                            <img className="img img_3" src={img3} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
