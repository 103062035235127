import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson11/step31-1.png";
import img1xs from "../../../image/lessons/lesson11/step31-1xs.png";


export default class step31 extends React.Component {

    render() {
        return (
            <div className="step31">
                <TextBlock
                    text={`Эти статистические данные показывают процент кошек с избыточных, нормальным и недостаточным весом в городах России разной численности населения.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 600px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <TextBlock
                    text={`Как видно из диаграммы, в России большинство кошек не страдает избыточным весом. Однако в Москве и Санкт-Петербурге количество кошек с излишним весом больше, чем в маленьких городах. Это объясняется пониженной активностью домашних кошек в мегаполисах.`}
                />
            </div>
        )
    }
}
