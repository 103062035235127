import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickCircleBlock from "../../../Components/LessonAndTestComponents/OnClickCircleBlock";

import img1 from "../../../image/lessons/lesson9/step6-1.png";
import img2 from "../../../image/lessons/lesson9/step6-2.png";
import img3 from "../../../image/lessons/lesson9/step6-3.png";

export default class step6 extends React.Component {

    state = {
        flexData: [img1, img2, img3],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        const {flexData} = this.state
        return (
            <div className="step6">
                <InfoBlock
                    text={`Нажмите на изображения, чтобы узнать больше о чувстве безопасности у кошки.`}
                />
                <OnClickCircleBlock
                    flexData={flexData}
                    showNextBtn={this.showNextBtn}
                    type={'img'}
                >
                    <div className="info_wrap">
                        <TextBlock
                            text={`<b>Страх и стресс на первичном приеме и при дальнейшем размещении в стационаре не должны быть оставлены без внимания!</b>
                            
                            Сильный страх перерастает в оцепенение, кошка пассивна, не двигается, не ест и не пьет, может днями не справлять естественные нужды.`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`<b>Страх может перерасти в агрессию.</b>
                            
                            Кошка чувствует себя загнанной в угол и не видит иного выхода, кроме как защищать свою жизнь всеми доступными ей средствами – царапаясь и кусаясь.`}
                        />
                    </div>
                    <div className="info_wrap">
                        <TextBlock
                            text={`Надлежащий уход и бережное и уважительное отношение к кошке с момента приема и до выписки домой – довольный и счастливый пациент.`}
                        />
                    </div>
                </OnClickCircleBlock>
            </div>
        )
    }
}
