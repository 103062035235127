import React from 'react'
import './style.sass'

export default (props) => {
    return (
        <div className={'DocumentLink ' + (props.disabled && 'disabled')}>
            <a
                href={props.link}
                className="DocumentLink_link"
                target="_blank"
                rel="noopener noreferrer"
            >
                {props.text}
            </a>
            <div className="DocumentLink_file-icon">
                {props.svg}
            </div>
            <div className="DocumentLink_updating">
                Информация обновляется...
            </div>
        </div>
    )
}
