import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step30-1.png";

export default class step30 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Постараться сделать поездку к ветеринарному врачу положительным опытом. Дать кошке любимые лакомства (если допустимо) или игрушки.
                        </li>
                        <li>
                            Когда кошка вернется домой после посещения ветеринарной клиники, убедиться, что в доме есть необходимое убежище. Кошки узнают друг друга по запаху. Когда кошка возвращается домой, другие кошки могут относиться к ней как к чужаку, поскольку она будет пахнуть клиникой. Возможно, придется приложить некоторые усилия, чтобы заново поэтапно познакомить ее с другими кошками или другими животными в вашем доме, чтобы избежать возможного возникновения конфликтных ситуаций.
                        </li>
                        <li>
                            В экстренной ситуации может понадобиться отвезти кошку к врачу без возможности заранее подготовиться к визиту. В этом случае можно завернуть кошку в полотенце и поместить ее в переноску.
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
