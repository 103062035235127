import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step46-1.png'


export default class step46 extends React.Component{

    render() {
        return (
            <div className="step46">
                <ul className="list">
                    <li className="list_item">
                        Необходимо определиться с правильным выбором когтеточки из соответствующего материала.
                    </li>
                    <li className="list_item">
                        Поместить ее рядом с тем предметом, который царапала кошка.
                    </li>
                    <li className="list_item">
                        Накрыть этот предмет, например, алюминиевой фольгой или заклеить двусторонней липкой лентой.
                    </li>
                    <li className="list_item">
                        Когда кошка начнет использовать когтеточку по прямому назначению, ее можно постепенно переместить в более подходящее место в доме.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
