import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ListNumeral from '../../../Components/LessonAndTestComponents/ListNumeral';

// import img1 from "../../../image/lessons/lesson8/step32-1.png";


export default class step32 extends React.Component {

    data = [
        `Госпитализируйте кошку, чтобы провести дополнительные процедуры.`,
        `Используйте искусственное расслабление с помощью феромонов.`,
        `Перенесите осмотр на другое время, когда у вас появится более эффективная стратегия.`,
    ]

    render() {
        return (
            <div className="step32">
                <div className="head">
                    Распознавание и реагирование на кошачьи сигналы
                </div>
                <TextBlock
                    text={`Чтобы правильно себя вести, правильно читайте язык тела кошки. При необходимости, разделите длительные процедуры обследования на отдельные этапы. Если кошка начинает проявлять страх, приостановите осмотр и сделайте перерыв. При обследовании в несколько этапов правильно скорректируйте время таких перерывов. Используйте лакомства или аналог кошачьего лицевого феромона.
                    
                    Будьте готовы изменить подход, чтобы адаптироваться к состояниям кошки.`}
                />
                <ListNumeral
                    data={this.data}
                />
                <TextBlock
                    text={`Проявляйте выдержку и спокойствие.
                    Всегда старайтесь закончить обследование животного на позитивной ноте.`}
                />
                {/*<img src={img1} alt="" className="imgCenter"/>*/}
            </div>
        )
    }
}
