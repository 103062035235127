import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";
import img1 from "../../../image/Lecturers/kadochnikova.png";
import img2 from "../../../image/Lecturers/kadochnikova-icon.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step3">
                Шаг 3
            </div>
        )
    }
}

