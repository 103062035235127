import React from 'react'
import './style.sass'

export default class OnClickCircleBlock extends React.Component {

    //Пример использования lesson8, step 6. Второй тип с svg в lesson9 step 7

    state = {
        flexActive: 0,
        flexs: [1],
        flexData: this.props.flexData,
    };

    // componentDidMount() {
    //     document.querySelector('.OnClickBlock').style.setProperty('--quantity', (100 / this.props.flexData.length) + '%')
    // }

    changeFlex = flexActive => {
        const {flexs, flexData} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive: flexActive === this.state.flexActive ? 0 : flexActive, flexs})
        if (flexs.length === flexData.length) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {
        this.setState({flexActive: 0})
    }

    render() {
        const {flexActive, flexData} = this.state
        return (
            <div className="OnClickCircleBlock">
                <div className={'flex'}>
                    {flexData.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'flex_el ' + (flexActive === (index + 1) && 'active')}
                                onClick={() => {this.changeFlex(index + 1)}}
                            >
                                {this.props.type === 'img'
                                    ?
                                    <img src={item} alt="" className="flex_img"/>
                                    :
                                    <div className='flex_svg-box'>
                                        {item}
                                    </div>
                                }

                                <div className={'info ' + (flexActive === (index + 1) && 'info--active')}>
                                    {React.Children.map(this.props.children, (child, i) => {
                                        if (i === index) return child
                                    })}
                                </div>

                            </div>
                        )
                    })}
                </div>

            </div>
        )
    }
}
