import React from 'react'
import SliderImgDes from '../../../Components/LessonAndTestComponents/SliderImgDes';

import img1 from '../../../image/lessons/lesson13/step6-1.png';
import img2 from '../../../image/lessons/lesson13/step6-2.png';
import img3 from '../../../image/lessons/lesson13/step6-3.png';

export default class step6 extends React.Component {

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    sliderData = {
        1: {
            text: `<p class="step6-slideP">Все двери наружу следует держать закрытыми. Кошка привыкла находиться на открытом воздухе и может попытаться выбраться обратно на улицу. Нужно постараться сделать внутреннюю среду в доме как можно более интересной для кошки. Например: </p>
            <ul class="step6-slideUl">
                <li>разместить насесты возле окон, чтобы кошка могла выглянуть наружу или смотреть в окно. Насесты должны быть от 60 до 90 см в высоту, чтобы у кошки был необходимый угол обзора, и она могла смотреть вниз на свое окружение. </li>
                <li>расположить в доме когтеточки в нескольких местах, чтобы кошка могла удовлетворить свой инстинкт царапаться и точить коготки. </li>
            </ul>`,
            img: img1,
        },
        2: {
            text: `
            <p class="step6-slideP">Несмотря на все усилия, ваша кошка все еще может иметь сильное желание убежать на улицу. Чтобы убрать у кошки охоту убегать из дома, когда она видит, что входные двери открыты, можно попытаться сделать так, чтобы двери для нее казались менее привлекательными.</p>       
            <p class="step6-slideP">Лучше отвлечь игрушкой – кинуть фантик или мячик, привлечь внимание, чем-то пошуршав или позвав по имени. </p>`,
            img: img2
        },
        3: {
            text: `Если владельцу хочется, чтобы кошка могла какое-то время проводить на свежем воздухе, можно купить специальную кошачью шлейку и постепенно приучать кошку к прогулкам на поводке. Также можно выпускать кошку в закрытый вольер, расположенный на солнечной веранде.`,
            img: img3
        },
    }

    render() {
        return (
            <div className="step6">
                <SliderImgDes
                    showNextBtn={this.showNextBtn}
                    sliderData={this.sliderData}
                />
            </div>
        )
    }
}
