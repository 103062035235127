import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson43/step4-1.png";
import Popup from "../../../Components/Popup";

export default class step4 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 1) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 1) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    render() {
        const {showPopup, pointActive} = this.state
        return (
            <div className="step4">
                <hr className="string"/>
                <div className="head">Как появилась идея создания музыки для кошек</div>

                <TextBlock
                    text={`Однажды американский композитор Дэвид Тейе зашел в кошачье кафе и включил местному коту свои треки. Кот был в восторге. С этого началась история целого альбома музыки для кошек. Дэвид вместе с исследователями выяснил, что пушистые питомцы реагируют на музыку так же, как и люди. Только музыка у нас с ними разная.`}
                />

                <div className="box">
                    <div className="box_img">
                        <img
                            src={img1} className="imgCenter" alt=""
                            onClick={() => {this.showPopup(1)}}
                        />
                        <div className={'block ' + (pointActive === 1 && 'active')}>
                            Дэвид Тейе – музыкант и композитор, солист Национального симфонического оркестра
                            <svg className="block__icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="24" cy="24" r="24" fill="#4147DA"/>
                                <path d="M21.6758 31.224C21.6758 32.376 22.3958 33.144 23.4038 33.144C24.4118 33.144 25.1318 32.376 25.1318 31.224V22.008C25.1318 20.856 24.4118 20.088 23.4038 20.088C22.3958 20.088 21.6758 20.856 21.6758 22.008V31.224ZM23.4038 14.904C22.3718 14.904 21.4598 15.816 21.4598 16.8C21.4598 17.856 22.3718 18.792 23.4038 18.792C24.4838 18.792 25.3478 17.88 25.3478 16.8C25.3478 15.792 24.4358 14.904 23.4038 14.904Z" fill="white"/>
                            </svg>

                        </div>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <TextBlock
                            text={`Дэвид Тейе – музыкант и композитор, солист Национального симфонического оркестра`}
                        />
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }

            </div>
        )
    }
}
