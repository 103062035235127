import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';


export default class step15 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Для кошки ваш дом – это весь ее мир! Любая внезапная перемена в ее обычной среде обитания может расстроить и взволновать ее. Далее представлен список потенциальных изменений в окружающей среде кошки и даны некоторые рекомендации по правильной адаптации к таким потенциальным изменениям.
                        </li>

                    </ul>
                </div>


            </div>
        )
    }
}
