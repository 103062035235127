import React from 'react'
import './style.sass'
import star from '../../image/CourseStat/star.png'
// import status from '../../image/CourseStat/status.png'
import progress from '../../image/CourseStat/progress.png'

export default class CourseStat extends React.Component{

    _renderStatus = () =>{
        const {courses} = this.props
        let rank = ''
        if(courses.rank){
            switch (courses.rank) {
                case 0:
                    rank = 'НОВИЧОК';
                    break;
                case 1:
                    rank = 'НОВИЧОК';
                    break;
                case 2:
                    rank = 'СПЕЦИАЛИСТ';
                    break;
                case 3:
                    rank = 'ПРОФЕССИОНАЛ';
                    break;
                case 5:
                    rank = 'МАСТЕР';
                    break;
                case 6:
                    rank = 'ЗНАТОК';
                    break;
                case 7:
                    rank = 'ГУРУ';
                    break;
                default :
                    rank = 'НОВИЧОК';
                    break;
            }
        }
        return rank
    }

    render() {
        const {courses} = this.props
        return (
            <div className={'CourseStat ' + (courses.progressTest !== undefined && 'CourseStatFull')}>
                <div className="head">
                    Статистика курса
                </div>
                <div className="balls">
                    <div className="icon">
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.4839 1.92202L13.5718 5.34902C14.4008 6.70982 15.7371 7.68494 17.286 8.05934L21.1891 9.00283L18.5746 12.0905C17.5566 13.2926 17.0523 14.8469 17.1704 16.4177L17.4739 20.4542L13.7796 18.9243C12.3097 18.3155 10.6581 18.3155 9.18819 18.9243L5.5009 20.4513L5.81983 16.4333C5.94516 14.8544 5.44109 13.29 4.41756 12.0812L1.8097 9.00147L5.6861 8.06041C7.23258 7.68497 8.56657 6.71053 9.39456 5.35148L11.4839 1.92202Z" stroke="#4147DA" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="balls__box">
                        Набрано баллов{courses.progressTest !== undefined && ' по урокам'}:&#8194;<b>{courses.points} из {this.props.pointTotal}</b>
                    </div>
                </div>
                {courses.pointsTest !== undefined ?
                    <div className="status">
                        <div className="icon">
                            <img src={star} alt=""/>
                        </div>
                        <div className="balls__box">
                            Набрано баллов{courses.progressTest !== undefined && ' по тестам'}:&#8194;<b>{courses.pointsTest} из {this.props.pointTotalTest}</b>
                        </div>
                    </div>
                    :
                    <div className="status">
                        <div className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.6928 11.523C21.6224 9.46733 21.0956 7.75768 20.1215 6.41648L22.3968 0.976241C22.6378 0.400105 22.0612 -0.181188 21.4832 0.0536732C17.4538 1.69442 17.7018 1.59363 17.6882 1.59926C16.5501 2.09898 14.3235 3.07499 14.0868 3.17953C12.7846 3.00655 11.2518 3.00186 9.91348 3.17953C9.76536 3.1139 7.43988 2.09429 6.31206 1.59926C6.29753 1.59269 6.54644 1.69442 2.51705 0.0536732C1.93767 -0.182125 1.36298 0.401511 1.60345 0.976241L3.87877 6.41601C2.90471 7.75721 2.37783 9.46733 2.30752 11.5225C-1.32531 14.7932 -0.511558 19.1369 3.64627 21.779C8.30473 24.7394 15.6932 24.7413 20.354 21.779C24.546 19.1159 25.2989 14.7693 21.6928 11.523ZM20.4473 1.99397L19.0776 5.26843C18.2896 4.57088 17.377 4.06553 16.3715 3.71254C18.7457 2.67137 17.7838 3.07874 20.4473 1.99397ZM7.62832 3.71254C6.62331 4.06553 5.71065 4.57041 4.92221 5.26843L3.55299 1.99397C6.21363 3.07733 5.25643 2.6723 7.62832 3.71254ZM3.24971 19.73L6.65285 18.2711C7.00957 18.1183 7.17503 17.7048 7.02222 17.3481C6.86941 16.9913 6.45597 16.8258 6.09925 16.9787L2.25455 18.6264C1.45532 17.492 1.16189 16.163 1.63626 14.8354L6.30269 15.3243C6.688 15.3646 7.03441 15.0857 7.07519 14.6985C7.1155 14.3122 6.83519 13.9663 6.44941 13.9259L2.38064 13.4993C2.71767 13.0601 3.10018 12.6869 3.45643 12.3865C3.61299 12.2547 3.70393 12.0611 3.70627 11.8567C3.77471 5.73862 8.2541 4.45462 11.9999 4.45462C15.7457 4.45462 20.2256 5.73862 20.294 11.8567C20.2963 12.0611 20.3877 12.2547 20.5438 12.3865C20.9001 12.6869 21.2826 13.0601 21.6196 13.4993L17.5509 13.9259C17.1646 13.9663 16.8843 14.3122 16.9251 14.6985C16.9654 15.0852 17.3118 15.3646 17.6976 15.3243L22.364 14.8354C22.8384 16.1634 22.5445 17.4925 21.7457 18.6264L17.9015 16.9787C17.5448 16.8258 17.1313 16.9909 16.9785 17.3481C16.8257 17.7048 16.9907 18.1183 17.3479 18.2711L20.751 19.73C16.461 23.5576 7.51488 23.5356 3.24971 19.73Z" fill="#4147DA"/>
                                <path d="M8.95395 11.3687V10.5348C8.95395 10.1466 8.63942 9.83158 8.25082 9.83158C7.86223 9.83158 7.54769 10.1461 7.54769 10.5348V11.3687C7.54769 11.7569 7.86223 12.0719 8.25082 12.0719C8.63942 12.0719 8.95395 11.7569 8.95395 11.3687Z" fill="#4147DA"/>
                                <path d="M15.7494 9.83158C15.3613 9.83158 15.0463 10.1461 15.0463 10.5348V11.3687C15.0463 11.7569 15.3608 12.0719 15.7494 12.0719C16.138 12.0719 16.4526 11.7573 16.4526 11.3687V10.5348C16.4526 10.1461 16.1376 9.83158 15.7494 9.83158Z" fill="#4147DA"/>
                                <path d="M14.6244 17.297C14.2363 17.297 13.9213 17.6115 13.9213 18.0001C13.9213 18.3363 13.648 18.6096 13.3119 18.6096C12.9758 18.6096 12.7026 18.3363 12.7026 18.0001V16.4532L13.9602 15.3965C15.3126 14.2602 14.5963 12.048 12.7494 12.048H11.2452C9.40958 12.048 8.68301 14.2574 10.0391 15.3965L11.2968 16.4532V18.0001C11.2968 18.3363 11.0235 18.6096 10.6874 18.6096C10.3513 18.6096 10.078 18.3363 10.078 18.0001C10.078 17.612 9.76348 17.297 9.37489 17.297C8.98629 17.297 8.67176 17.6115 8.67176 18.0001C8.67176 19.1116 9.57598 20.0154 10.6869 20.0154C11.188 20.0154 11.6465 19.8312 11.999 19.5279C12.3519 19.8317 12.8104 20.0154 13.311 20.0154C14.4224 20.0154 15.3262 19.1112 15.3262 18.0001C15.3276 17.6115 15.013 17.297 14.6244 17.297ZM10.776 13.7506C10.8163 13.6395 10.9316 13.4539 11.2504 13.4539H12.7499C13.0687 13.4539 13.1835 13.6395 13.2238 13.7501C13.2815 13.9091 13.2679 14.1421 13.056 14.3202L11.9999 15.2071L10.9438 14.3197C10.7319 14.1416 10.7183 13.9091 10.776 13.7506Z" fill="#4147DA"/>
                            </svg>
                        </div>
                        <div className="balls__box">
                            Статус: &nbsp; <b>{this._renderStatus()}</b>
                        </div>
                    </div>
                }
                <div className={'progress ' + (courses.progressTest !== undefined && 'progressHulf')}>
                    <div className="icon">
                        <svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.7126 7.49476L15.6916 1.04835C15.5639 0.983884 15.4361 0.983884 15.3084 1.04835L1.28744 7.49476C1.12775 7.55923 1 7.75262 1 7.94601C1 8.1394 1.09581 8.30056 1.28744 8.39726L7.38767 11.2014C7.38767 11.2337 7.35573 11.2981 7.35573 11.3304V17.6156C7.35573 17.9702 7.5793 21 15.5 21C23.4207 21 23.6443 17.9702 23.6443 17.6156V11.3304C23.6443 11.2659 23.6443 11.2337 23.6123 11.2014L26.5187 9.87994V14.5858C26.2632 14.5858 26.0396 14.8114 26.0396 15.0693C26.0396 15.3272 26.2632 15.5528 26.5187 15.5528V15.8751C26.2632 15.8751 26.0396 16.1007 26.0396 16.3586C26.0396 16.6164 26.2632 16.8421 26.5187 16.8421V17.1644C26.5187 17.4222 26.7423 17.6479 26.9978 17.6479C27.2533 17.6479 27.4769 17.4222 27.4769 17.1644V16.8421C27.7324 16.8421 27.9559 16.6164 27.9559 16.3586C27.9559 16.1007 27.7324 15.8751 27.4769 15.8751V15.5528C27.7324 15.5528 27.9559 15.3272 27.9559 15.0693C27.9559 14.8114 27.7324 14.5858 27.4769 14.5858V9.42869L29.7126 8.39726C29.8722 8.3328 30 8.1394 30 7.94601C30 7.75262 29.8722 7.59146 29.7126 7.49476ZM22.6861 17.6156C22.6861 17.6479 22.4626 20.033 15.5 20.033C8.53745 20.033 8.31388 17.6156 8.31388 17.6156V11.6205L15.3084 14.8437C15.3722 14.8759 15.4361 14.8759 15.5 14.8759C15.5639 14.8759 15.6278 14.8759 15.6916 14.8437L22.6861 11.6205V17.6156ZM15.5 13.8767L2.62885 7.94601L15.5 2.01531L28.3711 7.94601L15.5 13.8767Z" fill="#4147DA" stroke="#4147DA" strokeWidth="0.6" strokeMiterlimit="10"/>
                        </svg>
                    </div>
                    <div className="desc">
                        Общий прогресс{courses.progressTest !== undefined && ' по уроку'}:&#8194;<div className="textProgress textProgress--mobile" style={{left: courses.progress + '%'}}>{Math.round(courses.progress)}%</div>
                        <div className="line">
                            <div className="lineProgress" style={{width: Math.round(courses.progress) + '%'}}/>
                        </div>
                        <div className="textProgress" style={{left: courses.progress + '%'}}>{Math.round(courses.progress)}%</div>
                    </div>
                </div>
                {courses.progressTest !== undefined &&
                    <div className="progress progressHulf">
                        <div className="icon">
                            <img src={progress} alt=""/>
                        </div>
                        <div className="desc">
                            Общий прогресс по тесту:&#8194;<div className="textProgress textProgress--mobile" style={{left: courses.progressTest + '%'}}>{Math.round(courses.progressTest)}%</div>
                            <div className="line">
                                <div className="lineProgress" style={{width: Math.round(courses.progressTest) + '%'}}/>
                            </div>
                            <div className="textProgress" style={{left: courses.progressTest + '%'}}>{Math.round(courses.progressTest)}%</div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}
