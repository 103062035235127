import React from 'react';
import './style.sass';
import PageHeader from '../../Components/PageHeader'
import DocumentLink from '../../Components/DocumentLink'
import Buttons from '../../Components/Buttons'
import TextBlock from '../../Components/LessonAndTestComponents/TextBlock'
import Organizer from './organizer'

import img1 from '../../image/OrganizersInformation/logo.png';
import img2 from '../../image/OrganizersInformation/antanasova.png';
import img3 from '../../image/OrganizersInformation/tkachev.png';
import img4 from '../../image/OrganizersInformation/volf.png';
import img5 from '../../image/OrganizersInformation/rubel.png';
import img6 from '../../image/OrganizersInformation/siatkovskaia.png';


export default class OrganizersInformation extends React.Component {

    state = {
        isStartPage: false
    }

    componentDidMount() {
        if (this.props.isStartPage) {
            this.setState({isStartPage: this.props.isStartPage})
        }
    }

    organizersData = {
        1: {
            img: img1,
            name: ``,
            description: ``
        },
        2: {
            img: img2,
            name: `Атанасова Снежана Георгиевна`,
            description: `Президент Национальной ассоциации медицины кошек, ветеринарный врач, анестезиолог-реаниматолог, научный руководитель ветеринарной клиники «Клиника Кошек», г. Москва.`
        },
        3: {
            img: img3,
            name: `Ткачев-Кузьмин Александр Викторович`,
            description: `Вице-президент Национальной ассоциации медицины кошек. Вице-президент Ассоциации практикующих ветеринарных врачей. Ветеринарный врач. Кандидат ветеринарных наук. Директор ветеринарной клиники «Мовет», Москва`
        },
        4: {
            img: img4,
            name: `Вольф Эдвард Геннадьевич`,
            description: `Соучредитель Национальной ассоциации медицины кошек. Ветеринарный врач ветеринарной клиники «Универ», Москва`
        },
        5: {
            img: img5,
            name: `Рубель Игорь Сергеевич`,
            description: `Соучредитель Национальной ассоциации медицины кошек. Ветеринарный врач ветеринарной клиники «Оберег», Москва`
        },
        6: {
            img: img6,
            name: `Сятковская Ольга Серафимовна`,
            description: [`Почетный член Национальной ассоциации медицины кошек. Основатель и президент Эстонского общества врачей-фелинологов. Ветеринарный врач ветеринарного центра Хааберсти, Таллин, Эстония`, <br/>, <br/>, <b> Сертифицированный специалист ISFM (Международное общество медицины кошек) по поведению кошек.
            </b>]
        }
    }

    render() {
        const {isStartPage} = this.state
        return (
            <div className={'OrganizersInformation ' + (isStartPage && 'OrganizersInformation--startPage')}>
                {!isStartPage &&
                <PageHeader
                    svg={
                        <svg width="21" height="40" viewBox="0 0 21 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.67688 22.2651L6.67574 22.2697L4.40621 31.4009C3.72419 34.0313 3.65255 35.9173 4.23616 37.1913C4.54709 37.87 5.0315 38.347 5.62728 38.6378C6.19958 38.9171 6.8226 39 7.40072 39C8.60465 39 10.5151 38.4721 12.6516 37.4228C14.8213 36.3572 17.3197 34.7079 19.7021 32.3592C20.0602 32.0061 20.1004 31.4419 19.7959 31.0416L18.6912 29.5895C18.5304 29.3782 18.2923 29.2395 18.0292 29.204C17.7661 29.1684 17.4996 29.239 17.2886 29.4C16.3987 30.0793 15.5113 30.5862 14.7851 30.9199C14.5214 31.041 14.284 31.137 14.08 31.2094C14.1105 30.9879 14.1661 30.6979 14.2601 30.3261L16.8345 20.7179C17.3364 18.9227 17.5569 17.2875 17.3115 16.0405C17.1857 15.4014 16.922 14.7863 16.4316 14.3285C15.9255 13.8559 15.279 13.6454 14.5812 13.6454C13.1837 13.6454 11.0677 14.3026 8.78375 15.3825C6.45795 16.4822 3.82539 18.0835 1.3652 20.1046C0.977101 20.4235 0.885734 20.9807 1.1517 21.4068L2.13365 22.9799C2.27453 23.2056 2.49954 23.3659 2.75889 23.4252C3.01824 23.4846 3.29055 23.4381 3.51556 23.2962C4.22211 22.8504 5.182 22.3753 6.04755 22.0106C6.32139 21.8953 6.57967 21.7935 6.81148 21.709C6.77515 21.8689 6.73062 22.0532 6.67688 22.2651ZM14.0598 31.7641C14.0597 31.7642 14.0591 31.7622 14.0582 31.7579C14.0594 31.7619 14.0599 31.7641 14.0598 31.7641ZM6.94448 20.8709C6.9447 20.8706 6.94531 20.8751 6.94564 20.885C6.94442 20.876 6.94425 20.8711 6.94448 20.8709ZM19.509 5.50924C19.509 4.43832 19.2658 3.2802 18.464 2.38436C17.6433 1.4674 16.3963 1 14.7653 1C10.4251 1 8.36361 4.03239 8.42563 6.47916C8.39568 7.78425 8.75942 8.97548 9.66954 9.8305C10.5716 10.678 11.8543 11.0471 13.3538 11.0471C17.1114 11.0471 19.509 8.46739 19.509 5.50924Z" stroke="#4147DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                    text={`Информация об&nbsp;организаторах<br>Национальной ассоциации медицины кошек`}
                />
                }
                <div className="OrganizersInformation_content">
                    {isStartPage &&
                    <PageHeader
                        svg={
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="72" height="72" rx="36" fill="#4147DA"/>
                                <path d="M40.2653 18C43.1498 18 44.009 19.6336 44.009 21.5092C44.009 23.8689 42.1065 26.0471 38.8538 26.0471C36.1534 26.0471 34.8646 24.716 34.926 22.4773C34.8646 20.6017 36.4603 18 40.2653 18ZM32.9007 54C30.9368 54 29.5253 52.8504 30.8755 47.6471L33.1462 38.5109C33.5144 37.0588 33.5758 36.4538 33.1462 36.4538C32.5325 36.4538 30.0162 37.4824 28.4819 38.4504L27.5 36.8773C32.287 32.9445 37.7491 30.6454 40.0812 30.6454C42.0451 30.6454 42.352 32.9445 41.37 36.4538L38.7924 46.074C38.3628 47.7681 38.5469 48.3731 38.9765 48.3731C39.5903 48.3731 41.4928 47.6471 43.3953 46.195L44.5 47.6471C39.8971 52.1849 34.8646 54 32.9007 54Z" fill="white"/>
                            </svg>
                        }
                        text={`Информация об&nbsp;организаторах<br>Национальной ассоциации медицины кошек`}
                    />
                    }
                    <TextBlock
                        text={`Национальная ассоциация медицины кошек создана командой практикующих ветеринарных врачей, стремящихся объединить профессиональное ветеринарное сообщество <b>идеей культуры Cat Friendly – С любовью к кошкам</b>, основанной на понимании особенностей поведения и потребностей кошек. 

                        Национальная ассоциация медицины кошек создана для того, чтобы обеспечить качественное, дружелюбное и профессиональное обслуживание кошек в ветеринарных клиниках и повысить эффективность и результативность лечения и ухода за ними.`}
                    />
                    <div className="OrganizersInformation_flex">
                        {Object.keys(this.organizersData).map(item => {
                            return (
                                <div
                                    key={item}
                                    className="OrganizersInformation_flex_el"
                                >
                                    <Organizer
                                        img={this.organizersData[item].img}
                                        name={this.organizersData[item].name}
                                        description={this.organizersData[item].description}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    {!isStartPage &&
                    <div className="OrganizersInformation_buttons">
                        <DocumentLink
                            link={'/charter.pdf'}
                            text={`Устав Национальной ассоциации медицины кошек`}
                            svg={
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="20" height="20" fill="#EFEFEF"/>
                                    <path d="M7.84581 10.5312H6.81776C6.68866 10.5312 6.56484 10.5808 6.47367 10.6691C6.38249 10.7573 6.33151 10.8769 6.33193 11.0015L6.33909 14.5623C6.33909 14.8212 6.55661 15.031 6.82492 15.031C7.09323 15.031 7.31075 14.8212 7.31075 14.5623V13.4811C7.50939 13.4802 7.7216 13.4793 7.84581 13.4793C8.69945 13.4793 9.39396 12.8181 9.39396 12.0053C9.39396 11.1925 8.69945 10.5312 7.84581 10.5312ZM7.84581 12.5418C7.7205 12.5418 7.50751 12.5427 7.3081 12.5437C7.30706 12.3441 7.30615 12.1292 7.30615 12.0053C7.30615 11.8993 7.3056 11.6773 7.30502 11.4688H7.84578C8.15827 11.4688 8.42227 11.7144 8.42227 12.0053C8.42227 12.2961 8.1583 12.5418 7.84581 12.5418Z" fill="#4147DA"/>
                                    <path d="M11.9783 10.5312H10.9635C10.8345 10.5312 10.7108 10.5807 10.6197 10.6688C10.5285 10.7569 10.4775 10.8764 10.4777 11.0009C10.4777 11.0009 10.4849 14.4461 10.485 14.4586C10.4855 14.5829 10.5371 14.702 10.6285 14.7896C10.7196 14.8767 10.8426 14.9256 10.9708 14.9256H10.9726C11.0033 14.9255 11.7271 14.9228 12.0165 14.918C13.1142 14.8995 13.911 13.9786 13.911 12.7284C13.911 11.4142 13.1343 10.5312 11.9783 10.5312ZM11.9995 13.9806C11.8737 13.9828 11.6572 13.9845 11.455 13.9857C11.4536 13.5786 11.451 11.8919 11.4502 11.4688H11.9783C12.87 11.4688 12.9393 12.4329 12.9393 12.7285C12.9393 13.3438 12.6487 13.9697 11.9995 13.9806Z" fill="#4147DA"/>
                                    <path d="M17.113 11.4323C17.3813 11.4323 17.5988 11.2224 17.5988 10.9636C17.5988 10.7047 17.3813 10.4948 17.113 10.4948H15.6275C15.3592 10.4948 15.1417 10.7047 15.1417 10.9636V14.4997C15.1417 14.7586 15.3592 14.9685 15.6275 14.9685C15.8958 14.9685 16.1134 14.7586 16.1134 14.4997V13.1669H16.995C17.2633 13.1669 17.4808 12.9571 17.4808 12.6982C17.4808 12.4393 17.2633 12.2294 16.995 12.2294H16.1134V11.4323H17.113Z" fill="#4147DA"/>
                                    <path d="M18.5425 8.53125H18.1862V8.39753C18.1862 7.79497 17.9496 7.22231 17.5199 6.785L15.502 4.73122C15.0453 4.26653 14.4028 4 13.7391 4H7.27126C6.4676 4 5.81377 4.63084 5.81377 5.40625V8.53125H5.45749C4.65383 8.53125 4 9.16209 4 9.9375V15.5625C4 16.3379 4.65383 16.9688 5.45749 16.9688H5.81377V18.5938C5.81377 19.3692 6.4676 20 7.27126 20H16.7287C17.5324 20 18.1862 19.3692 18.1862 18.5938V16.9688H18.5425C19.3462 16.9688 20 16.3379 20 15.5625V9.9375C20 9.16209 19.3462 8.53125 18.5425 8.53125ZM6.78542 5.40625C6.78542 5.14778 7.00337 4.9375 7.27126 4.9375H13.7391C14.1373 4.9375 14.5228 5.09741 14.7968 5.37625L16.8148 7.43003C17.0726 7.69241 17.2146 8.036 17.2146 8.39753V8.53125H6.78542V5.40625ZM17.2146 18.5938C17.2146 18.8522 16.9966 19.0625 16.7287 19.0625H7.27126C7.00337 19.0625 6.78542 18.8522 6.78542 18.5938V16.9688H17.2146V18.5938ZM19.0283 15.5625C19.0283 15.821 18.8104 16.0312 18.5425 16.0312H5.45749C5.1896 16.0312 4.97166 15.821 4.97166 15.5625V9.9375C4.97166 9.67903 5.1896 9.46875 5.45749 9.46875H18.5425C18.8104 9.46875 19.0283 9.67903 19.0283 9.9375V15.5625Z" fill="#4147DA"/>
                                </svg>
                            }
                        />
                        {/*<Buttons*/}
                        {/*    class={'btn_violet'}*/}
                        {/*    to={'/memberOrganization'}*/}
                        {/*    name={`Как стать Членом НАМК?`}*/}
                        {/*/>*/}
                        {/*<Buttons*/}
                        {/*    class={'btn_violet'}*/}
                        {/*    to={'/accreditation'}*/}
                        {/*    name={`Аккредитация`}*/}
                        {/*/>*/}
                        {/*<div></div>*/}
                        <DocumentLink
                            link={'/ogrn.pdf'}
                            text={`Учредительные документы`}
                            svg={
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="20" height="20" fill="#EFEFEF"/>
                                    <path d="M7.84581 10.5312H6.81776C6.68866 10.5312 6.56484 10.5808 6.47367 10.6691C6.38249 10.7573 6.33151 10.8769 6.33193 11.0015L6.33909 14.5623C6.33909 14.8212 6.55661 15.031 6.82492 15.031C7.09323 15.031 7.31075 14.8212 7.31075 14.5623V13.4811C7.50939 13.4802 7.7216 13.4793 7.84581 13.4793C8.69945 13.4793 9.39396 12.8181 9.39396 12.0053C9.39396 11.1925 8.69945 10.5312 7.84581 10.5312ZM7.84581 12.5418C7.7205 12.5418 7.50751 12.5427 7.3081 12.5437C7.30706 12.3441 7.30615 12.1292 7.30615 12.0053C7.30615 11.8993 7.3056 11.6773 7.30502 11.4688H7.84578C8.15827 11.4688 8.42227 11.7144 8.42227 12.0053C8.42227 12.2961 8.1583 12.5418 7.84581 12.5418Z" fill="#4147DA"/>
                                    <path d="M11.9783 10.5312H10.9635C10.8345 10.5312 10.7108 10.5807 10.6197 10.6688C10.5285 10.7569 10.4775 10.8764 10.4777 11.0009C10.4777 11.0009 10.4849 14.4461 10.485 14.4586C10.4855 14.5829 10.5371 14.702 10.6285 14.7896C10.7196 14.8767 10.8426 14.9256 10.9708 14.9256H10.9726C11.0033 14.9255 11.7271 14.9228 12.0165 14.918C13.1142 14.8995 13.911 13.9786 13.911 12.7284C13.911 11.4142 13.1343 10.5312 11.9783 10.5312ZM11.9995 13.9806C11.8737 13.9828 11.6572 13.9845 11.455 13.9857C11.4536 13.5786 11.451 11.8919 11.4502 11.4688H11.9783C12.87 11.4688 12.9393 12.4329 12.9393 12.7285C12.9393 13.3438 12.6487 13.9697 11.9995 13.9806Z" fill="#4147DA"/>
                                    <path d="M17.113 11.4323C17.3813 11.4323 17.5988 11.2224 17.5988 10.9636C17.5988 10.7047 17.3813 10.4948 17.113 10.4948H15.6275C15.3592 10.4948 15.1417 10.7047 15.1417 10.9636V14.4997C15.1417 14.7586 15.3592 14.9685 15.6275 14.9685C15.8958 14.9685 16.1134 14.7586 16.1134 14.4997V13.1669H16.995C17.2633 13.1669 17.4808 12.9571 17.4808 12.6982C17.4808 12.4393 17.2633 12.2294 16.995 12.2294H16.1134V11.4323H17.113Z" fill="#4147DA"/>
                                    <path d="M18.5425 8.53125H18.1862V8.39753C18.1862 7.79497 17.9496 7.22231 17.5199 6.785L15.502 4.73122C15.0453 4.26653 14.4028 4 13.7391 4H7.27126C6.4676 4 5.81377 4.63084 5.81377 5.40625V8.53125H5.45749C4.65383 8.53125 4 9.16209 4 9.9375V15.5625C4 16.3379 4.65383 16.9688 5.45749 16.9688H5.81377V18.5938C5.81377 19.3692 6.4676 20 7.27126 20H16.7287C17.5324 20 18.1862 19.3692 18.1862 18.5938V16.9688H18.5425C19.3462 16.9688 20 16.3379 20 15.5625V9.9375C20 9.16209 19.3462 8.53125 18.5425 8.53125ZM6.78542 5.40625C6.78542 5.14778 7.00337 4.9375 7.27126 4.9375H13.7391C14.1373 4.9375 14.5228 5.09741 14.7968 5.37625L16.8148 7.43003C17.0726 7.69241 17.2146 8.036 17.2146 8.39753V8.53125H6.78542V5.40625ZM17.2146 18.5938C17.2146 18.8522 16.9966 19.0625 16.7287 19.0625H7.27126C7.00337 19.0625 6.78542 18.8522 6.78542 18.5938V16.9688H17.2146V18.5938ZM19.0283 15.5625C19.0283 15.821 18.8104 16.0312 18.5425 16.0312H5.45749C5.1896 16.0312 4.97166 15.821 4.97166 15.5625V9.9375C4.97166 9.67903 5.1896 9.46875 5.45749 9.46875H18.5425C18.8104 9.46875 19.0283 9.67903 19.0283 9.9375V15.5625Z" fill="#4147DA"/>
                                </svg>
                            }
                        />
                    </div>
                    }
                    {/*<div className="OrganizersInformation_bottom_text">*/}
                    {/*    *Cat Advocate - сертифицированный ISFM специалист по работе с кошками <br/> <br/>*/}
                    {/*    **ISFM - Международное общество медицины кошек*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

