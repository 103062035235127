import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/mitrohina.png'
import img2 from '../../../image/Lecturers/mitrohina-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Митрохина'}
                    name={'Наталья Викторовна'}
                    text={'Ветеринарный врач-патоморфолог, кандидат ветеринарных наук, основатель "Ветеринарного центра патоморфологии и лабораторной диагностики доктора Митрохиной", Москва'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/ODhbGQMJusI'}
                    title={'https://www.youtube.com/watch?v=ODhbGQMJusI'}
                />
            </div>
        )
    }
}
