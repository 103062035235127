import React from 'react'
import VideoBlock from '../../../Components/LessonAndTestComponents/VideoBlock'

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <div className="box">
                    <VideoBlock
                        src={'https://www.youtube.com/embed/0bV9ITPM8Ic'}
                        title={'https://www.youtube.com/watch?v=0bV9ITPM8Ic&feature=emb_logo'}
                    />
                </div>
            </div>
        )
    }
}