import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step38 extends React.Component{

    render() {
        return (
            <div className="step38">
                <div className="head">
                    Обращение с кошкой в ветеринарной клинике
                </div>
                <TextBlock
                    text={`Если вы знаете, что кошка будет чувствовать себя испуганной или встревоженной, проведите осмотр кошки незамедлительно по прибытии в клинику.
                    
                    Синтетический аналог лицевого феромона может успокоить кошек, но чувство стресса до конца не исчезнет.
                    
                    Если кошка испытывает боль - назначьте анальгетики, используйте минимальное ограничение двигательных функций и разделите обследование на несколько отдельных этапов.`}
                />
            </div>
        )
    }
}
