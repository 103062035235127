import React from 'react'
import {withRouter} from 'react-router-dom';
import Api from '../../../Service/Api'

import LessonPlan from "../../../Components/LessonAndTestComponents/LessonPlan";
import MainButton from "../../../Components/LessonAndTestComponents/MainButton";
import MainHeader from "../../../Components/LessonAndTestComponents/MainHeader";
import ModalAfterStep from "../../../Components/LessonAndTestComponents/ModalAfterStep";

import './style.sass'
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import Step7 from './step7';
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import Step11 from "./step11";
import Step12 from "./step12";
import Step13 from "./step13";
import Step14 from "./step14";
import Step15 from "./step15";
import Step16 from "./step16";
import Step17 from "./step17";
import Step18 from "./step18";
import Step19 from "./step19";

class Lesson32 extends React.Component {
    Api = new Api();

    state = {
        lessonStepsActive: 1, // Пункт меню справа
        stepActive: 1, // Текуший шаг урока
        MainHeader_header: 'Урок 15', // Первая линия заголовка шага
        MainHeader_subheader: 'Производство готовых кормов', // Вторая линия заголовка шага
        MainHeader_class: 'bigHeader', // Большой или маленький Заголовок
        MainHeader_text: '', //Текст под заголовком
        typeMainBtn: 'take a lesson', // тип кнопок (take a lesson - Пройти урок, '' - стандартно медлу щагами)
        showNextBtn: true, // Показывать кнопку продолжить?
        showPrevBtn: true, // Показывать кнопку вернуться?
        showPopap: false, // Показ попапа на шаге
        modalImg: '', // Картинка на верху модалки
        modalImg2: '', // Картинка в теле модалки
        modalHead: '', // шапка в модалке
        modalText: '', // текст в модалке
        arrayStepsPlan: [], //массив шагов плана урока (lessonStepsActive)
    }

    componentDidMount() {
        const {arrayStepsPlan} = this.state
        const {lesson} = this.props;
        let stepActive = lesson.steps
        stepActive = stepActive === lesson.attributes.steps ? 1 : stepActive + 1
        this.stepActiveStateUpdate(stepActive)
        // this.stepActiveStateUpdate(16)
        if (lesson.steps === lesson.attributes.steps) {
            // eslint-disable-next-line
            Object.values(this.settingStepsLesson).map((item) => {
                arrayStepsPlan.push(item.state.lessonStepsActive)
                this.setState({arrayStepsPlan: arrayStepsPlan})
            })
        }
    }

    // Переключалка шагов
    _renderStep = () => {
        const {stepActive} = this.state;
        const {lesson} = this.props;

        if (this.settingStepsLesson[stepActive] !== undefined) {
            return this.settingStepsLesson[stepActive].view;
        } else {
            lesson.plan = lesson.lessonPlan;
            lesson.MainHeader_header = 'Урок 15';
            lesson.MainHeader_subheader = 'Производство готовых кормов';
            this.props.history.push('/test', lesson);

        }
    }

    // Действие по кнопке продолжить
    showNextStep = () => {
        const {lesson} = this.props;
        let {stepActive} = this.state;
        this.Api.setLessonStats(lesson.attributes.id, stepActive)
        stepActive++;
        this.setStateBtnSteps(stepActive)
    }
    // Действие по кнопке вернутся
    showPrevStep = () => {
        let {stepActive} = this.state;
        stepActive--;
        this.setStateBtnSteps(stepActive)
    }
    // установка стайтов для смены шага
    setStateBtnSteps = stepActive => {
        this.setState({
            stepActive,
            showPopap: false
        });
        this.stepActiveStateUpdate(stepActive)
    }
    // показать попап на шаге.
    showPopap = () => {
        this.setState({
            showPopap: true
        });
    }

    closePopap = () => {
        this.setState({
            showPopap: false,
        })
    }

    // Состояния заголовков, кнопок, оглавления на текшем шаге
    stepActiveStateUpdate = stepActive => {
        this.setState({stepActive}, () => {
            if (this.settingStepsLesson[stepActive] !== undefined) {
                return this.setState({
                    MainHeader_header: this.settingStepsLesson[stepActive].state['MainHeader_header'],
                    MainHeader_subheader: this.settingStepsLesson[stepActive].state['MainHeader_subheader'],
                    MainHeader_class: this.settingStepsLesson[stepActive].state['MainHeader_class'],
                    MainHeader_text: this.settingStepsLesson[stepActive].state['MainHeader_text'],
                    typeMainBtn: this.settingStepsLesson[stepActive].state['typeMainBtn'],
                    showNextBtn: this.settingStepsLesson[stepActive].state['showNextBtn'],
                    lessonStepsActive: this.settingStepsLesson[stepActive].state['lessonStepsActive'],
                    modalImg: this.settingStepsLesson[stepActive].state['modalImg'],
                    modalImg2: this.settingStepsLesson[stepActive].state['modalImg2'],
                    modalHead: this.settingStepsLesson[stepActive].state['modalHead'],
                    modalText: this.settingStepsLesson[stepActive].state['modalText'],
                });
            }
        })
    }

    // Вызвать чтобы показать кнопку продолжить
    showNextBtn = () => {
        this.setState({
            showNextBtn: true
        })
    };

    render() {
        const {
            lessonStepsActive,
            typeMainBtn,
            showNextBtn,
            MainHeader_header,
            MainHeader_subheader,
            MainHeader_class,
            MainHeader_text,
            modalImg,
            modalImg2,
            modalHead,
            modalText,
            showPopap
        } = this.state;
        return (

            <div className="lesson">
                <div className="content_lesson">
                    <div className="step lesson32">
                        {/* Основной заголовок шага */}
                        <MainHeader
                            header={MainHeader_header}
                            subheader={MainHeader_subheader}
                            headerClass={MainHeader_class}
                            text={MainHeader_text}
                        />
                        {/* Вывод Шага урока */}
                        {this._renderStep()}
                    </div>
                    {/* Вывод Кнопок урока */}
                    <MainButton
                        type={typeMainBtn}
                        showPopap={this.showPopap}
                        showNextStep={this.showNextStep}
                        showPrevStep={this.showPrevStep}
                        showNextBtn={showNextBtn}
                        customBtnText={'Продолжить'}
                    />
                </div>
                {/* Модальное окно после шага */}
                {showPopap &&
                <ModalAfterStep
                    closePopap={this.closePopap}
                    modalImg={modalImg}
                    modalImg2={modalImg2}
                    modalHead={modalHead}
                    modalText={modalText}
                    type={'whiskas'}
                    showNextStep={this.showNextStep}
                />
                }
                {/* Вывод Оглавление урока */}
                <LessonPlan
                    lessonImg={this.props.lesson.attributes.image} // Картинка урока
                    lessonNumeral={parseInt(this.props.lesson.attributes.name.match(/\d+/), 10)} //Номер урока для планшета
                    lessonName={'Производство готовых кормов'} // Название урока
                    lessonSteps={this.props.lesson.lessonPlan} // Шаги урока
                    lessonStepsActive={lessonStepsActive - 1} // Активный шаг урока
                    onClick={this.setStateBtnSteps}
                    arrayStepsPlan={this.state.arrayStepsPlan}
                />
            </div>

        )
    }

    settingStepsLesson = {
        1: {
            view: <Step1 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Урок 15',
                MainHeader_subheader: 'Производство готовых кормов',
                MainHeader_class: 'bigHeader',
                typeMainBtn: 'custom',
                showNextBtn: true,
                lessonStepsActive: 1
            }
        },
        2: {
            view: <Step2 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Этапы создания готовых кормов для животных',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 2
            }
        },
        3: {
            view: <Step3 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Научная разработка готовых кормов',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 3
            }
        },
        4: {
            view: <Step4/>,
            state: {
                MainHeader_header: 'Научная разработка готовых кормов',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 3
            }
        },
        5: {
            view: <Step5 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Научная разработка готовых кормов',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 3,
            }
        },
        6: {
            view: <Step6 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Научная разработка готовых кормов',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 3,
            }
        },
        7: {
            view: <Step7 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Отбор и анализ сырья',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        8: {
            view: <Step8 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Категории сырьевых источников',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        9: {
            view: <Step9 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Основные сырьевые источники сухого корма',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        10: {
            view: <Step10 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Основные сырьевые источники влажного корма',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        11: {
            view: <Step11 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Миф',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        12: {
            view: <Step12 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Входной контроль сырья',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 4,
            }
        },
        13: {
            view: <Step13 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Схема производства сухого продукта',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 5,
            }
        },
        14: {
            view: <Step14 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Схема производства влажного продукта',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 5,
            }
        },
        15: {
            view: <Step15 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Схема производства сухого продукта',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 5,
            }
        },
        16: {
            view: <Step16 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Схема производства влажного продукта',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 5,
            }
        },
        17: {
            view: <Step17 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Контроль качества',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 5,
            }
        },
        18: {
            view: <Step18 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Контроль качества',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: false,
                lessonStepsActive: 6,
            }
        },
        19: {
            view: <Step19 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Итоги урока',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                showNextBtn: true,
                lessonStepsActive: 7,
            }
        },

    }

}

export default withRouter(Lesson32);
