import React from 'react'
// import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step44 extends React.Component{

    render() {
        return (
            <div className="step44">
                <div className="findings">
                    <div className="findings_el">
                        <div className="findings_number">1</div>
                        <div className="findings_text">
                            Предоставление эффективной ветеринарной помощи кошкам значительно улучшит их здоровье и благосостояние.
                        </div>
                    </div>
                    <div className="findings_el">
                        <div className="findings_number">2</div>
                        <div className="findings_text">
                            Владельцы кошек должны чувствовать себя уверенно, приводя своих кошек в ветеринарную клинику.
                        </div>
                    </div>
                    <div className="findings_el">
                        <div className="findings_number">3</div>
                        <div className="findings_text">
                            Повышенная осведомленность ветеринарной бригады о поведении кошек может способствовать улучшению техники и подходов к обращению с ними и увеличить частоту посещений ветеринарных клиник.
                        </div>
                    </div>
                    <div className="findings_el">
                        <div className="findings_number">4</div>
                        <div className="findings_text">
                            Относительно небольшие и простые шаги на каждом этапе ветеринарного визита могут сделать его значительно более счастливым и менее напряженным не только для кошки и ее владельца, но и для членов ветеринарной бригады.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
