import React from 'react'
import Resume from '../../../Components/LessonAndTestComponents/Resume'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson11/step30-1.png'


export default class step30 extends React.Component{

    state = {
        resumeData: [
            `Количество энергии, потребляемой с лакомствами, не должно превышать <b>15% от суточной потребности</b> животного в энергии.`,
            `Следует использовать только лакомства, <b>специально предназначенные</b> для животных.`,
            `В качестве <b>поощрения</b> нужно использовать не только корм, но также игрушки и подвижные игры.`,
        ]
    }

    render() {
        return (
            <div className="step9">
                <Resume
                    title={'Выводы'}
                    text={`Чтобы не нарушить сбалансированный рацион питомца, нужно следить за правильным использованием угощений и соблюдать следующие рекомендации:`}
                    resumeData={this.state.resumeData}
                    img={img1}
                />
            </div>
        )
    }
}
