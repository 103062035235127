import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson32/step7-1.png'
import img1xs from '../../../image/lessons/lesson32/step7-1xs.png'


export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <TextBlock
                    text={`Сырью, из которого производят корма для животных, уделяется особое внимание. 
                    Отбор и анализ сырья состоит из нескольких этапов.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img className="img" src={img1} alt=""/>
                </picture>
            </div>
        )
    }
}
