import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

// import img1 from '../../../image/lessons/lesson35/step8-1.png'

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <div className="head">
                    Иcточники питательных веществ
                </div>
                <TextBlock
                    text={`Содержание питательных веществ в корме обеспечивается разными сырьевыми источниками.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Белки
                        </div>
                        <div className="flex_el_wrp">
                            <h3 className="header">
                                Животные белки:
                            </h3>
                            <ul className="list">
                                <li className="list_item">мясная, куриная или рыбная мука (дегидратированное, то есть высушенное мясо);</li>
                                <li className="list_item">субпродукты.</li>
                            </ul>
                            <h3 className="header">Растительные белки:</h3>
                            <ul className="list">
                                <li className="list_item">изолят растительного белка</li>
                                <li className="list_item">пшеничный, кукурузный, рисовый глютен (глютен — это белковая часть злаков).</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Жиры
                        </div>
                        <div className="flex_el_wrp">
                            <h3 className="header">
                                Растительные жиры:
                            </h3>
                            <ul className="list">
                                <li className="list_item">подсолнечное масло;</li>
                                <li className="list_item">оливковое масло;</li>
                                <li className="list_item">льняное масло.</li>
                            </ul>
                            <h3 className="header">Животные жиры:</h3>
                            <ul className="list">
                                <li className="list_item">животный жир;</li>
                                <li className="list_item">рыбий жир.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Углеводы
                        </div>
                        <div className="flex_el_wrp">
                            <ul className="list">
                                <li className="list_item">злаки (рис, кукуруза, пшеница, овсяная крупа, молотый ячмень);</li>
                                <li className="list_item">клетчатка (пульпа сахарной свеклы, целлюлоза);</li>
                                <li className="list_item">кукурузный крахмал;</li>
                                <li className="list_item">овощи;</li>
                                <li className="list_item">картофель.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
