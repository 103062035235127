import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import OnClickTest from '../../../Components/LessonAndTestComponents/OnClickTest';

// import img1 from '../../../image/lessons/lesson6/step3-1.png'

export default class step18 extends React.Component {

    state = {
        answersData: [
            `Хранить при температуре от -4 °C до -35 °C и относительной влажности воздуха не более 75%`,
            `Хранить при температуре от +4 °C до +35 °C и относительной влажности воздуха не более 75%`,
            `Хранить при температуре от +10 °C до +25 °C и относительной влажности воздуха не более 55%`,
        ],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step18">
                <TextBlock
                    text={`Ранее мы выяснили, что в процессе производства кормов большое внимание уделяют качеству изготовляемого продукта.
                    
                    Однако чтобы сохранить его, необходимо соблюдать <b>инструкцию по хранению кормов</b>, которая расположена <b>на задней части упаковки</b>.
                    
                    Как вы думаете, каковы <b>рекомендации по хранению кормов?</b>`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[2]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Хранить корм лучше всего при температуре от +4 °C до +35 °C и относительной влажности воздуха не более 75%.`}
                />
            </div>
        )
    }
}
