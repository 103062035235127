import React from 'react'
import './style.sass'
import ArrowBack from '../ArrowBack';

export default class OnClickBlock extends React.Component {

    //Пример использования lesson11, step 18

    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
        flexData: this.props.flexData,
        trianglePosition: this.props.trianglePosition ? this.props.trianglePosition : [],
    };

    componentDidMount() {
        document.querySelector('.OnClickBlock').style.setProperty('--quantity', (100 / this.props.flexData.length) + '%')
    }

    changeFlex = flexActive => {
        const {flexs, flexData} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === flexData.length) {
            this.props.showNextBtn()
        }
        this.props.hideElement(this.state.flexHidden)
    }

    closeImgDiv = () => {
        this.setState({flexHidden: false, flexActive: 0})
        this.props.hideElement()
    }

    render() {
        const {flexActive, flexHidden, flexData, trianglePosition} = this.state
        return (
            <div className="OnClickBlock">
                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    {flexData.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={'flex_el'}
                            >
                                <div
                                    className={'flex_el_wrp ' + (flexActive === (index + 1) && 'active')}
                                    onClick={() => {this.changeFlex(index + 1)}}
                                >
                                    {item}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (trianglePosition.length ? trianglePosition[flexActive - 1] : '')}>
                    <ArrowBack
                        returnMenu={this.closeImgDiv}
                    />
                    {React.Children.map(this.props.children, (child, i) => {
                        if (i === flexActive - 1) return child
                    })}
                </div>
                }
            </div>
        )
    }
}
