import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from "../../../image/lessons/lesson33/step11-1.png";
import img2 from "../../../image/lessons/lesson33/step11-2.png";
import img3 from "../../../image/lessons/lesson33/step11-3.png";
import img4 from "../../../image/lessons/lesson33/step11-4.png";
import img5 from "../../../image/lessons/lesson33/step11-5.png";
import img6 from "../../../image/lessons/lesson33/step11-6.png";
import img7 from "../../../image/lessons/lesson33/step11-7.png";
import img8 from "../../../image/lessons/lesson33/step11-8.png";
import img9 from "../../../image/lessons/lesson33/step11-9.png";

export default class step11 extends React.Component {

    state = {
        popupActive: 0,
        showPopup: false,
        points: [],
    }

    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        1: {
            head: `Сухой корм WHISKAS® для котят «Подушечки с молочной начинкой, с индейкой и морковью».`,
            list: [
                `Оптимальное сочетание питательных веществ и энергии для поддержания обмена веществ;`,
                `Витамин Е и цинк для иммунитета;`,
                `Омега-6 и цинк для здоровья кожи и шерсти;`,
                `Витамин А и таурин для хорошего зрения;`,
                `Баланс кальция и фосфора для здоровья костей;`,
                `Высокоусваиваемые ингредиенты и клетчатка для пищеварения.`
            ],
            imgRight: img5
        },
        2: {
            head: `WHISKAS® для котят рагу с <span class="brown">курицей</span>, <span class="pink">ягненком</span>`,
            text: `Для здорового роста и правильного развития корм WHISKAS® для котят содержит оптимальное <b>сочетание питательных веществ, витаминов и минералов</b>.

            Для поддержания крепкого иммунитета корм WHISKAS® для котят содержит <b>витамин Е.</b>`,
            imgRight: img6,
        },
        3: {
            head: `WHISKAS® для котят желе с <span class="orange">телятиной</span>`,
            text: `Для здорового роста и правильного развития корм WHISKAS® для котят содержит оптимальное <b>сочетание питательных веществ, витаминов и минералов</b>.

            Для поддержания крепкого иммунитета корм WHISKAS® для котят содержит <b>витамин Е.</b>`,
            imgRight: img8,
        },
        4: {
            head: `WHISKAS® для котят паштет с <span class="brown">курицей</span>`,
            text: `Для здорового роста и правильного развития корм WHISKAS® для котят содержит оптимальное <b>сочетание питательных веществ, витаминов и минералов</b>.

            Для поддержания крепкого иммунитета корм WHISKAS® для котят содержит <b>витамин Е.</b>`,
            imgRight: img9,
        }
    }

    render() {
        const {showPopup, popupActive} = this.state
        return (
            <div className="step11">
                <div className="head">
                    Ключевые потребности котят в питании
                </div>
                <TextBlock
                    text={`Теперь Вам предстоит знакомство с текстурами и продуктами WHISKAS® для котят, а также с индивидуальными особенностями каждого продукта.`}
                />
                <InfoBlock
                    text={`Нажмите на иконки с кормом и узнайте о них больше.`}
                />
                <div className="flex">
                    <div className="flex_el" onClick={() => {this.showPopup(1)}}>
                        <img src={img1} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Сухой корм
                        </p>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(2)}}>
                        <img src={img2} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Рагу
                        </p>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(3)}}>
                        <img src={img3} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Желе
                        </p>
                    </div>
                    <div className="flex_el" onClick={() => {this.showPopup(4)}}>
                        <img src={img4} alt="" className="flex_el_img"/>
                        <p className="flex_el_name">
                            Паштет
                        </p>
                    </div>
                </div>
                {showPopup &&
                <PopupInStep
                    closePopup={this.closePopup}
                    head={'Рационы whiskas® для котят'}
                >
                    <div className="box">
                        {popupActive === 1
                            ?
                            <div className="box_el">
                                <div className="box_el_head"
                                     dangerouslySetInnerHTML={{ __html: this.popupData[popupActive].head }}
                                />
                                <div className="box_el_flex">
                                    <div className="box_el_flex_wrap">
                                        <div className="box_el_flex_head">
                                            Содержит:
                                        </div>
                                        <List
                                            ListData={this.popupData[1].list}
                                        />
                                    </div>
                                    <img src={this.popupData[1].imgRight} alt="" className="box_el_flex_img"/>
                                </div>
                            </div>
                            :
                            <div className="box_el">
                                <div className="box_el_head"
                                     dangerouslySetInnerHTML={{ __html: this.popupData[popupActive].head }}
                                />
                                <div className="box_el_flex">
                                    <TextBlock
                                        text={this.popupData[popupActive].text}
                                    />
                                    <img src={this.popupData[popupActive].imgRight} alt="" className="box_el_imgRight"/>
                                </div>
                                <img src={img7} alt="" className="box_el_imgBottom imgCenter"/>
                            </div>
                        }
                    </div>
                </PopupInStep>
                }
            </div>
        )
    }
}
