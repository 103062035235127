import React from 'react'
import './style.sass'


export default class Checkbox extends React.Component{
    render() {
        return (
            <div
                className={"checkbox " + (this.props.active && ' active ') + (this.props.error && ' error ') + (this.props.className ? this.props.className : '')}
                dangerouslySetInnerHTML={{ __html: this.props.text }}
                onClick={() => {this.props.onClick(this.props.text)}}
            />
        )
    }
}
