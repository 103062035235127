import React, {Fragment} from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Answer from './dnd/Answer';
import Questions from './dnd/Questions';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from '../../../image/lessons/lesson34/step11-1.png';
import img1xs from '../../../image/lessons/lesson34/step11-1xs.png';
import img1xl from '../../../image/lessons/lesson34/step10-1xs.png';
import img2 from "../../../image/lessons/lesson34/step11-2.png";
import img2xs from "../../../image/lessons/lesson34/step11-2xs.png";
import img2xl from "../../../image/lessons/lesson34/step10-2xs.png";
import img3 from "../../../image/lessons/lesson34/step11-3.png";
import img3xs from "../../../image/lessons/lesson34/step11-3xs.png";
import img3xl from "../../../image/lessons/lesson34/step10-3xs.png";
import img4 from "../../../image/lessons/lesson34/step11-4.png";
import img4xs from "../../../image/lessons/lesson34/step11-4xs.png";
import img4xl from "../../../image/lessons/lesson34/step10-4xs.png";
import img5 from "../../../image/lessons/lesson34/step11-5.png";
import img5xs from "../../../image/lessons/lesson34/step11-5xs.png";
import img5xl from "../../../image/lessons/lesson34/step10-5xs.png";
import img6 from "../../../image/lessons/lesson34/step11-6.png";
import img6xs from "../../../image/lessons/lesson34/step11-6xs.png";
import img6xl from "../../../image/lessons/lesson34/step10-6xs.png";
import img7 from "../../../image/lessons/lesson34/step11-7.png";
import img7xs from "../../../image/lessons/lesson34/step11-7xs.png";
import img7xl from "../../../image/lessons/lesson34/step10-7xs.png";
import img8 from "../../../image/lessons/lesson34/step11-8.png";
import img8xs from "../../../image/lessons/lesson34/step11-8xs.png";
import img8xl from "../../../image/lessons/lesson34/step10-8xs.png";
import img1_1 from "../../../image/lessons/lesson34/step11-1-1.png";
import img1_2 from "../../../image/lessons/lesson34/step11-1-2.png";
import img1_3 from "../../../image/lessons/lesson34/step11-1-3.png";
import img2_1 from "../../../image/lessons/lesson34/step11-2-1.png";
import img2_2 from "../../../image/lessons/lesson34/step11-2-2.png";
import img2_3 from "../../../image/lessons/lesson34/step11-2-3.png";
import img3_1 from "../../../image/lessons/lesson34/step11-3-1.png";
import img3_2 from "../../../image/lessons/lesson34/step11-3-2.png";
import img3_3 from "../../../image/lessons/lesson34/step11-3-3.png";
import img4_1 from "../../../image/lessons/lesson34/step11-4-1.png";
import img4_2 from "../../../image/lessons/lesson34/step11-4-2.png";
import img4_3 from "../../../image/lessons/lesson34/step11-4-3.png";
import img5_1 from "../../../image/lessons/lesson34/step11-5-1.png";
import img5_2 from "../../../image/lessons/lesson34/step11-5-2.png";
import img5_3 from "../../../image/lessons/lesson34/step11-5-3.png";
import img6_1 from "../../../image/lessons/lesson34/step11-6-1.png";
import img6_2 from "../../../image/lessons/lesson34/step11-6-2.png";
import img6_3 from "../../../image/lessons/lesson34/step11-6-3.png";
import img7_1 from "../../../image/lessons/lesson34/step11-7-1.png";
import img7_2 from "../../../image/lessons/lesson34/step11-7-2.png";
import img7_3 from "../../../image/lessons/lesson34/step11-7-3.png";
import img8_1 from "../../../image/lessons/lesson34/step11-8-1.png";
import img8_2 from "../../../image/lessons/lesson34/step11-8-2.png";
import img8_3 from "../../../image/lessons/lesson34/step11-8-3.png";


export default class step11 extends React.Component {
    // answer1;
    answer2;
    // answer3;
    answer4;
    // answer5;
    answer6;
    answer7;
    answer8;
    state = {
        popupActive: 0,
        points: [],

        // Ресайз
        windowWidth: window.innerWidth,

        // Работа десктопной версии
        answersObj: {1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: []},
        questionsObj: {1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: []},
        correct: '',
        answerCorrectObj: {1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: []},
        testActive: 0,

        //Работа мобильной версии
        answerActive: 0,
        questionActive: 0,
        answersMobile: [],
        questionsMobile: [],
        answersSelected: ['?', '?', '?', '?', '?', '?'],
        correctMobile: '',
        answerCorrectMobile: [],
        showPopup: false,
        testNumber: 0
    }

    showPopup = (popupActive) => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        this.setState({
            popupActive: popupActive,
            showPopup: true,
            points: points
        })

    }


    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    //Работа десктопной версии
    setAnswersObj = (answer_el, question_el) => {
        const {answersObj, testActive} = this.state
        let testItem = Object.values(answersObj)[testActive]

        if (testItem.indexOf(question_el) === -1) {
            let answer = []
            testItem[answer_el] = question_el
            testItem.push(answer)
        }
        this.setState({answersObj})

        let questionsObj = {1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: []};
        // eslint-disable-next-line
        testItem.map(answer => {

            if (answer.length) {
                Object.values(questionsObj)[testActive].push(answer)
            }

        });
        this.setState({questionsObj})
        if (Object.values(questionsObj)[testActive].length === 3) {
            this.checkAnswer()
        }
        console.log(111, questionsObj)
    }

    showTestActive = (testActive) => {
        const {answerCorrectObj} = this.state
        Object.values(answerCorrectObj)[testActive].map((item, index) => {
            return (
                item === ''
            )
        })
        this.setState({testActive: testActive, correct: ''})
    }

    checkAnswer = () => {
        const {answerCorrectObj, questionsObj, testActive} = this.state
        // eslint-disable-next-line
        Object.values(questionsObj).map((testItem, index) => {
            // eslint-disable-next-line
            testItem.map((answer, index) => {
                if (answer === String(index + 1)) {
                    Object.values(answerCorrectObj)[testActive].push('correct')
                } else {
                    Object.values(answerCorrectObj)[testActive].push('inCorrect')
                }
            })
        })
        this.setState({
            answerCorrectObj: answerCorrectObj,
        })
        if (Object.values(questionsObj)[testActive].length === 3 && Object.values(answerCorrectObj)[testActive].indexOf('inCorrect') === -1) {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }

        if (answerCorrectObj[1].length && answerCorrectObj[2].length && answerCorrectObj[3].length && answerCorrectObj[4].length && answerCorrectObj[5].length && answerCorrectObj[6].length && answerCorrectObj[7].length && answerCorrectObj[8].length) {
            this.props.showNextBtn()
        }
    };

    testDataDesktop = {
        1: {
            cardHead: `Для котят от 1 до 12 месяцев`,
            cardImg: img1,
            properties1: `Здоровый рост`,
            properties2: `здоровое развитие костей`,
            properties3: `развитие зрения и мозговой деятельности`,
            question1: `Богат белком высокого качества, содержит необходимые макро и микроэлементы для здорового роста котенка.`,
            question2: `Сбалансированный уровень кальция и фосфора помогает здоровому росту и развитию костей.`,
            question3: `Содержит омега-3 жирную кислоту, входящую в состав молока кормящей кошки и способствующую развитию мозговой деятельности и зрения котят`,
            icon1: img1_1,
            icon2: img1_2,
            icon3: img1_3,
            color: 'violet ',
        },
        2: {
            cardHead: `Для взрослых кошек`,
            cardImg: img2,
            properties1: `здоровье сердца`,
            properties2: `жизненная сила`,
            properties3: `здоровье зубов`,
            question1: `Содержит таурин, незаменимую для кошек аминокислоту, помогающую поддерживать сердце сильным и здоровым`,
            question2: `Обогащен витаминами группы в и железом для поддержания жизненной силы`,
            question3: `Хрустящие гранулы способствуют удалению зубного налета, а специальный ингредиент (stpp) снижает образование зубного камня`,
            icon1: img2_1,
            icon2: img2_2,
            icon3: img2_3,
            color: 'pink ',
        },
        3: {
            cardHead: `Для кошек с чувствительным пищеварением`,
            cardImg: img3,
            properties1: `здоровая микрофлора кишечника`,
            properties2: `без добавления пшеницы и сои`,
            properties3: `высокая усвояемость`,
            question1: `Содержит пребиотики, способствующие поддержанию здоровой микрофлоры кишечника`,
            question2: `Кошки предпочитают повышенное содержание белка и пониженное содержание углеводов.`,
            question3: `Содержит легко усваиваемые и высококачественные ингредиенты – индейку/лосось и рис`,
            icon1: img3_1,
            icon2: img3_2,
            icon3: img3_3,
            color: 'yellow ',
        },
        4: {
            cardHead: `Для домашних кошек`,
            cardImg: img4,
            properties1: `поддержание здорового веса`,
            properties2: `способствует уменьшению неприятного запаха`,
            properties3: `гладкая шерсть и здоровая кожа`,
            question1: `Содержит натуральные пищевые волокна, помогающие контролировать образование комочков шерсти в организме.`,
            question2: `Содержит Юкку Шидигера`,
            question3: `Специальная рецептура с пониженным на 15% содержанием жира и адаптированным нутриентным профилем.`,
            icon1: img4_1,
            icon2: img4_2,
            icon3: img4_3,
            color: 'blue ',
        },
        5: {
            cardHead: `Для стерилизованных кошек`,
            cardImg: img5,
            properties1: `ЗДОРОВЬЕ МОЧЕВЫВОДЯЩЕЙ СИСТЕМЫ`,
            properties2: `КОНТРОЛЬ ВЕСА`,
            properties3: `ЗДОРОВЫЙ ОБМЕН ВЕЩЕСТВ`,
            question1: `Баланс минералов, помогающий снижению риска формирования камней и поддержанию здоровья мочевыводящей системы`,
            question2: `Специальная рецептура с пониженным содержанием жира позволяет снизить потребление калорий в каждом кормлении`,
            question3: `Поддерживает здоровый обмен веществ после стерилизации благодаря содержанию всех необходимых питательных веществ`,
            icon1: img5_1,
            icon2: img5_2,
            icon3: img5_3,
            color: 'green ',
        },
        6: {
            cardHead: `Для стерилизованных кошек старше 7 лет`,
            cardImg: img6,
            properties1: `ЗДОРОВОЕ СЕРДЦЕ`,
            properties2: `ЗДОРОВЬЕ МОЧЕВЫВОДЯЩЕЙ СИСТЕМЫ`,
            properties3: `ПОДВИЖНОСТЬ СУСТАВОВ`,
            question1: `Содержит таурин, незаменимую для кошек аминокислоту, помогающую поддерживать сердце сильным и здоровым`,
            question2: `Специальная рецептура с пониженным содержанием жира позволяет снизить потребление калорий в каждом кормлении`,
            question3: `Содержит натуральный источник глюкозамина и омега-3 жирных кислот, помогающих поддерживать подвижность суставов`,
            icon1: img6_1,
            icon2: img6_2,
            icon3: img6_3,
            color: 'light-green ',
        },
        7: {
            cardHead: `Для красивой шерсти и здоровой кожи`,
            cardImg: img7,
            properties1: `ЗДОРОВАЯ КОЖА И КРАСИВАЯ ШЕРСТЬ`,
            properties2: `КОНТРОЛЬ ОБРАЗОВАНИЯ ВОЛОСЯНЫХ КОМОЧКОВ ШЕРСТИ`,
            properties3: `СПОСОБСТВУЕТ СОКРАЩЕНИЮ ВЫПАДЕНИЯ ШЕРСТИ`,
            question1: `Содержит цинк, участвующий в синтезе коллагена (основного белка кожи), а также подсолнечное масло - натуральный источник омега-6 кислот, препятствующих сухости кожи`,
            question2: `Содержит клетчатку, помогающую контролировать образование комочков шерсти в организме кошки`,
            question3: `Комплекс аминокислот, витаминов, минералов и баланс oмега-6 и oмега-3 жирных кислот способствует сокращению выпадения шерсти`,
            icon1: img7_1,
            icon2: img7_2,
            icon3: img7_3,
            color: 'light-blue ',
        },
        8: {
            cardHead: `Для поддержания здоровья почек`,
            cardImg: img8,
            properties1: `ПОДДЕРЖАНИЕ ФУНКЦИИ ПОЧЕК`,
            properties2: `ЗДОРОВЬЕ МОЧЕВЫВОДЯЩЕЙ СИСТЕМЫ`,
            properties3: `ПОДДЕРЖАНИЕ ЖИЗНЕННОВАЖНЫХ ФУНКЦИЙ`,
            question1: `Ограниченный уровень фосфора, омега-3 жирные кислоты и калий способствуют поддержанию здоровья почек`,
            question2: `Баланс минералов, помогающий снижению риска формирования камней и поддержанию здоровья мочевыводящей системы`,
            question3: `Незаменимые витамины, минералы и аминокислоты для здоровья организма кошки`,
            icon1: img8_1,
            icon2: img8_2,
            icon3: img8_3,
            color: 'purple ',
        },
    }


    _renderTestDesktop = () => {
        const {answerCorrectObj, correct, testActive, questionsObj} = this.state
        return (
            <div className="test_desktop">
                <div className="cards">
                    {Object.keys(this.testDataDesktop).map((item,index) => {
                        return (
                            <div
                                key={item}
                                className={'cards_el ' + this.testDataDesktop[item].color + (testActive === index && ' active')}
                                onClick={() => {
                                    this.showTestActive(index);
                                    // this.answer1.clearAnswer()
                                    this.answer2.clearAnswer()
                                    // this.answer3.clearAnswer()
                                    this.answer4.clearAnswer()
                                    // this.answer5.clearAnswer()
                                    this.answer6.clearAnswer()
                                }}
                            >
                                <p className="cards_el_text">
                                    {this.testDataDesktop[item].cardHead}
                                </p>
                                <div className="cards_el_box-img">
                                    <img src={this.testDataDesktop[item].cardImg} alt="" className="cards_el_img"/>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="questions">
                    <div
                        className={'question_el ' + (Object.values(questionsObj)[testActive].indexOf(String(1)) >= 0 && ' hidden')}
                    >
                        <Questions targetKey="1" question_el={String(1)} label={this.testDataDesktop[testActive + 1].question1} text={this.testDataDesktop[testActive + 1].question1}/>
                    </div>
                    <div
                        className={'question_el ' + (Object.values(questionsObj)[testActive].indexOf(String(2)) >= 0 && ' hidden')}
                    >
                        <Questions targetKey="1" question_el={String(2)} label={this.testDataDesktop[testActive + 1].question2} text={this.testDataDesktop[testActive + 1].question2}/>
                    </div>
                    <div
                        className={'question_el ' + (Object.values(questionsObj)[testActive].indexOf(String(3)) >= 0 && ' hidden')}
                    >
                        <Questions targetKey="1" question_el={String(3)} label={this.testDataDesktop[testActive + 1].question3} text={this.testDataDesktop[testActive + 1].question3}/>
                    </div>
                </div>
                <div className="flex">
                    <div className="answers">
                        <div className="answers_icon-box">
                            <img src={this.testDataDesktop[testActive + 1].icon1} alt="" className="answers_icon"/>
                        </div>
                        <div className={'answers_properties ' + this.testDataDesktop[testActive + 1].color}>
                            {this.testDataDesktop[testActive + 1].properties1}
                        </div>
                        <div
                            className="answers_item"
                        >
                            <Answer ref={Answer => {this.answer2 = Answer}} {...this.props} targetKey="1" setAnswer={this.setAnswersObj} answer_el={String(1)} text={'?'} count={'?'} answerCorrect={Object.values(answerCorrectObj)[testActive][0]}/>
                        </div>
                        <div className="answers_icon-box">
                            <img src={this.testDataDesktop[testActive + 1].icon2} alt="" className="answers_icon"/>
                        </div>
                        <div className={'answers_properties ' + this.testDataDesktop[testActive + 1].color}>
                            {this.testDataDesktop[testActive + 1].properties2}
                        </div>
                        <div
                            className="answers_item"
                        >
                            <Answer ref={Answer => {this.answer4 = Answer}} {...this.props} targetKey="1" setAnswer={this.setAnswersObj} answer_el={String(2)} text={'?'} count={'?'} answerCorrect={Object.values(answerCorrectObj)[testActive][1]}/>
                        </div>
                        <div className="answers_icon-box">
                            <img src={this.testDataDesktop[testActive + 1].icon3} alt="" className="answers_icon"/>
                        </div>
                        <div className={'answers_properties ' + this.testDataDesktop[testActive + 1].color}>
                            {this.testDataDesktop[testActive + 1].properties3}
                        </div>
                        <div
                            className="answers_item"
                        >
                            <Answer ref={Answer => {this.answer6 = Answer}} {...this.props} targetKey="1" setAnswer={this.setAnswersObj} answer_el={String(3)} text={'?'} count={'?'} answerCorrect={Object.values(answerCorrectObj)[testActive][2]}/>
                        </div>
                    </div>
                </div>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }

    //Работа мобильной версии
    questions = [
        `Для кого`,
        `Внешний вид корма`,
        `Вкус и текстура`,
        `Название и логотип`,
        `Возраст`,
        `Ключевые особенности корма`
    ]

    selectAnswer = (answerActive) => {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        this.setState({
            showPopup: true,
            answerActive: answerActive
        })
    }

    selectQuestion = (questionActive) => {
        const {answerActive, answersMobile, questionsMobile, answersSelected} = this.state

        if (answersMobile.indexOf(answerActive) === -1) {
            answersMobile.push(answerActive)
        }
        this.setState({answersMobile})

        if (questionsMobile.indexOf(questionsMobile) === -1) {
            questionsMobile.push(questionActive)
        }
        this.setState({questionsMobile})

        answersSelected.splice(answerActive - 1, 1, this.questions[questionActive - 1])

        document.body.style.overflow = "";
        this.setState({
            showPopup: false,
            questionActive: questionActive,
            answersSelected: answersSelected
        })
    }

    checkAnswerMobile = () => {
        const {answerCorrectMobile, answersSelected} = this.state
        // eslint-disable-next-line
        answersSelected.map((answer, index) => {
            if (answer === this.questions[index]) {
                answerCorrectMobile.push('correct')
            } else {
                answerCorrectMobile.push('inCorrect')
            }
        })
        this.setState({
            answerCorrect: answerCorrectMobile,
        })
        if (answerCorrectMobile.indexOf('?') === -1 && answerCorrectMobile.indexOf('inCorrect') === -1) {
            this.setState({correctMobile: '1'})
        } else {
            this.setState({correctMobile: '0'})
        }
        this.props.showNextBtn()
    }

    closePopup = () => {
        document.body.style.overflow = "";
        this.setState({showPopup: false})
    }

    renderOne = (number) => {
        this.setState({
            testNumber: number
        })
        console.log('number = ' + number)
        // this.renderTwo()

        switch(number) {
            case 1:
                return(
                    <div className="someClass">
                        <img className="img" src={img1xl} alt=""/>
                    </div>
                )
            case 2:
                return(
                    <div className="someClass">
                        <img className="img" src={img2xl} alt=""/>
                    </div>
                )
        }
    }

    renderTwo = (number) => {
        console.log('renderTwo')
        if (number === 1) {
            return (
                <div>test 1</div>
            )
            console.log('test 1')
        } else if (number === 2) {
            return (
                <div>test 2</div>
            )
            console.log('test 2')
        }
    }

    /*testCards = (number) => {
        console.log('show testCards ' + '& number=' + number)
        this.setState((state) => {
            switch(number) {
                case 1:
                    return(
                        <div className="someClass">
                            <img className="img" src={img1xl} alt=""/>
                        </div>
                    )
                case 2:
                    return(
                        <div className="someClass">
                            <img className="img" src={img2xl} alt=""/>
                        </div>
                    )
            }
        })

    }*/

    _renderTestMobile = () => {
        const {showPopup, answerActive, answersMobile, questionsMobile, answersSelected, correctMobile, answerCorrectMobile, testNumber} = this.state

        return (
            <div className="test_mobile">
                {
                    testNumber === 0 ?
                        /*show cards*/
                        <div className="cards">
                            {correctMobile !== '' ?
                                <div className={'info ' + (correctMobile === '0' && 'error')}>
                                    <div className="info_head">
                                        {correctMobile === '1' ? 'все верно!' : 'К сожалению, ваш ответ не верен.'}
                                    </div>
                                    <div className="infoText">
                                        {correctMobile === '1'
                                            ?
                                            `Вы правильно расположили основные элементы лицевой стороны упаковки. Далее ознакомьтесь с этикеткой на задней стороне.`
                                            :
                                            'Попробуйте еще раз расположить основные элементы лицевой стороны упаковки.'
                                        }
                                    </div>
                                </div>
                                :
                                <button className="btn btn_blue" onClick={() => this.checkAnswerMobile()}>
                                    ПРОВЕРИТЬ
                                </button>
                            }
                            <div className="flex">
                                {/*<div className="flex_img">
                        {this.questions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={'point ' + answerCorrectMobile[index]}
                                >
                                    {index + 1}
                                </span>
                            )
                        })
                        }
                        <img className="img" src={img1} alt=""/>
                    </div>*/}
                                <div className="flex_el">
                                    <div className="flex_el_cont"
                                         onClick={() => {this.renderOne(1)}}
                                    >
                                        <img className="img" src={img1xs} alt=""/>
                                    </div>
                                </div>
                                <div className="flex_el">
                                    <div className="flex_el_cont"
                                         onClick={() => {this.renderOne(2)}}
                                    >
                                        <img className="img" src={img2xs} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="flex_el">
                                    <div className="flex_el_cont"
                                         onClick={() => {this.renderOne(3)}}
                                    >
                                        <img className="img" src={img3xs} alt=""/>
                                    </div>
                                </div>
                                <div className="flex_el">
                                    <div className="flex_el_cont"
                                         onClick={() => {this.renderOne(4)}}
                                    >
                                        <img className="img" src={img4xs} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="flex_el">
                                    <div className="flex_el_cont"
                                         onClick={() => {this.renderOne(5)}}
                                    >
                                        <img className="img" src={img5xs} alt=""/>
                                    </div>
                                </div>
                                <div className="flex_el">
                                    <div className="flex_el_cont"
                                         onClick={() => {this.renderOne(6)}}
                                    >
                                        <img className="img" src={img6xs} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="flex_el">
                                    <div className="flex_el_cont"
                                         onClick={() => {this.renderOne(7)}}
                                    >
                                        <img className="img" src={img7xs} alt=""/>
                                    </div>
                                </div>
                                <div className="flex_el">
                                    <div className="flex_el_cont"
                                         onClick={() => {this.renderOne(8)}}
                                    >
                                        <img className="img" src={img8xs} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="answers">
                                {this.questions.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={'answers_item ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}
                                            onClick={() => {this.selectAnswer(index + 1)}}
                                        >
                                            {answersSelected[index]}
                                            <span className={'answers_number ' + (answersMobile.indexOf(index + 1) >=0 ? 'selected ' : '') + answerCorrectMobile[index]}>{index + 1}</span>
                                        </div>
                                    )
                                })

                                }
                            </div>
                            {showPopup &&
                            <div className="popup">
                                <div className="popup_wrp">
                                    <div
                                        className="popup_closed"
                                        onClick={() => {this.closePopup()}}
                                    />
                                    <div className="popup_flex">
                                        <div className="point">
                                            {answerActive}
                                        </div>
                                        <p className="popup_text">
                                            Выберите вариант ответа:
                                        </p>
                                        <div className="questions">
                                            {this.questions.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={'questions_item ' + (questionsMobile.indexOf(index + 1) >=0 ? 'selected ' : '')}
                                                        onClick={() => {this.selectQuestion(index + 1)}}
                                                    >
                                                        {item}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>/*end cards*/
                        : /*show test*/
                        <div className="task">

                            {this.renderTwo()}
                            Test mobile
                        </div>
                }

            </div>/*end testMobile*/
        )
    }

    render() {
        const {windowWidth} = this.state

        return (

            <div className="step11">
                <TextBlock
                    text={`Теперь Вам предстоит вспомнить особенности состава, благодаря которым достигаются те или иные полезные свойства каждого рациона.`}
                />
                <InfoBlock
                    text={`Нажмите на продукт и сопоставьте особенности состава с полезными свойствами данного продукта.`}
                />
                {windowWidth >= 768 ?
                    <div className="test">
                        {this._renderTestDesktop()}
                    </div>
                    :
                    <div className="test">
                        {this._renderTestMobile()}
                    </div>
                }
            </div>
        )
    }
}

