import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/spirina.png'
import img2 from '../../../image/Lecturers/spirina-icon.png'
import img3 from '../../../image/Lecturers/gorshkov.png'
import img4 from '../../../image/Lecturers/gorshkov-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Спирина'}
                    name={'Анна Сергеевна'}
                    text={'Ветеринарный врач, к.в.н., Президент Национального ветеринарного стоматологического союза; Руководитель отделения ветеринарной стоматологии и ЧЛХ сети ветеринарных клиник «Денталвет»,\n' +
                    'г. Москва. Старший преподаватель УВЦ «Денталвет». Докторант ДГТУ, заведующий кафедрой ветеринарной стоматологии и ЧЛХ ДГТУ'}
                />

                <Lecturer
                    img={img3}
                    icon={img4}
                    surname={'Горшков'}
                    name={'Сергей Сергеевич'}
                    text={'Ветеринарный врач-хирург, ветеринарная клиника БЭСТ, г. Новосибирск'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/1jXPET9bDFE'}
                    title={'https://www.youtube.com/watch?v=1jXPET9bDFE&feature=youtu.be'}
                />
            </div>
        )
    }
}
