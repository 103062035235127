import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson11/step3-1.png'

export default class step3 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 1) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 1) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {
            text: `Однако не все хищники одинаковы. В отличие от тех, кто может питаться как мясом животных, так и растительной пищей, так как обладают способностью переваривать и извлекать необходимые питательные вещества, включая белки, из бобовых, злаков и овощей, кошки – исключительные (облигатные*) хищники и истинные плотоядные.`,
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step3">
                <InfoBlock
                    text={`Нажмите на ключевые элементы, чтобы больше узнать о них .`}
                />
                <div className="box">
                    <div className="box_img">
                        <div
                            className={'box_point ' + (points.indexOf(1) >= 0 && 'active ') + (pointActive === 1 && 'heigher')}
                            onClick={() => {this.showPopup(1)}}
                        >
                            i
                        </div>
                        <div className={'block red ' + (points.indexOf(1) >= 0 && 'active')}>
                            <TextBlock
                                text={this.popupData[1].text}
                            />
                        </div>
                        <img src={img1} className="imgCenter" alt=""/>
                    </div>
                </div>
                <div className="definition">
                    <b>*Облигатный</b> — термин, используемый для описания организмов, которые ограничиваются каким-либо одним характерным для них способом существования.
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <div className="info_icon">
                            i
                        </div>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
