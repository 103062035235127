import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`Клиника со знаком <span class="text-blue">«Здесь комфортно кошкам»</span> не может иметь в списке выполняемых операций онихэктомию («Мягкие лапы»)`}
                />
            </div>
        )
    }
}