import React from 'react'
import '../style.sass'

import logo from '../../../image/NAMK-logo.svg'
import Inputs from '../../../Components/Inputs'
import InputSearch from '../../../Components/InputSearch'
import Specialty from '../../../Components/Specialty'
import SiteFooter from '../../../Components/SiteFooter'
import Checkbox from '../../../Components/Checkbox'
import OwnData from '../../../Components/OwnData'
import {Link} from "react-router-dom";
import Api from '../../../Service/Api'
import {withRouter} from 'react-router-dom';
import whiskas from "../../../image/whiskas-form-logo.png";
import whiskas_xs from "../../../image/whiskas-form-logo-xs.png";
import perfectfit from "../../../image/perfectfit-form-logo.png";
import perfectfit_xs from "../../../image/perfectfit-form-logo-xs.png";

class Owner extends React.Component {

    state = {
        town: [],
        clinics: [],
        newClinicsId: [],
        chooseAddress: false,
        addressArray: [],
        university: [],
        universityId: '',
        email: '',
        emailError: false,
        emailErrorText: false,
        password: '',
        passwordError: false,
        passwordErrorText: false,
        city: '',
        cityError: false,
        cityOwn: '',
        cityErrorOwn: false,
        clinic: '',
        clinicError: false,
        clinicId: '',
        clinicOwn: '',
        clinicErrorOwn: false,
        address: '',
        addressError: false,
        addressOwn: '',
        addressErrorOwn: false,
        ownAddress: false,
        // ownAddressError: false,
        college: '',
        collegeError: '',
        collegeId: '',
        agree: false,
        agreeError: false,
        eighteen: false,
        eighteenError: false,
        subscribe: false,
        subscribeError: false,
        surname: '',
        surnameError: false,
        name: '',
        nameError: false,
        patronymic: '',
        patronymicError: false,
        phone: '',
        phoneError: false,
        specialty: 1,
        formCompleted: false,
        customUniversity: false,
        universityOwn: '',
        universityOwnError: false,
    }

    Api = new Api()

    componentDidMount() {
        this.Api.getTowns()
            .then((res) => {
                if (res !== undefined) {
                    this.setState({town: res})
                    // this.updateInputSearchData(res)
                }
            })
        this.Api.getUniversity()
            .then((res) => {
                if (res !== undefined) {
                    this.setState({university: res})
                    // this.updateInputSearchData(res)
                }
            })
    }

    requestClinics = (town) => {
        this.Api.getClinics(town)
            .then((res) => {
                if (res !== undefined) {
                    this.setState({clinics: res})
                }
            })
    }

    addAddress = clinics => {
        // this.updateState('address', clinics[Number(addressId) - 1].address)
        this.setState({address: clinics.address, clinicId: clinics.id, chooseAddress: false, addressArray: [], newClinicsId: []})
        this.onCompletedForm()
    }

    addUniversityId = university => {
        this.setState({universityId: university.id})
        this.onCompletedForm()
    }

    addressClick = () => {
        console.log('just click')
    }

    changeOwnAddress = () => {
        const {ownAddress} = this.state
        this.setState({
            ownAddress: !ownAddress,
        })
    }

    changeCustomUniversity = () => {
        const {customUniversity} = this.state
        this.setState({
            customUniversity: !customUniversity,
        })
    }

    changeAgree = () => {
        const {agree} = this.state
        this.setState({
            agree: !agree,
            agreeError: false,
        })
        this.onCompletedForm()
    }

    changeEighteen = () => {
        this.setState({
            eighteen: !this.state.eighteen,
            eighteenError: false,
        })
    }

    changeSubscribe = () => {
        this.setState({
            subscribe: !this.state.subscribe,
            subscribeError: false,
        })
    }

    chooseSpecialty = (specialty) => {
        if (specialty === 2) {
            this.setState({
                specialty: specialty,
                ownAddress: true
            })
        } else {
            this.setState({
                specialty: specialty,
                ownAddress: false
            })
        }
    }

    onCompletedForm = () => {
        this.setState({formCompleted: true})
        // const {email, password, city, clinic, address, name, patronymic, phone, cityOwn, clinicOwn, addressOwn, ownAddress} = this.state
        // if (agree) {
        //     this.setState({formCompleted: true})
        // }
        // console.log(1010, agree)
        // if (!ownAddress) {
        //     if (this.valid_email(email) && password.length && city.length && clinic.length && address.length && name.length && patronymic.length && phone.length) {
        //         this.setState({formCompleted: true})
        //     } else {
        //         this.setState({formCompleted: false})
        //     }
        // } else {
        //     if (this.valid_email(email) && password.length && cityOwn.length && clinicOwn.length && addressOwn.length && name.length && patronymic.length && phone.length) {
        //         this.setState({formCompleted: true})
        //     } else {
        //         this.setState({formCompleted: false})
        //     }
        // }
        // console.log(this.state.formCompleted)
    }

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                this.onCompletedForm()
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false,
                    passwordErrorText: false
                })
                this.onCompletedForm()
                break;
            }
            case 'city' : {
                this.setState({
                    city: value,
                    cityError: false,
                    clinic: '',
                    address: '',
                })
                this.requestClinics(value)
                this.onCompletedForm()
                break;
            }
            case 'city_own' : {
                this.setState({
                    cityOwn: value,
                    cityErrorOwn: false,
                })
                break;
            }
            case 'clinic' : {
                const {clinics} = this.state
                let {addressArray, newClinicsId} = this.state
                this.setState({
                    clinic: value.name,
                    clinicError: false,
                })

                //Собираем массив объектов клиник с одинаковыми названиями
                const newClinics = clinics.filter(item => {
                    return item.name === value.name
                })

                //Если их больше чем одна, то формируем массив адресов клиник и их id. Показываем инпут с поиском
                if (newClinics.length > 1) {
                    addressArray = []
                    newClinicsId = []
                    newClinics.map(item => {
                        addressArray.push(item.address)
                        newClinicsId.push(item.id)
                    })
                    this.setState({chooseAddress: true, addressArray: addressArray, newClinicsId: newClinicsId, address: ''})
                } else {
                    this.addAddress(value)
                }
                this.onCompletedForm()
                break;
            }
            case 'clinic_own' : {
                this.setState({
                    clinicOwn: value,
                    clinicErrorOwn: false
                })
                break;
            }
            case 'address' : {
                const {chooseAddress, newClinicsId} = this.state

                //Записываем нужный id клиники по адресу использую индекс выбранного адреса
                if (chooseAddress) {
                    this.setState({
                        address: value,
                        addressError: false,
                        clinicId: newClinicsId[index]
                    })
                } else {
                    this.setState({
                        address: value,
                        addressError: false
                    })
                }
                this.onCompletedForm()
                break;
            }
            case 'address_own' : {
                this.setState({
                    addressOwn: value,
                    addressErrorOwn: false
                })
                break;
            }
            case 'college' : {
                this.setState({
                    college: value.name,
                    collegeError: false,
                })
                this.addUniversityId(value)
                this.onCompletedForm()
                break;
            }
            case 'custom_university' : {
                this.setState({
                    universityOwn: value,
                    universityOwnError: false,
                })
                this.onCompletedForm()
                break;
            }
            case 'surname' : {
                this.setState({
                    surname: value,
                    surnameError: false,
                })
                this.onCompletedForm()
                break;
            }
            case 'name' : {
                this.setState({
                    name: value,
                    nameError: false,
                })
                this.onCompletedForm()
                break;
            }
            case 'patronymic' : {
                this.setState({
                    patronymic: value,
                    patronymicError: false,
                })
                this.onCompletedForm()
                break;
            }
            case 'phone' : {
                this.setState({
                    phone: value,
                    phoneError: false,
                })
                this.onCompletedForm()
                break;
            }
        }
    }

    sendDate = () => {
        const {
            surname,
            name,
            patronymic,
            email,
            phone,
            city,
            cityOwn,
            specialty,
            clinic,
            clinicOwn,
            clinicId,
            universityId,
            universityOwn,
            addressOwn,
            password,
            agree,
            eighteen,
            ownAddress,
            college,
            customUniversity,
        } = this.state

        const cityToSend = !ownAddress ? city : cityOwn
        const clinicToSend = !ownAddress ? '' : clinicOwn

        if (!this.valid_email(email)) {
            this.setState({emailError: true})
        } else if (!city.length && !ownAddress) {
            this.setState({cityError: true})
        } else if (!cityOwn.length && ownAddress) {
            this.setState({cityErrorOwn: true})
        } else if (!password.length) {
            this.setState({passwordError: true})
        } else if (!agree) {
            this.setState({agreeError: true})
        } else if (!eighteen) {
            this.setState({eighteenError: true})
        } else if (!name.length) {
            this.setState({nameError: true})
        } else if (!surname.length) {
            this.setState({surnameError: true})
        } else if (!patronymic.length) {
            this.setState({patronymicError: true})
        } else if (!phone.length) {
            this.setState({phoneError: true})
        } else if (!clinic.length && !ownAddress) {
            this.setState({clinicError: true})
        } else if (!clinicOwn.length && ownAddress && specialty === 1) {
            this.setState({clinicErrorOwn: true})
        } else if (!addressOwn.length && ownAddress && specialty === 1) {
            this.setState({addressErrorOwn: true})
        } else if (!college.length && !customUniversity && specialty === 2) {
            this.setState({collegeError: true})
        } else if (!universityOwn.length && customUniversity && specialty === 2) {
            this.setState({universityOwnError: true})
        } else {
            this.Api.registrationOwner(name, surname, patronymic, email, password, specialty, cityToSend, phone, clinicId, clinicToSend, addressOwn, Number(ownAddress), universityId, universityOwn)
                .then((res) => {
                    if (res.status === 200) {
                        this.props.changeType();
                    }else{
                        Object.keys(res.error).map(key => {
                            // eslint-disable-next-line
                            this.state[key + 'Error'] = true;
                            // eslint-disable-next-line
                            this.state[key + 'ErrorText'] = res.error[key];
                            this.setState({errors: new Date()})
                            return false
                        })
                    }
                })
        }
    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-._0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    render() {
        const {
            chooseAddress,
            addressArray,
            emailError,
            emailErrorText,
            city,
            cityError,
            cityErrorOwn,
            clinic,
            clinicError,
            clinicErrorOwn,
            address,
            addressError,
            addressErrorOwn,
            passwordError,
            ownAddress,
            ownAddressError,
            college,
            collegeError,
            agree,
            agreeError,
            eighteen,
            eighteenError,
            subscribe,
            subscribeError,
            town,
            university,
            universityOwn,
            universityOwnError,
            clinics,
            surnameError,
            nameError,
            patronymicError,
            phoneError,
            formCompleted,
            specialty,
            customUniversity,
        } = this.state
        return (
            <div className="Entrance">
                <div className="wrp">
                    <form action="" className="form">
                        <div className="logo">
                            <svg className="logo_cat" width="234" height="54" viewBox="0 0 226 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M223.37 0C222.942 0 222.562 0.208 222.562 0.736C222.562 0.992001 222.657 1.408 222.689 1.744C222.229 1.424 221.897 1.088 221.548 1.088C221.057 1.088 220.756 1.552 220.756 2.016C220.756 2.624 221.532 2.752 222.007 3.008C221.532 3.248 220.756 3.376 220.756 3.984C220.756 4.448 221.057 4.928 221.548 4.928C221.897 4.928 222.229 4.576 222.689 4.256C222.657 4.592 222.562 5.008 222.562 5.264C222.562 5.792 222.942 6 223.37 6C223.798 6 224.178 5.792 224.178 5.264C224.178 5.008 224.083 4.592 224.051 4.256C224.495 4.576 224.812 4.928 225.176 4.928C225.651 4.928 226 4.448 226 3.984C226 3.376 225.224 3.248 224.733 3.008C225.224 2.752 226 2.624 226 2.016C226 1.552 225.651 1.088 225.176 1.088C224.812 1.088 224.495 1.424 224.051 1.744C224.083 1.408 224.178 0.992001 224.178 0.736C224.178 0.208 223.798 0 223.37 0Z" fill="#4147DA"/>
                                <path d="M0 13C0 20.3125 5.01133 26 12.2262 26C14.8563 26 20.223 25.2228 20.223 21.8315C20.223 21.019 19.4766 19.4293 17.8773 19.4293C16.4201 19.4293 15.2117 20.913 12.2262 20.913C7.85463 20.913 5.54445 17.3098 5.54445 13.106C5.54445 8.79619 7.81909 5.08696 12.2262 5.08696C15.2117 5.08696 16.2068 6.32337 17.664 6.32337C18.9791 6.32337 19.8676 5.22826 19.8676 3.95652C19.8676 0.847825 14.8207 0 12.2262 0C4.97578 0 0 5.65217 0 13Z" fill="#4147DA"/>
                                <path d="M22.1539 22.1495C22.0828 22.3261 21.9051 22.75 21.9051 23.3152C21.9051 24.7283 23.1135 25.788 24.5352 25.788C25.9924 25.788 26.7387 25.0109 27.0941 23.9158L28.1604 20.6304H38.0409L39.1071 23.9511C39.4625 25.0109 40.2089 25.788 41.7016 25.788C43.0166 25.788 44.154 24.8342 44.154 23.4565C44.154 23.2092 44.0829 22.7147 43.8696 22.1495L36.9035 2.93206C36.2993 1.23641 34.9843 0 33.1006 0C31.288 0 29.9019 1.30706 29.2977 2.93206L22.1539 22.1495ZM33.0651 5.51087H33.1362L36.4415 15.7554H29.6887L33.0651 5.51087Z" fill="#4147DA"/>
                                <path d="M47.5335 23.0326C47.5335 24.7989 48.6708 25.788 50.199 25.788C51.7273 25.788 52.8646 24.7989 52.8646 23.0326V5.29892H56.7387C58.4446 5.29892 59.1555 4.0625 59.1555 2.86142C59.1555 1.625 58.2669 0.423914 56.7387 0.423914H43.6594C42.1312 0.423914 41.2426 1.625 41.2426 2.86142C41.2426 4.0625 41.9535 5.29892 43.6594 5.29892H47.5335V23.0326Z" fill="#4147DA"/>
                                <path d="M71.2767 23.0326C71.2767 24.7989 72.414 25.788 73.9423 25.788C75.4706 25.788 76.6079 24.7989 76.6079 23.0326V15.3315H82.1168C83.8228 15.3315 84.6758 14.0951 84.6758 12.8587C84.6758 11.6576 83.8228 10.4565 82.1168 10.4565H76.6079V5.29892H83.1475C84.7469 5.29892 85.5288 4.0625 85.5288 2.86142C85.5288 1.625 84.7113 0.423914 83.1475 0.423914H74.0489C72.2719 0.423914 71.2767 1.73098 71.2767 3.17935V23.0326Z" fill="#4147DA"/>
                                <path d="M87.7798 23.0326C87.7798 24.7989 88.9171 25.788 90.4454 25.788C91.9737 25.788 93.111 24.7989 93.111 23.0326V15.3668H93.1821L101.072 24.8696C101.641 25.5408 102.494 25.788 103.134 25.788C104.697 25.788 105.799 24.4457 105.799 23.0679C105.799 22.5734 105.586 22.0435 105.088 21.5136L99.0464 15.0842C102.672 14.519 105.622 12.2935 105.622 8.40761C105.622 2.86141 102.245 0.423914 96.7718 0.423914H90.5165C88.7394 0.423914 87.7798 1.48369 87.7798 3.17935V23.0326ZM93.111 5.29892H96.7718C98.7265 5.29892 100.077 6.39402 100.077 8.40761C100.077 10.3859 98.7265 11.5516 96.7718 11.5516H93.111V5.29892Z" fill="#4147DA"/>
                                <path d="M108.24 23.0326C108.24 24.7989 109.377 25.788 110.905 25.788C112.434 25.788 113.571 24.7989 113.571 23.0326V2.96739C113.571 1.20108 112.434 0.211956 110.905 0.211956C109.377 0.211956 108.24 1.20108 108.24 2.96739V23.0326Z" fill="#4147DA"/>
                                <path d="M117.489 22.9266C117.489 24.5163 118.662 25.5761 120.225 25.5761H129.395C131.066 25.5761 131.919 24.3397 131.919 23.1386C131.919 21.9022 131.101 20.7011 129.395 20.7011H122.82V15.3315H128.649C130.355 15.3315 131.243 14.0951 131.243 12.894C131.243 11.6576 130.39 10.4565 128.649 10.4565H122.82V5.29892H129.075C130.746 5.29892 131.599 4.0625 131.599 2.86142C131.599 1.625 130.781 0.423914 129.075 0.423914H120.261C118.377 0.423914 117.489 1.73098 117.489 3.49728V22.9266Z" fill="#4147DA"/>
                                <path d="M134.438 23.0326C134.438 24.7989 135.575 25.788 137.104 25.788C138.632 25.788 139.769 24.7989 139.769 23.0326V9.85598H139.84L151.107 24.6223C151.605 25.2582 152.671 25.788 153.346 25.788C154.874 25.788 156.012 24.7989 156.012 23.0326V2.96739C156.012 1.20108 154.874 0.211956 153.346 0.211956C151.818 0.211956 150.68 1.20108 150.68 2.96739V16.3207H150.609L139.343 1.37772C138.881 0.741848 137.779 0.211956 137.104 0.211956C135.575 0.211956 134.438 1.20108 134.438 2.96739V23.0326Z" fill="#4147DA"/>
                                <path d="M182.693 22.6793C182.693 24.4457 183.723 25.5761 185.465 25.5761H194.35C195.914 25.5761 196.838 24.4103 196.909 23.2446C196.945 22.0435 196.163 20.7011 194.35 20.7011H188.024V2.96739C188.024 1.20109 186.887 0.211956 185.358 0.211956C183.83 0.211956 182.693 1.20109 182.693 2.96739V22.6793Z" fill="#4147DA"/>
                                <path d="M159.536 22.9266C159.536 24.9402 161.171 25.5761 162.344 25.5761H168.528C175.85 25.5761 180.683 20.7717 180.683 13.3533C180.683 5.51087 176.134 0.423914 168.173 0.423914H162.273C160.674 0.423914 159.536 1.51902 159.536 3.14402V22.9266ZM164.868 5.29892H168.102C173.006 5.29892 175.139 8.58424 175.139 13.106C175.139 17.3451 173.042 20.7011 168.457 20.7011H164.868V5.29892Z" fill="#4147DA"/>
                                <path d="M205.213 23.2092C205.213 24.5163 205.995 25.788 207.879 25.788C209.762 25.788 210.544 24.5163 210.544 23.2092V13.7772L217.262 4.38044C217.581 3.9212 217.759 3.35598 217.759 2.79076C217.759 1.37772 216.693 0.211956 215.2 0.211956C214.276 0.211956 213.459 0.600545 212.997 1.27174L207.879 8.65489L202.761 1.27174C202.299 0.600545 201.481 0.211956 200.557 0.211956C199.064 0.211956 197.998 1.37772 197.998 2.79076C197.998 3.35598 198.176 3.9212 198.496 4.38044L205.213 13.7772V23.2092Z" fill="#4147DA"/>
                                <path d="M30.5985 51.0481C27.8819 51.0481 26.5771 48.738 26.5771 46.1283C26.5771 43.5401 27.9247 41.2086 30.5985 41.2086C33.2723 41.2086 34.6199 43.5401 34.6199 46.1283C34.6199 48.738 33.3151 51.0481 30.5985 51.0481ZM30.5985 54C34.9836 54 37.9568 50.5561 37.9568 46.1283C37.9568 41.7861 34.898 38.2567 30.5985 38.2567C26.2349 38.2567 23.2402 41.6791 23.2402 46.1283C23.2402 50.5561 26.2563 54 30.5985 54Z" fill="#A7A8B9"/>
                                <path d="M39.704 52.2032C39.704 53.2727 40.3885 53.8717 41.3082 53.8717C42.228 53.8717 42.9125 53.2727 42.9125 52.2032V47.5401H49.6291V52.2032C49.6291 53.2727 50.3136 53.8717 51.2334 53.8717C52.1532 53.8717 52.8377 53.2727 52.8377 52.2032V40.0535C52.8377 38.984 52.1532 38.385 51.2334 38.385C50.3136 38.385 49.6291 38.984 49.6291 40.0535V44.5882H42.9125V40.0535C42.9125 38.984 42.228 38.385 41.3082 38.385C40.3885 38.385 39.704 38.984 39.704 40.0535V52.2032Z" fill="#A7A8B9"/>
                                <path d="M60.7757 41.4652H65.0323V52.1604C65.0323 53.2941 65.6954 53.8717 66.6366 53.8717C67.5564 53.8717 68.2195 53.2941 68.2195 52.1604V40.0963C68.2195 39.0909 67.4708 38.5134 66.6152 38.5134H59.3639C58.4869 38.5134 57.888 39.0695 57.7596 40.0963L56.8184 47.8182C56.5404 50.0642 55.8773 50.6417 55.0216 50.984C54.4441 51.2193 54.1446 51.5829 54.1446 52.246C54.1446 53.1658 54.6794 53.8717 55.7061 53.8717C57.7596 53.8717 59.4495 52.1818 59.8559 48.9091L60.7757 41.4652Z" fill="#A7A8B9"/>
                                <path d="M69.8071 51.6684C69.7643 51.7754 69.6573 52.0321 69.6573 52.3743C69.6573 53.2299 70.3846 53.8717 71.2402 53.8717C72.1172 53.8717 72.5664 53.4011 72.7803 52.738L73.4221 50.7487H79.3686L80.0103 52.7594C80.2242 53.4011 80.6734 53.8717 81.5718 53.8717C82.3632 53.8717 83.0477 53.2941 83.0477 52.4599C83.0477 52.3102 83.0049 52.0107 82.8766 51.6684L78.6841 40.0321C78.3204 39.0053 77.529 38.2567 76.3953 38.2567C75.3044 38.2567 74.4702 39.0481 74.1065 40.0321L69.8071 51.6684ZM76.3739 41.5936H76.4167L78.406 47.7968H74.3418L76.3739 41.5936Z" fill="#A7A8B9"/>
                                <path d="M94.3821 52.1604C94.3821 53.2941 95.0666 53.8717 95.9864 53.8717C96.9276 53.8717 97.5907 53.2941 97.5907 52.1604V40.0963C97.5907 38.9626 96.9062 38.385 95.9864 38.385C95.1308 38.385 94.8099 38.7487 93.8046 40.0535L87.7083 47.9465H87.6655V40.0963C87.6655 38.9626 87.0024 38.385 86.0613 38.385C85.1415 38.385 84.4784 38.9626 84.4784 40.0963V52.1604C84.4784 53.3155 85.1415 53.8717 86.0613 53.8717C86.9169 53.8717 87.2377 53.508 88.2431 52.2032L94.3393 44.2888H94.3821V52.1604ZM87.7725 35.2834C87.7725 36.8877 89.7832 37.508 91.088 37.508C92.3714 37.508 94.4035 36.8235 94.4035 35.2834C94.4035 34.7273 93.9971 34 93.4409 34C92.0506 34 92.2431 35.4545 91.088 35.4545C89.7618 35.4545 90.0613 34 88.735 34C88.0292 34 87.7725 34.6845 87.7725 35.2834Z" fill="#A7A8B9"/>
                                <path d="M99.9463 52.2032C99.9463 53.2727 100.631 53.8717 101.551 53.8717C102.47 53.8717 103.155 53.2727 103.155 52.2032V47.5401H109.871V52.2032C109.871 53.2727 110.556 53.8717 111.476 53.8717C112.395 53.8717 113.08 53.2727 113.08 52.2032V40.0535C113.08 38.984 112.395 38.385 111.476 38.385C110.556 38.385 109.871 38.984 109.871 40.0535V44.5882H103.155V40.0535C103.155 38.984 102.47 38.385 101.551 38.385C100.631 38.385 99.9463 38.984 99.9463 40.0535V52.2032Z" fill="#A7A8B9"/>
                                <path d="M116.355 46.1925C115.563 46.2567 115.136 46.7914 115.136 47.5615C115.136 48.2888 115.563 48.8235 116.355 48.8877H120.911C121.702 48.8235 122.13 48.2888 122.13 47.5615C122.13 46.7914 121.702 46.2567 120.911 46.1925H116.355Z" fill="#A7A8B9"/>
                                <path d="M139.984 50.7914H135.278V40.0963C135.278 38.9626 134.615 38.385 133.674 38.385C132.754 38.385 132.07 38.9626 132.07 40.0963V50.7914H127.364V40.0963C127.364 38.9626 126.701 38.385 125.76 38.385C124.84 38.385 124.177 38.9626 124.177 40.0963V52.1604C124.177 53.1658 124.925 53.7433 125.781 53.7433H141.588C142.423 53.7433 143.193 53.1872 143.193 52.1604V40.0963C143.193 38.9626 142.508 38.385 141.588 38.385C140.647 38.385 139.984 38.9626 139.984 40.0963V50.7914Z" fill="#A7A8B9"/>
                                <path d="M145.535 52.3316C145.535 53.2727 146.22 53.8717 147.14 53.8717C148.102 53.8717 148.744 53.1658 148.744 52.3316V46.877L154.284 52.8663C154.883 53.508 155.289 53.8717 155.91 53.8717C156.787 53.8717 157.578 53.4011 157.578 52.3957C157.578 51.754 157.193 51.2834 156.68 50.7701L151.739 45.615L155.781 41.6364C156.487 40.9305 156.829 40.4813 156.829 39.9251C156.829 39.0053 156.059 38.385 155.247 38.385C154.648 38.385 154.241 38.7273 154.006 38.984L148.744 44.5882V39.9679C148.744 38.984 148.059 38.385 147.14 38.385C146.177 38.385 145.535 39.0909 145.535 39.9679V52.3316Z" fill="#A7A8B9"/>
                                <path d="M165.799 51.0481C163.082 51.0481 161.778 48.738 161.778 46.1283C161.778 43.5401 163.125 41.2086 165.799 41.2086C168.473 41.2086 169.82 43.5401 169.82 46.1283C169.82 48.738 168.516 51.0481 165.799 51.0481ZM165.799 54C170.184 54 173.157 50.5561 173.157 46.1283C173.157 41.7861 170.099 38.2567 165.799 38.2567C161.435 38.2567 158.441 41.6791 158.441 46.1283C158.441 50.5561 161.457 54 165.799 54Z" fill="#A7A8B9"/>
                                <path d="M180.487 41.4652H184.744V52.1604C184.744 53.2941 185.407 53.8717 186.348 53.8717C187.268 53.8717 187.931 53.2941 187.931 52.1604V40.0963C187.931 39.0909 187.183 38.5134 186.327 38.5134H179.076C178.199 38.5134 177.6 39.0695 177.471 40.0963L176.53 47.8182C176.252 50.0642 175.589 50.6417 174.733 50.984C174.156 51.2193 173.856 51.5829 173.856 52.246C173.856 53.1658 174.391 53.8717 175.418 53.8717C177.471 53.8717 179.161 52.1818 179.568 48.9091L180.487 41.4652Z" fill="#A7A8B9"/>
                                <path d="M189.519 51.6684C189.476 51.7754 189.369 52.0321 189.369 52.3743C189.369 53.2299 190.096 53.8717 190.952 53.8717C191.829 53.8717 192.278 53.4011 192.492 52.738L193.134 50.7487H199.08L199.722 52.7594C199.936 53.4011 200.385 53.8717 201.284 53.8717C202.075 53.8717 202.759 53.2941 202.759 52.4599C202.759 52.3102 202.717 52.0107 202.588 51.6684L198.396 40.0321C198.032 39.0053 197.241 38.2567 196.107 38.2567C195.016 38.2567 194.182 39.0481 193.818 40.0321L189.519 51.6684ZM196.086 41.5936H196.128L198.118 47.7968H194.054L196.086 41.5936Z" fill="#A7A8B9"/>
                            </svg>
                            <div className="logo_box">
                                <a href="https://whiskas.ru/">
                                    <picture>
                                        <source srcSet={whiskas_xs} media="(max-width: 480px)"/>
                                        <img src={whiskas} alt=""/>
                                    </picture>
                                </a>
                                <img className="logo_namk" src={logo} alt=""/>
                                <a href="https://www.perfectfit.ru/">
                                    <picture>
                                        <source srcSet={perfectfit_xs} media="(max-width: 480px)"/>
                                        <img src={perfectfit} alt=""/>
                                    </picture>
                                </a>
                            </div>
                        </div>
                        <div className="head">
                            <Link to={'/startPage/auth'}>Войдите</Link> или зарегистрируйтесь в онлайн-школе.<br/>
                            Пожалуйста, заполните поля ниже.
                        </div>
                        <div className="flex">
                            <Inputs
                                name={'surname'}
                                placeholder={'Фамилия'}
                                type={'text'}
                                className={surnameError && 'error'}
                                onChange={this.updateState}
                                required={'required'}
                            />
                            <Inputs
                                name={'name'}
                                placeholder={'Имя'}
                                type={'text'}
                                className={nameError && 'error'}
                                onChange={this.updateState}
                                required={'required'}
                            />
                        </div>
                        <div className="flex">
                            <Inputs
                                name={'patronymic'}
                                placeholder={'Отчество'}
                                type={'text'}
                                className={patronymicError && 'error'}
                                onChange={this.updateState}
                                required={'required'}
                            />
                            <Inputs
                                name={'email'}
                                placeholder={'Email'}
                                onChange={this.updateState}
                                type={'email'}
                                className={emailError && 'error'}
                                errorText={emailErrorText}
                                required={'required'}
                            />
                        </div>
                        <div className="flex">
                            <Inputs
                                name={'phone'}
                                placeholder={'Телефон'}
                                type={'tel'}
                                className={phoneError && 'error'}
                                onChange={this.updateState}
                                mask={'+7 (999) 999-99-99'}
                                required={'required'}
                            />
                            <Inputs
                                name={'password'}
                                placeholder={'Придумайте пароль'}
                                type={'password'}
                                onChange={this.updateState}
                                className={passwordError && 'error'}
                                required={'required'}
                            />
                        </div>
                        <Specialty
                            chooseSpecialty={this.chooseSpecialty}
                        />
                        {specialty === 1
                            ?
                            <React.Fragment>
                                <div className={'form_box ' + (!ownAddress && 'active')}>
                                    <InputSearch
                                        name={'city'}
                                        placeholder={'Город (из списка)'}
                                        onChange={this.updateState}
                                        type={'text'}
                                        value={city}
                                        className={cityError && 'error'}
                                        required={'required'}
                                        data={town}
                                        key={1}
                                    />
                                    <InputSearch
                                        name={'clinic'}
                                        placeholder={'Название клиники'}
                                        onChange={this.updateState}
                                        type={'text'}
                                        value={clinic}
                                        className={clinicError && 'error'}
                                        required={'required'}
                                        data={clinics}
                                        complete={clinic.length ? true : false}
                                        key={2}
                                    />
                                    {chooseAddress
                                        ?
                                        <InputSearch
                                            name={'address'}
                                            placeholder={'Адрес клиники'}
                                            onChange={this.updateState}
                                            type={'text'}
                                            value={address}
                                            className={addressError && 'error'}
                                            required={'required'}
                                            data={addressArray}
                                            complete={clinic.length ? true : false}
                                            key={3}
                                        />
                                        :
                                        <Inputs
                                            name={'address'}
                                            placeholder={'Адрес клиники'}
                                            onChange={this.updateState}
                                            onClick={this.addressClick}
                                            type={'text'}
                                            value={address}
                                            className={addressError && 'error'}
                                            required={'required'}
                                            options={true}
                                            complete={clinic.length ? true : false}
                                        />
                                    }
                                </div>

                                <div className={'form_box ' + (ownAddress && 'active')}>
                                    <Inputs
                                        name={'city_own'}
                                        placeholder={'Город (на ваш выбор)'}
                                        type={'text'}
                                        className={cityErrorOwn && 'error'}
                                        onChange={this.updateState}
                                        required={'required'}
                                    />
                                    <Inputs
                                        name={'address_own'}
                                        placeholder={'Адрес клиники'}
                                        type={'text'}
                                        className={addressErrorOwn && 'error'}
                                        onChange={this.updateState}
                                        required={'required'}
                                    />
                                    <Inputs
                                        name={'clinic_own'}
                                        placeholder={'Название клиники'}
                                        type={'text'}
                                        className={clinicErrorOwn && 'error'}
                                        onChange={this.updateState}
                                        required={'required'}
                                    />
                                </div>
                                <OwnData
                                    active={ownAddress}
                                    text={'Ввести свои данные'}
                                    label={'Если вы не нашли в выпадающем списке свой город/клинику, воспользуйтесь кнопкой «Ввести свои данные».'}
                                    onClick={this.changeOwnAddress}
                                    key={1}
                                />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Inputs
                                    name={'city_own'}
                                    placeholder={'Город'}
                                    type={'text'}
                                    className={cityErrorOwn && 'error'}
                                    onChange={this.updateState}
                                    required={'required'}
                                />
                                {customUniversity
                                    ?
                                    <Inputs
                                        name={'custom_university'}
                                        placeholder={'Укажите универститет'}
                                        onChange={this.updateState}
                                        type={'text'}
                                        value={universityOwn}
                                        className={universityOwnError && 'error'}
                                        required={'required'}
                                    />
                                    :
                                    <InputSearch
                                        name={'college'}
                                        placeholder={'Выберите университет'}
                                        onChange={this.updateState}
                                        type={'text'}
                                        value={college}
                                        className={collegeError && 'error'}
                                        required={'required'}
                                        data={university}
                                        // complete={clinic.length ? true : false}
                                        key={4}
                                    />
                                }
                                <OwnData
                                    active={customUniversity}
                                    text={'Ввести свои данные'}
                                    label={'Если вы не нашли в выпадающем списке свой вуз, воспользуйтесь кнопкой «Ввести свои данные».'}
                                    onClick={this.changeCustomUniversity}
                                    key={2}
                                />
                            </React.Fragment>
                        }
                        <div className="flex_wrp">
                            <div className="agree">
                                <span className="agree_star">*</span>
                                <Checkbox
                                    active={agree}
                                    error={agreeError}
                                    onClick={this.changeAgree}
                                />
                                <div className="agree_text">
                                    <span className="link-no">Я ознакомился с </span><a href="/confidentiality.pdf" target="_blank" rel="noopener noreferrer"><span className="link-yes">Положением о конфиденциальности, </span></a>
                                    <a href="/agreement.pdf" target="_blank" rel="noopener noreferrer"><span className="link-yes">Пользовательским соглашением</span></a><span className="link-no"> и даю свое согласие на обработку персональных данных.</span>
                                </div>
                            </div>
                            <div className="agree">
                                <span className="agree_star">*</span>
                                <Checkbox
                                    active={eighteen}
                                    error={eighteenError}
                                    onClick={this.changeEighteen}
                                />
                                <span className="link-no">Мне исполнилось 18 лет.</span>
                            </div>
                            <div className="agree">
                                <Checkbox
                                    active={subscribe}
                                    error={subscribeError}
                                    onClick={this.changeSubscribe}
                                />
                                <span className="link-no">Я даю согласие на получение рекламной рассылки (в т.ч. в виде смс, электронных писем и/или через месенджеры).</span>
                            </div>
                            <div className={'info ' + (formCompleted && agree && eighteen && 'complete')}>
                                {formCompleted && agree && eighteen
                                    ?
                                    <p className="info__icon">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10" cy="10" r="10" fill="#4DD06A"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.843 7.13475C15.0439 7.32213 15.0532 7.6351 14.8638 7.83379L9.20522 13.7679C9.11075 13.8669 8.97915 13.9231 8.84146 13.9231C8.70378 13.9231 8.57218 13.8669 8.47771 13.7679L6.13625 11.3124C5.94679 11.1137 5.95606 10.8007 6.15695 10.6133C6.35785 10.426 6.67429 10.4351 6.86375 10.6338L8.84146 12.7078L14.1362 7.15523C14.3257 6.95654 14.6422 6.94737 14.843 7.13475Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>

                                    </p>
                                    :
                                    <p className="info__icon">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.86957 0.000713581C4.34823 0.0659263 -0.0644992 4.60908 0.000713581 10.1304C0.0659263 15.6518 4.60908 20.0645 10.1304 19.9993C15.6518 19.9341 20.0645 15.3909 19.9993 9.86957C19.9341 4.34823 15.3909 -0.0644991 9.86957 0.000713581ZM10.9782 3.32656C11.9999 3.32656 12.3042 3.91348 12.3042 4.58734C12.3042 5.43511 11.6303 6.21766 10.4782 6.21766C9.52177 6.21766 9.06528 5.73943 9.08702 4.93514C9.06528 4.26128 9.63046 3.32656 10.9782 3.32656ZM8.36968 16.2604C7.67408 16.2604 7.17412 15.8474 7.65234 13.978L8.45663 10.6956C8.58706 10.1739 8.6088 9.95652 8.45663 9.95652C8.23926 9.95652 7.34801 10.3261 6.80458 10.6739L6.45677 10.1087C8.15231 8.69574 10.087 7.86972 10.913 7.86972C11.6086 7.86972 11.7173 8.69574 11.3695 9.95652L10.4565 13.4128C10.3043 14.0214 10.3695 14.2388 10.5217 14.2388C10.7391 14.2388 11.4129 13.978 12.0868 13.4563L12.4781 13.978C10.8478 15.6083 9.06528 16.2604 8.36968 16.2604Z" fill="#C6C6C6"/>
                                        </svg>

                                    </p>
                                }
                                <p className="info__text">
                                    <span className="info__star">&#42;</span> Поля, отмеченные звездочкой, обязательны для заполнения.
                                </p>
                            </div>
                        </div>
                        <div className="buttons">
                        <button
                            type='button'
                            className="btn btn_violet"
                            onClick={() => {
                                this.sendDate()
                            }}
                            disabled={formCompleted && agree && eighteen ? '' : 'disabled'}
                        >
                            зарегистрироваться
                        </button>
                        </div>
                        {/*<Partners brStyle={{display: 'none'}}/>*/}
                    </form>
                </div>
                <SiteFooter/>
            </div>
        )
    }

}

export default withRouter(Owner);