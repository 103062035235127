import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson7/step12-1.png'
import img2 from '../../../image/lessons/lesson7/step12-2.png'
import img3 from '../../../image/lessons/lesson7/step12-3.png'

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <ul className="list">
                    <li className="list_item">Кошки обычно предпочитают <b>большие, открытые лотки</b>. Как правило, кошачий лоток должен быть достаточно большим, чтобы кошка могла встать на четвереньки и развернуться.</li>
                    <li className="list_item">Кошачьи лотки с высокими бортами или краями вокруг верхней части подходят для кошек, склонных разбрасывать наполнитель по сторонам во время копания. С низкими бортами - для маленьких котят и пожилых кошек.</li>
                    <li className="list_item">Некоторые кошки могут предпочитать закрытые лотки из-за дополнительной приватности. <span className="text_red">Минус</span> – такие лотки будут концентрировать запахи сильнее.</li>
                </ul>
                <div className="flex">
                    <img src={img1} alt="" className="flex_img"/>
                    <img src={img2} alt="" className="flex_img"/>
                    <img src={img3} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
