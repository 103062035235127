import React from 'react'
import './style.sass'

export default class InputFileNew extends React.Component {

    state = {
        fileName: this.props.placeholder,
        className: '',
        iconClassName: '',
        fileValue: '',
    }

    addsFileName = (name, value, size, context) => {
        let inputFileNew = value;
        let reader  = new FileReader();
        let fileName = name;
        let fileSize = size;
        let fileExt = fileName.split('.').pop();
        if (fileSize <= 2097152 && (fileExt === 'jpg'|| fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'svg')) {
            reader.readAsDataURL(inputFileNew)
            reader.onloadend = reloadState
            function reloadState () {
                context.setState({fileName: fileName, className: 'inputFileNew__name--bold', iconClassName: 'inputFileNew__icon--hide', fileValue: reader.result})
                context.props.updateFile(inputFileNew, fileName, false)
            }
        } else if (fileSize > 2097152) {
            context.setState({fileName: 'Выберите файл размером меньше 2 Мб', className: 'inputFileNew__name--red', iconClassName: 'inputFileNew__icon--hide'})
            context.props.updateFile('', '', true)
        } else {
            context.setState({fileName: 'Нужен формат файла jpg, png, svg', className: 'inputFileNew__name--red', iconClassName: 'inputFileNew__icon--hide'})
            context.props.updateFile('', '', true)
        }

    }

    render() {
        const {fileName, className, iconClassName, fileValue} = this.state
        return (
            <div className={'inputFileNew ' + this.props.className}>
                <input
                    className="visually-hidden"
                    type="file"
                    name={this.props.name}
                    id={this.props.id}
                    onChange={(e) => {this.addsFileName(e.target.files[0].name, e.target.files[0], e.target.files[0].size, this)}}
                    //multiple={'multiple'}
                />
                <label
                    className="inputFileNew__label"
                    htmlFor={this.props.id}
                >
                    <span className={'inputFileNew__icon ' + iconClassName}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 11C2 10.4477 1.55228 10 1 10C0.447715 10 0 10.4477 0 11H2ZM16 11C16 10.4477 15.5523 10 15 10C14.4477 10 14 10.4477 14 11H16ZM3.4 3.2C2.95817 3.53137 2.86863 4.15817 3.2 4.6C3.53137 5.04183 4.15817 5.13137 4.6 4.8L3.4 3.2ZM8 1L8.6 0.2C8.24444 -0.0666667 7.75556 -0.0666667 7.4 0.2L8 1ZM11.4 4.8C11.8418 5.13137 12.4686 5.04183 12.8 4.6C13.1314 4.15817 13.0418 3.53137 12.6 3.2L11.4 4.8ZM7 10C7 10.5523 7.44772 11 8 11C8.55228 11 9 10.5523 9 10H7ZM13 14H3V16H13V14ZM2 13V11H0V13H2ZM14 11V13H16V11H14ZM3 14C2.44772 14 2 13.5523 2 13H0C0 14.6569 1.34315 16 3 16V14ZM13 16C14.6569 16 16 14.6569 16 13H14C14 13.5523 13.5523 14 13 14V16ZM4.6 4.8L8.6 1.8L7.4 0.2L3.4 3.2L4.6 4.8ZM7.4 1.8L11.4 4.8L12.6 3.2L8.6 0.2L7.4 1.8ZM7 1V10H9V1H7Z" fill="#4147DA"/>
                        </svg>
                    </span>
                    <span className={'inputFileNew__name ' + className}>{fileName}</span>
                    {fileValue.length > 0 &&
                    <img src={fileValue} alt="" className="inputFileNew__preview"/>
                    }
                </label>
            </div>
        )
    }
}
