import React from 'react'
import './style.sass'

export default class UsedMaterials extends React.Component {

    // Пример использования lesson 6 step1
    render() {
        return (
            <div className="UsedMaterials">
               <div className="UsedMaterials_head">
                   {this.props.head}
               </div>
                <div className="UsedMaterials_text">
                    {this.props.text}
                </div>
            </div>
        )
    }
}
