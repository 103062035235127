import React from 'react'
import {withRouter} from 'react-router-dom';
import Api from '../../../Service/Api'

import LessonPlan from "../../../Components/LessonAndTestComponents/LessonPlan";
import MainButton from "../../../Components/LessonAndTestComponents/MainButton";
import MainHeader from "../../../Components/LessonAndTestComponents/MainHeader";
import ModalAfterStep from "../../../Components/LessonAndTestComponents/ModalAfterStep";
import PopapAfterTest from "../../Test/popapAfterTest"

import './style.sass'
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import Step7 from './step7';
import Step8 from './step8';
import Step9 from './step9';
import Step10 from './step10';
import Step11 from './step11';
import Step12 from './step12';
import Step13 from './step13';
import Step14 from './step14';
import Step15 from './step15';
import Step16 from './step16';
import Step17 from './step17';
import Step18 from './step18';
import Step19 from './step19';
import Step20 from './step20';
import Step21 from './step21';
import Step22 from './step22';
import Step23 from './step23';
import Step24 from './step24';
import Step25 from './step25';
import Step26 from './step26';
import Step27 from './step27';
import Step28 from './step28';
import Step29 from './step29';
import Step30 from './step30';
import Step31 from './step31';
import Step32 from './step32';
import Step33 from './step33';

class Lesson13 extends React.Component {
    Api = new Api();

    state = {
        lessonStepsActive: 1, // Пункт меню справа
        stepActive: 1, // Текуший шаг урока
        MainHeader_header: 'Урок 11', // Первая линия заголовка шага
        MainHeader_subheader: 'Причины возникновения стресса у кошек в повседневной жизни', // Вторая линия заголовка шага
        MainHeader_class: 'bigHeader', // Большой или маленький Заголовок
        MainHeader_text: '', //Текст под заголовком
        typeMainBtn: 'take a lesson', // тип кнопок (take a lesson - Пройти урок, '' - стандартно медлу щагами)
        showNextBtn: true, // Показывать кнопку продолжить?
        showPrevBtn: true, // Показывать кнопку вернуться?
        showPopap: false, // Показ попапа на шаге
        modalImg: '', // Картинка на верху модалки
        modalImg2: '', // Картинка в теле модалки
        modalHead: '', // шапка в модалке
        modalText: '', // текст в модалке
        arrayStepsPlan: [] //массив шагов плана урока (lessonStepsActive)
        //showPopapAfterLesson: false
    }

    componentDidMount() {
        const {arrayStepsPlan} = this.state
        const {lesson} = this.props;
        let stepActive = lesson.steps
        stepActive = stepActive === lesson.attributes.steps ? 1 : stepActive + 1
        this.stepActiveStateUpdate(stepActive)
        // this.stepActiveStateUpdate(11)
        if (lesson.steps === lesson.attributes.steps) {
            // eslint-disable-next-line
            Object.values(this.settingStepsLesson).map((item) => {
                arrayStepsPlan.push(item.state.lessonStepsActive)
                this.setState({arrayStepsPlan: arrayStepsPlan})
            })
        }
    }

    // Переключалка шагов
    _renderStep = () => {
        const {stepActive} = this.state;
        const {lesson} = this.props;

        if (this.settingStepsLesson[stepActive] !== undefined) {
            return this.settingStepsLesson[stepActive].view;
        } else {
            lesson.plan = lesson.lessonPlan;
            lesson.MainHeader_header = 'Причины возникновения стресса у кошек в повседневной жизни';
            lesson.MainHeader_subheader = 'О кошках';
            this.props.history.push('/test', lesson);

        }
    }

    // Действие по кнопке продолжить
    showNextStep = () => {
        const {lesson} = this.props;
        let {stepActive} = this.state;
        this.Api.setLessonStats(lesson.attributes.id, stepActive)
        stepActive++;
        this.setStateBtnSteps(stepActive)
    }
    // Действие по кнопке вернутся
    showPrevStep = () => {
        let {stepActive} = this.state;
        stepActive--;
        this.setStateBtnSteps(stepActive)
    }
    // установка стайтов для смены шага
    setStateBtnSteps = stepActive => {
        this.setState({
            stepActive,
            showPopap: false
        });
        this.stepActiveStateUpdate(stepActive)
    }
    // показать попап на шаге.
    showPopap = () => {
        this.setState({
            showPopap: true
        });
    }

    showPopapAfterLesson = () =>{
        this.setState({
            showPopapAfterLesson:true
        })
    }


    closePopap = () => {
        this.setState({
            showPopap: false,
        })
    }

    // Состояния заголовков, кнопок, оглавления на текшем шаге
    stepActiveStateUpdate = stepActive => {
        this.setState({stepActive}, () => {
            if (this.settingStepsLesson[stepActive] !== undefined) {
                return this.setState({
                    MainHeader_header: this.settingStepsLesson[stepActive].state['MainHeader_header'],
                    MainHeader_subheader: this.settingStepsLesson[stepActive].state['MainHeader_subheader'],
                    MainHeader_class: this.settingStepsLesson[stepActive].state['MainHeader_class'],
                    MainHeader_text: this.settingStepsLesson[stepActive].state['MainHeader_text'],
                    typeMainBtn: this.settingStepsLesson[stepActive].state['typeMainBtn'],
                    showNextBtn: this.settingStepsLesson[stepActive].state['showNextBtn'],
                    lessonStepsActive: this.settingStepsLesson[stepActive].state['lessonStepsActive'],
                    modalImg: this.settingStepsLesson[stepActive].state['modalImg'],
                    modalImg2: this.settingStepsLesson[stepActive].state['modalImg2'],
                    modalHead: this.settingStepsLesson[stepActive].state['modalHead'],
                    modalText: this.settingStepsLesson[stepActive].state['modalText'],
                });
            }
        })
    }

    // Вызвать чтобы показать кнопку продолжить
    showNextBtn = () => {
        this.setState({
            showNextBtn: true
        })
    };

    render() {
        const {
            lessonStepsActive,
            typeMainBtn,
            showNextBtn,
            MainHeader_header,
            MainHeader_subheader,
            MainHeader_class,
            MainHeader_text,
            modalImg,
            modalImg2,
            modalHead,
            modalText,
            showPopap,
            //showPopapAfterLesson
        } = this.state;
        return (

            <div className="lesson">
                <div className="content_lesson">
                    <div className="step lesson13">
                        {/* Основной заголовок шага */}
                        <MainHeader
                            header={MainHeader_header}
                            subheader={MainHeader_subheader}
                            headerClass={MainHeader_class}
                            text={MainHeader_text}
                        />
                        {/* Вывод Шага урока */}
                        {this._renderStep()}
                    </div>
                    {/* Вывод Кнопок урока */}
                    <MainButton
                        type={typeMainBtn}
                        showPopap={this.showPopap}
                        showNextStep={this.showNextStep}
                        showPrevStep={this.showPrevStep}
                        //showPopapAfterLesson={this.showPopapAfterLesson}
                        showNextBtn={showNextBtn}
                        customBtnText={'Давайте узнаем'}
                    />
                    {/*{
                        showPopapAfterLesson && <PopapAfterTest history={this.props.history}/>
                    }*/}

                </div>
                {/* Модальное окно после шага */}
                {showPopap &&
                <ModalAfterStep
                    closePopap={this.closePopap}
                    modalImg={modalImg}
                    modalImg2={modalImg2}
                    modalHead={modalHead}
                    modalText={modalText}
                    type={'whiskas'}
                    showNextStep={this.showNextStep}
                />
                }
                {/* Вывод Оглавление урока */}
                <LessonPlan
                    lessonImg={this.props.lesson.attributes.image} // Картинка урока
                    lessonNumeral={parseInt(this.props.lesson.attributes.name.match(/\d+/), 10)} //Номер урока для планшета
                    lessonName={'Причины возникновения стресса у кошек в повседневной жизни'} // Название урока
                    lessonSteps={this.props.lesson.lessonPlan} // Шаги урока
                    lessonStepsActive={lessonStepsActive - 1} // Активный шаг урока
                    onClick={this.setStateBtnSteps}
                    arrayStepsPlan={this.state.arrayStepsPlan}
                />
            </div>

        )
    }

    settingStepsLesson = {
        1: {
            view: <Step1/>,
            state: {
                typeMainBtn: 'take a lesson',
                MainHeader_header: 'Урок 11',
                MainHeader_subheader: 'Причины возникновения стресса у кошек в повседневной жизни',
                MainHeader_class: 'bigHeader',
                lessonStepsActive: 1,
                MainHeader_text: ''
            }
        },
        2: {
            view: <Step2/>,
            state: {
                MainHeader_header: 'Что такое стресс в повседневной жизни?',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 2,
                showNextBtn: true,

            }
        },
        3: {
            view: <Step3/>,
            state: {
                MainHeader_header: 'Что такое стресс в повседневной жизни?',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'show popap',
                lessonStepsActive: 2,
                showNextBtn: true,
                modalText : "Далее в уроке мы рассмотрим самые распространённые факторы стресса для кошек и приведём практические рекомендации, как снизить уровень стресса у кошки и, соответственно, сделать жизнь животного более комфортной."
            }
        },
        4: {
            view: <Step4/>,
            state: {
                MainHeader_header: 'Как правильно адаптировать кошку к жизни в доме',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 2,
                showNextBtn: true,
            }
        },
        5: {
            view: <Step5 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Убежище',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 2,
                showNextBtn: false,
            }
        },
        6: {
            view: <Step6 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Желание вернуться на улицу',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 2,
                showNextBtn: false,


            }
        },
        7: {
            view: <Step7 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Игрушки и игры',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 3,
                showNextBtn: true,
            }
        },

        8: {
            view: <Step8 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: 'Как выбрать игрушку',
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'show popap',
                lessonStepsActive: 3,
                showNextBtn: true,
                modalText:`Определение любимой добычи должно занять всего несколько минут при игре с большинством кошек, но по мере того, как придет умение думать как кошка, измениться и мнение о предпочтениях кошки в добыче.`
            }
        },

        9: {
            view: <Step9 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Рекомендации по адаптации брошенной кошки к жизни в новом доме`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 4,
                showNextBtn: true,
            }
        },

        10: {
            view: <Step10 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Как облегчить кошке боязнь расставания`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 4,
                showNextBtn: false,
            }
        },

        11: {
            view: <Step11 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Знакомство кошки с новым человеком в семье`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 5,
                showNextBtn: true,
            }
        },

        12: {
            view: <Step12 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Знакомство кошки с ребенком`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 5,
                showNextBtn: true,
            }
        },

        13: {
            view: <Step13 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Смерть члена семьи или животного`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 5,
                showNextBtn: true,
            }
        },

        14: {
            view: <Step14 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Знакомство с гостями`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 5,
                showNextBtn: true,
            }
        },

        15: {
            view: <Step15 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Новая среда обитания`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        16: {
            view: <Step16 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Гостиница или передержка для кошек`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        17: {
            view: <Step17 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Перестановка мебели в доме`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        18: {
            view: <Step18 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Переезд в новый дом`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        19: {
            view: <Step19 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Ремонт в доме`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        20: {
            view: <Step20 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `События в доме и/или на территории вокруг дома`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        21: {
            view: <Step21 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Изменение расписания владельца`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        22: {
            view: <Step22 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Смена времен года`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        23: {
            view: <Step23 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Непогода`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },

        24: {
            view: <Step24 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Строительные и ремонтные работы вне дома`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 6,
                showNextBtn: true,
            }
        },
        25: {
            view: <Step25 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Знакомство вашей кошки с новыми домашними животными`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 7,
                showNextBtn: true,
            }
        },

        26: {
            view: <Step26 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Знакомство с новой кошкой`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 7,
                showNextBtn: true,
            }
        },

        27: {
            view: <Step27 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Знакомство с новой кошкой`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 7,
                showNextBtn: true,
            }
        },

        28: {
            view: <Step28 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Знакомство с новой собакой`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 7,
                showNextBtn: true,
            }
        },

        29: {
            view: <Step29 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Посещение ветеринарной клиники`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 8,
                showNextBtn: true,
            }
        },

        30: {
            view: <Step30 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Посещение ветеринарной клиники`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 8,
                showNextBtn: true,
            }
        },

        31: {
            view: <Step31 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Госпитализация кошки`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 8,
                showNextBtn: true,
            }
        },

        32: {
            view: <Step32 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Лечение и выздоровление дома`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 8,
                showNextBtn: true,
            }
        },

        33: {
            view: <Step33 showNextBtn={this.showNextBtn}/>,
            state: {
                MainHeader_header: `Путешествие с кошкой`,
                MainHeader_subheader: '',
                MainHeader_class: '',
                typeMainBtn: 'next step',
                lessonStepsActive: 9,
                showNextBtn: true,

            }
        },


    }

}

export default withRouter(Lesson13);
