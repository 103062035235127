import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson11/step29-1.png";
import img1xs from "../../../image/lessons/lesson11/step29-1xs.png";


export default class step29 extends React.Component {

    render() {
        return (
            <div className="step29">
                <TextBlock
                    text={`Рассмотрите таблицу. На ней показано, каким образом кормление животного пищей для людей влияет на суточное потребление калорий животным. Некоторые из этих популярных угощений составляют более половины суточного рациона животного!`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 600px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>

                <TextBlock
                    text={`<span class="text_violet">Можно сделать вывод, что частое угощение животных едой со стола ведет к ожирению и последующим проблемам со здоровьем.</span>`}
                />
            </div>
        )
    }
}
