import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson6/step13-1.png'

export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="head">
                    Кошачий насест и его роль для кошки
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<b>Если вы предпочитаете, чтобы ваша кошка использовала другое место, нежели то, которая она сама выберет для отдыха, вам будет необходимо предложить ей лакомства и ласку, когда вы отнесете вашу кошку в выбранное вами место.</b> Сделайте это место более заманчивым для кошки, если она откажется там оставаться, а затем позвольте ей приблизиться к нему самостоятельно. Положите любимое лакомство или игрушку вашей кошки на насест, чтобы кошка могла исследовать новое место. В любом случае она может просто использовать диван, приставной столик или верхнюю часть комода, чтобы сделать свой собственный насест.`}
                />
            </div>
        )
    }
}
