export default class Api {

    baseUrl = process.env.NODE_ENV === 'development' ? '/ajax/' : '/ajax/';
    // baseUrl = 'http://cat-friendly.loc/ajax/';
    //http://cats:7888/ajax/

    getResource = async (url, body) => {
        const res = await fetch(`${this.baseUrl}${url}`, {
            method: 'POST',
            withCredentials: true,
            headers: {
                'Accept': 'application/json, text/javascript, */*; q=0.01',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            // body: JSON.stringify(body)
            body: body !== undefined ? Object.keys(body).map(key => key + '=' + body[key]).join('&') : ''

        })
        return res.json(); // Возвращение промиса
    }

    sendFormData = async (url, body) => {
        const res = await fetch(`${this.baseUrl}${url}`, {
            method: 'POST',
            body: body,
            withCredentials: true,
        })
        return res.json(); // Возвращение промиса
    }

    /*getClubs = async (test) => {
        return await this.getResource('get-clubs')
    }*/

    getTypeUser = async (typeUser) => {
        return await this.getResource('get-type-user')
    }

    // Список курсов с уроками
    getLessonCourses = async () => {
        return await this.getResource('get-lesson-courses')
    }

    // Количество пройденных шагов в уроке
    // getActualStep = async (lesson) => {
    //     return await this.getResource(`get-accreditated-clinics?search=${lesson}`)
    // }

    // Подсчет статистики для уроков.
    setLessonStats = async (id, step) => {
        return await this.getResource('set-lesson-stats',{
            id: id,
            step: step,
        })
    }

    // Список курсов с тестами
    getTestCourses = async () => {
        return await this.getResource('get-test-courses')
    }

    startTest = async (lId) => {
        return await this.getResource(`test-step?lId=${lId}`)
    }

    checkAnswer = async (lId, qId, answers) => {
        return await this.getResource(`test-step?lId=${lId}`, {
            qId: qId,
            answers: [answers]
        })
    }

    getAccreditatedClinics = async () => {
        return await this.getResource('get-accreditated-clinics')
    }

    searchAccreditatedClinics = async (clinicSearch) => {
        return await this.getResource(`get-accreditated-clinics?search=${clinicSearch}`)
    }

    sortAccreditatedClinics = async (clinicSort) => {
        return await this.getResource(`get-accreditated-clinics?sort=${clinicSort}`)
    }

    registrationOwner = async (name, surname, patronymic, email, password, specialty, cityToSend, phone, clinicId, clinicToSend, addressOwn, ownAddress, universityId, universityOwn) => {
        let body = {
            first_name: name,
            last_name: surname,
            middle_name: patronymic,
            email: email,
            password: password,
            type: specialty,
            city: cityToSend,
            phone: phone,
            clinic_id: clinicId,
            clinic: clinicToSend,
            clinic_address: addressOwn,
            own_address: ownAddress,
            university_id: universityId,
            custom_university: universityOwn,
        }
        return await this.getResource('registration', body)
    };

    questionnaireForm = async (name, surname, patronymic, email, specialty, phone, city, birthday, placeWork, specialization, university, file, ext) => {
        let body = {
            name: name,
            surname: surname,
            middle_name: patronymic,
            email: email,
            type: specialty,
            phone: phone,
            city: city,
            birthday: birthday,
            work_place: placeWork,
            work_type: specialization,
            university: university,
            file: file,
            extension: ext,
        }
        return await this.getResource('questionnaire-form', body)
    };

    accreditationForm = async (name, surname, patronymic, email, specialty, phone, city, birthday, placeWork, specialization, university, file, ext) => {
        let body = {
            name: name,
            surname: surname,
            middle_name: patronymic,
            email: email,
            type: specialty,
            phone: phone,
            city: city,
            birthday: birthday,
            work_place: placeWork,
            work_type: specialization,
            university: university,
            file: file,
            extension: ext,
        }
        return await this.getResource('questionnaire-form', body)
    };

    updateUserData = async (name, surname, patronymic, email, specialty, cityToSend, phone, clinicId, clinicToSend, addressOwn, ownAddress) => {
        let body = {
            first_name: name,
            last_name: surname,
            middle_name: patronymic,
            email: email,
            type: specialty,
            city: cityToSend,
            phone: phone,
            clinic_id: clinicId,
            clinic: clinicToSend,
            clinic_address: addressOwn,
            own_address: ownAddress,
        }
        return await this.getResource('update-user-data', body)
    };

    /*updateAccreditation = async (inputs, inputSpec) => {
        let inputImg = [];

        Object.keys(inputs).map((item) => {
            if (item.indexOf('info_board') >=0 && item.indexOf('extension') === -1) {
                inputImg.push({
                    type: 'desk',
                    data: inputs[item],
                    ext: inputs[item + '_extension']
                })
            } else if (item.indexOf('info_poster') >=0 && item.indexOf('extension') === -1) {
                inputImg.push({
                    type: 'poster',
                    data: inputs[item],
                    ext: inputs[item + '_extension']
                })
            } else if (item.indexOf('info_site') >=0 && item.indexOf('extension') === -1) {
                inputImg.push({
                    type: 'information',
                    data: inputs[item],
                    ext: inputs[item + '_extension']
                })
            } else if (item.indexOf('info_wait') >=0 && item.indexOf('extension') === -1) {
                inputImg.push({
                    type: 'wait',
                    data: inputs[item],
                    ext: inputs[item + '_extension']
                })
            } else if (item.indexOf('info_screen') >=0 && item.indexOf('extension') === -1) {
                inputImg.push({
                    type: 'partition',
                    data: inputs[item],
                    ext: inputs[item + '_extension']
                })
            } else if (item.indexOf('info_nav') >=0 && item.indexOf('extension') === -1) {
                inputImg.push({
                    type: 'navigation',
                    data: inputs[item],
                    ext: inputs[item + '_extension']
                })
            }
            else if (item.indexOf('info_box') >=0 && item.indexOf('extension') === -1) {
                inputImg.push({
                    type: 'box',
                    data: inputs[item],
                    ext: inputs[item + '_extension']
                })
            }
            else if (item.indexOf('info_radius') >=0 && item.indexOf('extension') === -1) {
                inputImg.push({
                    type: 'radius',
                    data: inputs[item],
                    ext: inputs[item + '_extension']
                })
            }
        })

        Object.keys(inputs).map((item, index) => {
            if (item.indexOf('info') >= 0) {
                delete inputs[item]
            }
        })
        // inputSpec.map((item) => {
        //     inputSpecNew.push(Object.entries(item))
        // })
        let body = {
            ...inputs,
            specs: JSON.stringify(inputSpec),
            files: JSON.stringify(inputImg),
        }
        return await this.getResource('accreditation-form', body)
    };*/

    updateAccreditation = async (inputs, inputSpec) => {
        const body = new FormData()

        Object.keys(inputs).map((item, index) => {
            if (item.indexOf('error') === -1) {
                body.append(`${item}`, inputs[item])
            }
        })

        body.append(`specs`, JSON.stringify(inputSpec))

        return await this.sendFormData('accreditation-form', body)
    };

    updateAccreditationTest = async (inputs) => {
        let inputImg = [];

        Object.keys(inputs).map((item) => {
            if (item.indexOf('info_board') >=0 && item.indexOf('extension') === -1) {
                for (let i = 1; i <= 24; i++){
                    inputImg.push({
                        type: 'desk',
                        data: inputs[item],
                        ext: inputs[item + '_extension']
                    })
                }
            }
        })

        Object.keys(inputs).map((item, index) => {
            if (item.indexOf('info') >= 0) {
                delete inputs[item]
            }
        })
        // inputSpec.map((item) => {
        //     inputSpecNew.push(Object.entries(item))
        // })
        let body = {
            ...inputs,
            files: JSON.stringify(inputImg),
        }
        return await this.getResource('accreditation-form', body)
    };

    login = async (email, password) => {
        return await this.getResource('login', {
            email: email,
            password: password,
        })
    }

    requestPasswordReset = async (email) => {
        return await this.getResource('request-password-reset', {
            email: email
        })
    }

    logout = async () => {
        return await this.getResource('logout')
    }

    isGuest = async () => {
        return await this.getResource('is-guest')
    }

    getUserData = async () => {
        return await this.getResource('get-user-data')
    }

    getCourses = async () => {
        return await this.getResource('get-courses')
    }
    getCourse = async (id) => {
        return await this.getResource('get-course',{
            id: id
        })
    }

    getTestStepForLessonStep = async (lId, lStep) => {
        return await this.getResource(`test-step-for-lesson-step?lId=${lId}&lStep=${lStep}`)
    }
    getTestStepForLessonStepCheck = async (lId, lStep,qId, answers) => {
        return await this.getResource(`test-step-for-lesson-step?lId=${lId}&lStep=${lStep}`, {
            qId: qId,
            answers: [answers]
        })
    }

    getTowns = async () => {
        return await this.getResource('get-towns')
    }

    getUniversity = async () => {
        return await this.getResource('get-university')
    }

    getClinics = async (town) => {
        return await this.getResource(`get-clinics?town=${town}`)
    }

    getTopics = async () => {
        return await this.getResource('get-lesson-topics')
    }

    setUserPhoto = async (photo, extension) => {
        return await this.getResource('set-photo', {
            data: photo,
            ext: extension
        })
    }

    getUserStatistic = async () => {
        return await this.getResource('get-user-statistic')
    }

    sendFormCoocie = async (email, name, text) => {
        let body = {
            email: email,
            title: name,
            message: text
        }
        return await this.getResource(`send-cookie-mail`, body)
    }

    getVideoLecture = async () => {
        return await this.getResource('get-video-lecture')
    }

    setShowSertificate = async () => {
        return await this.getResource('set-show-sertificate?flag=0', )
    }
}

