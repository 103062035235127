import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";
import List from '../../../Components/LessonAndTestComponents/List';

import img1 from "../../../image/lessons/lesson43/step11-1.png";
import img2 from "../../../image/lessons/lesson43/step11-2.png";
import img3 from "../../../image/lessons/lesson43/step11-3.png";
import img4 from "../../../image/lessons/lesson43/step11-4.png";

export default class step11 extends React.Component {

    dataList = [
        `Скачайте музыку в личном кабинете после прохождения данного урока`,
        // `Добавьте плейлист «Жизнь замурррчательна» в избранное на любой из площадок и с легкостью возвращайтесь к нему в любой момент`,
        `Делитесь музыкой с владельцами кошек, чтобы сделать их жизнь замурррчательной`
    ]

    render() {
        return (
            <div className="step11">
                <hr className="string"/>
                <div className="head">Где найти музыку для кошек</div>

                <List
                    ListData={this.dataList}
                />

                <div className="link__container">
                    {/*<a className="link__block" href="https://music.yandex.ru/album/17191452" target="_blank">*/}
                    {/*    <img className="link__image" src={img1} alt=""/>*/}
                    {/*</a>*/}
                    {/*<a className="link__block" href="#">*/}
                    {/*    <img className="link__image" src={img2} alt=""/>*/}
                    {/*</a>*/}
                    {/*<a className="link__block" href="#">*/}
                    {/*    <img className="link__image" src={img3} alt=""/>*/}
                    {/*</a>*/}
                    {/*<a className="link__block" href="#">*/}
                    {/*    <img className="link__image" src={img4} alt=""/>*/}
                    {/*</a>*/}
                </div>

            </div>
        )
    }
}
