import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step51-1.png'


export default class step51 extends React.Component{

    render() {
        return (
            <div className="step51">
                <TextBlock
                    text={`Можно приобрести специальный насест, который крепится к подоконнику, можно поставить диван, тумбочку, стул или стол перед окном, чтобы кошка могла взобраться наверх и смотреть на улицу. Сделать окружающий пейзаж более привлекательным поможет кормушка для птиц во дворе или красивые цветы.
                    
                    Любимое лакомство или игрушка, предварительно помещенные на насест, привлекут внимание кошки, чтобы она могла исследовать новое место.
                    
                    При возникновении проблемы с поиском подходящего места для насеста, нужно понаблюдать за кошкой и выяснить, где она обычно сворачивается калачиком и сладко засыпает.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
