import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/belenson.png'
import img2 from '../../../image/Lecturers/belenson-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Когда действительно стоит подозревать что-то эндокринное у кошек?`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Беленсон'}
                    name={'Максим Михайлович'}
                    text={'Ветеринарный врач – эндокринолог. Сеть ветеринарных клиник «Белый клык»'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
