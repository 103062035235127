import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";
import UsedMaterials from "../../../Components/LessonAndTestComponents/UsedMaterials";

import img1 from "../../../image/Lecturers/namk.png";

export default class step1 extends React.Component {

    planData = [
        `Цели подхода Cat Friendly – С любовью к кошкам`,
        `Социальное поведение и коммуникация кошек`,
        `Ранние признаки проявления стресса и тревоги`,
        `Подготовка клиента и кошки к визиту в ветеринарную клинику`,
        `Общие принципы создания позитивной среды`,
        `Подготовка клиники`,
        `Взаимодействие с кошкой в ветеринарной клинике`,
        `Выписка`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Национальной ассоциацией медицины кошек'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке мы рассмотрим следующие темы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
                <UsedMaterials
                    head={'Использованные материалы:'}
                    text={'AAFP and ISFM Feline-Friendly Handling Guidelines//Journal of Feline Medicine and Surgery (2011) 13, 364–375. '}
                />
            </div>
        )
    }
}
