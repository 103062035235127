import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";
import UsedMaterials from "../../../Components/LessonAndTestComponents/UsedMaterials";

import img1 from "../../../image/Lecturers/namk.png";

export default class step1 extends React.Component {

    planData = [
        `Практические советы по уходу за больными кошками для ветеринарных врачей и владельца`,
        `Советы по домашнему уходу за питомцами для владельцев`,
        `Использование плана действий и комплексных мероприятий SMART`,
        `Выводы`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Национальной ассоциацией медицины кошек'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке мы расскажем об оборудовании для снижения стресса у кошек, перечислим практические советы по взаимодействию ветеринарных врачей и владельца кошки во время домашнего лечения питомца. В конце урока вы узнаете, как внедрить полученные знания на практике с помощью методологии SMART.  `}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
                <UsedMaterials
                    head={'Использованные материалы:'}
                    text={'AAFP and ISFM Feline-Friendly Nursing Care Guidelines//Journal of Feline Medicine and Surgery (2012) 14, 337–351'}
                />
            </div>
        )
    }
}
