import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step25 extends React.Component {

    render() {
        return (
            <div className="step25">
                <div className="head">
                    Обращение с кошкой в стационаре
                </div>
                <ul className="list">
                    <li className="list_item">
                        У кошки могут вызвать беспокойство ситуации, когда она понимает, что ее укрытие больше не предоставляет необходимого уровня защиты, а ее запах или местонахождение стали известны окружающим.
                    </li>
                    <li className="list_item">
                        Важно уметь распознавать любые признаки кошачьего стресса и быть готовым отреагировать соответствующим образом.
                    </li>
                </ul>
            </div>
        )
    }
}
