import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson33/step14-1.png";
import img2 from "../../../image/lessons/lesson33/step14-2.png";
import img2xs from "../../../image/lessons/lesson33/step14-2xs.png";
import img3 from "../../../image/lessons/lesson33/step14-3.png";
import img3xs from "../../../image/lessons/lesson33/step14-3xs.png";

export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`WHISKAS® для стерилизованных кошек – это <b>сбалансированный корм</b>, предназначенный для кормления кошки в течение всей жизни с момента стерилизации:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="advantages">
                            <div className="advantages_number">1</div>
                            <div className="advantages_text">
                                <b>Учитывает особые потребности</b> стерилизованных кошек;
                            </div>
                        </div>
                        <div className="advantages">
                            <div className="advantages_number">2</div>
                            <div className="advantages_text">
                                <b>Поддерживает здоровый вес при соблюдении рекомендаций по кормлению</b>:
                            </div>
                        </div>
                        <div className="advantages">
                            <div className="advantages_number">3</div>
                            <div className="advantages_text">
                                <b>Специальный баланс минералов, для здоровья мочевыделительной системы:</b>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                </div>
                {/*<picture>*/}
                {/*    <source srcSet={img2xs} media="(max-width: 767px)"/>*/}
                {/*    <img src={img2} alt="" className="imgCenter"/>*/}
                {/*</picture>*/}
                <picture>
                    <source srcSet={img3xs} media="(max-width: 767px)"/>
                    <img src={img3} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
