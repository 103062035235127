import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from '../../../Components/LessonAndTestComponents/Lecturer';

import img1 from '../../../image/Lecturers/smirnova.png'
import img2 from '../../../image/Lecturers/smirnova-icon.png'

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Разнообразный гиперальдостеронизм у кошек`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Смирнова'}
                    name={'Ольга Олеговна'}
                    text={'Ветеринарный врач-эндокринолог, ветеринарная клиника доктора Сотникова, г. Санкт-Петербург'}
                />

                <hr className="string"/>

                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке вы узнаете:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
