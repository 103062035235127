import React from 'react'
import './style.sass'


export default class StickyBlock extends React.Component{
    //Компонент нужно улучшить сделав более универсальным. В пропсы передать элемент, относительно которого
    // начинает спускаться элемент и передавать первоначальные координаты stickyBlock. Так же желательно передать еще до какого элемента прокручивается плавающий блок
    //Этот пример лежит в анкете акредитации

    state = {
        stickyTop: 0
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const parentsTop = document.querySelector('.step2__inputs')
        const parentsOffset = parentsTop.getBoundingClientRect().top

        if (parentsOffset < 100) {
            this.setState({
                stickyTop: -parentsOffset,
            })
        }
    }

    render() {
        const {stickyTop} = this.state
        return (
            <div className={"StickyBlock"} style={{top: stickyTop}}>
                {this.props.children}
            </div>
        )
    }
}
