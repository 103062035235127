import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step16-1.png";

export default class step16 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Когда кошка оказывается в гостинице или на передержке, она вынуждена столкнуться с незнакомой обстановкой и ранее незнакомыми ей людьми и животными. При этом привычный график
                            и распорядок дня кошки также меняются.
                        </li>
                        <li>
                            Нужно взять с собой кошачью еду, одеяло, игрушки и несколько предметов одежды владельца. Запах одежды будет успокаивать кошку, поскольку он будет для нее знакомым и поможет обрести чувство, что владелец рядом.
                        </li>
                        <li>
                            Когда владелец  будет забирать кошку обратно домой, следует убедиться в наличии необходимого убежища. Кошки узнают друг друга по запаху. Когда кошка возвращается домой, другие кошки могут относиться к ней как к чужаку, поскольку от нее будет исходить чужой запах. Возможно, придется заново поэтапно познакомить ее с другими обитателями вашего дома, чтобы избежать возможного возникновения конфликтных ситуаций
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
