import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";
import UsedMaterials from "../../../Components/LessonAndTestComponents/UsedMaterials";

import img1 from "../../../image/Lecturers/namk.png";

export default class step1 extends React.Component {

    planData = [
        `Знакомство с потребностями кошек`,
        `Главные факты о кошках`,
        `Основы обучения кошек`,
        `Основы выбора кошачьего лотка и наполнителя и приучения кошки к лотку`,
        `Кошки и игрушки`,
        `Создание убежища для кошки`,
        `Урегулирование конфликтов`,
        `Повышение активности кошки`,
        `Когтеточка и уход за когтями`,
        `Организация зоны отдыха для кошки и приучение к переноске`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Национальной ассоциацией медицины кошек'}
                />
                <hr className="string"/>
                <TextBlock
                    text={`Для повышения качества жизни домашних кошек нами было разработано руководство по их грамотному содержанию. У кошек, как и у всех живых существ, есть основные потребности. Своевременное удовлетворение этих потребностей поможет обеспечить долгосрочное здоровье и благополучие кошки.`}
                />
                <div className="head_first_step">План урока</div>

                <TextBlock
                    text={`В этом уроке мы рассмотрим следующие темы:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
                <UsedMaterials
                    head={'Использованные материалы:'}
                    text={'Basic Indoor Cat Needs. The Ohio State University: College of Veterinary Medicine.'}
                />
            </div>
        )
    }
}
