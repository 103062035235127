import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step3-1.png";

export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <div className="text-top">
                    <p>
                        Есть кошки, которые были брошены своими владельцами или отбились от дома и заблудились. <b>Брошенные животные</b> хорошо знакомы с человеческим обществом, поэтому в обычных ситуациях они могут успешно адаптироваться к новому жилищу. Брошенная кошка может чувствовать себя комфортно приближаясь к человеку, может позволять погладить себя и с большей вероятностью будет возвращаться к месту встречи, если оставлять ей еду.
                    </p>

                    <div className="warning">
                        <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.9994 30.8952C22.6575 30.8952 21.5326 32.0201 21.5326 33.362C21.5326 34.7039 22.6575 35.8288 23.9994 35.8288C25.292 35.8288 26.4662 34.7039 26.407 33.4212C26.4662 32.0102 25.3512 30.8952 23.9994 30.8952Z" fill="#CC3333"/>
                            <path d="M46.832 39.6375C48.3811 36.9635 48.391 33.7764 46.8517 31.1123L31.3997 4.35263C29.8703 1.6589 27.1075 0.0604248 24.0093 0.0604248C20.911 0.0604248 18.1482 1.66877 16.6188 4.34276L1.1471 31.132C-0.392172 33.8258 -0.382304 37.0326 1.1767 39.7066C2.71598 42.351 5.46891 43.9396 8.54746 43.9396H39.4119C42.5003 43.9396 45.2729 42.3312 46.832 39.6375ZM43.4771 37.7036C42.6187 39.1836 41.0991 40.0618 39.402 40.0618H8.53759C6.86018 40.0618 5.3505 39.2034 4.5118 37.7529C3.66322 36.2827 3.65335 34.5263 4.50193 33.0463L19.9736 6.26685C20.8123 4.79665 22.3121 3.92834 24.0093 3.92834C25.6965 3.92834 27.2062 4.80652 28.0449 6.27672L43.5067 33.0561C44.3356 34.4967 44.3257 36.2334 43.4771 37.7036Z" fill="#CC3333"/>
                            <path d="M23.3876 13.5784C22.2134 13.9139 21.4833 14.9795 21.4833 16.2721C21.5425 17.0516 21.5918 17.841 21.651 18.6205C21.8188 21.5905 21.9865 24.5013 22.1542 27.4713C22.2134 28.4778 22.9929 29.208 23.9994 29.208C25.0058 29.208 25.7952 28.4284 25.8445 27.4121C25.8445 26.8004 25.8445 26.2379 25.9038 25.6163C26.0123 23.712 26.1307 21.8076 26.2392 19.9032C26.2984 18.6698 26.407 17.4365 26.4662 16.2031C26.4662 15.759 26.407 15.3644 26.2392 14.9697C25.736 13.8645 24.5618 13.3021 23.3876 13.5784Z" fill="#CC3333"/>
                        </svg>

                        <p>
                            <b>Одичавшие домашние кошки</b> – это кошки, которые прожили всю свою жизнь практически без контакта с человеком. Такие кошки, как правило, избегают и опасаются людей, и их адаптация к жизни с человеком – длительный и трудоемкий процесс. Взрослые одичавшие кошки избегают людей и не могут быть легко социализированы.

                        </p>
                    </div>
                    <p>
                      <b>  Главное в адаптации кошек к новому дому – быть терпеливыми и сделать так, чтобы кошка стала не только счастливой, но и была постоянно чем-то занята. В этом помогут игры.</b>
                    </p>
                </div>

            </div>
        )
    }
}
