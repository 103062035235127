import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step39-1.png'


export default class step39 extends React.Component{

    render() {
        return (
            <div className="step39">
                <TextBlock
                    text={`Кошки обычно требуют и используют гораздо больше пространства, чем им позволяет дом или квартира средних размеров. Добавление трехмерных пространств, таких как кошачьи насесты, картонные коробки, кровати или ящики, может помочь уменьшить конфликт.
                    
                    Кошки, вовлеченные в конфликтную ситуацию, могут больше никогда не стать «лучшими друзьями», но они могут уживаться вместе без признаков конфликта.
                    
                    Можно обратиться за помощью к ветеринарному врачу. В тяжелых ситуациях некоторым кошкам могут помочь препараты, корректирующие поведение.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
