import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/voiteha.png'
import img2 from '../../../image/Lecturers/voiteha-icon.png'
import img3 from '../../../image/Lecturers/shilkin.png'
import img4 from '../../../image/Lecturers/shilkin-icon.png'
import img5 from '../../../image/Lecturers/barinina.png'
import img6 from '../../../image/Lecturers/barinina-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Войтеха'}
                    name={'Мария Александровна'}
                    text={'Ветеринарный врач-офтальмолог, кандидат биологических наук, микрохирург Центра ветеринарной офтальмологии доктора Шилкина А.Г., г. Москва.'}
                />

                <Lecturer
                    img={img3}
                    icon={img4}
                    surname={'Шилкин'}
                    name={'Алексей Германович'}
                    text={'Ветеринарный врач-офтальмолог, кандидат медицинских наук, доцент, руководитель Центра ветеринарной офтальмологии доктора Шилкина А.Г., г. Москва.'}
                />

                <Lecturer
                    img={img5}
                    icon={img6}
                    surname={'Барынина'}
                    name={'Юлия Сергеевна'}
                    text={'Ветеринарный врач Центра ветеринарной офтальмологии доктора Шилкина А.Г., г. Москва.'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/xOe4LYi_cfQ'}
                    title={'https://www.youtube.com/watch?v=xOe4LYi_cfQ&feature=youtu.be'}
                />
            </div>
        )
    }
}
