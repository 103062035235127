import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step2-1.png";

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <div className="text-top">
                    <p><b>Стрессоры</b> – это события и изменения в окружающей среде кошки, которые могут повлиять
                    на ее дальнейшее благополучие. Исследования показывают, что некоторые кошки необычайно чувствительны к своему окружению. Такие кошки могут достаточно болезненно реагировать
                    на жизненные стрессоры, становясь агрессивными, нервными или боязливыми. <br/><br/>
                    </p>
                    <p>
                        Особенно восприимчивые кошки могут даже заболеть или развить в себе поведенческие проблемы, такие как агрессия.
                    </p>
                </div>
                
                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
