import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson7/step13-1.png'

export default class step13 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 6) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 6) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {

            text: `Большинство кошек предпочитает мелкозернистый наполнитель без запаха.`,
        },
        2: {
            text: `Комкующийся наполнитель имеет более мелкие гранулы, чем обычные глиняные наполнители, поэтому их легко содержать в чистоте ежедневно.`,
        },
        3: {
            text: `Многих кошек отпугивает запах ароматных или дезодорированных кошачьих наполнителей, поэтому нельзя размещать дезодоранты или освежители воздуха рядом.`,
        },
        4: {
            text: `Наполнитель из кошачьих лотков необходимо менять минимум раз в день, а также мыть сам лоток раз в неделю мягким моющим средством для мытья посуды.`,
        },
        5: {
            text: `После подбора подходящего наполнителя для кошки нельзя менять его тип или марку, иначе кошка может отказаться от лотка.`,
        },
        6: {
            text: `Необходимо наполнять лоток достаточным количеством наполнителя, чтобы кошка могла царапать днище лотка и закапывать свои отходы (обычно около 5 см в глубину).`,
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step13">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о правилах пользования кошачьим наполнителем.`}
                />
                <div className="box">
                    <div className="box_img">
                        {Object.keys(this.popupData).slice(1, 7).map(item => {
                            return (
                                <div
                                    className={'box_point ' + (points.indexOf(Number(item)) >= 0 && 'active ') + (pointActive === Number(item) && 'heigher')}
                                    onClick={() => {this.showPopup(Number(item))}}
                                >
                                    <div className="box_point_inner"/>
                                </div>
                            )
                        })}
                        {Object.keys(this.popupData).slice(1, 7).map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'block red ' + (pointActive === Number(item) && 'active')}
                                >
                                    <TextBlock
                                        text={this.popupData[Number(item)].text}
                                    />
                                </div>
                            )
                        })}
                        <img src={img1} className="imgCenter" alt=""/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <div className="info_point">
                            <div className="info_point_inner"/>
                        </div>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet btn_center"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
