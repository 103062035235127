import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

export default class step28 extends React.Component {

    render() {
        return (
            <div className="step28">
                <div className="head">
                    Кошачьи клетки в ветеринарной клинике
                </div>
                <TextBlock
                    text={`Оптимальная кошачья клетка должна быть достаточно большой, чтобы вместить переноску.
                    Кошачий лоток должен располагаться вдали от пищи, кошачьей подстилки и воды.
                    
                    Клетки лучше расположить на уровне пояса и выше в ряд, чтобы кошки не видели друг друга. Клетка должна обеспечивать безопасное убежище для кошки. Можно накрыть переднюю часть клетки полотенцем.
                    В клетке должна быть контролируемая температура и звукоизоляция.
                    Стеклопластиковые клетки более теплые, менее светоотражающие и более тихие.
                    
                    В клетке должно находиться полотенце и/или игрушки из дома.
                    Необходимо наличие предпочитаемого кошкой корма и любимой подстилки`}
                />
            </div>
        )
    }
}
