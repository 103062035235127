import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step4-1.png";

export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`Миски с едой и кошачьи лотки должны располагаться на значительном расстоянии друг от друга, также они должны быть размещены:`}
                />
                <ul className="list">
                    <li className="list_item">подальше от бытовой техники и воздуховодов, которые могут неожиданно включиться во время принятия пищи или туалета;</li>
                    <li className="list_item">так, чтобы другое животное (или человек!) не могли подкрасться к кошке во время использования.</li>
                </ul>
                <TextBlock
                    text={`Корм и вода должны быть всегда свежими. Наполнитель в кошачьем лотке необходимо менять каждый день.<br>Стоит отметить, что некоторые кошки не любят пить там же, где они принимают пищу. Поэтому миски с едой и водой для таких кошек стоит размещать на расстоянии друг от друга.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
