import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <div className="head">
                    Рекомендации владельцу
                </div>
                <ul className="list">
                    <li className="list_item">
                        научитесь правильному обращению с лапами и осмотру ушей, чтобы надлежащим образом подготовиться к осмотру ушей вашей кошки и обрезке когтей в ветеринарной клинике;
                    </li>
                    <li className="list_item">
                        постарайтесь сделать так, чтобы ваша кошка воспринимала процесс открывания рта для осмотра ротовой полости как то действие, за которым последует поощрение вкусным лакомством;
                    </li>
                    <li className="list_item">
                        уделяйте внимание поглаживанию лап и шерсти на туловище, чтобы ваша кошка была готова к медицинскому осмотру;
                    </li>
                    <li className="list_item">
                        не забывайте про важность регулярного ухода за шерстью;
                    </li>
                    <li className="list_item">
                        проводите регулярные медицинские процедуры в домашних условиях (например, введение предписанных медикаментов для профилактики паразитов, измерение уровня глюкозы в крови у кошек, больных сахарным диабетом).
                    </li>
                </ul>
            </div>
        )
    }
}
