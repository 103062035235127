import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson31/step14-1.png';


export default class step14 extends React.Component {


    render() {
        return (
            <div className="step14">
                <div className="head">
                    Классификация по балансу питательных веществ
                </div>
                <TextBlock
                    text={`Как же определить, является ли корм сбалансированным или полнорационным? Для этого необходимо взглянуть на упаковку — на ней должны быть указаны следующие фразы: «Рацион сбалансирован» или «Полнорационный корм».`}
                />
                <img className="img" src={img1} alt=""/>
                <div className="advice">
                    Если вышеуказанные слова отсутствуют на упаковке, значит, корм является несбалансированным и неполнорационным, т.е. не может использоваться как основной рацион. Как правило, такой корм является лакомством и может использоваться как дополнение к основному корму для решения определенных задач.
                </div>
            </div>
        )
    }
}
