import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";

import img1 from "../../../image/lessons/lesson32/step1-1.svg";

export default class step1 extends React.Component {

    planData = [
        `Информация, содержащаяся на упаковках готовых кормов для животных`,
        `Ингредиенты, используемые в промышленных кормах`,
        `Интерпретация сырьевого состава корма`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Отделом научной коммуникации компании «МАРС»'}
                    text={'Использованы материалы научно-исследовательского института Waltham. <a class="text_link" href="https://www.waltham.com" target="_blank" rel="noopener noreferrer">https://www.waltham.com</a>'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`Промышленные корма удовлетворяют все потребности животных, так как они изготавливаются по точным расчетам и с использованием высококачественных ингредиентов. В этом уроке вы научитесь правильно интерпретировать информацию о свойствах корма с упаковок, а также узнаете больше о составе кормов.`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
            </div>
        )
    }
}
