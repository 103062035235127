import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step17-1.jpg";

export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <div className="head">
                    Кошачьи переноски
                </div>
                <TextBlock
                    text={`Существует множество разновидностей и конструкций кошачьей переноски.
                    Для примера рассмотрим кошачью переноску, изображенную на фотографиях.
                    Она застегивается на молнию.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}