import React from 'react'
import './style.sass'

export default props => {
    return (
        <div className={'VideoBlock ' + (props.modify ? props.modify : '')}>
            <iframe title={props.title} width="560" height="315" src={props.src} id={(props.id ? props.id : '')} frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
        </div>
    )
}
