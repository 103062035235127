import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson12/step11-1.svg'

export default class step11 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        showPopup: false,
        points: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    showPopup = (pointActive) => {
        const {points, showPopup, windowWidth} = this.state;
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            if (!showPopup) {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                })
            } else if (pointActive >= 4) {
                this.setState({showPopup: false, pointActive: 0})
            } else {
                this.setState({
                    pointActive: pointActive +1,
                    showPopup: true,
                })
            }
        } else {
            this.setState({points: points, pointActive: pointActive})
        }
        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    popupData = {
        0: {
            text: '',
        },
        1: {

            head: `Положение хвоста`,
            text: `Хвост расположен близко к туловищу; загибается вперед близко к туловищу в положении стоя.`,
        },
        2: {
            head: `Расположение тела`,
            text: `Кошка лежит на животе или приседает на поверхность всех лап, может трястись; в положении стоя туловище расположено близко к земле, кошка может трястись.`,
        },
        3: {
            head: `Состояние дыхания`,
            text: `Учащенное.`,
        },
        4: {
            head: `Положение лап`,
            text: `Лапы согнуты; в положении стоя лапы согнуты близко к поверхности.`,
        },
    }

    render() {
        const {showPopup, pointActive, points} = this.state
        return (
            <div className="step11">
                <div className="head">
                    Активность
                </div>
                <div className="text-top">
                    Кошка находится в неподвижном состоянии, насторожена или начинает ползти.
                </div>
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше про кошку в состоянии легкого испуга.`}
                />
                <div className="box">
                    <div className="box_img">
                        <div
                            className={'box_point ' + (points.indexOf(1) >= 0 && 'active ') + (pointActive === 1 && 'heigher')}
                            onClick={() => {this.showPopup(1)}}
                        >
                            <div className="box_point_inner"/>
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(2) >= 0 && 'active ') + (pointActive === 2 && 'heigher')}
                            onClick={() => {this.showPopup(2)}}
                        >
                            <div className="box_point_inner"/>
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(3) >= 0 && 'active ') + (pointActive === 3 && 'heigher')}
                            onClick={() => {this.showPopup(3)}}
                        >
                            <div className="box_point_inner"/>
                        </div>
                        <div
                            className={'box_point ' + (points.indexOf(4) >= 0 && 'active ') + (pointActive === 4 && 'heigher')}
                            onClick={() => {this.showPopup(4)}}
                        >
                            <div className="box_point_inner"/>
                        </div>
                        <div className={'block red ' + (pointActive === 1 && 'active')}>
                            <div className="block_head">
                                {this.popupData[1].head}
                            </div>
                            <TextBlock
                                text={this.popupData[1].text}
                            />
                        </div>
                        <div className={'block green ' + (pointActive === 2 && 'active')}>
                            <div className="block_head">
                                {this.popupData[2].head}
                            </div>
                            <TextBlock
                                text={this.popupData[2].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 3 && 'active')}>
                            <div className="block_head">
                                {this.popupData[3].head}
                            </div>
                            <TextBlock
                                text={this.popupData[3].text}
                            />
                        </div>
                        <div className={'block ' + (pointActive === 4 && 'active')}>
                            <div className="block_head">
                                {this.popupData[4].head}
                            </div>
                            <TextBlock
                                text={this.popupData[4].text}
                            />
                        </div>
                        <img src={img1} className="imgCenter" alt=""/>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className={'info'}>
                        <div className="info_head">
                            {this.popupData[pointActive].head}
                        </div>
                        <TextBlock
                            text={this.popupData[pointActive].text}
                        />
                    </div>
                    <button
                        className="btn btn_violet"
                        onClick={() => {this.showPopup(pointActive)}}
                    >
                        Продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
