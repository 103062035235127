import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson43/step10-1.png";

export default class step10 extends React.Component {

    planData = [
        `Что такое музыка для кошек`,
        `Как появилась идея создания музыки для кошек`,
        `Почему музыка для кошек важна в рамках подхода Cat Friendly`,
        `Где найти музыку для кошек`,
    ]

    render() {
        return (
            <div className="step10">
                <hr className="string"/>
                <div className="head">Где найти музыку для кошек</div>

                <TextBlock
                    text={`<span>«Жизнь замурррчательна»</span> — много минут приятной музыки, разработанной специально для кошек совместно с WHISKAS®, включающих звуки, которые отзовутся в их сердцах. Включите альбом кошке, чтобы минимизировать у нее уровень стресса во время приема. `}
                />

                <img src={img1} className="imgCenter" alt=""/>
            </div>
        )
    }
}
