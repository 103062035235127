import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson7/step50-1.png'


export default class step50 extends React.Component{

    render() {
        return (
            <div className="step50">
                <TextBlock
                    text={`При организации насеста необходимо учитывать, что:`}
                />
                <ul className="list">
                    <li className="list_item">
                        Кошки должны знать, что происходит вокруг них и что они находятся в безопасности. Незнакомый звук или шум, странный запах могут сильно напугать их, если они не видят сам предмет, от которого исходит этот шум или запах.
                    </li>
                    <li className="list_item">
                        Кошкам нравятся предметы или вещи, позволяющие им забираться достаточно высоко в доме.
                    </li>
                    <li className="list_item">
                        Кошки любят лазить, поэтому специальные перекладины или насесты на возвышенности обеспечат им безопасное и уединенное место, с которого они смогут наблюдать за происходящим сверху.
                    </li>
                </ul>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
