import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step7-1.png";

export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <div className="listNum">
                    <span className="listNum_number">1.</span>
                    Большинство домашних животных, например собаки, лошади, коровы и овцы, предпочитают жить группами (стаями, табунами, стадами). Это свойственно и человеку как виду. Кошки же по своей природе одиночки. Это различие означает, что:
                    <div className="letterList">
                        <span className="letterList_letter">a.</span>
                        Мы идем к другим людям для общения и совместного времяпрепровождения. Как правило, наши друзья отвечают нам взаимностью и охотно идут на контакт. Кошки же предпочитают САМИ приходить к нам на СВОИХ условиях.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">b.</span>
                        Мы предпочитаем более длительные визиты. Кошки предпочитают более короткие и частые визиты.
                    </div>
                    <div className="letterList">
                        <span className="letterList_letter">c.</span>
                        Мы рассматриваем крики и наказания как форму «разрешения конфликтов». Кошки видят в них угрозу для своей жизни.
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
