import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step20-1.png";


export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <TextBlock
                    text={`Кормящие матери и другие самки в их группе воспитывают котят. Котята начинают пробовать добычу матери еще во время кормления грудью и начинают охотиться в одиночку в возрасте <b>от 8 до 16 недель</b>. Они обычно отдыхают и ухаживают за шерстью вместе со своими матерями, пока им не исполнится <b>от 6 до 12 месяцев</b>.
                    
                    Взрослые самцы, патрулирующие территорию дома, в котором живет мать, обычно выселяют малолетних самцов. При этом малолетние самки могут уйти сами, если запасы пищи невелики. Взрослые особи создают свою собственную домашнюю территорию (ареал обитания) и могут быть доминирующими или, наоборот, подчиняться другим кошкам в тех частях своего ареала, которые соседствуют с ареалом обитания других кошек.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
