import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step19-1.jpg";

export default class step19 extends React.Component {

    render() {
        return (
            <div className="step19">
                <div className="head">
                    Обучение кошки пользоваться переноской
                </div>
                <TextBlock
                    text={`Цель в том, чтобы кошка научилась регулярно заходить в переноску добровольно. Сделайте кошачью переноску привычной частью мебели у вас дома.
                    
                    Поместите лакомства, кошачью мяту и/или игрушки в открытую кошачью переноску, чтобы ваша кошка захотела зайти в переноску самостоятельно.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                {/*<div className="center-text">*/}
                {/*    Спокойный котенок с любимой игрушкой.*/}
                {/*</div>*/}
            </div>
        )
    }
}