import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson32/step17-1.png';
import img2 from '../../../image/lessons/lesson32/step17-2.png';
import img3 from '../../../image/lessons/lesson32/step17-3.png';


export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`Соблюдение качества продукта и его безопасности является главным приоритетом при производстве кормов. В процессе изготовления корма необходимо регулярно осуществлять контроль качества.
                    
                    Например, в компании Mars Petcare используется система HACCP (Hazard Analysis & Critical Control Points — система непрерывного анализа рисков с помощью критических контрольных точек, установленных на производстве), которая выявляет, оценивает и контролирует риски, существенные для безопасности выпускаемой продукции.
                    
                    Давайте еще раз повторим, что такое качество.<br/><b>Качество — это:</b>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img className="img" src={img1} alt=""/>
                        <p className="flex_text">
                            cоответствие продукта его задаче
                        </p>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img2} alt=""/>
                        <p className="flex_text">
                            cоответствие установленным спецификациям
                        </p>
                    </div>
                    <div className="flex_el">
                        <img className="img" src={img3} alt=""/>
                        <p className="flex_text">
                            отсутствие видимых и скрытых дефектов
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
