import React from 'react';
import './style.sass'
import Whiskas from '../../image/LeftSideBar/whiskas.png'
import PerfectFit from '../../image/LeftSideBar/perfect-fit.png'


export default function Partners(props) {
    return (
        <div className={"partners " + props.className}>
            Партнёр проекта <br style={(props.brStyle)}/>
            MARS Petcare
            <div className={"partners__box"}>
                <a href="https://whiskas.ru/" target="_blank" rel="noopener noreferrer"><img src={Whiskas} alt=""/></a>
                <a href="https://www.perfectfit.ru/" target="_blank" rel="noopener noreferrer"><img src={PerfectFit} alt=""/></a>
            </div>
        </div>
    );
}
