import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';


export default class step25 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <p>
                        Знакомство кошки с новыми домашними животными должно быть надлежащим образом организовано и продумано до мелочей. Это означает, что процесс знакомства и представления кошке новых питомцев должен осуществляться медленно и осторожно.
                    </p>
                    <p className="warn">
                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.51485 10.4677C0.706931 10.4677 0.0297028 11.1449 0.0297028 11.9528C0.0297028 12.7607 0.706931 13.438 1.51485 13.438C2.29307 13.438 3 12.7607 2.96436 11.9885C3 11.139 2.32871 10.4677 1.51485 10.4677Z" fill="#CC3333"/>
                            <path d="M1.14653 0.0419281C0.439603 0.243908 0 0.885493 0 1.66371C0.0356436 2.13302 0.0653468 2.60826 0.10099 3.07757C0.20198 4.86569 0.30297 6.61816 0.40396 8.40628C0.439604 9.01222 0.908911 9.45183 1.51485 9.45183C2.12079 9.45183 2.59604 8.98252 2.62574 8.37064C2.62574 8.00232 2.62574 7.66371 2.66139 7.28945C2.72673 6.14291 2.79802 4.99638 2.86337 3.84985C2.89901 3.10727 2.96436 2.3647 3 1.62213C3 1.3548 2.96436 1.11717 2.86337 0.879551C2.5604 0.214205 1.85346 -0.124408 1.14653 0.0419281Z" fill="#CC3333"/>
                        </svg>

                        Не стоит торопиться, поскольку так можно будет избежать возникновения враждебности
                        и возможных конфликтных ситуаций между животными.
                    </p>
                    <p className="warn">
                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.51485 10.4677C0.706931 10.4677 0.0297028 11.1449 0.0297028 11.9528C0.0297028 12.7607 0.706931 13.438 1.51485 13.438C2.29307 13.438 3 12.7607 2.96436 11.9885C3 11.139 2.32871 10.4677 1.51485 10.4677Z" fill="#CC3333"/>
                            <path d="M1.14653 0.0419281C0.439603 0.243908 0 0.885493 0 1.66371C0.0356436 2.13302 0.0653468 2.60826 0.10099 3.07757C0.20198 4.86569 0.30297 6.61816 0.40396 8.40628C0.439604 9.01222 0.908911 9.45183 1.51485 9.45183C2.12079 9.45183 2.59604 8.98252 2.62574 8.37064C2.62574 8.00232 2.62574 7.66371 2.66139 7.28945C2.72673 6.14291 2.79802 4.99638 2.86337 3.84985C2.89901 3.10727 2.96436 2.3647 3 1.62213C3 1.3548 2.96436 1.11717 2.86337 0.879551C2.5604 0.214205 1.85346 -0.124408 1.14653 0.0419281Z" fill="#CC3333"/>
                        </svg>

                        Не стоит оставлять животных вместе без присмотра в момент знакомства.

                    </p>
                    <p className="warn">
                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.51485 10.4677C0.706931 10.4677 0.0297028 11.1449 0.0297028 11.9528C0.0297028 12.7607 0.706931 13.438 1.51485 13.438C2.29307 13.438 3 12.7607 2.96436 11.9885C3 11.139 2.32871 10.4677 1.51485 10.4677Z" fill="#CC3333"/>
                            <path d="M1.14653 0.0419281C0.439603 0.243908 0 0.885493 0 1.66371C0.0356436 2.13302 0.0653468 2.60826 0.10099 3.07757C0.20198 4.86569 0.30297 6.61816 0.40396 8.40628C0.439604 9.01222 0.908911 9.45183 1.51485 9.45183C2.12079 9.45183 2.59604 8.98252 2.62574 8.37064C2.62574 8.00232 2.62574 7.66371 2.66139 7.28945C2.72673 6.14291 2.79802 4.99638 2.86337 3.84985C2.89901 3.10727 2.96436 2.3647 3 1.62213C3 1.3548 2.96436 1.11717 2.86337 0.879551C2.5604 0.214205 1.85346 -0.124408 1.14653 0.0419281Z" fill="#CC3333"/>
                        </svg>

                        Необходимо позаботиться, чтобы в этот ответственный момент животные не чувствовали себя
                        в одиночестве или брошенными.
                    </p>
                    <p>
                        Далее представлены соответствующие рекомендации, которым можно следовать, чтобы знакомство новых домашних животных с кошкой прошло наилучшим образом.
                    </p>
                </div>

            </div>
        )
    }
}
