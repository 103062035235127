import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`Кошки, как известно, очень чувствительны к своему окружению и имеют хорошо развитую реакцию «дерись или беги». Эти реакции самозащиты, обычно необходимые для выживания, могут оказать негативный эффект при лечении в ветеринарной клинике или домашних условиях, либо когда они сохраняются в течение длительного периода времени.
                    
                    Незнакомые обстоятельства или окружение, с которыми кошки сталкиваются в ветеринарных клиниках, могут привести к неблагоприятным последствиям, выраженным в виде появления тревоги и страха.
                    
                    Эти неблагоприятные эффекты подавляют нормальное поведение (например, отдых и питание) и повышают бдительность, скрытность и дисфункциональные признаки, проявляющиеся у животного, такие как анорексия, рвота и диарея или даже отсутствие элиминации болезни.`}
                />
            </div>
        )
    }
}
