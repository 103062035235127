import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';


export default class step20 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <p>
                        Кошки не всегда понимают, какие перемены происходят в их жизни. В непогоду может лить сильный дождь и греметь гром, а времена года могут сменять друг друга. Расписание владельца и распорядок дня кошки также могут поменяться.
                    </p>
                    <p>
                        Все эти перемены в жизни кошки могут по-настоящему встревожить кошку. Далее приведен список некоторых событий в доме и на территории вокруг дома, а также предложены некоторые рекомендации по снижению стресса  кошки.
                    </p>
                </div>


            </div>
        )
    }
}
