import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step5 extends React.Component {


    render() {
        return (
            <div className="step5">
                <hr className="string"/>
                <div className="head">Как появилась идея создания музыки для кошек</div>

                <TextBlock
                    text={`Посмотрите фильм о том, как создавалась музыка для кошек и как она каждый день помогает кошкам избавиться от стресса в ветеринарных клиниках и на приеме у специалистов.`}
                />

                <VideoBlock
                    src={'https://www.youtube.com/embed/VjxPtgNoSbo'}
                    title={'https://www.youtube.com/watch?v=VjxPtgNoSbo&feature=youtu.be'}
                />

            </div>
        )
    }
}
