import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step21-1.png";

export default class step21 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Когда меняется ежедневное расписание владельца, меняется и распорядок дня кошки. Нарушения в повседневной жизни кошки могут стать очень неприятными для нее и вызвать стресс. Необходимо постараться, чтобы ежедневное расписание кошки было как можно более привычным. Когда соответствующие изменения неизбежны, рекомендуют увеличить
                            количество взаимодействия и ежедневных игр с кошкой, а также обеспечить ласку и уход за кошкой, чтобы помочь ей успокоиться.
                        </li>
                        <li>
                            Если кошка будет находиться в одиночестве в течение длительного периода времени, стоит выделить два или более 10-минутных интервала для занятий и игр с кошкой в течение дня.
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
