import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step26-1.png";

export default class step26 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Новая кошка должна быть обеспечена собственной комнатой, в которой будут все необходимые ресурсы, такие как миски с едой и водой, кошачий лоток, когтеточка, насест и разнообразные игрушки. Не стоит разрешать новой кошке сразу покидать свою комнату. Новую кошку необходимо держать в комнате, по крайней мере, в течение недели. Кошкам нужно время, чтобы привыкнуть к новой обстановке и окружению, узнать друг друга поближе, а также понюхать друг друга, не сталкиваясь при этом лицом к лицу. Несмотря на то, что кошка будет чувствовать исходящий от владельца запах новой кошки, процесс знакомства можно сделать более комфортным.
                        </li>
                        <li>
                            Для знакомства кошек с запахом друг друга можно дать каждой кошке подстилки/пледы другой кошки.
                        </li>
                        <li>
                            Когда кошки адаптируются к запаху друг друга, попробуйте покормить их в разных помещениях с противоположной стороны закрытой двери. Это создаст положительную ассоциацию между кормом и запахом другой кошки. Если кошки комфортно принимают пищу у двери, можно постепенно начать вводить визуальный контакт.
                        </li>

                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
