import React from 'react'

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <ul className="list">
                    <li className="list_item">Успешное лечение часто зависит от способности владельца кошки продолжать уход за больной кошкой, начатый в ветеринарной клинике, в домашней обстановке. Задача ветеринарного врача заключается в том, чтобы подсказать владельцу, как обеспечить оптимальный уход за животным в домашних условиях. Соответствующее обучение владельца, руководство и постоянная поддержка со стороны ветеринарных врачей также могут способствовать ранней выписке животного, что в конечном итоге будет благоприятным исходом для всех.</li>
                    <li className="list_item">При обсуждении вариантов лечения с владельцем ветеринарный врач может рассказать о доступных лекарственных препаратах. Если владелец не имеет никакого предшествующего опыта введения лекарств и медикаментов, ветеринарный врач может предложить альтернативы, например, введение с пищей ароматизированной жидкости, которую может быть легче вводить, чем давать кошке горькую таблетку или капсулу. Ветеринарный врач может также продемонстрировать несколько методов введения назначенных им препаратов, чтобы помочь владельцам животного решить, какой вариант является наиболее приемлемым в конкретном случае.</li>
                </ul>
            </div>
        )
    }
}
