import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Creator from "../../../Components/LessonAndTestComponents/Creator";
import UsedMaterials from "../../../Components/LessonAndTestComponents/UsedMaterials";

import img1 from "../../../image/Lecturers/namk.png";

export default class step1 extends React.Component {

    planData = [
        `Основные цели ухода за больной кошкой`,
        `Понимание страха и стресса с точки зрения кошки`,
        `Источники стресса для кошек в ветеринарных клиниках`,
        `Преимущество снижения стресса у кошек в ветеринарных клиниках`,
    ]

    render() {
        return (
            <div className="step1">
                <Creator
                    img={img1}
                    name={'Национальной ассоциацией медицины кошек'}
                />
                <hr className="string"/>
                <div className="head">План урока</div>
                <TextBlock
                    text={`В этом уроке мы рассмотрим следующие темы.`}
                />
                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />
                <UsedMaterials
                    head={'Использованные материалы:'}
                    text={'AAFP and ISFM Feline-Friendly Nursing Care Guidelines//Journal of Feline Medicine and Surgery (2012) 14, 337–349'}
                />
            </div>
        )
    }
}
