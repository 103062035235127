import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step28-1.png";

export default class step28 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Прежде чем привести собаку домой, следует убедиться, что у кошки есть необходимое убежище. Когда собака придет в дом, не давайте ей доступ ко всему дому сразу, а ограничьте его одним-двумя помещениями. Для этого можно использовать детский барьер. Не стоит подгонять  кошку – пусть она познакомится с собакой в своем собственном темпе.
                        </li>
                        <li>
                            Затем организуйте несколько встреч между собакой и кошкой под присмотром. Посадить собаку на поводок и отвести ее в комнату, где находится кошка. При приближении к комнате, в которой находится кошка, можно ослабить поводок собаки, поскольку слишком сильно затянутый поводок передаст тревогу о текущей ситуации собаке. Не позволять собаке вести себя неподобающим образом по отношению к кошке (лаять, бросаться, гоняться). Достаточно будет соответствующим образом откорректировать поведение собаки командой «нельзя», а затем дать команду «сидеть». Если команда будет дана в слишком жесткой форме, собака будет ассоциировать знакомство с кошкой в качестве возможных неприятностей. Поощряйте спокойное поведение собаки с помощью похвалы и любимых лакомств. Кроме того, поощряйте также и кошку любимыми лакомствами и похвалой, когда собака находится в комнате, чтобы помочь создать у кошки положительную ассоциацию с присутствием собаки.
                        </li>
                        <li>
                            Кошка может начать шипеть, царапаться и рычать на собаку. Не наказывать ее за это, иначе она будет ассоциировать появление собаки с наказанием. Повторять встречи кошки с собакой под присмотром до тех пор, пока оба животных не начнут вести себя спокойно и расслабленно друг с другом. Не позволять собаке свободно бегать по дому, пока  животные не начнут чувствовать себя комфортно рядом друг с другом.
                        </li>
                        <li>
                            Большинство собак и кошек могут мирно существовать под одной крышей. Важно помнить, что собаки являются естественными врагами для кошек. По этой причине нельзя позволять собаке преследовать кошку, прикусывать ее зубами или брать в рот. Владелец можете быть уверен, что у собаки определенно дружеские намерения по отношению к кошке, но кошка об этом не знает.
                        </li>
                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
