import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';


export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <div className="head">
                    Что делать, если ваша кошка не желает заходить в переноску
                </div>
                <ul className="list">
                    <li className="list_item">
                        Расположите переноску в небольшой комнате с небольшим количеством укромных мест
                    </li>
                    <li className="list_item">
                        За 30 минут до транспортировки используйте в переноске синтетический аналог кошачьего лицевого феромона
                    </li>
                    <li className="list_item">
                        Поместите в переноску знакомую кошачью подстилку, игрушку и/или лакомство
                    </li>
                    <li className="list_item">
                        Не нужно гнаться за кошкой, чтобы поймать ее и насильно положить в переноску.
                    </li>
                </ul>
            </div>
        )
    }
}
