import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson11/step11-1.png'

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Корм для котят обязательно должен содержать необходимое количество следующих элементов:`}
                        />
                        <ul className="list">
                            <li className="list_item"><b>белок;</b></li>
                            <li className="list_item"><b>таурин;</b></li>
                            <li className="list_item"><b>кальций.</b></li>
                        </ul>
                    </div>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
                <TextBlock
                    text={`Корм должен обладать <b>высокой калорийностью</b>, чтобы обеспечить котенка <b>необходимым уровнем энергии</b>. Лучшее решение — специализированный корм для котят.
                    
                    Например, корм <b>Whiskas® для котят</b> от 1 до 12 месяцев или <b>Perfect Fit™ для котят.</b>`}
                />
            </div>
        )
    }
}
