import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson6/step23-1.png";


export default class step23 extends React.Component {

    render() {
        return (
            <div className="step23">
                <TextBlock
                    text={`<b>Уличные кошки могут испытывать широкий диапазон температуры тела</b>. Поскольку кошки от природы практически не потеют, они учатся получать прохладу в условиях теплых температур, а тепло – в холодных.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
