import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Нежелательные физиологические реакции на стресс включают в себя:`}
                />
                <ul className="list">
                    <li className="list_item">Гипергликемию;</li>
                    <li className="list_item">Снижение концентрации калия в сыворотке крови;</li>
                    <li className="list_item">Повышение концентрации креатининфосфокиназы в сыворотке крови;</li>
                    <li className="list_item">Лимфопению;</li>
                    <li className="list_item">Нейтрофилию;</li>
                    <li className="list_item">Неустойчивую реакцию на седацию и анестезию;</li>
                    <li className="list_item">Иммуносупрессию;</li>
                    <li className="list_item">Гипертонию;</li>
                    <li className="list_item">Шумы в сердце.</li>
                </ul>
                <TextBlock
                    text={`<b>Эти изменения могут осложнить клиническую оценку и процесс лечения кошек, а также продлить госпитализацию.</b>`}
                />
            </div>
        )
    }
}
