import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step12 extends React.Component {

    render() {
        return (
            <div className="step12">
                <div className="head">
                    Рекомендации владельцу
                </div>
                <TextBlock
                    text={`Потратив необходимое время на то, чтобы приучить котенка или кошку к совместным поездкам и научиться должному обращению с ними, можно уменьшить стресс от визитов в ветеринарную клинику на протяжении всей жизни кошки. Ветеринарный врач и владелец кошки могут совместно работать над разработкой стратегий подготовки кошек к позитивному ветеринарному опыту.`}
                />
                <ol className="numeral">
                    <li className="numeral_item">
                        <span className="numeral_item_number">1.</span><span>Отрепетируйте посещение ветеринарного врача, поощряя кошку соответствующей наградой (например, вкусными лакомствами), чтобы познакомить кошку с обстановкой в клинике и подготовить ее к нахождению рядом с другими людьми и кошками.</span>
                    </li>
                </ol>
                <ul className="list">
                    <li className="list_item">
                        <b>Постарайтесь адаптировать желаемое поведение кошки, используя рекомендации, чтобы познакомить кошку с особенностями клиники и помочь ей привыкнуть к контакту или нахождению с другими людьми и кошками.</b>
                    </li>
                </ul>
            </div>
        )
    }
}
