import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ListNumeral from '../../../Components/LessonAndTestComponents/ListNumeral';

export default class step15 extends React.Component {

    data = [
        `Дайте кошке привыкнуть к кошачьей переноске.`,
        `Найдите кошку задолго до того, как отправитесь в ветеринарную клинику.`,
        `Возьмите с собой предметы, которые имеют знакомый запах.`,
        `Заранее уведомите ветеринарного врача о вашем визите в клинику и характере кошки.`,
        `Научитесь понимать влияние вашего собственного беспокойства или стресса на кошку.`,
        `Оставайтесь позитивными.`,
        `В случае соответствующей рекомендации ветеринарного врача используйте предписанные анксиолитики и/или лекарства от тошноты.`,
        `Заранее спланируйте выписку из ветеринарной клиники и продумайте возвращение кошки домой.`,
    ]

    render() {
        return (
            <div className="step15">
                <div className="head">
                    Рекомендации владельцу
                </div>
                <ListNumeral
                    data={this.data}
                />
                <div className="info">
                    Если вы позволите кошкам почувствовать себя в безопасности, пока они находятся в ветеринарной клинике, такой подход может облегчить процесс лечения.
                </div>
            </div>
        )
    }
}
