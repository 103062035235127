import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step14-1.png";

export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <div className="text-top">
                    <p>
                        Появление новых людей в доме может сбить с толку
                        и напугать кошку. Наличие убежища поможет кошке чувствовать себя в безопасности и предоставит ей место
                        для отступления в тот момент, когда обстановка вокруг кошки станет беспокойной.
                    </p>
                    <p>
                        <b>
                        Нужно позволить кошке приближаться к гостям, если она этого хочет, но никогда не заставлять ее общаться
                        с новыми людьми. Следует убедиться, что гости уважают личную жизнь и пространство кошки, пока она находится
                        в своем убежище.
                        </b>

                    </p>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
