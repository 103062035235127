import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PlanInFirstStep from '../../../Components/LessonAndTestComponents/PlanInFirstStep';
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";
import img1 from "../../../image/Lecturers/antanasova.png";
import img2 from "../../../image/Lecturers/antanasova-icon.png";

export default class step1 extends React.Component {

    planData = [
        `Видеолекция: Стресс у кошек. Как помочь кошкам в ОРИТ?`,
        `Тест`,
    ]

    render() {
        return (
            <div className="step1">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Атанасова'}
                    name={'Снежана Георгиевна'}
                    text={'Президент Национальной ассоциации медицины кошек, ветеринарный врач, анестезиолог-реаниматолог, научный руководитель ветеринарной клиники «Клиника Кошек», г. Москва.'}
                />

                <hr className="string"/>
                <div className="head">План урока</div>

                <TextBlock
                    text={`В этом уроке Вас ждёт:`}
                />

                <PlanInFirstStep
                    planData={this.planData}
                    type={'violet'}
                />

            </div>
        )
    }
}
