import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step18-1.jpg";

export default class step18 extends React.Component {

    render() {
        return (
            <div className="step18">
                <div className="head">
                    Кошачьи переноски
                </div>
                <TextBlock
                    text={`Другая конструкция кошачьей переноски со съемным верхом и съемной передней частью.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}