import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";

import img1 from "../../../image/lessons/lesson33/step9-1.png";
import img1xs from "../../../image/lessons/lesson33/step9-1xs.png";

export default class step9 extends React.Component {

    render() {
        return (
            <div className="step9">
                <div className="head">
                    Стадии жизни кошек
                </div>
                <TextBlock
                    text={`Выделяют 3 стадии жизни кошек. WHISKAS® заботится о кошке на каждой стадии ее жизни.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <InfoBlock
                    text={`На каждой стадии жизни у кошки есть свои особенности и потребности в питании. Давайте разберемся, какие существуют риски для здоровья, если кормить кошку, не учитывая ее стадии жизни. Нажмите продолжить, чтобы узнать.`}
                />
            </div>
        )
    }
}
