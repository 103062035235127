import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import OnClickTest from '../../../Components/LessonAndTestComponents/OnClickTest';

// import img1 from '../../../image/lessons/lesson6/step3-1.png'

export default class step3 extends React.Component {

    state = {
        answersData: [
            `Корм для котят менее калорийный, чем корм для взрослых кошек`,
            `Корм для пожилых кошек должен хорошо усваиваться`,
            `Корм для котят более калорийный, чем корм для взрослых кошек`,
        ],
    }

    showNextBtn = () => {
        this.props.showNextBtn()
    }

    render() {
        return (
            <div className="step3">
                <div className="head">
                    Классификация по возрасту животного
                </div>
                <TextBlock
                    text={`Корма для животных разного возраста отличаются по составу. 
                    
                    Ранее вы уже изучали особенности питания кошек на разных стадиях жизни. Вспомните, чем отличаются корма для животных разного возраста.`}
                />
                <InfoBlock
                    text={`Какие утверждения являются верными? Выберите и нажмите «Подтвердить».`}
                />
                <OnClickTest
                    ansewrsData={this.state.answersData}
                    type={'line'}
                    rightAnswers={[2, 3]}
                    showNextBtn={this.showNextBtn}
                    correctText={`Все утверждения верны, кроме одного. На самом деле, корм для котят более калорийный, чем для взрослых кошек, так как им необходимо много энергии для роста.`}
                />
            </div>
        )
    }
}
