import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from "../../../image/lessons/lesson11/step25-1.png";
import img1xs from "../../../image/lessons/lesson11/step25-1xs.png";
import img2 from "../../../image/lessons/lesson11/step25-2.png";
import img2xs from "../../../image/lessons/lesson11/step25-2xs.png";


export default class step25 extends React.Component {

    render() {
        return (
            <div className="step25">
                <TextBlock
                    text={`Рекомендуемая норма кормления должна тщательно рассчитываться, но, как следует из названия, это всего лишь рекомендация относительно количества корма, которая может потребовать коррекции применительно к индивидуальной потребности животного в энергии.`}
                />
                <InfoBlock
                    text={`Ниже показан пример стандартной рекомендуемой схемы кормления кошек сухим и влажным кормом.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 500px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <picture>
                    <source srcSet={img2xs} media="(max-width: 500px)"/>
                    <img src={img2} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
