import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson33/step23-1.png";
import img2 from "../../../image/lessons/lesson33/step23-2.png";
import img2xs from "../../../image/lessons/lesson33/step23-2xs.png";


export default class step23 extends React.Component {

    render() {
        return (
            <div className="step23">
                <div className="flex">
                    <TextBlock
                        text={`Если мы угостим <b>4-килограмовую кошку</b> одной небольшой <b>сосиской (50 г)</b>, то из нее кошка получит сразу <b>2/3 своей суточной нормы</b> в калориях.
                        
                        А если угостим маленьким кусочком <b>сыра (20 г)</b> – кошка получит сразу <b>1/3 суточных калорий.</b>
                        
                        Это очень много! При таком ежедневном угощении кошка быстро наберет лишний вес, что плохо для ее здоровья.`}
                    />
                    <img src={img1} alt="" className="flex_img"/>
                </div>
                {/* <picture>
                    <source srcSet={img2xs} media="(max-width: 767px)"/>
                    <img src={img2} alt="" className="imgCenter"/>
                </picture>
                <div className="promo">
                    <span className="text_violet">Лакомства WHISKAS® DUO Treats</span> специально
                    разработаны для кошек и содержат всего<br/>
                    <span className="text_violet">2 Ккал в 1 подушечке!</span>
                </div> */}
            </div>
        )
    }
}
