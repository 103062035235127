import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson11/step15-1.png";
import img2 from "../../../image/lessons/lesson11/step15-2.png";
import img3 from "../../../image/lessons/lesson11/step15-3.png";

export default class step15 extends React.Component {


    render() {
        return (
            <div className="step15">
                <TextBlock
                    text={`Поскольку кошки едят <b>маленькими порциями и часто</b>, у них в доступе всегда должен быть корм. Рекомендуется кормить кошек по следующей схеме:`}
                />
                <div className="flex">
                    <div className="flex_el text">
                        <div className="flex_el_text">
                            <b>Влажный корм</b><br/> 2 раза в день.
                        </div>
                        <img src={img1} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el plus">+</div>
                    <div className="flex_el text">
                        <div className="flex_el_text">
                            <b>Сухой корм</b><br/> в постоянном доступе в миске.
                        </div>
                        <img src={img2} alt="" className="flex_el_img"/>
                    </div>
                    <div className="flex_el plus">+</div>
                    <div className="flex_el text">
                        <div className="flex_el_text">
                            <b>Вода</b><br/> в постоянном доступе в миске.
                        </div>
                        <img src={img3} alt="" className="flex_el_img"/>
                    </div>
                </div>

            </div>
        )
    }
}
