import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step3-1.png";

export default class step3 extends React.Component {

    render() {
        return (
            <div className="step3">
                <div className="text-top">
                    <ul>
                        <li>
                            Мы можем не осознавать, насколько чувствительна кошка к таким обычным  вещам, как перемещение мебели в квартире или присутствие компании на обеде. Для кошек привычки играют очень важную роль
                        </li>
                        <li>
                            Когда мы планируем сделать что-либо, что впоследствии изменит окружение кошки даже на короткий период времени, мы можем помочь снизить ее стресс и устранить дискомфорт, следуя нескольким простым рекомендациям.
                        </li>
                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
