import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson32/step19-1.png'
import List from "../../../Components/LessonAndTestComponents/List";


export default class step19 extends React.Component {

    //Данные для списка
    ListData = [
        `научная разработка, которая включает в себя создание рецептуры, изготовление пробной партии и её исследование;`,
        `анализ сырья;`,
        `производство;`,
        `хранение продукта.`
    ]

    render() {
        return (
            <div className="step19">
                <TextBlock
                    text={`В этом уроке вы изучили процесс производства готовых кормов для животных, от этапа разработки рецептуры до упаковки и складирования.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_head">
                            Процесс создания кормов имеет несколько этапов:
                        </h3>
                        <List
                            ListData={this.ListData}
                        />
                        <TextBlock
                            text={`Производство сухих и влажных кормов отличается друг от друга. Крупные производители используют передовые технологии и оборудование для приготовления готовых рационов, чтобы продукт соответствовал заявленным качествам и критериям безопасности.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="img"/>
                    </div>
                </div>
            </div>
        )
    }
}
