import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson8/step41-1.jpg'

export default class step41 extends React.Component{

    render() {
        return (
            <div className="step41">
                <div className="head">
                    Как правильно выпускать кошку к другим домашним питомцам после посещения ветеринарной клиники<br/>
                </div>
                <div className="subheader">
                    Рекомендации владельцу
                </div>
                <TextBlock
                    text={`Если случаи проявления агрессии со стороны других кошек ранее отсутствовали:
                    вернувшуюся из клиники кошку оставьте в переноске, чтобы посмотреть, как отреагируют на неё другие кошки.
                    
                    Если никаких признаков шипения или агрессии/страха не наблюдается, позвольте кошкам остаться наедине. При этом кошка должна быть под вашим наблюдением.
                    
                    Затем достаньте кошку и оставьте ее в комнате с другими кошками, следите за любой реакцией. Если появляются признаки агрессии, разделите кошек по разным комнатам.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
