import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson11/step14-1.png'

export default class step14 extends React.Component {

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`Главная цель ухода за взрослой кошкой — <b>поддержание хорошего состояния здоровья и оптимальной физической формы</b>. Кошка по своей природе является хищником. Поэтому ей необходимо большое количество мясных ингредиентов в пище.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <TextBlock
                            text={`Характеристики оптимального корма для кошек:`}
                        />
                        <ul className="list">
                            <li className="list_item"><b>Высокое содержание белка;</b></li>
                            <li className="list_item"><b>Низкое содержание углеводов;</b></li>
                            <li className="list_item"><b>Хорошая перевариваемость.</b></li>
                        </ul>
                    </div>
                    <img src={img1} alt="" className="flex_img"/>
                </div>
            </div>
        )
    }
}
