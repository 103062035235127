import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson34/step3-1.png';

export default class step3 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step3">
                <div className="box">
                    <div className="flex">
                        <div className="flex_el">
                            <div className="flex_el_box-img">
                                <img src={img1} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                        <div className="flex_el">
                            <TextBlock
                                text={`<span class="text_blue">Научно-исследовательский институт Waltham</span> изучает вопросы питания домашних животных <span class="text_blue">с 1963 года.</span>
                            
                            <span class="text_blue">Научно-исследовательский институт Waltham</span> – мировой лидер в области ухода за домашними животными и их питания.
                            
                            Обширные научные знания <span class="text_blue">научно-исследовательского института Waltham</span> легли в основу разработки рецептур <span class="text_blue">PERFECT FIT™.</span>`}
                            />
                        </div>
                    </div>
                    <TextBlock
                        text={`За это время научно-исследовательский институт Waltham совершил ряд важных научных открытий, ценность которых очевидна и сегодня:`}
                    />
                    <div className="pin-box">
                        <div className="pin_el">
                            <svg width="55" height="48" viewBox="0 0 55 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M46.5753 32.1651C43.2345 41.4003 34.388 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C34.394 0 43.2448 6.6074 46.5812 15.8511C49.0118 19.6486 51.6363 21.2648 54.9128 23.2826L55 23.3363C50.2571 26.6834 48.1351 29.5131 46.5753 32.1651Z" fill="#4147DA"/>
                                <path d="M23.4049 28.704C23.4049 29.604 23.9809 30.108 24.7549 30.108C25.5289 30.108 26.1049 29.604 26.1049 28.704V18.462C26.1049 17.742 25.6729 17.184 24.8989 17.184H22.3429C21.5689 17.184 21.1189 17.796 21.1189 18.426C21.1189 19.038 21.4789 19.668 22.3429 19.668H23.4049V28.704Z" fill="white"/>
                            </svg>
                            <TextBlock
                                text={`1991 г - Определение <b>необходимого уровня таурина</b> во влажных и сухих рационах для кошек.`}
                            />
                        </div>
                        <div className="pin_el">
                            <svg width="55" height="48" viewBox="0 0 55 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M46.5753 32.1651C43.2345 41.4003 34.388 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C34.394 0 43.2448 6.6074 46.5812 15.8511C49.0118 19.6486 51.6363 21.2648 54.9128 23.2826L55 23.3363C50.2571 26.6834 48.1351 29.5131 46.5753 32.1651Z" fill="#4147DA"/>
                                <path d="M23.656 27.624C25.186 25.698 27.976 23.214 27.976 20.712C27.976 18.426 26.194 16.968 23.98 16.968C21.766 16.968 19.75 18.786 19.75 21.054C19.75 21.756 20.2 22.368 21.064 22.368C22.792 22.368 21.802 19.452 23.872 19.452C24.61 19.452 25.168 20.1 25.168 20.838C25.168 21.486 24.862 22.044 24.538 22.512C23.17 24.492 21.55 26.112 20.056 27.894C19.876 28.11 19.642 28.434 19.642 28.812C19.642 29.64 20.254 30 21.046 30H27.058C27.742 30 28.354 29.64 28.354 28.812C28.354 27.984 27.742 27.624 26.95 27.624H23.656Z" fill="white"/>
                            </svg>
                            <TextBlock
                                text={`1995 г - Создание первой диеты, помогающей <b>контролировать образование струвитных и кальций</b> – оксалатных камней у кошек.`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
