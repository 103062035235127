import React from 'react'
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
import Lecturer from "../../../Components/LessonAndTestComponents/Lecturer";

import img1 from '../../../image/Lecturers/mochalova.png'
import img2 from '../../../image/Lecturers/mochalova-icon.png'
import img3 from '../../../image/Lecturers/shilkin2.png'
import img4 from '../../../image/Lecturers/shilkin2-icon.png'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step2">
                <Lecturer
                    img={img1}
                    icon={img2}
                    surname={'Мочалова'}
                    name={'Ульяна Эдуардовна'}
                    text={'Ветеринарный врач-офтальмолог, микрохирург Центра ветеринарной офтальмологии доктора Шилкина А.Г., г.Москва'}
                />

                <Lecturer
                    img={img3}
                    icon={img4}
                    surname={'Шилкин'}
                    name={'Алексей Германович'}
                    text={'Ветеринарный врач-офтальмолог, к.м.н., доцент, руководитель Центра ветеринарной офтальмологии доктора Шилкина А.Г., г.Москва'}
                />

                <hr className="string"/>

                <VideoBlock
                    src={'https://www.youtube.com/embed/u8LABxQT7NM'}
                    title={'https://www.youtube.com/watch?v=u8LABxQT7NM&feature=youtu.be'}
                />
            </div>
        )
    }
}
