import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step11-1.png";

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <div className="text-top">
                    <ul>
                        <li>
                            Во время знакомства кошки с новым человеком в семье распорядок дня кошки должен оставаться как можно более привычным.
                        </li>
                        <li>
                            Обеспечьте кошке необходимое <b>убежище</b>.
                        </li>
                        <li>
                            Увеличьте количество игр, ласки и ухода за кошкой, чтобы помочь ей успокоиться.
                        </li>
                        <li>
                            Позвольте новому человеку принимать участие в игровых занятиях с кошкой. Игровое взаимодействие, включающее в себя вкусные лакомства, может помочь установить позитивные отношения между кошкой и новым человеком. Самое главное, позвольте кошке исследовать нового человека и его вещи в своем собственном темпе. Каждая кошка приспосабливается к своему темпу, и ей может потребоваться определенное время, чтобы привыкнуть к новому человеку.
                        </li>
                        <li>
                            Пусть кошка сама решит, когда ей будет удобно начать знакомство.
                        </li>
                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
