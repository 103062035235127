import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson33/step20-1.png";

export default class step20 extends React.Component {

    render() {
        return (
            <div className="step20">
                <div className="head">Идеальное решение — сухой и влажный корм каждый день</div>
                <TextBlock
                    text={`<b>Сочетание сухого и влажного корма</b> — лучшее решение при составлении рациона питомца, причем не обязательно сочетать разные виды кормов в рамках одного бренда. Можно использовать разные!
                    
                    Польза от чередования видов кормов доказана клиническими исследованиями WALTHAM™ Research. Такой способ кормления соединяет <b>преимущества обоих видов кормов.</b>`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Способствует улучшению гигиены полости рта.</li>
                                <li className="list_item">Содержит больше клетчатки, благотворно влияющей на моторику кишечника.</li>
                                <li className="list_item">Может длительно храниться в миске.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_box">
                            <ul className="list">
                                <li className="list_item">Позволяет разнообразить рацион питомца различными вкусами и текстурами.</li>
                                <li className="list_item">Способствует соблюдению водного баланса.</li>
                                <li className="list_item">Позволяет избежать переедания.</li>
                                <li className="list_item">Способствует профилактике мочекаменной болезни за счет большего содержания влаги.</li>
                            </ul>
                        </div>
                    </div>
                    <img src={img1} alt="" className="flex_el_img"/>
                </div>
            </div>
        )
    }
}
