import React from 'react'
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson13/step18-1.png";

export default class step18 extends React.Component {

    render() {
        return (
            <div className="step13">
                <div className="text-top">
                    <ul>
                        <li>
                            Прежде чем начать паковать вещи, предоставьте кошке необходимое убежище.
                        </li>
                        <li>
                            Оставьте радиоприемник или телевизор включенными рядом с убежищем, чтобы заглушить нежелательные звуки и обеспечить успокаивающий «белый шум» для  кошки.
                        </li>
                        <li>
                            Создайте в новом доме новое убежище для кошки. Не забывайте навещать кошку в ее убежище и старайтесь ежедневно проводить с ней время, например, играть вместе с кошкой
                            в ее любимые игрушки, гладить ее или ухаживать за ее шерстью.
                        </li>
                        <li>
                            Оставьте кошку в убежище до тех пор, пока  полностью не закончится перенос вещей. Как только дом будет приведен в порядок, позвольте кошке самостоятельно исследовать свое новое окружение.
                        </li>
                    </ul>
                </div>

                <div className="infoImage">
                    <img src={img1} alt=""/>
                </div>

            </div>
        )
    }
}
