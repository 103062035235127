import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson31/step17-1.png';
import img1xs from '../../../image/lessons/lesson31/step17-1xs.png';


export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <div className="head">
                    Классификация по классу
                </div>
                <TextBlock
                    text={`Ещё одним отличием кормов разных классов является <b>дневная норма кормления,</b> то есть количество корма, которое необходимо животному съесть в сутки, чтобы получить все питательные вещества.
                    
                    Как видим из пирамиды, дневная норма кормления у корма <b>эконом класса выше,</b> в то время как у корма <b>супер-премиум она ниже.</b>
                    
                    Ниже приведен пример дневной нормы кормления кошки весом 4 кг сухими кормами различных классов. Вы видите, что при использовании корма эконом класса KITEKAT™ ей требуется 63 г сухого корма в сутки, а корма премиум класса PERFECT FIT™ - 55 г.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt=""/>
                </picture>
                <div className="advice">
                    Таким образом, кормить кошек <b>полезно и сбалансированно можно любым из классов кормов</b>. <b>Важно соблюдать рекомендованную норму кормления!</b>
                </div>
            </div>
        )
    }
}
