import React from 'react'
import './style.sass'
import logo from '../../image/annual-pr-logo.png'
import cat from '../../image/annual-cat.png'

export default props => {
    return (
        <div className="annual_premium-block">
            <div className="annual_premium-logo">
                <img src={logo} alt=""/>
            </div>
            <div className="annual_premium-lights"/>
            <div className="annual_premium-lights-2"/>
            <div className="annual_premium-cat">
                <img src={cat} alt=""/>
            </div>
            <div className="annual_premium-title">
                Успей принять участие до&nbsp;17&nbsp;февраля 2025&nbsp;г!
            </div>
            <div className="annual_premium-title-2">
                Национальная ассоциация медицины кошек объявляет
                <span> ежегодную премию</span>.
            </div>
            <div className="annual_premium-actions">
                <a href="/CVD_rules.pdf" target="_blank" className="annual_premium-btn">
                    Узнать больше
                </a>
                <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=ND7BLz_wi0mYKny0RuJbxiTzaCf9dUhJrC3d_QAEznJURExIM1paOUtYSjJERFNUTDM4TFpZUFdaOC4u" target="_blank" className="annual_premium-btn">
                    Подать заявку
                </a>
            </div>
        </div>
    )
}
