import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step22-1.png";


export default class step22 extends React.Component {

    render() {
        return (
            <div className="step22">
                <TextBlock
                    text={`<b>Управление запахами</b>`}
                />
                <ul className="list">
                    <li className="list_item">Вовремя удаляйте посторонние запахи</li>
                    <li className="list_item">Тщательно проветривайте помещение</li>
                    <li className="list_item">Регулярно выбрасывайте мусор</li>
                    <li className="list_item">Обрабатывайте поверхности</li>
                    <li className="list_item">Производите чистку предметов</li>
                </ul>
                <TextBlock
                    text={`<b>Некоторые запахи могут вызывать тревогу или страх:</b> освежители воздуха, дезинфицирующие средства, спирт для протирки, кровь, дезодорант, духи, запах незнакомой одежды.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
