import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson6/step15-1.png'

export default class step15 extends React.Component {

    render() {
        return (
            <div className="step15">
                <div className="head">
                    Кошачий насест и его роль для кошки
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                <TextBlock
                    text={`<b>Вы можете поставить диван, тумбочку, стул или прочный стол перед окном, чтобы ваша кошка могла взобраться наверх и смотреть на улицу</b>. Чтобы сделать окружающий пейзаж более привлекательным, вы можете повесить кормушку для птиц во дворе или посадить красивые цветы, чтобы привлечь пчел, бабочек и других насекомых. Это заинтересует вашу кошку, поскольку она сможет часами сидеть на своем насесте и смотреть на улицу.`}
                />
            </div>
        )
    }
}
