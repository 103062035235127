import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from '../../../image/lessons/lesson32/step14-1.png'
import img2 from '../../../image/lessons/lesson32/step14-2.png'
import img3 from '../../../image/lessons/lesson32/step14-3.png'
import img4 from '../../../image/lessons/lesson32/step14-4.png'
import img5 from '../../../image/lessons/lesson32/step14-5.png'



export default class step14 extends React.Component {

    state = {
        popupActive: 0,
        popupShow: false,
        flexHidden: false,
        points: [],
    };

    showPopup = popupActive => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (points.length === 5) {
            this.setState({popupActive: 0, popupShow: false})
        }

        this.setState({
            popupShow: true,
            popupActive: popupActive,
            points: points
        })

        if (popupActive > 5) {
            this.setState({popupActive: 0, popupShow: false})
        }

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({popupShow: false})
    }

    popupWrp = {
        1: {
            img_head: img1,
            head: 'Сырьё',
            text: `Сырье для влажного производства поставляется в замороженном виде, чтобы обеспечить его микробиологическую безопасность и свежесть.
            
            При этом каждая партия сырья проверяется в лаборатории на показатели качества и пищевой безопасности.`,
        },
        2: {
            img_head: img2,
            head: 'Перемешивание и измельчение',
            text: `Так же, как и в сухом производстве, производство влажного продукта начинается с этапа «перемешивание и измельчение». Все сырьевые компоненты соединяются вместе согласно рецептуре и подвергаются измельчению.
            
            Результатом этого этапа является получение однородной мясной смеси, которая напоминает однородный, хорошо помолотый фарш. Далее из этого фарша будут готовиться мясные кусочки.`,
        },
        3: {
            img_head: img3,
            head: 'Приготовление кусочков и соуса',
            text: `Приготовление кусочков происходит в паровом тоннеле.  На плавно движущийся конвейер вытекает однородная эмульсия (мясной фарш) через специальные краны и, проходя по конвейеру, обдается паром. На выходе располагаются ножи, которые режут единообразную массу на кусочки.
            
            Параллельно с этим варится подлива — соус или желе.
            
            <span class="text_violet">Преимущества парового тоннеля:</span>`,
            ListData: [
                `щадящая паровая обработка;`,
                `короткое время термической обработки (5 минут).`,
            ],
        },
        4: {
            img_head: img4,
            head: 'Наполнение упаковки готовым продуктом',
            text: `Когда готовы кусочки и подлива, наступает этап наполнения упаковки (например, пауча) готовым продуктом.
            
            На этом этапе в пустой пакетик влажного корма кладётся определенное количество кусочков, далее заливается подлива, и, наконец, пауч герметично закрывается. Иными словами, в результате этого этапа получается закрытый пауч с кормом внутри.
            
            Однако на этом процесс производства влажного продукта не заканчивается, т.к. важно простерилизовать этот закрытый пакет, чтобы обеспечить его микробиологическую безопасность и длительный срок годности без использования консервантов.`,
        },
        5: {
            img_head: img5,
            head: 'Стерилизация',
            text: `Стерилизация (автоклавирование) — финальный этап производства влажного корма, происходящий в специальных установках, работающих по принципу скороварки. В процессе стерилизации важно следить за временем, давлением и температурой.
            
            <span class="text_violet">Преимущества стерилизации:</span>`,
            ListData: [
                `стерилизуется и продукт, и упаковка — уменьшение микробиологических рисков;`,
                `герметичный процесс;`,
                `дает возможность длительного хранения продукта.`,
            ],
        },
    }

    render() {
        const {popupShow, popupActive} = this.state
        return (
            <div className="step14">
                <TextBlock
                    text={`Влажный процесс отличается от сухого. Рассмотрите схему, чтобы понять отличия.`}
                />
                <InfoBlock
                    text={`Нажмите на вопросительные знаки, чтобы посмотреть описания производственных процессов.`}
                />
                <div className="schema">
                    <div className="flex_left">
                        <div className="flex_raw">
                            Сырье
                            <span className="point" onClick={() => {this.showPopup(1)}}>?</span>
                        </div>
                        <div className="flex_wrp">
                            <div className="flex_wrp_left">
                                <div className="flex_wrp_el">
                                    Перемешивание и измельчение
                                    <svg className="arrow_down" width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1C7 0.447715 7.44772 7.68068e-08 8 0C8.55228 -7.68068e-08 9 0.447715 9 1L7 1ZM8.70711 25.7071C8.31659 26.0976 7.68342 26.0976 7.2929 25.7071L0.928935 19.3431C0.53841 18.9526 0.53841 18.3195 0.928935 17.9289C1.31946 17.5384 1.95262 17.5384 2.34315 17.9289L8 23.5858L13.6569 17.9289C14.0474 17.5384 14.6805 17.5384 15.0711 17.9289C15.4616 18.3195 15.4616 18.9526 15.0711 19.3431L8.70711 25.7071ZM9 1L9 25L7 25L7 1L9 1Z" fill="#4147DA"/>
                                    </svg>
                                    <span className="point" onClick={() => {this.showPopup(2)}}>?</span>
                                </div>
                                <div className="flex_wrp_el">
                                    Приготовление кусочков
                                    <svg className="arrow_down" width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1C7 0.447715 7.44772 7.68068e-08 8 0C8.55228 -7.68068e-08 9 0.447715 9 1L7 1ZM8.70711 25.7071C8.31659 26.0976 7.68342 26.0976 7.2929 25.7071L0.928935 19.3431C0.53841 18.9526 0.53841 18.3195 0.928935 17.9289C1.31946 17.5384 1.95262 17.5384 2.34315 17.9289L8 23.5858L13.6569 17.9289C14.0474 17.5384 14.6805 17.5384 15.0711 17.9289C15.4616 18.3195 15.4616 18.9526 15.0711 19.3431L8.70711 25.7071ZM9 1L9 25L7 25L7 1L9 1Z" fill="#4147DA"/>
                                    </svg>
                                    <span className="point" onClick={() => {this.showPopup(3)}}>?</span>
                                </div>
                                <div className="flex_wrp_el">
                                    Наполнение упаковки готовым продуктом
                                    <svg className="arrow_down" width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1C7 0.447715 7.44772 7.68068e-08 8 0C8.55228 -7.68068e-08 9 0.447715 9 1L7 1ZM8.70711 25.7071C8.31659 26.0976 7.68342 26.0976 7.2929 25.7071L0.928935 19.3431C0.53841 18.9526 0.53841 18.3195 0.928935 17.9289C1.31946 17.5384 1.95262 17.5384 2.34315 17.9289L8 23.5858L13.6569 17.9289C14.0474 17.5384 14.6805 17.5384 15.0711 17.9289C15.4616 18.3195 15.4616 18.9526 15.0711 19.3431L8.70711 25.7071ZM9 1L9 25L7 25L7 1L9 1Z" fill="#4147DA"/>
                                    </svg>
                                    <svg className="arrow_right" width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7L1 9ZM25.7071 7.29289C26.0976 7.68342 26.0976 8.31658 25.7071 8.70711L19.3431 15.0711C18.9526 15.4616 18.3195 15.4616 17.9289 15.0711C17.5384 14.6805 17.5384 14.0474 17.9289 13.6569L23.5858 8L17.9289 2.34315C17.5384 1.95262 17.5384 1.31946 17.9289 0.928932C18.3195 0.538408 18.9526 0.538408 19.3431 0.928932L25.7071 7.29289ZM1 7L25 7V9L1 9L1 7Z" fill="#4147DA"/>
                                    </svg>
                                    <span className="point" onClick={() => {this.showPopup(4)}}>?</span>
                                </div>
                            </div>
                            <div className="flex_wrp_right">
                                <div className="flex_wrp_el hidden">
                                    <div className="answer"/>
                                </div>
                                <div className="flex_wrp_el">
                                    Приготовление соуса
                                    <svg className="arrow_left" width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.2636 5.29289C22.6541 4.90237 23.2873 4.90237 23.6778 5.29289C24.0683 5.68342 24.0683 6.31658 23.6778 6.70711L22.2636 5.29289ZM6.00014 23.9706C5.44786 23.9706 5.00014 23.5228 5.00014 22.9706L5.00014 13.9706C5.00014 13.4183 5.44786 12.9706 6.00014 12.9706C6.55243 12.9706 7.00014 13.4183 7.00014 13.9706L7.00014 21.9706L15.0001 21.9706C15.5524 21.9706 16.0001 22.4183 16.0001 22.9706C16.0001 23.5228 15.5524 23.9706 15.0001 23.9706L6.00014 23.9706ZM23.6778 6.70711L6.70725 23.6777L5.29303 22.2635L22.2636 5.29289L23.6778 6.70711Z" fill="#4147DA"/>
                                    </svg>
                                    <svg className="arrow_down arrow_down--big" width="16" height="126" viewBox="0 0 16 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1L9 1ZM7.29289 125.707C7.68342 126.098 8.31658 126.098 8.70711 125.707L15.0711 119.343C15.4616 118.953 15.4616 118.319 15.0711 117.929C14.6805 117.538 14.0474 117.538 13.6569 117.929L8 123.586L2.34315 117.929C1.95262 117.538 1.31946 117.538 0.928932 117.929C0.538408 118.319 0.538408 118.953 0.928932 119.343L7.29289 125.707ZM7 1L7 125H9L9 1L7 1Z" fill="#4147DA"/>
                                    </svg>
                                    <svg className="arrow_arc" width="67" height="72" viewBox="0 0 67 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.792879 64.8616C0.402353 64.4711 0.402353 63.838 0.792879 63.4474L7.15684 57.0835C7.54737 56.6929 8.18053 56.6929 8.57106 57.0835C8.96158 57.474 8.96158 58.1072 8.57106 58.4977L2.9142 64.1545L8.57105 69.8114C8.96158 70.2019 8.96158 70.8351 8.57105 71.2256C8.18053 71.6161 7.54736 71.6161 7.15684 71.2256L0.792879 64.8616ZM65.5 16.1545L64.5 16.1545L65.5 16.1545ZM17.5 65.1545L1.49999 65.1545L1.49999 63.1545L17.5 63.1545L17.5 65.1545ZM64.5 16.1545L64.5 0.154541L66.5 0.154541L66.5 16.1545L64.5 16.1545ZM17.5 63.1545C43.4574 63.1545 64.5 42.1119 64.5 16.1545L66.5 16.1545C66.5 43.2165 44.5619 65.1545 17.5 65.1545L17.5 63.1545Z" fill="#4147DA"/>
                                    </svg>
                                </div>
                                <div className="flex_wrp_el hidden--mobile">
                                    Стерилизация
                                    <svg className="arrow_right" width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7L1 9ZM25.7071 7.29289C26.0976 7.68342 26.0976 8.31658 25.7071 8.70711L19.3431 15.0711C18.9526 15.4616 18.3195 15.4616 17.9289 15.0711C17.5384 14.6805 17.5384 14.0474 17.9289 13.6569L23.5858 8L17.9289 2.34315C17.5384 1.95262 17.5384 1.31946 17.9289 0.928932C18.3195 0.538408 18.9526 0.538408 19.3431 0.928932L25.7071 7.29289ZM1 7L25 7V9L1 9L1 7Z" fill="#4147DA"/>
                                    </svg>
                                    <span className="point point--hidden" onClick={() => {this.showPopup(5)}}>?</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex_right">
                        <div className="flex_wrp_el flex_wrp_el--mobile">
                            Стерилизация
                            <svg className="arrow_right" width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 9C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7L1 9ZM25.7071 7.29289C26.0976 7.68342 26.0976 8.31658 25.7071 8.70711L19.3431 15.0711C18.9526 15.4616 18.3195 15.4616 17.9289 15.0711C17.5384 14.6805 17.5384 14.0474 17.9289 13.6569L23.5858 8L17.9289 2.34315C17.5384 1.95262 17.5384 1.31946 17.9289 0.928932C18.3195 0.538408 18.9526 0.538408 19.3431 0.928932L25.7071 7.29289ZM1 7L25 7V9L1 9L1 7Z" fill="#4147DA"/>
                            </svg>
                            <span className="point" onClick={() => {this.showPopup(5)}}>?</span>
                        </div>
                        <div className="flex_wrp_el gray">
                            Готовый продукт
                        </div>
                    </div>
                </div>
                {popupShow &&
                <PopupInStep
                    closePopup={this.closePopup}
                    imgHead={this.popupWrp[popupActive].img_head}
                    head={this.popupWrp[popupActive].head}
                    changePoint={() => this.showPopup(popupActive + 1)}
                >
                    <TextBlock
                        text={this.popupWrp[popupActive].text}
                    />
                    {this.popupWrp[popupActive].ListData &&
                    <List ListData={this.popupWrp[popupActive].ListData}/>
                    }
                </PopupInStep>
                }
            </div>
        )
    }
}
