import React from 'react'

export default class step2 extends React.Component {

    render() {
        return (
            <div className="step5">
                Шаг 5
            </div>
        )
    }
}

