import React from 'react'

import img1 from '../../../image/lessons/lesson32/step11-1.png'
import img2 from '../../../image/lessons/lesson32/step11-2.png'
import img3 from '../../../image/lessons/lesson32/step11-3.png'
import img4 from '../../../image/lessons/lesson32/step11-4.png'


export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <div className="flex flex_error">
                    <img className="img_up" src={img3} alt=""/>
                    <p className="flex_el">
                        Некоторые люди считают, что субпродукты не имеют
                        полезных свойств и плохо усваиваются организмом
                        животных.<br/>
                        Однако данное суждение неверно.
                    </p>
                    <div className="flex_el">
                        <img src={img1} alt=""/>
                    </div>
                </div>
                <div className="flex flex_correct">
                    <img className="img_up" src={img4} alt=""/>
                    <ul>
                        <li>Субпродукты — это части тела животного, не попадающие под понятие «мышцы», например, печень и почки. Они являются прекрасным <b>источником белка, аминокислот и витаминов.</b></li>
                        <li>В природе животные, поймав свою добычу, в первую очередь занимаются поеданием <b>внутренних органов, т.е. субпродуктов.</b></li>
                        <li>Употребление субпродуктов является неотъемлемой составляющей при приеме пищи животным, так как <b>они содержат большое количество полезных веществ.</b></li>
                    </ul>
                    <div className="flex_el">
                        <img src={img2} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
