import React, {useEffect, useState} from 'react';
import './style.sass';

import Api from '../../Service/Api'
import Inputs from "../../Components/Inputs";
import InputFileNew from "../../Components/InputFileNew";
import InputSelect from "../../Components/InputSelect";
import Checkbox from "../../Components/Checkbox";
import StickyBlock from "../../Components/StickyBlock";
import TextBlock from '../../Components/LessonAndTestComponents/TextBlock';

const Accreditation = () => {
    const THISApi = new Api();
    const [validate, setValidate] = useState('')
    const [fileOne, setfileOne] = useState(false)
    const [fileTwo, setfileTwo] = useState(false)
    const [fileThree, setfileThree] = useState(false)
    const [fileFour, setfileFour] = useState(false)
    const [fileFive, setfileFive] = useState(false)
    const [fileSix, setfileSix] = useState(false)
    const [fileSeven, setfileSeven] = useState(false)
    const [fileEight, setfileEight] = useState(false)
    const [fileNine, setfileNine] = useState(false)
    const [fileTen, setfileTen] = useState(false)
    const [fileEleven, setfileEleven] = useState(false)
    const [fileTwelve, setfileTwelve] = useState(false)
    const [fileThirteen, setfileThirteen] = useState(false)
    const [fileFourteen, setfileFourteen] = useState(false)
    const [fileFifteen, setfileFifteen] = useState(false)
    const [fileSixteen, setfileSixteen] = useState(false)
    const [fileSeventeen, setfileSeventeen] = useState(false)
    const [fileEighteen, setfileEighteen] = useState(false)
    const [fileNineteen, setfileNineteen] = useState(false)
    const [fileTwenty, setfileTwenty] = useState(false)
    const [fileTwentyOne, setfileTwentyOne] = useState(false)
    const [fileTwentyTwo, setfileTwentyTwo] = useState(false)
    const [fileTwentyThree, setfileTwentyThree] = useState(false)
    const [fileTwentyFour, setfileTwentyFour] = useState(false)
    const [defaultState, setDefaultState] = useState('')
    const [next, setNext] = useState(false)
    const [comleted, setCompleted] = useState(false)
    const [stepZero, setStepZero] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [inputs, setInputs] = useState({
        clinic_name: '',
        clinic_site: '',
        clinic_phone: '',
        clinic_city: '',
        clinic_address: '',
        contact_name: '',
        contact_middle_name: '',
        contact_surname: '',
        contact_phone: '',
        contact_position: '',
        principle_separate: false,
        principle_towels: false,
        principle_lock: false,
        principle_no_transparent_walls: false,
        principle_hard_box: false,
        principle_distance: false,
        principle_onychectomy: false,
        confirm_agreement: false,
        principle_landmarks: false,
        principle_carrying: false,
        'files[wait][0]': '',
        error_board_file_one: false,
        'files[wait][1]': '',
        error_board_file_two: false,
        'files[wait][2]': '',
        error_board_file_three: false,
        'files[poster][0]': '',
        error_poster_file_one: false,
        'files[poster][1]': '',
        error_poster_file_two: false,
        'files[poster][2]': '',
        error_poster_file_three: false,
        'files[carrying][0]': '',
        error_site_file_one: false,
        'files[carrying][1]': '',
        error_site_file_two: false,
        'files[carrying][2]': '',
        error_site_file_three: false,
        'files[towels][0]': '',
        error_wait_file_one: false,
        'files[towels][1]': '',
        error_wait_file_two: false,
        'files[towels][2]': '',
        error_wait_file_three: false,
        'files[lock][0]': '',
        error_screen_file_one: false,
        'files[lock][1]': '',
        error_screen_file_two: false,
        'files[lock][2]': '',
        error_screen_file_three: false,
        'files[cabinet_walls][0]': '',
        error_nav_file_one: false,
        'files[cabinet_walls][1]': '',
        error_nav_file_two: false,
        'files[cabinet_walls][2]': '',
        error_nav_file_three: false,
        'files[box_walls][0]': '',
        error_box_file_one: false,
        'files[box_walls][1]': '',
        error_box_file_two: false,
        'files[box_walls][2]': '',
        error_box_file_three: false,
        'files[box][0]': '',
        error_radius_file_one: false,
        'files[box][1]': '',
        error_radius_file_two: false,
        'files[box][2]': '',
        error_radius_file_three: false,
        // hospital_cell_size: '',
        // principle_stress: false,
        // principle_fear: false,
        // principle_recommendation: false,
        // principle_scarfing: false,
        // principle_noise: false,
        // principle_doctor: false,
        // principle_contact: false,
        // principle_zone: false,
        // principle_inpatient: false,
    })
    const [inputSpec, setInputSpec] = useState([
        {
            name: '',
            middle_name: '',
            surname: '',
            position: '',
        }
    ])

    const getData = async () => {
        await THISApi.getUserData().then(user => {
                THISApi.getClinics(user.user.city)
                    .then(clinic => (clinic.map(clinic => {
                            if (clinic.id === user.user.clinic_id) {
                                setInputs({
                                    ...inputs,
                                    clinic_city: user.user.city,
                                    contact_surname: user.user.last_name,
                                    contact_name: user.user.first_name,
                                    contact_middle_name: user.user.middle_name,
                                    contact_phone: user.user.phone.slice(1),
                                    clinic_name: clinic.name,
                                    clinic_address: clinic.address,
                                })
                            }
                        }))
                    )
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const completed = () => {
        setCompleted(true)
        setTimeout(function () {
            document.location.href = "/";
        }, 5000)
    }

    const stepOne = () => {
        setStepZero(!stepZero)
    }

    const validateStepOne = () => {
        setValidate(true)
        for (let key in inputs) {
            if (inputs[key].length <= 0) {
                return;
            }
            if (key === 'contact_position') {
                setNext(!next)
                setValidate(false)
            }
        }
    }

    const validateStepTwo = () => {
        setValidate(true)
        let inputsNew = {...inputs}

        Object.keys(inputsNew).map((item, index) => {
            if (item.indexOf('files') >= 0) {
                delete inputsNew[item]

            }
        })

        inputsNew = {
            ...inputsNew,
            'files[wait][0]': inputs['files[wait][0]'],
            'files[poster][0]': inputs['files[poster][0]'],
            'files[carrying][0]': inputs['files[carrying][0]'],
            'files[towels][0]': inputs['files[towels][0]'],
            'files[lock][0]': inputs['files[lock][0]'],
            'files[cabinet_walls][0]': inputs['files[cabinet_walls][0]'],
            'files[box_walls][0]': inputs['files[box_walls][0]'],
            'files[box][0]': inputs['files[box][0]'],
        }

        for (let key in inputsNew) {
            if (inputsNew[key].length <= 0 || inputsNew.confirm_agreement === false) {
                return;
            } else if (key.indexOf('principle') >= 0) {
                if (inputsNew[key] === false) {
                    return;
                }
            }
        }
        setValidate(false)
        if (inputs.clinic_name.length > 0) {
            sendForm()
        }
        // sendForm()
    }
    const sendForm = async () => {
//        setNext(!next)
        setIsLoading(true)

        await THISApi.updateAccreditation(inputs, inputSpec)
            .then((res) => {
                completed();
                // console.log('some')
            }).catch(err => console.log(err));

        setIsLoading(false)
    }

    const updateState = (type, value) => {
        setInputs({...inputs, [type]: value})
    }

    const updateStateFileOne = (value, name, error) => {
        setInputs({...inputs, 'files[wait][0]': value, error_board_file_one: error})
        setfileOne(name)
    }
    const updateStateFileTwo = (value, name, error) => {
        setInputs({...inputs, 'files[wait][1]': value, error_board_file_two: error})
        setfileTwo(name)
    }
    const updateStateFileThree = (value, name, error) => {
        setInputs({...inputs, 'files[wait][2]': value, error_board_file_three: error})
        setfileThree(name)
    }
    const updateStateFileFour = (value, name, error) => {
        setInputs({...inputs, 'files[poster][0]': value, error_poster_file_one: error})
        setfileFour(name)
    }
    const updateStateFileFive = (value, name, error) => {
        setInputs({...inputs, 'files[poster][1]': value, error_poster_file_two: error})
        setfileFive(name)
    }
    const updateStateFileSix = (value, name, error) => {
        setInputs({...inputs, 'files[poster][2]': value, error_poster_file_three: error})
        setfileSix(name)
    }
    const updateStateFileSeven = (value, name, error) => {
        setInputs({...inputs, 'files[carrying][0]': value, error_site_file_one: error})
        setfileSeven(name)
    }
    const updateStateFileEight = (value, name, error) => {
        setInputs({...inputs, 'files[carrying][1]': value, error_site_file_two: error})
        setfileEight(name)
    }
    const updateStateFileNine = (value, name, error) => {
        setInputs({...inputs, 'files[carrying][2]': value, error_site_file_three: error})
        setfileNine(name)
    }
    const updateStateFileTen = (value, name, error) => {
        setInputs({...inputs, 'files[towels][0]': value, error_wait_file_one: error})
        setfileTen(name)
    }
    const updateStateFileEleven = (value, name, error) => {
        setInputs({...inputs, 'files[towels][1]': value, error_wait_file_two: error})
        setfileEleven(name)
    }
    const updateStateFileTwelve = (value, name, error) => {
        setInputs({...inputs, 'files[towels][2]': value, error_wait_file_three: error})
        setfileTwelve(name)
    }
    const updateStateFileThirteen = (value, name, error) => {
        setInputs({...inputs, 'files[lock][0]': value, error_screen_file_one: error})
        setfileThirteen(name)
    }
    const updateStateFileFourteen = (value, name, error) => {
        setInputs({...inputs, 'files[lock][1]': value, error_screen_file_two: error})
        setfileFourteen(name)
    }
    const updateStateFileFifteen = (value, name, error) => {
        setInputs({...inputs, 'files[lock][2]': value, error_screen_file_three: error})
        setfileFifteen(name)
    }
    const updateStateFileSixteen = (value, name, error) => {
        setInputs({...inputs, 'files[cabinet_walls][0]': value, error_nav_file_one: error})
        setfileSixteen(name)
    }
    const updateStateFileSeventeen = (value, name, error) => {
        setInputs({...inputs, 'files[cabinet_walls][1]': value, error_nav_file_two: error})
        setfileSeventeen(name)
    }
    const updateStateFileEighteen = (value, name, error) => {
        setInputs({...inputs, 'files[cabinet_walls][2]': value, error_nav_file_three: error})
        setfileEighteen(name)
    }
    const updateStateFileNineteen = (value, name, error) => {
        setInputs({...inputs, 'files[box_walls][0]': value, error_box_file_one: error})
        setfileNineteen(name)
    }
    const updateStateFileTwenty = (value, name, error) => {
        setInputs({...inputs, 'files[box_walls][1]': value, error_box_file_two: error})
        setfileTwenty(name)
    }
    const updateStateFileTwentyOne = (value, name, error) => {
        setInputs({...inputs, 'files[box_walls][2]': value, error_box_file_three: error})
        setfileTwentyOne(name)
    }
    const updateStateFileTwentyTwo = (value, name, error) => {
        setInputs({...inputs, 'files[box][0]': value, error_radius_file_one: error})
        setfileTwentyTwo(name)
    }
    const updateStateFileTwentyThree = (value, name, error) => {
        setInputs({...inputs, 'files[box][1]': value, error_radius_file_two: error})
        setfileTwentyThree(name)
    }
    const updateStateFileTwentyFour = (value, name, error) => {
        setInputs({...inputs, 'files[box][2]': value, error_radius_file_three: error})
        setfileTwentyFour(name)
    }

    function addSpec() {
        setInputSpec([...inputSpec, {
            name: '',
            middle_name: '',
            surname: '',
            position: '',
        }])
    }

    const updateStateSpec = (type, value, index) => {

        // setInputSpec(inputSpec[index][type] = value)
        inputSpec[index][type] = value
    }

    return (
        <div className="Accreditation">
            <div className="Accreditation_content">
                <div className="Accreditation__wrp">
                    {!stepZero ?
                        <div>
                            <div className="Accreditation__name">
                                Заявка на получение знака<br/>
                                «Здесь комфортно кошкам»
                            </div>
                            <TextBlock
                                text={`Подать <span class="text-blue">заявку на получение знака «Здесь комфортно кошкам»</span> для ветеринарной клиники может руководитель клиники (владелец, генеральный директор, главный врач).`}
                            />
                            <TextBlock
                                text={`Для получения знака для вашей клиники, <br/>пожалуйста, выполните два шага:<br/>
                                <span class="text-blue">Шаг 1:</span> 
                                Заполните форму с информацией о вашей клинике
                                и контактами;<br/>
                                <span class="text-blue">Шаг 2:</span> 
                                Предоставьте информацию о всех сотрудниках клиники, прошедших обучение на портале <a href="/" class="text-blue">catfriendly.ru</a>, подтвердите соответствие клиники требованиям к клиникам, комфортным для кошек, и загрузите необходимые фотографии
                                `}
                            />
                            <div className="button_container">
                                <button
                                    type="button"
                                    className="btn btn_step btn_default btn_updating">Вернуться</button>
                                <button
                                    type="button"
                                    className="btn btn_step btn_default btn_violet"
                                    onClick={() => stepOne()}
                                >
                                    Продолжить
                                </button>
                            </div>
                        </div>
                        :
                        <div>
                            {!comleted ?
                                <form action="#" className="Accreditation__form">
                                    <div className="Accreditation__name">
                                        Заявка на получение знака <br/>
                                        «Здесь комфортно кошкам»
                                    </div>
                                    <div className="Accreditation__description">
                                        Для получения знака для вашей клиники, <br/>
                                        пожалуйста, заполните форму из двух шагов.
                                    </div>

                                    <div className="step1">
                                        <div className={!next ? 'Accreditation__flex' : 'Accreditation__flex sticky__gap'}>
                                            <div className={next ? 'commonInfo accInfo active' : 'commonInfo accInfo'}>
                                                <p className="info__icon">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.86957 0.000713581C4.34823 0.0659263 -0.0644992 4.60908 0.000713581 10.1304C0.0659263 15.6518 4.60908 20.0645 10.1304 19.9993C15.6518 19.9341 20.0645 15.3909 19.9993 9.86957C19.9341 4.34823 15.3909 -0.0644991 9.86957 0.000713581ZM10.9782 3.32656C11.9999 3.32656 12.3042 3.91348 12.3042 4.58734C12.3042 5.43511 11.6303 6.21766 10.4782 6.21766C9.52177 6.21766 9.06528 5.73943 9.08702 4.93514C9.06528 4.26128 9.63046 3.32656 10.9782 3.32656ZM8.36968 16.2604C7.67408 16.2604 7.17412 15.8474 7.65234 13.978L8.45663 10.6956C8.58706 10.1739 8.6088 9.95652 8.45663 9.95652C8.23926 9.95652 7.34801 10.3261 6.80458 10.6739L6.45677 10.1087C8.15231 8.69574 10.087 7.86972 10.913 7.86972C11.6086 7.86972 11.7173 8.69574 11.3695 9.95652L10.4565 13.4128C10.3043 14.0214 10.3695 14.2388 10.5217 14.2388C10.7391 14.2388 11.4129 13.978 12.0868 13.4563L12.4781 13.978C10.8478 15.6083 9.06528 16.2604 8.36968 16.2604Z"
                                                            fill="#C6C6C6"/>
                                                    </svg>
                                                </p>
                                                <p className="topInfo">
                                                    Общая информация
                                                </p>
                                            </div>
                                            <div className={next ? 'requirements accInfo active' : "requirements accInfo"}>
                                                <p className="info__icon">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.86957 0.000713581C4.34823 0.0659263 -0.0644992 4.60908 0.000713581 10.1304C0.0659263 15.6518 4.60908 20.0645 10.1304 19.9993C15.6518 19.9341 20.0645 15.3909 19.9993 9.86957C19.9341 4.34823 15.3909 -0.0644991 9.86957 0.000713581ZM10.9782 3.32656C11.9999 3.32656 12.3042 3.91348 12.3042 4.58734C12.3042 5.43511 11.6303 6.21766 10.4782 6.21766C9.52177 6.21766 9.06528 5.73943 9.08702 4.93514C9.06528 4.26128 9.63046 3.32656 10.9782 3.32656ZM8.36968 16.2604C7.67408 16.2604 7.17412 15.8474 7.65234 13.978L8.45663 10.6956C8.58706 10.1739 8.6088 9.95652 8.45663 9.95652C8.23926 9.95652 7.34801 10.3261 6.80458 10.6739L6.45677 10.1087C8.15231 8.69574 10.087 7.86972 10.913 7.86972C11.6086 7.86972 11.7173 8.69574 11.3695 9.95652L10.4565 13.4128C10.3043 14.0214 10.3695 14.2388 10.5217 14.2388C10.7391 14.2388 11.4129 13.978 12.0868 13.4563L12.4781 13.978C10.8478 15.6083 9.06528 16.2604 8.36968 16.2604Z"
                                                            fill="#C6C6C6"/>
                                                    </svg>
                                                </p>
                                                <p className="topInfo">
                                                    Соответствия требованиям<br/>
                                                    клиники Cat Friendly
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            !next ?
                                                <div className="step1__inputs">
                                                    <div className="inputsBlock">
                                                        <div className="inputsBlock__title">Информация о нас</div>
                                                        <div className="inputFull">
                                                            <Inputs
                                                                name={'clinic_name'}
                                                                placeholder={'Название ветеринарной клиники'}
                                                                type={'text'}
                                                                className={validate && inputs.clinic_name.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                required={''}
                                                                key={'clinic_name'}
                                                                value={inputs.clinic_name}
                                                            />
                                                        </div>
                                                        <div className="inputHalf">
                                                            <Inputs
                                                                name={'clinic_site'}
                                                                placeholder={'Сайт клиники'}
                                                                type={'text'}
                                                                className={validate && inputs.clinic_site.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                required={''}
                                                                key={'clinic_site'}
                                                                value={inputs.clinic_site}
                                                            />
                                                        </div>
                                                        <div className="inputHalf">
                                                            <Inputs
                                                                name={'clinic_phone'}
                                                                placeholder={'Телефон клиники'}
                                                                type={'tel'}
                                                                className={validate && inputs.clinic_phone.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                mask={'+7 (999) 999-99-99'}
                                                                required={''}
                                                                key={'clinic_phone'}
                                                                value={inputs.clinic_phone}
                                                            />
                                                        </div>
                                                        <div className="inputHalf">
                                                            <Inputs
                                                                name={'clinic_city'}
                                                                placeholder={'Город'}
                                                                type={'text'}
                                                                className={validate && inputs.clinic_city.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                required={''}
                                                                key={'clinic_city'}
                                                                value={inputs.clinic_city}
                                                            />
                                                        </div>
                                                        <div className="inputHalf">
                                                            <Inputs
                                                                name={'clinic_address'}
                                                                placeholder={'Адрес'}
                                                                type={'text'}
                                                                className={validate && inputs.clinic_address.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                required={''}
                                                                key={'clinic_address'}
                                                                value={inputs.clinic_address}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="inputsBlock">
                                                        <div className="inputsBlock__title">Как с нами связаться</div>

                                                        <div className="inputHalf">
                                                            <Inputs
                                                                name={'contact_surname'}
                                                                placeholder={'Фамилия'}
                                                                type={'text'}
                                                                className={validate && inputs.contact_surname.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                required={''}
                                                                key={'contact_surname'}
                                                                value={inputs.contact_surname}
                                                            />
                                                        </div>
                                                        <div className="inputHalf">
                                                            <Inputs
                                                                name={'contact_name'}
                                                                placeholder={'Имя'}
                                                                type={'text'}
                                                                className={validate && inputs.contact_name.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                required={''}
                                                                key={'contact_name'}
                                                                value={inputs.contact_name}
                                                            />
                                                        </div>
                                                        <div className="inputHalf">
                                                            <Inputs
                                                                name={'contact_middle_name'}
                                                                placeholder={'Отчество'}
                                                                type={'text'}
                                                                className={validate && inputs.contact_middle_name.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                required={''}
                                                                key={'contact_middle_name'}
                                                                value={inputs.contact_middle_name}
                                                            />
                                                        </div>
                                                        <div className="inputHalf">
                                                            <Inputs
                                                                name={'contact_phone'}
                                                                placeholder={'Телефон'}
                                                                type={'tel'}
                                                                className={validate && inputs.contact_phone.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                mask={'+7 (999) 999-99-99'}
                                                                required={''}
                                                                key={'contact_phone'}
                                                                value={inputs.contact_phone}
                                                            />
                                                        </div>
                                                        <div className="inputFull">
                                                            <InputSelect
                                                                name={'contact_position'}
                                                                placeholder={'Должность'}
                                                                type={'text'}
                                                                className={validate && inputs.contact_position.length <= 0 && 'error'}
                                                                onChange={updateState}
                                                                required={''}
                                                                key={'contact_position'}
                                                                data={['Владелец клиники', 'Генеральный директор', 'Главный врач']}
                                                            />

                                                            {/*<Inputs*/}
                                                            {/*    name={'contact_position'}*/}
                                                            {/*    placeholder={'Должность'}*/}
                                                            {/*    type={'text'}*/}
                                                            {/*    className={validate && inputs.contact_position.length <= 0 && 'error'}*/}
                                                            {/*    onChange={updateState}*/}
                                                            {/*    required={'required'}*/}
                                                            {/*    key={'contact_position'}*/}
                                                            {/*    value={inputs.contact_position}*/}
                                                            {/*/>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="step2__inputs">

                                                    <StickyBlock>
                                                        <div className="Accreditation_status">
                                                            <div className={'Accreditation_status_number Accreditation_status_active'}><a className="Accreditation_status_nav" href="#nav_one">1</a></div>
                                                            <div className={'Accreditation_status_path Accreditation_status_active'}/>
                                                            <div
                                                                className={
                                                                    'Accreditation_status_number ' +
                                                                    (inputs['files[wait][0]'] && inputs['files[poster][0]'] && inputs['files[carrying][0]'] && inputs['files[towels][0]'] && inputs.principle_separate && inputs.principle_landmarks && inputs.principle_carrying && inputs.principle_towels && ' Accreditation_status_active ') +
                                                                    (((inputs.error_board_file_one || inputs.error_poster_file_one || inputs.error_site_file_one || inputs.error_wait_file_one) ||
                                                                        (validate && (inputs['files[wait][0]'].length <= 0 || inputs['files[poster][0]'].length <= 0 || inputs['files[carrying][0]'].length <= 0 || inputs['files[towels][0]'].length <= 0)) ||
                                                                        (validate && !inputs.principle_separate) || (validate && !inputs.principle_landmarks) || (validate && !inputs.principle_carrying) || (validate && !inputs.principle_towels)) && ' Accreditation_status_error')
                                                                }
                                                            >
                                                                <a className="Accreditation_status_nav" href="#nav_two">2</a>
                                                            </div>
                                                            <div className={'Accreditation_status_path ' + (inputs['files[wait][0]'] && inputs['files[poster][0]'] && inputs['files[carrying][0]'] && inputs['files[towels][0]'] && inputs.principle_separate && inputs.principle_landmarks && inputs.principle_carrying && inputs.principle_towels && ' Accreditation_status_active ')}/>
                                                            <div
                                                                className={
                                                                    'Accreditation_status_number ' +
                                                                    (inputs['files[lock][0]'] && inputs['files[cabinet_walls][0]'] && inputs.principle_lock && inputs.principle_no_transparent_walls && ' Accreditation_status_active ') +
                                                                    (((inputs.error_screen_file_one || inputs.error_nav_file_one) ||
                                                                        (validate && (inputs['files[lock][0]'].length <= 0 || inputs['files[cabinet_walls][0]'].length <= 0)) || (validate && !inputs.principle_lock) || (validate && !inputs.principle_no_transparent_walls)) && ' Accreditation_status_error')
                                                                }
                                                            >
                                                                <a className="Accreditation_status_nav" href="#nav_three">3</a>
                                                            </div>
                                                            <div className={'Accreditation_status_path ' + (inputs['files[lock][0]'] && inputs['files[cabinet_walls][0]'] && inputs.principle_lock && inputs.principle_no_transparent_walls && ' Accreditation_status_active ')}/>
                                                            <div
                                                                className={
                                                                    'Accreditation_status_number ' +
                                                                    (inputs['files[box_walls][0]'] && inputs['files[box][0]'] && inputs.principle_hard_box && inputs.principle_distance && ' Accreditation_status_active ') +
                                                                    (((inputs.error_box_file_one || inputs.error_radius_file_one) ||
                                                                        (validate && (inputs['files[box_walls][0]'].length <= 0 || inputs['files[box][0]'].length <= 0)) || (validate && !inputs.principle_hard_box) || (validate && !inputs.principle_distance)) && ' Accreditation_status_error')
                                                                }
                                                            >
                                                                <a className="Accreditation_status_nav" href="#nav_four">4</a>
                                                            </div>
                                                            <div className={'Accreditation_status_path ' + (inputs['files[box_walls][0]'] && inputs['files[box][0]'] && inputs.principle_hard_box && inputs.principle_distance && ' Accreditation_status_active ')}/>
                                                            <div
                                                                className={
                                                                    'Accreditation_status_number ' +
                                                                    (inputs.principle_onychectomy && inputs.confirm_agreement &&' Accreditation_status_active ') +
                                                                    (validate && !inputs.principle_onychectomy && !inputs.confirm_agreement && ' Accreditation_status_error')
                                                                }
                                                            >
                                                                <a className="Accreditation_status_nav" href="#nav_five">5</a>
                                                            </div>
                                                        </div>
                                                    </StickyBlock>
                                                    <div className="inputsBlock">
                                                        <span id="nav_one"></span>
                                                        <div className="inputsBlock__title">
                                                            <div className="inputsBlock__title__point">1</div>
                                                            <div className="inputsBlock__name">Укажите всех сотрудников клиники, получивших сертификат <span className="inputsBlock__title__text_blue">Cпециалист Cat Friendly</span> на портале catfriendly.ru.</div>
                                                        </div>
                                                    </div>
                                                    <div className="Accreditation_condition">
                                                        <div className="Accreditation_condition_flex">
                                                            <div className="Accreditation_condition_el Accreditation_condition_el--left">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="20" height="20" rx="10" fill="#4147DA"/>
                                                                    <path d="M8.83 11.706C8.83 12.294 9.264 12.658 9.838 12.658C10.398 12.658 10.846 12.28 10.846 11.706V6.316C10.846 5.742 10.398 5.364 9.838 5.364C9.264 5.364 8.83 5.728 8.83 6.316V11.706ZM9.838 15.668C10.44 15.668 10.93 15.178 10.93 14.576C10.93 13.974 10.44 13.484 9.838 13.484C9.236 13.484 8.746 13.974 8.746 14.576C8.746 15.178 9.236 15.668 9.838 15.668Z" fill="#EFEFEF"/>
                                                                </svg>
                                                            </div>
                                                            <div className="Accreditation_condition_el Accreditation_condition_el--right">
                                                                <div className="Accreditation_condition_text">
                                                                    Для получения знака хотя бы один специалист клиники должен обучиться по всем темам (100%) раздела «Общий курс Cat Friendly», состоящего из 15 уроков, и пройти обучение (минимум на 30%) в разделе курса «Медицина кошек», правильно ответив на 70% вопросов тестов в каждом уроке.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="inputsBlock">
                                                        { inputSpec.map((item, i) =>
                                                        <div className="inputsBlock" key={i}>
                                                            <div className="inputsBlock__person">Специалист Cat Friendly:</div>
                                                            <div className="inputHalf">
                                                                <Inputs
                                                                    name={'surname'}
                                                                    placeholder={'Фамилия'}
                                                                    type={'text'}
                                                                    className={validate && inputSpec[i].surname.length <= 0 && 'error'}
                                                                    onChange={updateStateSpec}
                                                                    required={''}
                                                                    key={'surname'}
                                                                    value={inputSpec.surname}
                                                                    index={i}
                                                                />
                                                            </div>
                                                            <div className="inputHalf">
                                                                <Inputs
                                                                    name={'name'}
                                                                    placeholder={'Имя'}
                                                                    type={'text'}
                                                                    className={validate && inputSpec[i].name.length <= 0 && 'error'}
                                                                    onChange={updateStateSpec}
                                                                    required={''}
                                                                    key={'name'}
                                                                    value={inputSpec.name}
                                                                    index={i}
                                                                />
                                                            </div>
                                                            <div className="inputHalf">
                                                                <Inputs
                                                                    name={'middle_name'}
                                                                    placeholder={'Отчество'}
                                                                    type={'text'}
                                                                    className={validate && inputSpec[i].middle_name.length <= 0 && 'error'}
                                                                    onChange={updateStateSpec}
                                                                    required={''}
                                                                    key={'middle_name'}
                                                                    value={inputSpec.middle_name}
                                                                    index={i}
                                                                />
                                                            </div>
                                                            <div className="inputHalf">
                                                                <Inputs
                                                                    name={'position'}
                                                                    placeholder={'Должность'}
                                                                    type={'text'}
                                                                    className={validate && inputSpec[i].position.length <= 0 && 'error'}
                                                                    onChange={updateStateSpec}
                                                                    required={''}
                                                                    key={'position'}
                                                                    value={inputSpec.position}
                                                                    index={i}
                                                                />
                                                            </div>
                                                        </div>
                                                        )}
                                                        <button
                                                            onClick={addSpec}
                                                            type="button"
                                                            className="btn btn_step btn_default btn_more">ДОБАВИТЬ ЕЩЕ</button>
                                                    </div>

                                                    <div className="Accreditation__description-new">
                                                        Подтвердите соответствие требованиям клиники Cat Friendly <br/>
                                                        и заполните данные ниже:
                                                    </div>
                                                    <div className="inputsBlock">
                                                        <span id="nav_two"></span>
                                                        <div className="inputsBlock__title">
                                                            <div className="inputsBlock__title__point">2</div>
                                                            <div className="inputsBlock__title__text padding-text">Зал ожидания.</div>
                                                        </div>
                                                        {/*<div className="inputsBlock__desc">Отметьте пункты ниже галочкой.</div>*/}
                                                        <div className="agree inputFull">
                                                            <Checkbox
                                                                active={inputs.principle_separate}
                                                                error={validate && !inputs.principle_separate}
                                                                onClick={() => setInputs({
                                                                    ...inputs,
                                                                    principle_separate: !inputs.principle_separate
                                                                })}
                                                            />
                                                            <span className="link-no">Наличие отдельной комнаты или зоны ожидания для владельцев с кошками, разделённой перегородками, предотвращающими визуальный контакт между собаками и кошками.</span>
                                                        </div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_board_file_one'}
                                                                name={'files[wait][0]'}
                                                                placeholder={fileOne ? fileOne : 'Добавить еще фото'}
                                                                updateFile={updateStateFileOne}
                                                                key={'info_board_file_one'}
                                                                className={validate && inputs['files[wait][0]'].length<= 0 && 'error'}
                                                            />
                                                            <InputFileNew
                                                                id={'info_board_file_two'}
                                                                name={'files[wait][1]'}
                                                                placeholder={fileTwo ? fileTwo : 'Добавить еще фото'}
                                                                updateFile={updateStateFileTwo}
                                                                key={'info_board_file_two'}
                                                                className=""
                                                            />
                                                            <InputFileNew
                                                                id={'info_board_file_three'}
                                                                name={'files[wait][2]'}
                                                                placeholder={fileThree ? fileThree : 'Добавить еще фото'}
                                                                updateFile={updateStateFileThree}
                                                                key={'info_board_file_three'}
                                                                className=""
                                                            />
                                                        </div>
                                                        <div className="agree inputFull">
                                                            <Checkbox
                                                                active={inputs.principle_landmarks}
                                                                error={validate && !inputs.principle_landmarks}
                                                                onClick={() => setInputs({
                                                                    ...inputs,
                                                                    principle_landmarks: !inputs.principle_landmarks
                                                                })}
                                                            />
                                                            <span className="link-no">Наличие простых и понятных ориентиров для владельцев, где они могут разместиться с питомцем.</span>
                                                        </div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_poster_file_one'}
                                                                name={'files[poster][0]'}
                                                                placeholder={fileFour ? fileFour : 'Добавить еще фото'}
                                                                updateFile={updateStateFileFour}
                                                                key={'info_poster_file_one'}
                                                                className={validate && inputs['files[poster][0]'].length<= 0 && 'error'}
                                                            />
                                                            <InputFileNew
                                                                id={'info_poster_file_two'}
                                                                name={'files[poster][1]'}
                                                                placeholder={fileFive ? fileFive : 'Добавить еще фото'}
                                                                updateFile={updateStateFileFive}
                                                                key={'info_poster_file_two'}
                                                                className=""
                                                            />
                                                            <InputFileNew
                                                                id={'info_poster_file_three'}
                                                                name={'files[poster][2]'}
                                                                placeholder={fileSix ? fileSix : 'Добавить еще фото'}
                                                                updateFile={updateStateFileSix}
                                                                key={'info_poster_file_three'}
                                                                className=""
                                                            />
                                                        </div>
                                                        <div className="agree inputFull">
                                                            <Checkbox
                                                                active={inputs.principle_carrying}
                                                                error={validate && !inputs.principle_carrying}
                                                                onClick={() => setInputs({
                                                                    ...inputs,
                                                                    principle_carrying: !inputs.principle_carrying
                                                                })}
                                                            />
                                                            <span className="link-no">Наличие в зале ожидания оборудования для размещения кошачьих переносок выше уровня пола (специальные столики, тумбочки или дополнительное место рядом с владельцем).</span>
                                                        </div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_site_file_one'}
                                                                name={'files[carrying][0]'}
                                                                placeholder={fileSeven ? fileSeven : 'Добавить еще фото'}
                                                                updateFile={updateStateFileSeven}
                                                                key={'info_site_file_one'}
                                                                className={validate && inputs['files[carrying][0]'].length<= 0 && 'error'}
                                                            />
                                                            <InputFileNew
                                                                id={'info_site_file_two'}
                                                                name={'files[carrying][1]'}
                                                                placeholder={fileEight ? fileEight : 'Добавить еще фото'}
                                                                updateFile={updateStateFileEight}
                                                                key={'info_site_file_two'}
                                                                className=""
                                                            />
                                                            <InputFileNew
                                                                id={'info_site_file_three'}
                                                                name={'files[carrying][2]'}
                                                                placeholder={fileNine ? fileNine : 'Добавить еще фото'}
                                                                updateFile={updateStateFileNine}
                                                                key={'info_site_file_three'}
                                                                className=""
                                                            />
                                                        </div>
                                                        <div className="agree inputFull gap">
                                                            <Checkbox
                                                                active={inputs.principle_towels}
                                                                error={validate && !inputs.principle_towels}
                                                                onClick={() => setInputs({
                                                                    ...inputs,
                                                                    principle_towels: !inputs.principle_towels
                                                                })}
                                                            />
                                                            <span className="link-no">В доступе должны быть чистые покрывала, полотенца и пр., которыми можно укрыть переноску с кошкой.</span>
                                                        </div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_wait_file_one'}
                                                                name={'files[towels][0]'}
                                                                placeholder={fileTen ? fileTen : 'Добавить еще фото'}
                                                                updateFile={updateStateFileTen}
                                                                key={'info_wait_file_one'}
                                                                className={validate && inputs['files[towels][0]'].length<= 0 && 'error'}
                                                            />
                                                            <InputFileNew
                                                                id={'info_wait_file_two'}
                                                                name={'files[towels][1]'}
                                                                placeholder={fileEleven ? fileEleven : 'Добавить еще фото'}
                                                                updateFile={updateStateFileEleven}
                                                                key={'info_wait_file_two'}
                                                                className=""
                                                            />
                                                            <InputFileNew
                                                                id={'info_wait_file_three'}
                                                                name={'files[towels][2]'}
                                                                placeholder={fileTwelve ? fileTwelve : 'Добавить еще фото'}
                                                                updateFile={updateStateFileTwelve}
                                                                key={'info_wait_file_three'}
                                                                className=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="inputsBlock">
                                                        <span id="nav_three"></span>
                                                        <div className="inputsBlock__title">
                                                            <div className="inputsBlock__title__point">3</div>
                                                            <div className="inputsBlock__title__text padding-text">Кабинет приема.</div>
                                                        </div>
                                                        <div className="agree inputFull">
                                                            <Checkbox
                                                                active={inputs.principle_lock}
                                                                error={validate && !inputs.principle_lock}
                                                                onClick={() => setInputs({
                                                                    ...inputs,
                                                                    principle_lock: !inputs.principle_lock
                                                                })}
                                                            />
                                                            <span className="link-no">Кабинет приема должен полностью закрываться на замок для обеспечения конфиденциальности и безопасности кошки и ее владельца.</span>
                                                        </div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_screen_file_one'}
                                                                name={'files[lock][0]'}
                                                                placeholder={fileThirteen ? fileThirteen : 'Добавить еще фото'}
                                                                updateFile={updateStateFileThirteen}
                                                                key={'info_screen_file_one'}
                                                                className={validate && inputs['files[lock][0]'].length<= 0 && 'error'}
                                                            />
                                                            <InputFileNew
                                                                id={'info_screen_file_two'}
                                                                name={'files[lock][1]'}
                                                                placeholder={fileFourteen ? fileFourteen : 'Добавить еще фото'}
                                                                updateFile={updateStateFileFourteen}
                                                                key={'info_screen_file_two'}
                                                                className=""
                                                            />
                                                            <InputFileNew
                                                                id={'info_screen_file_three'}
                                                                name={'files[lock][2]'}
                                                                placeholder={fileFifteen ? fileFifteen : 'Добавить еще фото'}
                                                                updateFile={updateStateFileFifteen}
                                                                key={'info_screen_file_three'}
                                                                className=""
                                                            />
                                                        </div>
                                                        <div className="agree inputFull">
                                                            <Checkbox
                                                                active={inputs.principle_no_transparent_walls}
                                                                error={validate && !inputs.principle_no_transparent_walls}
                                                                onClick={() => setInputs({
                                                                    ...inputs,
                                                                    principle_no_transparent_walls: !inputs.principle_no_transparent_walls
                                                                })}
                                                            />
                                                            <span className="link-no">Стены кабинета не должны быть прозрачными (возможно использование специальных пленок для стекол).</span>
                                                        </div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_nav_file_one'}
                                                                name={'files[cabinet_walls][0]'}
                                                                placeholder={fileSixteen ? fileSixteen : 'Добавить еще фото'}
                                                                updateFile={updateStateFileSixteen}
                                                                key={'info_nav_file_one'}
                                                                className={validate && inputs['files[cabinet_walls][0]'].length<= 0 && 'error'}
                                                            />
                                                            <InputFileNew
                                                                id={'info_nav_file_two'}
                                                                name={'files[cabinet_walls][1]'}
                                                                placeholder={fileSeventeen ? fileSeventeen : 'Добавить еще фото'}
                                                                updateFile={updateStateFileSeventeen}
                                                                key={'info_nav_file_two'}
                                                                className=""
                                                            />
                                                            <InputFileNew
                                                                id={'info_nav_file_three'}
                                                                name={'files[cabinet_walls][2]'}
                                                                placeholder={fileEighteen ? fileEighteen : 'Добавить еще фото'}
                                                                updateFile={updateStateFileEighteen}
                                                                key={'info_nav_file_three'}
                                                                className=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="inputsBlock">
                                                        <span id="nav_four"></span>
                                                        <div className="inputsBlock__title">
                                                            <div className="inputsBlock__title__point">4</div>
                                                            <div className="inputsBlock__title__text">Стационар —<br/> <span className="inputsBlock__title__subtitle">(или при его отсутствии - помещение длительного лечения пациентов и место пробуждения пациентов после операций).</span></div>
                                                        </div>
                                                        <div className="agree inputFull">
                                                            <Checkbox
                                                                active={inputs.principle_hard_box}
                                                                error={validate && !inputs.principle_hard_box}
                                                                onClick={() => setInputs({
                                                                    ...inputs,
                                                                    principle_hard_box: !inputs.principle_hard_box
                                                                })}
                                                            />
                                                            <span className="link-no">
                                                                Стены и потолок клеток/боксов должны быть сделаны из твердого материала и быть непрозрачными. <br/>
                                                                <i className="mini-text">
                                                                    Если стены или потолок представляют собой сетку или решетку, необходимо использовать специальные перегородки, предотвращающие визуальный контакт и распространение нежелательных запахов.
                                                                </i>
                                                            </span>
                                                        </div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_box_file_one'}
                                                                name={'files[box_walls][0]'}
                                                                placeholder={fileNineteen ? fileNineteen : 'Добавить еще фото'}
                                                                updateFile={updateStateFileNineteen}
                                                                key={'info_box_file_one'}
                                                                className={validate && inputs['files[box_walls][0]'].length<= 0 && 'error'}
                                                            />
                                                            <InputFileNew
                                                                id={'info_box_file_two'}
                                                                name={'files[box_walls][1]'}
                                                                placeholder={fileTwenty ? fileTwenty : 'Добавить еще фото'}
                                                                updateFile={updateStateFileTwenty}
                                                                key={'info_box_file_two'}
                                                                className=""
                                                            />
                                                            <InputFileNew
                                                                id={'info_box_file_three'}
                                                                name={'files[box_walls][2]'}
                                                                placeholder={fileTwentyOne ? fileTwentyOne : 'Добавить еще фото'}
                                                                updateFile={updateStateFileTwentyOne}
                                                                key={'info_box_file_three'}
                                                                className=""
                                                            />
                                                        </div>
                                                        <div className="agree inputFull">
                                                            <Checkbox
                                                                active={inputs.principle_distance}
                                                                error={validate && !inputs.principle_distance}
                                                                onClick={() => setInputs({
                                                                    ...inputs,
                                                                    principle_distance: !inputs.principle_distance
                                                                })}
                                                            />
                                                            <span className="link-no">
                                                                Клетки не должны быть обращены друг к другу или не примыкают друг к другу под углом менее 180°. <br/>
                                                                <i className="mini-text">
                                                                    Если клетки расположены таким образом, что одна клетка обращена к другой клетке (прямо или под углом), между клетками должен быть установлен сплошной непрозрачный барьер.
                                                                </i>
                                                            </span>
                                                        </div>
                                                        <div className="inputFull inputFull-file">
                                                            <InputFileNew
                                                                id={'info_radius_file_one'}
                                                                name={'files[box][0]'}
                                                                placeholder={fileTwentyTwo? fileTwentyTwo : 'Добавить еще фото'}
                                                                updateFile={updateStateFileTwentyTwo}
                                                                key={'info_radius_file_one'}
                                                                className={validate && inputs['files[box][0]'].length<= 0 && 'error'}
                                                            />
                                                            <InputFileNew
                                                                id={'info_radius_file_two'}
                                                                name={'files[box][1]'}
                                                                placeholder={fileTwentyThree ? fileTwentyThree: 'Добавить еще фото'}
                                                                updateFile={updateStateFileTwentyThree}
                                                                key={'info_radius_file_two'}
                                                                className=""
                                                            />
                                                            <InputFileNew
                                                                id={'info_radius_file_three'}
                                                                name={'files[box][2]'}
                                                                placeholder={fileTwentyFour ? fileTwentyFour : 'Добавить еще фото'}
                                                                updateFile={updateStateFileTwentyFour}
                                                                key={'info_radius_file_three'}
                                                                className=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="inputsBlock">
                                                        <span id="nav_five"></span>
                                                        <div className="inputsBlock__title">
                                                            <div className="inputsBlock__title__point">5</div>
                                                            <div className="inputsBlock__title__text padding-text">Оказываемые услуги.</div>
                                                        </div>
                                                        <div className="inputFull">
                                                            <div className="agree inputFull">
                                                                <Checkbox
                                                                    active={inputs.principle_onychectomy}
                                                                    error={!inputs.principle_onychectomy}
                                                                    onClick={() => setInputs({
                                                                        ...inputs,
                                                                        principle_onychectomy: !inputs.principle_onychectomy
                                                                    })}
                                                                />
                                                                <span className="link-no">Отсутствие в списке выполняемых клиникой операций <span className="inputsBlock__title_red">онихэктомии</span><br/>
                                                                    <span className="checkdoubleDesc">(операции «Мягкие лапы»).</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="agree inputFull">
                                                        <Checkbox
                                                            active={inputs.confirm_agreement}
                                                            error={validate && !inputs.confirm_agreement}
                                                            onClick={() => setInputs({
                                                                ...inputs,
                                                                confirm_agreement: !inputs.confirm_agreement
                                                            })}
                                                        />
                                                        <a href="/sign_rules.pdf"
                                                           target="_blank"
                                                           rel="noopener noreferrer"
                                                        >
                                                            <span className="link-no">Подтверждаю ознакомление с </span><span className="link-yes">Правилами выдачи знака «Здесь комфортно кошкам»</span>
                                                        </a>
                                                    </div>
                                                    {/*<div className="inputsBlock">*/}
                                                    {/*    <div className="inputsBlock__title">*/}
                                                    {/*        <div className="inputsBlock__title__point">6</div>*/}
                                                    {/*        <div className="inputsBlock__title__text">Подтверждаю отсутствие в клинике услуг по проведению недопустимых операций. </div>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="inputsBlock__desc">Отметьте пункты ниже галочкой.</div>*/}
                                                    {/*    */}
                                                    {/*</div>*/}
                                                </div>
                                        }
                                    </div>

                                    {inputs.error_board_file_one || inputs.error_board_file_two || inputs.error_board_file_three || inputs.error_poster_file_one || inputs.error_poster_file_two || inputs.error_poster_file_three || inputs.error_site_file_one || inputs.error_site_file_two || inputs.error_site_file_three || inputs.error_wait_file_one || inputs.error_wait_file_two || inputs.error_wait_file_three || inputs.error_screen_file_one || inputs.error_screen_file_two || inputs.error_screen_file_three || inputs.error_nav_file_one || inputs.error_nav_file_two || inputs.error_nav_file_three || inputs.error_box_file_one || inputs.error_box_file_two || inputs.error_box_file_three || inputs.error_radius_file_one || inputs.error_radius_file_two || inputs.error_radius_file_three
                                        ?
                                        <div className="Accreditation_error">
                                            <svg className="Accreditation_error_icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="10" cy="10" r="10" fill="#CC3333"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.23431 6.23431C6.54673 5.9219 7.05327 5.9219 7.36569 6.23431L10 8.86863L12.6343 6.23431C12.9467 5.9219 13.4533 5.9219 13.7657 6.23431C14.0781 6.54673 14.0781 7.05327 13.7657 7.36569L11.1314 10L13.7657 12.6343C14.0781 12.9467 14.0781 13.4533 13.7657 13.7657C13.4533 14.0781 12.9467 14.0781 12.6343 13.7657L10 11.1314L7.36569 13.7657C7.05327 14.0781 6.54673 14.0781 6.23431 13.7657C5.9219 13.4533 5.9219 12.9467 6.23431 12.6343L8.86863 10L6.23431 7.36569C5.9219 7.05327 5.9219 6.54673 6.23431 6.23431Z" fill="white"/>
                                            </svg>
                                            Ошибка. Файл должен иметь формат jpg, png или svg.<br/>
                                            Размер файла не больше 2 мб.
                                        </div>
                                        :
                                        <div className="infoInputs">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.86957 0.000713581C4.34823 0.0659263 -0.0644992 4.60908 0.000713581 10.1304C0.0659263 15.6518 4.60908 20.0645 10.1304 19.9993C15.6518 19.9341 20.0645 15.3909 19.9993 9.86957C19.9341 4.34823 15.3909 -0.0644991 9.86957 0.000713581ZM10.9782 3.32656C11.9999 3.32656 12.3042 3.91348 12.3042 4.58734C12.3042 5.43511 11.6303 6.21766 10.4782 6.21766C9.52177 6.21766 9.06528 5.73943 9.08702 4.93514C9.06528 4.26128 9.63046 3.32656 10.9782 3.32656ZM8.36968 16.2604C7.67408 16.2604 7.17412 15.8474 7.65234 13.978L8.45663 10.6956C8.58706 10.1739 8.6088 9.95652 8.45663 9.95652C8.23926 9.95652 7.34801 10.3261 6.80458 10.6739L6.45677 10.1087C8.15231 8.69574 10.087 7.86972 10.913 7.86972C11.6086 7.86972 11.7173 8.69574 11.3695 9.95652L10.4565 13.4128C10.3043 14.0214 10.3695 14.2388 10.5217 14.2388C10.7391 14.2388 11.4129 13.978 12.0868 13.4563L12.4781 13.978C10.8478 15.6083 9.06528 16.2604 8.36968 16.2604Z"
                                                    fill="#C6C6C6"/>
                                            </svg>
                                            Все поля обязательны для заполнения
                                        </div>
                                    }

                                    <div className="button_container">
                                        <button
                                            onClick={() => !next ? stepOne() : setNext(!next)}
                                            type="button"
                                            className="btn btn_step btn_default">Вернуться
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn_step btn_default btn_violet fright"
                                            onClick={() => !next ? validateStepOne() : validateStepTwo()}
                                        >
                                            {!next ? 'Далее' : isLoading
                                                ? <svg className="video_loading" width="100%" height="100%" viewBox="0 0 128 35">
                                                    <g>
                                                        <circle fill="#FFF" cx="17.5" cy="17.5" r="17.5"/>
                                                        <animate attributeName="opacity" dur="2700ms" begin="0s"
                                                                 repeatCount="indefinite" keyTimes="0;0.167;0.5;0.668;1"
                                                                 values="0;1;1;0;0"/>
                                                    </g>
                                                    <g>
                                                        <circle fill="#FFF" cx="110.5" cy="17.5" r="17.5"/>
                                                        <animate attributeName="opacity" dur="2700ms" begin="0s"
                                                                 repeatCount="indefinite" keyTimes="0;0.334;0.5;0.835;1"
                                                                 values="0;0;1;1;0"/>
                                                    </g>
                                                    <g>
                                                        <circle fill="#FFF" cx="64" cy="17.5" r="17.5"/>
                                                        <animate attributeName="opacity" dur="2700ms" begin="0s"
                                                                 repeatCount="indefinite" keyTimes="0;0.167;0.334;0.668;0.835;1"
                                                                 values="0;0;1;1;0;0"/>
                                                    </g>
                                                </svg> : 'Отправить'}
                                        </button>
                                    </div>
                                </form>
                                :<div className="complet">
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clip-rule="evenodd"
                                              d="M32.6861 17.2725C33.0879 17.6514 33.1064 18.2843 32.7275 18.6861L21.4104 30.6861C21.2215 30.8864 20.9583 31 20.6829 31C20.4076 31 20.1444 30.8864 19.9554 30.6861L15.2725 25.7206C14.8936 25.3188 14.9121 24.6859 15.3139 24.307C15.7157 23.9281 16.3486 23.9466 16.7275 24.3484L20.6829 28.5425L31.2725 17.3139C31.6514 16.9121 32.2843 16.8936 32.6861 17.2725Z"
                                              fill="#4DD06A"/>
                                        <rect x="1" y="1" width="46" height="46" rx="23" stroke="#4DD06A" stroke-width="2"/>
                                    </svg>
                                    <div className="complet__title">
                                        Спасибо!<br/>
                                        Мы приняли Вашу заявку.
                                    </div>
                                    <div className="complet__desc">
                                        Ваша заявка будет рассмотрена в течение месяца.
                                    </div>
                                    <div className="complet__mess">
                                        Через 5 секунд вы перейдете обратно на <a href="/">портал</a>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default Accreditation